import { InfoOutlined } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { useSelectedAccreditationQuery } from "../../generated/graphql"
import useAnchor from "../../hooks/useAnchor"
import { FormatDate } from "../FormatDate/FormatDate"
import Show from "../Show/Show"
import ShowMoreInfoPopover from "../ShowMoreInfoPopover/ShowMoreInfoPopover"
import styles from './ShowSelectedAccreditation.module.scss'

interface ShowSelectedAccreditationProps {
    startDate?: any
    endDate?: any
    concessionOrganization?: any
    accreditationType?: any
}
export default function ShowSelectedAccreditation(props: ShowSelectedAccreditationProps) {

    const { concessionOrganization, startDate, endDate, accreditationType } = props
    const [anchor, setAnchor] = useAnchor()

    const content = [
        "Não existindo uma acreditação que cubra as datas do subcontrato dá-se início a um processo de acreditação mediado para a entidade subcontratada selecionada.",
        "Após a submissão da documentação por parte da entidade subcontratada, irá receber uma notificação para validar a documentação e solicitar a acreditação à concessão."
    ]

    const { data } = useSelectedAccreditationQuery({
        variables: {
            input: {
                concessionOrganization: concessionOrganization?.id,
                concessionOrganizationAccreditationType: accreditationType?.id,
                startDate: startDate,
                endDate: endDate
            }
        }
    })

    if (data?.selectedAccreditation === null) {
        return (
            <div className={styles.NoAccreditation}>
                <Typography variant="body1" component="span">
                    Não existe, atualmente, nenhuma acreditação <b>aprovada para a entidade e tipo de acreditação selecionada</b>
                </Typography>
                <IconButton onClick={(event: any) => setAnchor(event.currentTarget)}>
                    <InfoOutlined style={{ fontSize: 20 }} />
                </IconButton>
                <ShowMoreInfoPopover anchor={anchor} setAnchor={setAnchor} content={content} />
            </div >
        )
    }

    return (
        <Show on={!!data?.selectedAccreditation?.id}>
            <div className={styles.AccreditationFound}>
                <Typography variant="body1">
                    Foi encontrada uma acreditação para a entidade selecionada
                </Typography>
                <Typography variant="body1">
                    <b>Início Acreditação:</b> <FormatDate date={data?.selectedAccreditation?.startDate as unknown as Date} />
                </Typography>
                <Typography variant="body1">
                    <b>Fim Acreditação:</b>  <FormatDate date={data?.selectedAccreditation?.endDate as unknown as Date} />
                </Typography>
                <Typography variant="body1">
                    <b>Entidade Mediadora:</b> {data?.selectedAccreditation?.mediatingOrganization?.organization?.name}
                </Typography>
            </div>
        </Show>
    )
}
