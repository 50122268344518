import { Grid } from "@mui/material"
import { FC } from "react"
import { Badge as BadgeType } from "../../generated/graphql"
import useTranslate from "../../hooks/useTranslate"
import Truncate from "../Truncate/Truncate"
import { PDFMode } from "../WorkPermitView/PDF"
import styles from './Badge.module.scss'
import BadgeMoreActions from "./BadgeMoreActions"

interface BadgeProps {
    badge: Partial<BadgeType>
    generate: (badge: Partial<BadgeType>, mode: PDFMode) => void,
    refetch: any
}

const Badge: FC<BadgeProps> = props => {
    const { badge, generate, refetch } = props
    const { name, company, driver, licensePlate, state } = badge

    const t = useTranslate()

    return (
        <div id={styles.Badge}>
            <div className={styles.Actions}>
                <Grid container justifyContent="flex-end">
                    <BadgeMoreActions badge={badge} refetch={refetch} generate={generate} />
                </Grid>
                <div className={styles.Content}>
                    <Grid container justifyContent="space-between" className={styles.Title}>
                        <span>{name}</span>
                        <span>{t(state, "capitalize")}</span>
                    </Grid>
                    <Grid container justifyContent="space-between" className={styles.SecondLine}>
                        <Grid item container xs={8} spacing={4}>
                            <Grid item xs={6} className={styles.Company}>
                                <Truncate text={company} /></Grid>
                            <Grid item xs={6} className={styles.Driver}>
                                <Truncate text={driver} /> </Grid>
                        </Grid>
                        <div>{licensePlate}</div>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Badge
