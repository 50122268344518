import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import ConcessionOrganizationUserRoleField from "../ConcessionOrganizationUserRoleField/ConcessionOrganizationUserRoleField"
import { ConcessionOrganizationUsersMode } from "../ConcessionOrganizationUsers/ConcessionOrganizationUsers"
import InputPasswordField from "../InputPasswordField/InputPasswordField"
import InputTextField from "../InputTextField/InputTextField"
import { createUserFormInit } from "./createUserFormInit"

interface CreateUserFormProps {
    onSubmit: (values: FormikValues, helpers: FormikHelpers<any>) => void
    setMode: (mode: ConcessionOrganizationUsersMode) => void
    organizationTypeId: string
}

export default function CreateUserForm(props: CreateUserFormProps) {
    const { onSubmit, setMode, organizationTypeId } = props
    const { initial, schema } = createUserFormInit()

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h6" color="secondary" gutterBottom>
                Adicionar Novo Utilizador
            </Typography>
            <Typography variant="body2">
                Preencha o formulário para criar um novo utilizador na plataforma GIN. O utilizador irá receber um email com toda a informação.
            </Typography>
            <Formik initialValues={initial} onSubmit={onSubmit} validationSchema={schema}>
                {() => {
                    return (
                        <Form>
                            <p><InputTextField name="name" label="nome" fullWidth autoFocus /></p>
                            <p><InputTextField name="email" label="email" fullWidth /></p>
                            <p><InputTextField name="phone" label="telefone" fullWidth /></p>
                            <p><InputPasswordField /></p>
                            <p><ConcessionOrganizationUserRoleField organizationTypeId={organizationTypeId} /></p>
                            <Grid container justifyContent="flex-end" style={{ marginTop: 30 }}>
                                <Button type="submit" variant="outlined" onClick={() => setMode("FIND")} style={{ marginRight: 20 }}>Cancelar</Button>
                                <Button type="submit" variant="contained">Criar</Button>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik >
        </div>
    )
}
