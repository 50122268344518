import { Field } from 'formik';
import { useSafetyTechnicianFieldQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { ContractConcessionOrganizationUser } from '../../types/types';
import { ContractUserAutocomplete } from '../ContractUserAutocomplete/ContractUserAutocomplete';

export default function SafetyTechnicianField(props: ContractConcessionOrganizationUser) {
    const { disabled } = props
    const concession = useConcession()
    const { data } = useSafetyTechnicianFieldQuery({ fetchPolicy: "no-cache", variables: { concession: concession?.id } })

    const name = "contractUsers"
    const label = "Técnico Segurança"
    const options = data?.concessionUsers?.nodes || []
    const role = "SAFETYTECHNICIAN"

    return (
        <Field
            name={name}
            component={ContractUserAutocomplete}
            options={options}
            label={label}
            role={role}
            disabled={disabled}
        />
    )
}
