import { Fade } from "@mui/material"
import { useContext, useEffect, useMemo } from "react"
import Config from "../../services/config"
import Datagrid from "../Datagrid/Datagrid"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import getSubcontractColumns from "./Helpers/getSubcontractColumns"
import styles from './SubcontractsList.module.scss'

export default function SubcontractsList() {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.subcontracts?.nodes
    const rows = nodes || []
    const columns = useMemo(() => getSubcontractColumns, [])

    useEffect(() => {
        context.setView("subcontractsView")
        context.setColumnDefs(columns)
    }, [])

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.List}>
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                />
            </div>
        </Fade>
    )
}
