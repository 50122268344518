import { ArrayParam, StringParam } from "use-query-params";
import { numberFilterConfig } from "../../utils/filters";
import { searchExpFunc, stateLookupExpression } from "../../utils/lookups";
import InterventionsActions from "../InterventionsActions/InterventionsActions";
import InterventionsFilters from "../InterventionsFilters/InterventionsFilters";
import InterventionsList from "../InterventionsList/InterventionsList";
import InterventionProvider from "./InterventionProvider";
import styles from './InterventionsView.module.scss';

const InterventionsView = () => {

    const filterConfig = [
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "workPermit", variant: ArrayParam, defaultValue: [] },
        { name: "state", variant: ArrayParam, defaultValue: [], lookupExpFunc: stateLookupExpression },
        numberFilterConfig({ name: "year" }),
        numberFilterConfig({ name: "week" }),
    ]

    return (
        <div className={styles.Wrapper}>
            <InterventionProvider filterConfig={filterConfig}>
                <InterventionsActions />
                <InterventionsFilters />
                <InterventionsList />
            </InterventionProvider>
        </div>
    )
}

export default InterventionsView
