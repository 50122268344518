import { Link } from 'react-router-dom';
import styles from '../Datagrid.module.scss';

const getWorkPermit = (params: any) => {
    if (params?.row?.__typename === "Task") {
        return params?.row?.intervention?.workPermit
    } else if (params?.row?.__typename === "Intervention") {
        return params?.row?.workPermit
    }
}

export const WorkPermitRender = (params: any) => {
    const workPermit = getWorkPermit(params)
    const route = 'work-permit'
    return (
        <Link to={`/${route}/${workPermit.id}`} className={styles.Link}>
            {workPermit.name}
        </Link>
    )
}
