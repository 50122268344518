import { FC, ReactNode } from "react"
import { useCreateOneSurveyRecordMutation, useDeleteOneSurveyRecordMutation, useSurveyRecordsQuery, useUpdateOneSurveyRecordMutation } from "../../generated/graphql"
import { FilterConfig } from "../../hooks/useFilters"
import DataViewProvider from "../DataViewProvider/DataViewProvider"

const SurveyRecordsDataSource = {
    createFunc: useCreateOneSurveyRecordMutation,
    readFunc: useSurveyRecordsQuery,
    updateFunc: useUpdateOneSurveyRecordMutation,
    deleteFunc: useDeleteOneSurveyRecordMutation
}

interface SurveyProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const SurveyProvider: FC<SurveyProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={SurveyRecordsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default SurveyProvider
