import { Close } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { Concession, ConcessionOrganization, Organization } from "../../generated/graphql"
import { useConcession } from "../../hooks/useConcession"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import styles from './ConcessionOrganizationRoles.module.scss'

export const filterOrganizationRolesByConcession = (organization: Organization, concession?: Partial<Concession>) => {
    if (!concession) return []
    return organization?.concessionOrganizations?.filter(
        concessionOrganization => concessionOrganization?.concession?.id === concession?.id);
}

export const isExecutingOrganizationInConcession = (organization: Organization, concession?: Partial<Concession>) => {
    if (!concession) return null
    const roles = filterOrganizationRolesByConcession(organization, concession);
    return !!roles?.find(role => role?.organizationType?.id === "2")
}

export const getConcessionOrganizationExecutingRole = (organization: Organization, concession: Partial<Concession>) => {
    if (!concession) return null
    const roles = filterOrganizationRolesByConcession(organization, concession);
    return roles?.find(role => role?.organizationType?.id === "2")
}

export const getConcessionOrganizationByOrganizationTypeId = (organization: Partial<Organization>, concession: Partial<Concession>, organizationTypeId: string) => {
    if (!concession) return null
    const roles = filterOrganizationRolesByConcession(organization as Organization, concession);
    return roles?.find(role => role?.organizationType?.id === organizationTypeId)
}

const ConcessionOrganizationRoles = (props: any) => {
    const { organization, onDeleteConcessionOrganization } = props
    const concession = useConcession()
    const roles = filterOrganizationRolesByConcession(organization, concession)
    return (
        <Grid container justifyContent="space-between" alignItems="flex-end" style={{ paddingTop: 20 }}>
            {!roles?.length && <Typography variant="body1" color="secondary">Organização sem função associada.</Typography>}
            {roles?.map((concessionOrganization: ConcessionOrganization) => (
                <Grid item xs={8} key={concessionOrganization?.id} className={styles.Role}>
                    {concessionOrganization?.organizationType?.name}
                    <ConfirmButton onClick={() => onDeleteConcessionOrganization(concessionOrganization)} message="Tem a certeza que pretende remover função?">
                        <Close fontSize="small" />
                    </ConfirmButton>
                </Grid>
            )
            )}
        </Grid>
    )
}

export default ConcessionOrganizationRoles
