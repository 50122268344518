import { Card, CardContent, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { Intervention, useCreateOneInterventionMutation, useUpdateOneInterventionMutation } from "../../generated/graphql"
import useContractPreviewData from "../../hooks/useContractPreviewData"
import useGoTo from "../../hooks/useGoto"
import useToast from "../../hooks/useToast"
import useTranslate from "../../hooks/useTranslate"
import { Actions, Subjects } from "../../services/ability"
import { createTransform, updateTransform } from "../../services/utils"
import ContractPreview from "../ContractPreview/ContractPreview"
import DisplayState from "../DisplayState/DisplayState"
import InputNumberField from "../InputNumberField/InputNumberField"
import Permission from "../Permission"
import Truncate from "../Truncate/Truncate"
import WeekBoundariesDisplay from "../WeekBoundariesDisplay/WeekBoundariesDisplay"
import WorkPermitField from "../WorkPermitField/WorkPermitField"
import styles from './InterventionForm.module.scss'
import interventionFormInit from "./InterventionFormInit"

interface InterventionFormProps {
    intervention?: any
    formRef?: any
}

export default function InterventionForm(props: InterventionFormProps) {
    const { intervention, formRef } = props
    const { schema, initial } = interventionFormInit(intervention as Intervention)
    const [createMutation] = useCreateOneInterventionMutation()
    const [updateMutation] = useUpdateOneInterventionMutation()
    const { successToast, errorToast } = useToast()
    const t = useTranslate()
    const { goToIntervention } = useGoTo()
    const { listen, previewData } = useContractPreviewData()

    const action = intervention?.id ? Actions.UPDATE : Actions.CREATE
    const subject = Subjects.INTERVENTION

    const nonUpdatableFields = ["name", "state", "createdAt", "tasks"]

    const create = async (values: FormikValues) => {
        const { ...data } = values

        // @ts-ignore
        const response = await createMutation(createTransform(data, "intervention"))

        if (response.errors?.length) {
            errorToast("error ao criar permissão")
        } else {
            successToast("Permissão criada com sucesso.")
            goToIntervention(response?.data?.createOneIntervention?.id)
        }
    }

    const update = async (values: FormikValues) => {
        const { InterventionAuthorities, ...data } = values
        const response = await updateMutation(updateTransform(data, nonUpdatableFields))

        if (response?.errors?.length) {
            errorToast("erro ao atualizar permissão")
        } else {
            successToast("Permissão atualizada com sucesso.")
        }
    }

    const handleSubmit = async (values: FormikValues) => {
        intervention?.id ? update(values) : create(values)
    }

    const interventionName = intervention?.id ? `Intervenção ${intervention?.name}` : "Nova Intervenção"

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize innerRef={formRef}>
            {({ values }) => {

                listen(values?.workPermit?.contract?.id)

                return (

                    <Form className={styles.Form}>
                        <Grid container className={styles.InterventionForm}>
                            <Grid item xs={6} className={styles.GeneralSection}>
                                <Card style={{ padding: 20 }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography variant="h4" color="primary">
                                                    <Truncate text={interventionName} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DisplayState stateName={t(intervention?.state)} />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <Permission action={action} subject={subject} field="workPermit" passThrough>
                                                    {allowed => <WorkPermitField disabled={!allowed} />}
                                                </Permission>
                                            </Grid>
                                            <Grid container item xs={2} justifyContent="flex-end">
                                                <ContractPreview contract={previewData?.contract} />
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <Grid container justifyContent="space-between">
                                            <div>
                                                <Permission action={action} subject={subject} field="week" passThrough>
                                                    {allowed => <InputNumberField name="week" label="Semana" disabled={!allowed} />}
                                                </Permission>
                                            </div>
                                            <div>
                                                <Permission action={action} subject={subject} field="year" passThrough>
                                                    {allowed => <InputNumberField name="year" label="Ano" disabled={!allowed} />}
                                                </Permission>
                                            </div>
                                            <div className={styles.WeekBoundaries}>
                                                <WeekBoundariesDisplay week={values.week} year={values.year} />
                                            </div>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )
}
