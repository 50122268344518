import { format } from 'date-fns';
import { jsPDF } from "jspdf";
import config from "../../services/config";
import { generateQRCode } from "../../services/qrcode";
import { formatStrGroups, staticFile } from "../../services/utils";
import { breakLines } from '../../utils/strings';
import { getContractExecutingOrganization } from "../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete";
import { getContractExternalManager, getContractExternalTechnician } from "../ContractUserAutocomplete/ContractUserAutocomplete";
import { ControlCentersSupport, MissingPDFRequiredInformation, PDFaddCustomFonts, PDFcolors, PDFMode } from "./PDF";

const prepareData = (workPermit: any) => {
    const executingOrganization = getContractExecutingOrganization(workPermit?.contract?.contractConcessionOrganizations)
    const externalManager = getContractExternalManager(workPermit?.contract?.contractUsers)
    const externalTechnician = getContractExternalTechnician(workPermit?.contract?.contractUsers)

    return {
        workPermit: {
            id: workPermit?.id,
            name: workPermit?.name,
            startAt: format(workPermit?.startAt, config.GIN_DATE_FORMAT),
            endAt: format(workPermit?.endAt, config.GIN_DATE_FORMAT),
            publishedAt: format(workPermit?.publishedAt, config.GIN_DATE_FORMAT)
        },
        contract: {
            name: workPermit?.contract?.name,
            description: workPermit?.contract?.description
        },
        concession: {
            name: executingOrganization?.concessionOrganization?.concession?.name,
            logo: executingOrganization?.concessionOrganization?.concession?.logo,
        },
        executingOrganization: {
            name: executingOrganization?.concessionOrganization?.organization?.name,
            tin: executingOrganization?.concessionOrganization?.organization?.tin,
            address: executingOrganization?.concessionOrganization?.organization?.address,
            zipCode: executingOrganization?.concessionOrganization?.organization?.zipCode
        },
        externalManager: {
            name: externalManager?.user?.name,
            phone: externalManager?.user?.phone,
        },
        externalTechnician: {
            name: externalTechnician?.user?.name,
            phone: externalTechnician?.user?.phone,

        },
        controlCenters: executingOrganization?.concessionOrganization?.concession?.controlCenters,

    }
}

const generateWorkPermitPDF = (workPermit: any, mode: PDFMode = PDFMode.PRINT) => {

    if (!workPermit) {
        throw new MissingPDFRequiredInformation()
    }

    const data = prepareData(workPermit)

    // solução temporária para o número variável de centros de controlo
    if (data?.controlCenters?.length !== 1) {
        throw new ControlCentersSupport()
    }

    const doc = new jsPDF();

    PDFaddCustomFonts(doc)

    doc.addImage(staticFile(data?.concession?.logo!), 'PNG', 19, 34, 59, 31)

    const qrcode = generateQRCode(`${config.APP_URL}${data?.workPermit?.id}`)
    doc.addImage(qrcode, 'PNG', 165, 34, 26, 26)

    // Bold 12pt Black
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(12)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("Permissão de Trabalho", 95, 38)

    // Bold 14pt Black
    doc.text("N.º: " + data?.workPermit?.name, 95, 43)
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(14)
    doc.setTextColor(PDFcolors.COLOR1)

    // SemiBold 9pt Color3
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(9)
    doc.setTextColor(PDFcolors.COLOR3)
    doc.text("Publicação " + data?.workPermit?.publishedAt, 95, 52)
    doc.text(formatStrGroups(data?.externalManager?.phone), 95, 234)
    doc.text(formatStrGroups(data?.externalTechnician?.phone), 95, 248)

    // Bold 10pt Color4
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(10)
    doc.setTextColor(PDFcolors.COLOR4)
    doc.text("Validade " + data?.workPermit?.endAt, 95, 57)

    // SemiBold 10pt Color1
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(10)
    doc.setTextColor(PDFcolors.COLOR1)
    breakLines(data?.contract?.description!, 50, 300).map((line, index) => doc.text(line, 95, 127 + index * 6))

    // SemiBold 11pt Black
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(11)
    doc.text(data?.concession?.name!, 95, 97)
    doc.text(data?.executingOrganization?.name!, 95, 178)
    //doc.text(data?.executingOrganization?.address!, 95, 187)
    const lines = breakLines(data?.executingOrganization?.address!, 50, 300)
    lines.map((line, index) => doc.text(line, 95, 187 + index * 6))
    doc.text(data?.executingOrganization?.zipCode!, 95, lines?.length === 1 ? 193 : 199)

    doc.text(formatStrGroups(data?.executingOrganization?.tin), 95, 208)
    doc.text(data?.externalManager?.name!, 95, 229)
    doc.text(data?.externalTechnician?.name!, 95, 243)

    // Bold 9pt Black
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(9)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("Concessão", 72, 97)
    doc.text("Trabalhos", 72.8, 110)
    doc.text("Entidade Executante", 56, 168)
    doc.text("Responsáveis no Local", 54, 220)

    // Semibold 9pt gray
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(9)
    doc.setTextColor(PDFcolors.COLOR2)
    doc.text("N.º Obra/Serviço (OSI)", 55.3, 118)
    doc.text("Descrição dos Trabalhos", 51.5, 127)
    doc.text("Designação Social", 61, 178)
    doc.text("Morada", 76.8, 187)
    doc.text("NIPC/NIF", 74.8, 208)
    doc.text("Gestor Obra", 70, 229)
    doc.text("Técnico Segurança", 60, 243)

    // Semibold 12pt Black
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(12)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text(data?.contract?.name!, 95, 118)


    // Semibold 7pt Gray + Black
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(7)
    doc.setTextColor(PDFcolors.COLOR2)
    doc.text("Contactos dos Centros de Controlo de Tráfego", 17, 266)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("(ou através dos postos SOS)", 75, 266)

    // Semibold 7pt Color3
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(7)
    doc.setTextColor(PDFcolors.COLOR3)
    // @ts-ignore
    doc.text(data?.controlCenters[0]?.name!, 17, 273)
    // @ts-ignore
    doc.text(formatStrGroups(data?.controlCenters[0]?.phone), 35, 273)

    // regular 6pt Color1
    doc.setFont("OpenSans", "regular")
    doc.setFontSize(6)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("mod. v1 - gin.meom.pt", 167, 285)


    if (mode === PDFMode.DOWNLOAD) {
        doc.save(`${workPermit?.name}.pdf`);
    } else {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    }
}

export default generateWorkPermitPDF;
