import { StateOption, TransitionMap } from "../types/types"

export const getTransitions = (transitionMap?: TransitionMap, currentState?: string): string[] => {
    if (!currentState || !transitionMap) return []
    return transitionMap[currentState] || []
}

export const getStateOptions = (stateOptions: StateOption[], transitions: string[]) => {
    return stateOptions.filter(
        (option: StateOption) => transitions.includes(option.stateTo))
}
