import { Organization, useCreateOneOrganizationMutation } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";

interface CreateOrganizationData {
    name: string
    tin: string
    address: string
    zipCode: string
}

export default function useOrganizationManager() {

    const [_create] = useCreateOneOrganizationMutation()

    const createOrganization = async (data: CreateOrganizationData): Promise<OperationResult<Organization>> => {
        const { name, tin, address, zipCode } = data

        try {
            const response = await _create({
                variables: {
                    input: { organization: { name, tin, address, zipCode } }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response.errors)
                return { success: false, errors }
            }

            return { success: true, data: response.data?.createOneOrganization }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createOrganization
    }
}
