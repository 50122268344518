import { ApolloError } from "@apollo/client";
import { ConcessionOrganizationAccreditationFilter, ConcessionOrganizationAccreditationSort, ConcessionOrganizationAccreditationSortFields, OffsetPaging, SortDirection, useConcessionOrganizationAccreditationsQuery } from "../generated/graphql";
import Config from "../services/config";

export interface AccreditationCollectionManagerOptions {
    concessionOrganizationId?: string
    concessionOrganizationAccreditationTypeId?: string
}

// este manager não faz extends como a referência SubcontractDataManager
export interface AccreditationCollectionManager {
    accreditations?: Partial<any>[]
    accreditationsLoading: boolean
    accreditationsError: ApolloError | undefined
    refetch: any
}

export default function useAccreditationCollectionManager(opts?: AccreditationCollectionManagerOptions): AccreditationCollectionManager {

    const { concessionOrganizationId = "", concessionOrganizationAccreditationTypeId = "" } = opts || {}

    const filter: ConcessionOrganizationAccreditationFilter = {
        concessionOrganization: {
            id: { eq: concessionOrganizationId }
        },
        concessionOrganizationAccreditationType: {
            id: { eq: concessionOrganizationAccreditationTypeId }
        },
        concessionOrganizationAccreditationState: {
            code: { in: ["APPROVED", "EXPIRED"] }
        }
    }

    const paging: OffsetPaging = { limit: Config.MAX_RESULTS_SIZE }
    const sorting: ConcessionOrganizationAccreditationSort = { field: ConcessionOrganizationAccreditationSortFields.EndDate, direction: SortDirection.Desc }

    const { data, loading, error, refetch } = useConcessionOrganizationAccreditationsQuery({ variables: { filter, paging, sorting } })

    return {
        accreditations: data?.concessionOrganizationAccreditations?.nodes,
        accreditationsLoading: loading,
        accreditationsError: error,
        refetch
    }
}
