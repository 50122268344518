import { translate } from '../../../hooks/useTranslate';
import { black, blue, gray, green, red, yellow } from '../../../services/config';
import styles from './ContractStateRender.module.scss';

export const getTaskStateColor = (state: string) => {
    const contractColors: { [key: string]: string } = {
        PREPARATION: gray,
        ISSUED: gray,
        SUBMITTED: yellow,
        APPROVED: green,
        REJECTED: red,
        EXECUTED: blue,
        NOT_EXECUTED: red,
        IN_EXECUTION: yellow
    };

    return contractColors[state] || black
}

export const TaskStateRender = (params: any) => {
    const backgroundColor = getTaskStateColor(params?.row?.state)
    return (
        <div className={styles.ContractState} style={{ backgroundColor }}>
            {translate(params?.row?.state, "capitalize")}</div>
    )
}
