import { FormikValues } from "formik"
import { Maybe, useCreateOneWorkPermitMutation, useUpdateOneWorkPermitMutation, WorkPermit } from "../../generated/graphql"
import useGoTo from "../../hooks/useGoto"
import useToast from "../../hooks/useToast"
import { createTransform, updateTransform } from "../../services/utils"

const WorkPermitErrors = {
    "Work permit dates are out of bounds.": "Erro! Datas da permissão fora das datas de contrato."
}


const useWorkPermitStore = () => {
    const [createMutation] = useCreateOneWorkPermitMutation()
    const [updateMutation] = useUpdateOneWorkPermitMutation()

    const { goToWorkPermit } = useGoTo()
    const { successToast, errorToast } = useToast()

    const findError = (e: any) => {
        // @ts-ignore
        const message = WorkPermitErrors[e.message]
        return message || "Erro desconhecido."
    }

    const create = async (values: FormikValues) => {
        try {
            // @ts-ignore
            const response = await createMutation(createTransform(values, "workPermit"))
            const workPermitId = response?.data?.createOneWorkPermit?.id

            if (!workPermitId) {
                errorToast("Erro ao criar permissão")
            } else {
                successToast("Permissão criada com sucesso")
                goToWorkPermit(workPermitId)
            }

            return response?.data?.createOneWorkPermit?.id
        } catch (_: unknown) {
            errorToast("Erro desconhecido")
        }
    }

    const update = async (values: FormikValues, workPermit?: Maybe<WorkPermit>) => {

        if (!workPermit) return

        const nonUpdatableFields = ["badges", "name", "createdAt", "publishedAt"]

        try {
            const response = await updateMutation(updateTransform(values, nonUpdatableFields))
            if (response?.errors?.length) {
                errorToast("Erro ao atualizar permissão")
            } else {
                successToast("Permissão atualizada com sucesso")
            }
        } catch (e: unknown) {
            const message = findError(e)
            errorToast(message)
        }
    }

    return { create, update }
}


export default useWorkPermitStore
