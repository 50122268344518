import { ArrayParam, StringParam } from "use-query-params"
import { searchExpFunc } from "../../utils/lookups"
import AccreditationsActions from "../AccreditationsActions/AccreditationsActions"
import AccreditationsFilters from "../AccreditationsFilters/AccreditationsFilters"
import AccreditationsList from "../AccreditationsList/AccreditationsList"
import AccreditationProvider from "./AccreditationProvider"
import styles from './AccreditationsView.module.scss'

export default function AccreditationsView() {

    const filterConfig = [
        { name: "observations", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "concessionOrganizationAccreditationState", variant: ArrayParam, defaultValue: [] },
    ]

    return (
        <div className={styles.Wrapper}>
            <AccreditationProvider filterConfig={filterConfig}>
                <AccreditationsActions />
                <AccreditationsFilters />
                <AccreditationsList />
            </AccreditationProvider>
        </div>
    )
}
