import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { useState } from "react"
import ConcessionOrganizationUserRoleField from "../ConcessionOrganizationUserRoleField/ConcessionOrganizationUserRoleField"
import { ConcessionOrganizationUsersMode } from "../ConcessionOrganizationUsers/ConcessionOrganizationUsers"
import FindUserForm from "../FindUserForm/FindUserForm"

interface AssignUserFormProps {
    onSubmit: (values: FormikValues, helpers: FormikHelpers<any>) => void
    setMode: (mode: ConcessionOrganizationUsersMode) => void
    organizationTypeId?: string
}

export default function AssignUserForm(props: AssignUserFormProps) {
    const { onSubmit, setMode, organizationTypeId } = props
    const [user, setUser] = useState<any>(null)

    const initialValues = { concessionOrganizationUserRole: "", user }

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="body1" gutterBottom color="secondary">Para associar um utilizador a esta entidade introduza o email do utilizador. Se o utilizador não for encontrado poderá ser criado de seguida.</Typography>
            <br />
            <FindUserForm setUser={setUser} setMode={setMode} />
            {user && <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={8}>
                                    <ConcessionOrganizationUserRoleField organizationTypeId={organizationTypeId} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button type="submit" variant="contained" size="small">Adicionar</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik >}
        </div>
    )
}
