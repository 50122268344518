import { AutocompleteRenderInputParams, TextField, TextFieldProps } from '@mui/material';
import { FastField } from 'formik';
import { Autocomplete } from 'formik-mui';
import { FC } from 'react';
import { ConcessionUserRoles } from '../../generated/graphql';
import { translate } from '../../hooks/useTranslate';


interface ConcessionUserRoleFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
}

const ConcessionUserRoleField: FC<ConcessionUserRoleFieldProps> = props => {
    const { name = "concessionUserRole", label = "Função", textFieldProps = { variant: "standard" } } = props
    const t = translate

    const options = [
        { id: ConcessionUserRoles.Contractmanager, label: t(ConcessionUserRoles.Contractmanager, "lowercase") },
        { id: ConcessionUserRoles.Servicemanager, label: t(ConcessionUserRoles.Servicemanager, "lowercase") },
        { id: ConcessionUserRoles.Safetytechnician, label: t(ConcessionUserRoles.Safetytechnician, "lowercase") },
        { id: ConcessionUserRoles.Operator, label: t(ConcessionUserRoles.Operator, "lowercase") },
        { id: ConcessionUserRoles.Platformmanager, label: t(ConcessionUserRoles.Platformmanager, "lowercase") },
        { id: ConcessionUserRoles.Supervisor, label: t(ConcessionUserRoles.Supervisor, "lowercase") }
    ]

    return (
        <FastField
            name={name}
            component={Autocomplete}
            disableClearable
            options={options}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                />
            )}
        />
    )

}

export default ConcessionUserRoleField
