import { jsPDF } from "jspdf";
import OpenSansBold from '../../assets/fonts/OpenSansBold';
import OpenSansRegular from '../../assets/fonts/OpenSansRegular';
import OpenSansSemiBold from '../../assets/fonts/OpenSansSemiBold';

export class MissingPDFRequiredInformation extends Error {
    message = "Informação em falta."
}

// este é um erro temporário
export class ControlCentersSupport extends Error {
    message: string = `Número não suportado de centros de controlo, por favor comunique erro ao administrador.`
}

export enum PDFMode {
    PRINT = "PRINT",
    DOWNLOAD = "DOWNLOAD"
}

export const PDFcolors = {
    COLOR1: "#414042", // 65 64 66
    COLOR2: "#808285", // 128 130 133
    COLOR3: "#93959D", // 147 149 157
    COLOR4: "#58595B", // 88 89 91
    COLOR5: "#6D6E71", // 109 110 113
    COLOR6: "#FFFFFF", // 255 255 255
}


export const PDFaddCustomFonts = (doc: jsPDF) => {
    // add regular open sans
    doc.addFileToVFS('OpenSansRegular.ttf', OpenSansRegular);
    doc.addFont('OpenSansRegular.ttf', 'OpenSans', 'regular');

    // add semi-bold open sans
    doc.addFileToVFS('OpenSansSemiBold.ttf', OpenSansSemiBold);
    doc.addFont('OpenSansSemiBold.ttf', 'OpenSans', 'semi-bold');

    // add bold open sans
    doc.addFileToVFS('OpenSansBold.ttf', OpenSansBold);
    doc.addFont('OpenSansBold.ttf', 'OpenSans', 'bold');
}
