import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ContractFilter from "../ContractFilter/ContractFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Search from "../Search/Search";
import WorkPermitIsPublishedFilter from "../WorkPermitIsPublishedFilter/WorkPermitIsPublishedFilter";
import WorkPermitStateFilter from "../WorkPermitStateFilter/WorkPermitStateFilter";
import styles from './WorkPermitsFilters.module.scss';

const WorkPermitsFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.WorkPermitsFilters}>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <ContractFilter value={context.filters.contract} onChange={context.setFilter} />
                <WorkPermitStateFilter value={context.filters.state} onChange={context.setFilter} />
                <WorkPermitIsPublishedFilter value={context.filters.isPublished} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default WorkPermitsFilters;
