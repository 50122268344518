import { Link } from "react-router-dom";
import { useHasInternalRole } from "../../../hooks/useHasRole";
import { getContractExecutingOrganization } from "../../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete";
import styles from '../Datagrid.module.scss';

const getContractConcessionOrganizations = (params: any) => {
    if (params?.row?.__typename === "Contract") {
        return params?.row?.contractConcessionOrganizations
    } else if (params?.row?.__typename === "Task") {
        return params?.row?.intervention?.workPermit?.contract?.contractConcessionOrganizations
    }
}

export const ExecutingOrganizationRender = (params: any) => {
    const hasInternalRole = useHasInternalRole()
    const contractConcessionOrganizations = getContractConcessionOrganizations(params)
    const executingOrganization = getContractExecutingOrganization(contractConcessionOrganizations)
    const { id, organization } = executingOrganization?.concessionOrganization!
    const route = hasInternalRole ? `/organization/${id}` : '/organization'
    return <Link to={route} className={styles.Link}>{organization?.name}</Link>
}
