import { useCreateOneInterventionElementMutation, useDeleteOneInterventionElementMutation, useInterventionElementsQuery, useUpdateOneInterventionElementMutation } from "../../generated/graphql"

const useInterventionElementStore = () => {
    const query = useInterventionElementsQuery
    const name = "interventionElement"
    const [create] = useCreateOneInterventionElementMutation()
    const [update] = useUpdateOneInterventionElementMutation()
    const [remove] = useDeleteOneInterventionElementMutation()
    return { query, name, create, update, remove }
}

export default useInterventionElementStore
