import { Card, CardContent, Fade, Grid, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import { Badge, useWorkPermitQuery, WorkPermit, WorkPermitStates } from "../../generated/graphql";
import useChecklistItemManager from "../../hooks/useChecklistItemManager";
import useContractPreviewData from "../../hooks/useContractPreviewData";
import useFormFieldManager from "../../hooks/useFormFieldManager";
import { useHasInternalRole } from "../../hooks/useHasRole";
import useParamId from "../../hooks/useParamId";
import useToast from "../../hooks/useToast";
import useTranslate from "../../hooks/useTranslate";
import { Actions, Subjects } from "../../services/ability";
import Config from "../../services/config";
import { parseDateGQLField } from "../../services/utils";
import ActionsBar from "../ActionsBar/ActionsBar";
import BackButton from "../BackButton/BackButton";
import Badges from "../Badges/Badges";
import ContractField from "../ContractField/ContractField";
import ContractPreview from "../ContractPreview/ContractPreview";
import { ContractStates } from "../ContractView/ContractViewMoreActions";
import DisplayState from "../DisplayState/DisplayState";
import { FormatDate } from "../FormatDate/FormatDate";
import InputDateField from "../InputDateField/InputDateField";
import InputTextField from "../InputTextField/InputTextField";
import MessageBoard from "../MessageBoard/MessageBoard";
import Permission from "../Permission";
import RecordNotFound from "../RecordNotFound/RecordNotFound";
import SaveIconButton from "../SaveIconButton/SaveIconButton";
import Show from "../Show/Show";
import WorkPermitChecklist from "../WorkPermitChecklist/WorkPermitChecklist";
import workPermitFormInit from "../WorkPermitForm/WorkPermitFormInit";
import useWorkPermitStore from "../WorkPermitForm/WorkPermitStore";
import WorkPermitsOverview from "../WorkPermitsOverview/WorkPermitsOverview";
import generateBadgePDF from "./GenerateBadgePDF";
import getDisabledActions from "./Helpers/getDisabledActions";
import { PDFMode } from "./PDF";
import { workPermitFieldRules } from "./workPermitFieldRules";
import styles from './WorkPermitView.module.scss';
import WorkPermitViewMoreActions from "./WorkPermitViewMoreActions";

const WorkPermitView = () => {

    const id = useParamId()
    const action = id ? "update" : "create"
    const subject = Subjects.WORKPERMIT

    const t = useTranslate()
    const { errorToast } = useToast()

    const { data, loading, refetch } = useWorkPermitQuery({ variables: { id } })
    const { create, update } = useWorkPermitStore()
    let { schema, initial } = workPermitFormInit(data?.workPermit as WorkPermit)
    const { listen, previewData } = useContractPreviewData()
    const isFieldDisabled = useFormFieldManager(workPermitFieldRules)
    const isInternalUser = useHasInternalRole()
    const { updateChecklistItem } = useChecklistItemManager()

    const handleSubmit = async (values: FormikValues) => {
        id ? update(values, data?.workPermit as WorkPermit) : create(values)
    }

    const getWorkPermitName = () => {
        return data?.workPermit?.id ? `Permissão ${data?.workPermit?.name}` : "Nova Permissão"
    }

    const handleGenerateBadgePDF = (badge: Partial<Badge>, mode: PDFMode) => {
        try {
            generateBadgePDF(data?.workPermit, badge, mode)
        } catch (e) {
            errorToast(`Erro ao gerar dístico. ${(e as Error).message} `)
        }
    }

    const handleItemCheck = async (id: string, isCompleted: boolean) => {
        const response = await updateChecklistItem({ id, isCompleted })
        response.success ? refetch() : errorToast(`response?.errors?.join(", ")`)
    }

    const contractStateId = data?.workPermit?.contract?.contractState?.id
    const disableBadges = (contractStateId && +contractStateId !== +ContractStates.Ongoing) || (data?.workPermit?.state === WorkPermitStates.Expired) || false
    const workPermitState = data?.workPermit?.state
    const minDate = parseDateGQLField(previewData?.contract?.realStartDate)
    const maxDate = parseDateGQLField(previewData?.contract?.realEndDate)
    const publishedAt = (data?.workPermit?.publishedAt as unknown) as Date
    const disabledActions = getDisabledActions(data?.workPermit?.checklist)
    const showChecklist = !!data?.workPermit?.checklist
    const disableChecklist = workPermitState !== WorkPermitStates.Preparation

    if (id && !loading && !data?.workPermit) {
        return <RecordNotFound message="Erro! Permissão não encontrada." />
    }

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
            {({ values }) => {

                listen(values?.contract?.id)

                return (
                    <Form>
                        <Fade in={!loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>

                            <div className={styles.Wrapper}>

                                <ActionsBar variant="DETAIL">
                                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                                            <BackButton />
                                        </Grid>
                                        <Grid item xs={10} container justifyContent="flex-end" alignContent="center">
                                            {/* <Button type="submit">Guardar e Fechar</Button> */}

                                            <Permission action={action} subject={subject}>
                                                <>
                                                    <SaveIconButton />
                                                </>
                                            </Permission>

                                            <WorkPermitViewMoreActions workPermit={data?.workPermit} refetch={refetch} disabledActions={disabledActions} />

                                        </Grid>
                                    </Grid>
                                </ActionsBar>


                                <Show on={action === Actions.UPDATE && disableBadges}>
                                    <MessageBoard content="Contrato não se encontra em curso ou permissão expirada. Não é permitida a criação de dísticos." variant="h4" />
                                </Show>

                                <Grid container justifyContent="space-around" className={styles.Main} component="main">

                                    <Grid item xs={4} className={styles.Section} component="section">
                                        <Card style={{ padding: 20 }}>
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={9}>
                                                        <Typography variant="h4" color="primary">{getWorkPermitName()}</Typography >
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <DisplayState stateName={t(workPermitState)} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Permission action={action} subject={subject} passThrough>
                                                            {allowed => <ContractField
                                                                disabled={!allowed || isFieldDisabled({
                                                                    fieldName: "contract",
                                                                    state: workPermitState, action
                                                                })} />}
                                                        </Permission>
                                                    </Grid>
                                                    <Grid container item xs={2} justifyContent="flex-end">
                                                        <ContractPreview contract={previewData?.contract} />
                                                    </Grid>
                                                    <Grid container>
                                                        <WorkPermitsOverview workPermits={previewData?.contract?.workPermits} current={id} />
                                                    </Grid>
                                                </Grid>


                                                <Grid container justifyContent="space-between">
                                                    <Permission action={action} subject={subject} passThrough>
                                                        {allowed => <InputDateField
                                                            name="startAt"
                                                            label="Início"
                                                            minDate={minDate}
                                                            maxDate={maxDate}
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "startAt", state: workPermitState, action
                                                            })} />}
                                                    </Permission>

                                                    <Permission action={action} subject={subject} passThrough>
                                                        {allowed => <InputDateField
                                                            name="endAt"
                                                            label="Fim"
                                                            minDate={minDate}
                                                            maxDate={maxDate}
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "endAt", state: workPermitState, action
                                                            })} />}

                                                    </Permission>
                                                </Grid>
                                                <br className={styles.Observations} />

                                                <Permission action={action} subject={subject} passThrough>
                                                    {allowed => <InputTextField
                                                        name="observations"
                                                        label="observações"
                                                        multiline={true}
                                                        fullWidth
                                                        rows={3}
                                                        disabled={!allowed || isFieldDisabled({
                                                            fieldName: "observations",
                                                            state: workPermitState,
                                                            action,
                                                            isInternalUser
                                                        })}
                                                    />}
                                                </Permission>

                                                <Show on={!!data?.workPermit?.publishedAt}>
                                                    <Grid container justifyContent="flex-end" className={styles.Issued}>
                                                        <Typography variant="caption">
                                                            Permissão publicada a <FormatDate date={publishedAt} />
                                                        </Typography>
                                                    </Grid>
                                                </Show>

                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={4} className={styles.Section} component="section">
                                        <Show on={showChecklist}>
                                            <WorkPermitChecklist
                                                checklist={data?.workPermit?.checklist}
                                                onItemCheck={handleItemCheck}
                                                disabled={disableChecklist}
                                            />
                                        </Show>
                                    </Grid>


                                    <Grid item xs={3} className={styles.Section} component="section">
                                        <Badges
                                            badges={data?.workPermit?.badges}
                                            workPermitId={data?.workPermit?.id}
                                            refetch={refetch}
                                            show={!!id}
                                            generate={handleGenerateBadgePDF}
                                            disabled={disableBadges}
                                        />
                                    </Grid>

                                </Grid>

                            </div>
                        </Fade>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default WorkPermitView
