import { clsx } from 'clsx'
import styles from './DymanicStateDisplay.module.scss'

interface DynamicStateDisplayProps {
    name?: string
    color?: string
    backgroundColor?: string
    variant?: "tiny" | "small" | "medium"
}

/**
 * display para entidades que têm um estado cuja cor e a cor de fundo estão definidas na API
 * certas entidades não têm essa definição (cf: DocumentStateDisplay, ContractStateDisplay)
 * esta é a conveção mais atual, fazer refactor dos restantes
 */
export const DynamicStateDisplay = (props: DynamicStateDisplayProps) => {

    const {
        name,
        color = "#ffffff",
        backgroundColor = "#000000",
        variant = "medium"
    } = props

    if (!name) return null

    const cls = clsx({
        [styles.StateTiny]: variant === "tiny",
        [styles.StateSmall]: variant === "small",
        [styles.StateMedium]: variant === "medium"
    })

    return (
        <span className={cls} style={{ color, backgroundColor }}>
            {name}
        </span>
    )
}
