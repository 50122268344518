import { ChecklistItem, useUpdateOneChecklistItemMutation } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";

export interface UpdateChecklistItemData {
    id: string
    isCompleted: boolean
}

export interface ChecklistItemManager {
    updateChecklistItem: (data: UpdateChecklistItemData) => Promise<OperationResult<ChecklistItem>>
}

export default function useChecklistItemManager(): ChecklistItemManager {

    const [_updateChecklistItem] = useUpdateOneChecklistItemMutation()

    const updateChecklistItem = async (data: UpdateChecklistItemData): Promise<OperationResult<ChecklistItem>> => {
        const { id, isCompleted } = data
        try {
            const response = await _updateChecklistItem({
                variables: {
                    input: {
                        id,
                        update: {
                            isCompleted
                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }
            return { success: true }
        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        updateChecklistItem
    }
}
