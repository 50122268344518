import { useSubcontractsQuery } from "../../generated/graphql"
import { noop } from "../../services/utils"
import { DataProviderProps } from "../../types/types"
import DataViewProvider from "../DataViewProvider/DataViewProvider"

const SubcontractsDataSource = {
    createFunc: noop,
    readFunc: useSubcontractsQuery,
    updateFunc: noop,
    deleteFunc: noop
}

export default function SubcontractProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={SubcontractsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
