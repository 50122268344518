import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Search from "../Search/Search";
import SubcontractStateFilter from "../SubcontractStateFilter/SubcontractStateFilter";
import styles from './SubcontractsFilters.module.scss';

export default function SubcontractsFilters() {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.Filters}>
                <Search value={context.filters.description} onChange={context.setFilter} name="description" />
                <SubcontractStateFilter
                    value={context?.filters?.contractConcessionOrganizationState}
                    onChange={context.setFilter}
                />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}
