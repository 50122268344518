import { useJunctionsQuery } from "../../generated/graphql"
import { useConcessionId } from "../../hooks/useConcession"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

interface JunctionFilter extends FilterProps {
    field: string
    label: string
    roads: string[]
}

export default function JunctionFilter(props: JunctionFilter) {
    const { field, label, value, onChange, roads, width = 120 } = props
    const query = useJunctionsQuery
    const concession = useConcessionId()
    const filter = roads?.length > 0 ? { road: { id: { in: roads } } } : { concession: { id: { eq: concession } } }

    return <SelectFilter
        value={value}
        onChange={onChange}
        query={query}
        multiple={true}
        field={field}
        label={label}
        filter={filter}
        width={width}
        paging={{ limit: 999 }}
    />
}
