import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useContractTypesQuery } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface ContractTypeFieldProps {
    textFieldProps?: TextFieldProps
    disabled?: boolean
    limit?: number
}

const ContractTypeField: FC<ContractTypeFieldProps> = props => {
    const {
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props
    const concession = useConcession()

    return (
        <SelectAutocompleteDataField
            name="contractType"
            label="Tipo Contrato"
            query={useContractTypesQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}
        />
    )
}

export default ContractTypeField
