import { DynamicStateDisplay } from "../../DymanicStateDisplay/DymanicStateDisplay"

export const AccreditationStateRender = (params: any) => {
    const name = params?.row?.concessionOrganizationAccreditationState?.name
    const color = params?.row?.concessionOrganizationAccreditationState?.color
    const backgroundColor = params?.row?.concessionOrganizationAccreditationState?.backgroundColor
    return (
        <DynamicStateDisplay name={name} color={color} backgroundColor={backgroundColor} />
    )
}
