import { Grid, Typography } from "@mui/material";
import { UpdateSubcontractData } from '../../hooks/useSubcontractManager';
import { OperationResult, Subcontract } from "../../types/types";
import { DynamicStateDisplay } from "../DymanicStateDisplay/DymanicStateDisplay";
import SubcontractPreviewMoreActions from "../SubcontractPreviewMoreActions/SubcontractPreviewMoreActions";

interface SubcontractPreviewProps {
    subcontract: Partial<Subcontract>
    updateSubcontract: (data: UpdateSubcontractData) => Promise<OperationResult<Subcontract>>
    deleteSubcontract: (id: string) => Promise<OperationResult<Subcontract>>
}

export default function SubcontractPreview(props: SubcontractPreviewProps) {
    const { subcontract, updateSubcontract, deleteSubcontract } = props

    const {
        concessionOrganization,
        contractConcessionOrganizationState,
        concessionOrganizationAccreditation,
    } = subcontract

    const accreditatonState = concessionOrganizationAccreditation?.concessionOrganizationAccreditationState

    return (
        <Grid container justifyContent="space-between" style={{ marginBottom: -30 }}>
            <Grid item xs={4} style={{ marginBottom: 0 }}>
                <Typography variant="body1" color="text">
                    {concessionOrganization?.organization?.name}
                </Typography>
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 0 }}>
                <DynamicStateDisplay
                    name={contractConcessionOrganizationState?.name!}
                    color={contractConcessionOrganizationState?.color}
                    backgroundColor={contractConcessionOrganizationState?.backgroundColor}
                    variant="tiny"
                />
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 0 }}>
                <DynamicStateDisplay
                    name={accreditatonState?.name}
                    color={accreditatonState?.color}
                    backgroundColor={accreditatonState?.backgroundColor}
                    variant="tiny"
                />
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end" style={{ marginBottom: 0 }}>
                <SubcontractPreviewMoreActions
                    subcontract={subcontract}
                    updateSubcontract={updateSubcontract}
                    deleteSubcontract={deleteSubcontract}
                />
            </Grid>
        </Grid >
    )
}
