import { ConcessionOrganization, ConcessionOrganizationUserRoles } from "../generated/graphql";
import { errorParser } from "../utils/errorParser";
import useConcessionOrganizationManager from "./useConcessionOrganizationManager";
import useConcessionOrganizationUserManager from "./useConcessionOrganizationUserManager";
import useOrganizationManager from "./useOrganizationManager";
import { Settings, useSettingManager } from "./useSettingManager";
import useUserManager from "./useUserManager";

export type SubcontractOrganization = ConcessionOrganization

export interface SubcontractOrganizationManager {
    createFullSubcontractOrganization: (data: CreateFullSubcontractOrganizationData) => any
}

export interface CreateFullSubcontractOrganizationData {
    name: string
    tin: string
    address: string
    zipCode: string
    contactName: string
    contactEmail: string
}

export interface CreateFullSubcontractOrganizationResult {
    success: boolean
    errors?: string[]
}

export default function useSubcontractOrganizationManager(): SubcontractOrganizationManager {

    const { createOrganization } = useOrganizationManager()
    const { createConcessionOrganization } = useConcessionOrganizationManager()
    const { createUser } = useUserManager()
    const { createConcessionOrganizationUser } = useConcessionOrganizationUserManager()
    const getSetting = useSettingManager()

    const createFullSubcontractOrganization = async (data: CreateFullSubcontractOrganizationData): Promise<CreateFullSubcontractOrganizationResult> => {

        const { name, tin, address, zipCode, contactEmail, contactName } = data

        const organizationTypeId = getSetting(Settings.SUBCONTRACT_ORGANIZATION_TYPE_ID)

        const organizationResult = await createOrganization({ name, tin, address, zipCode })

        if (organizationResult.success) {
            // agora vamos criar o concession organization
            const concessionOrganizationResult = await createConcessionOrganization({
                organizationId: organizationResult?.data?.id!,
                organizationTypeId,
            })

            if (!concessionOrganizationResult.success) {
                const errors = errorParser(concessionOrganizationResult.errors)
                return { success: false, errors }
            }

            const userResult = await createUser({
                name: contactName, email: contactEmail
            })

            if (!userResult.success) {
                const errors = errorParser(userResult.errors)
                return { success: false, errors }
            }

            const concessionOrganizationUserResult = await createConcessionOrganizationUser({
                roleId: ConcessionOrganizationUserRoles.Subcontractauthority,
                userId: userResult.data?.id!,
                concessionOrganizationId: concessionOrganizationResult?.data?.id!
            })

            if (!concessionOrganizationUserResult.success) {
                const errors = errorParser(concessionOrganizationUserResult.errors)
                return { success: false, errors }
            }

            return { success: true }

        } else {
            return { success: false, errors: organizationResult.errors }
        }
    }


    return {
        createFullSubcontractOrganization
    }
}
