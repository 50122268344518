import { useApolloClient } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import { Concession, useMeLazyQuery } from "../generated/graphql";
import abilityFactory from "./ability";
import Config from "./config";


export interface AuthUser {
    id: string,
    name: string,
    email: string,
    phone: string,
    concession: Partial<Concession>
    ability: any,
    start: string,
    roles: any
}

const authContext = createContext<any>(undefined)

const AuthProvider = ({ children }: { children: any }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// hooks
export const useAuth = () => useContext(authContext)

const useProvideAuth = () => {
    const [user, setUser] = useState<AuthUser | boolean>(false);
    const [getMe, { data, refetch }] = useMeLazyQuery()
    const client = useApolloClient();

    const login = async (email: string, password: string) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        };

        return fetch(Config.AUTH_ENDPOINT, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    localStorage.setItem(Config.AUTH_TOKEN_KEY, data.token);
                    getMe()
                    return true
                }
                return false
            })
    };

    const logout = () => {
        localStorage.removeItem(Config.AUTH_TOKEN_KEY);
        client.cache.reset()
        setUser(false)
        window.location.href = Config.APP_URL
    };

    const refresh = () => {
        refetch()
    }

    useEffect(() => {
        getMe()

        const { ability, roles } = abilityFactory(data)
        const start = "/status"

        if (data?.me) {
            setUser({
                id: data.me.id,
                name: data?.me?.name,
                email: data?.me?.email,
                phone: data?.me?.phone || "",
                concession: data?.me?.defaultConcession,
                ability,
                start,
                roles
            })
        } else {
            setUser(false)
        }
    }, [data, getMe]);

    return {
        user,
        login,
        logout,
        refresh
    };
}


export default AuthProvider
