import { black, blue, gray, red, yellow } from '../../services/config'
import styles from '../Datagrid/Renders/ContractStateRender.module.scss'

interface DocumentStateDisplayProps {
    id: string
    name: string
}

const getDocumentStateColor = (id: number) => {
    const contractColors: { [key: number]: string } = {
        1: gray,
        2: yellow,
        3: blue,
        4: red,
        5: red,
    }
    return contractColors[id] || black
}

export const DocumentStateDisplay = (props: DocumentStateDisplayProps) => {
    const { id, name } = props
    const backgroundColor = getDocumentStateColor(+id)
    return (
        <span className={styles.ContractState} style={{ backgroundColor }}>
            {name}
        </span>
    )
}
