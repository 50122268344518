import { useConcessionOrganizationDocumentsQuery, useCreateOneConcessionOrganizationDocumentMutation, useDeleteManyConcessionOrganizationDocumentsMutation, useDeleteOneConcessionOrganizationDocumentMutation, useUpdateManyConcessionOrganizationDocumentsMutation, useUpdateOneConcessionOrganizationDocumentMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const OrganizationDocumentsDataSource = {
    readFunc: useConcessionOrganizationDocumentsQuery,
    createFunc: useCreateOneConcessionOrganizationDocumentMutation,
    updateFunc: useUpdateOneConcessionOrganizationDocumentMutation,
    deleteFunc: useDeleteOneConcessionOrganizationDocumentMutation,
    updateManyFunc: useUpdateManyConcessionOrganizationDocumentsMutation,
    deleteManyFunc: useDeleteManyConcessionOrganizationDocumentsMutation
}

export default function OrganizationDocumentProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={OrganizationDocumentsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
