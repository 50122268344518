import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, IconButtonProps as IconButtonPropsType, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createElement, FC, MouseEvent, ReactNode, useState } from 'react';
import styles from './ConfirmButton.module.scss';

// https://stackoverflow.com/questions/53688899/typescript-and-react-children-type
interface ConfirmButtonProps {
    children: ReactNode
    title?: string,
    message?: string,
    confirmText?: string,
    cancelText?: string,
    toast?: string | "off"
    onClick?: any
    duration?: number
    IconButtonProps?: IconButtonPropsType
    ButtonProps?: ButtonProps,
    disabled?: boolean,
    component?: any, // só deve ser aceite Button e IconButton
    tooltip?: string
}

const ConfirmButton: FC<ConfirmButtonProps> = (props) => {

    const {
        title = "Continuar?",
        children,
        cancelText = "Cancelar",
        confirmText = "Continuar",
        message = "Tem a certeza que quer continuar, a ação é irreverísvel",
        toast = "Registo removido",
        duration = 3000,
        onClick,
        IconButtonProps = {},
        ButtonProps = {},
        disabled,
        tooltip = "Remover",
        component = IconButton
    } = props

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const handleClick = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        setOpen(true)
    }

    const handleConfirm = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        onClick()?.then((response: any) => {
            if (response && toast !== "off") enqueueSnackbar(toast, { autoHideDuration: duration })
        })
        setOpen(false);
    };

    const handleCancel = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        setOpen(false);
    }

    return <>
        <Tooltip title={tooltip}>
            {
                component === IconButton ?
                    createElement(
                        component,
                        { onClick: handleClick, ...IconButtonProps, disabled },
                        children) :
                    createElement(
                        component,
                        { onClick: handleClick, ...ButtonProps, disabled },
                        children)
            }
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleCancel}

        >
            <div className={styles.ConfirmButton}>
                <DialogTitle id="alert-dialog-title" >{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="inherit" size="small">
                        {cancelText}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" variant="contained" size="small">
                        {confirmText}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    </>
}


export default ConfirmButton;
