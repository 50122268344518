import { Card, Grid } from "@mui/material";
import { FC, ReactNode } from "react";
import Logo from '../../assets/imgs/logo.png';
import styles from './AuthView.module.scss';

interface AuthViewProps {
    children: ReactNode
}

const AuthView: FC<AuthViewProps> = props => {
    const { children } = props
    return (
        <Grid container id={styles.AuthView} justifyContent="center" alignItems="center">
            <img src={Logo} className={styles.Logo} />
            <Card className={styles.Main}>
                {children}
            </Card>
        </Grid>
    )
}

export default AuthView
