import { Fade, Grid } from "@mui/material";
import Config from "../../services/config";
import ActionsBar from "../ActionsBar/ActionsBar";
import BackButton from "../BackButton/BackButton";
import SettingsEditor from "./SettingsEditor";
import SettingsEntities from "./SettingsEntities";
import SettingsRecords from "./SettingsRecords";
import useSettingsStore, { getRecords } from "./SettingsStore";
import styles from './SettingsView.module.scss';

// temos que ter o query tb
// temos que ter um create, update e delete mutation
const SettingsView = () => {
    const { entities, selectedEntity, setSelectedEntity, data, loading, selectedRecord, setSelectedRecord, store, editor, viewer } = useSettingsStore()

    const records = getRecords(data)

    return (
        <Fade in={!loading} timeout={Config.DEFAULT_FADE_TIMEOUT} className={styles.Wrapper}>
            <div>
                <ActionsBar variant="DETAIL">
                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                            <BackButton />
                        </Grid>
                    </Grid>
                </ActionsBar>

                <Grid container justifyContent="space-around" className={styles.Main} component="main">
                    <Grid item xs={3} component="section">
                        <SettingsEntities entities={entities} selected={selectedEntity} onSelect={setSelectedEntity} />
                    </Grid>

                    <Grid item xs={4} component="section">
                        <SettingsRecords records={records} selected={selectedRecord} onSelect={setSelectedRecord} viewer={viewer} selectedEntity={selectedEntity} />
                    </Grid>

                    <Grid item xs={4} component="section">
                        <SettingsEditor store={store} selected={selectedRecord} deselect={() => setSelectedRecord(null)} editor={editor} entity={selectedEntity} />
                    </Grid>
                </Grid>
            </div>
        </Fade >
    )

}

export default SettingsView
