import { Field } from 'formik';
import { FC } from 'react';
import { useExecutingOrganizationsFieldQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import ContractConcessionOrganizationAutocomplete from '../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete';

interface ExecutingOrganizationFieldProps {
    disabled?: boolean
}

const ExecutingOrganizationField: FC<ExecutingOrganizationFieldProps> = props => {
    const { disabled } = props
    const concession = useConcession()
    const { data } = useExecutingOrganizationsFieldQuery({ fetchPolicy: "no-cache", variables: { concession: concession?.id, paging: { limit: 999 } } })

    const name = "contractConcessionOrganizations"
    const label = "Entidade Executante"
    const options = data?.concessionOrganizations?.nodes || []

    return (
        <Field
            name={name}
            component={ContractConcessionOrganizationAutocomplete}
            options={options}
            label={label}
            organizationType={2}
            disabled={disabled}
        />
    )
}

export default ExecutingOrganizationField
