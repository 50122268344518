import { IconButton, Tooltip } from "@mui/material";
import { MouseEvent } from 'react';

interface IconButtonTooltipProps {
    onClick?: (event: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    title: string
    children: any
}

export default function IconButtonTooltip(props: IconButtonTooltipProps) {
    const {
        onClick,
        disabled = false,
        title,
        children
    } = props

    return (
        <Tooltip title={title}>
            <span>
                <IconButton onClick={onClick} disabled={disabled}>
                    {children}
                </IconButton>
            </span>
        </Tooltip>
    )
}
