import * as Yup from "yup";
import { Maybe, WorkPermit } from "../../generated/graphql";
import { getInitialValues, requiredEndDate, REQUIRED_FIELD } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        startAt: Yup.date().required().default(null).typeError(REQUIRED_FIELD),
        endAt: requiredEndDate("startAt"),
        contract: Yup.mixed().required().default(null),
        observations: Yup.string().nullable().default("")
    })
}

const workPermitFormInit = (workPermit?: Maybe<WorkPermit>) => {
    const schema = getSchema()
    const initial = workPermit ? workPermit : getInitialValues(schema)
    return { schema, initial }
}


export default workPermitFormInit
