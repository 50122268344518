import { Actions } from "../../services/ability";
import { ContractStates } from "./ContractViewMoreActions";

/**
 * retorna true se o contrato estiver a ser criado ou se o estado
* do contrato for preparação
 */
const creatingOrPreparation = (params: any) => {
    const { state, action } = params
    if (action === Actions.CREATE || +state === +ContractStates.Preparation) return true
    return false
}

/**
 * retorna true se o contrato estiver a ser criado ou se o estado
* do contrato for preparação ou em curso
 */
const creatingOrOngoing = (params: any) => {
    const { state, action } = params
    if (action === Actions.CREATE || +state <= +ContractStates.Ongoing) return true
    return false
}

export const contractFieldRules = {
    contractType: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    interventionElement: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    interventionType: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    description: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    interventionLocation: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    contractRoads: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    contractConcessionOrganizations: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    service: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    contractUsers: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrOngoing(params) ? false : true
        },
    },
    startDate: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    endDate: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    contractDocuments: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrOngoing(params) ? false : true
        }
    },
    realStartDate: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrOngoing(params) ? false : true
        },
    },
    realEndDate: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrOngoing(params) ? false : true
        },
    }
};
