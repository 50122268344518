import { Close } from '@mui/icons-material';
import { Drawer, DrawerProps, Grid, IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';
import styles from './TemporaryDrawer.module.scss';

interface TemporaryDrawerProps {
    isOpen: boolean
    setIsOpen: (value: any) => void
    children: ReactNode
    hideBackdrop?: boolean
    transitionDuration?: number,
    anchor?: DrawerProps["anchor"]
}

const TemporaryDrawer: FC<TemporaryDrawerProps> = props => {
    const {
        isOpen,
        setIsOpen,
        children,
        hideBackdrop = true,
        transitionDuration = 300,
        anchor = "right"
    } = props

    return (
        <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor={anchor} variant="temporary" hideBackdrop={hideBackdrop} transitionDuration={transitionDuration}>
            <Grid className={styles.SideMenuHeader} container justifyContent="flex-end" alignItems="center">
                <IconButton onClick={() => setIsOpen(false)}>
                    <Close />
                </IconButton>
            </Grid>
            <Grid className={styles.Sidemenu}>
                {children}
            </Grid>
        </Drawer>

    );
};

export default TemporaryDrawer
