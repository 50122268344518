import { Block, Delete, Description, Done, LinearScale, Verified } from '@mui/icons-material';
import { useState } from 'react';
import useGoTo from '../../hooks/useGoto';
import { subcontractTransitionMap } from '../../hooks/useSettingManager';
import { UpdateSubcontractData } from '../../hooks/useSubcontractManager';
import useToast from '../../hooks/useToast';
import { Actions, Subjects } from '../../services/ability';
import { OperationResult, StateOption, Subcontract } from '../../types/types';
import { getTransitions } from '../../utils/states';
import CustomModal from '../CustomModal/CustomModal';
import MenuDivider from '../MenuDivider/MenuDivider';
import MoreMenu from '../MoreMenu/MoreMenu';
import { MoreMenuItem } from '../MoreMenuItem/MoreMenuItem';
import { MoreMenuItemPermission } from '../MoreMenuItemPermission/MoreMenuItemPermission';
import { MoreMenuItemPermissionWithConfirmation } from '../MoreMenuItemPermissionWithConfirmation/MoreMenuItemPermissionWithConfirmation';
import SubcontractDetails from '../SubcontractDetails/SubcontractDetails';

const stateOptions: StateOption[] = [
    {
        stateTo: "3",
        icon: <LinearScale fontSize="small" />,
        label: "Curso",
        action: Actions.UPDATE,
        subject: Subjects.SUBCONTRACT
    },
    {
        stateTo: "4",
        icon: <Done fontSize="small" />,
        label: "Concluído",
        action: Actions.UPDATE,
        subject: Subjects.SUBCONTRACT
    },
    {
        stateTo: "5",
        icon: <Block fontSize="small" />,
        label: "Cancelada",
        action: Actions.UPDATE,
        subject: Subjects.SUBCONTRACT
    },

]

interface SubcontractPreviewMoreActionsProps {
    subcontract: Partial<Subcontract>
    updateSubcontract: (data: UpdateSubcontractData) => Promise<OperationResult<Subcontract>>
    deleteSubcontract: (id: string) => Promise<OperationResult<Subcontract>>
}

export default function SubcontractPreviewMoreActions(props: SubcontractPreviewMoreActionsProps) {

    const { subcontract, updateSubcontract, deleteSubcontract } = props
    const { successToast, errorToast } = useToast()
    const { goToAccreditation } = useGoTo()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const transitionMap = subcontractTransitionMap
    const accreditationId = subcontract?.concessionOrganizationAccreditation?.id

    if (!subcontract) {
        return null
    }

    const handleShowDetails = () => {
        setIsOpen(true)
    }

    const handleShowAccreditation = () => {
        goToAccreditation(accreditationId)
    }

    const handleDelete = async () => {
        const result = await deleteSubcontract(subcontract?.id!)
        result.success ? successToast("Subcontrato removido") : errorToast("Erro ao remover subcontrato")
    }

    const handleTransition = async (stateTo: string) => {
        const id = subcontract.id!
        const result = await updateSubcontract({ id, state: stateTo })
        result.success ? successToast("Subcontrato atualizado") : errorToast("Erro ao atualizar subcontrato")
    }

    const transitions = getTransitions(
        transitionMap, subcontract?.contractConcessionOrganizationState?.id!)

    const options = stateOptions.filter((option: StateOption) => transitions.includes(option.stateTo))

    return (
        <div>
            <MoreMenu>
                <MoreMenuItem
                    icon={<Description fontSize="small" />}
                    label="Detalhes Subcontrato"
                    onClick={handleShowDetails}
                />
                <MoreMenuItem
                    icon={<Verified fontSize="small" />}
                    label="Consultar Acreditação"
                    onClick={handleShowAccreditation}
                    disabled={!accreditationId}
                />
                <MenuDivider />
                {options.map((option: StateOption) => {
                    const { action, subject, stateTo, icon, label } = option
                    return (
                        <MoreMenuItemPermission
                            icon={icon}
                            label={label}
                            onClick={() => handleTransition(stateTo)}
                            action={action}
                            subject={subject}
                        />
                    )
                })}
                <MenuDivider on={!!options?.length} />
                <MoreMenuItemPermissionWithConfirmation
                    icon={<Delete fontSize="small" style={{ color: "red" }} />}
                    label="Remover Subcontrato"
                    onClick={handleDelete}
                    action={Actions.DELETE}
                    subject={Subjects.SUBCONTRACT}
                />
            </MoreMenu>
            <CustomModal isActive={isOpen} handleClose={() => setIsOpen(false)}>
                <SubcontractDetails subcontract={subcontract} />
            </CustomModal>
        </div>

    )
}
