import { ConcessionOrganizationAccreditation, useConcessionOrganizationAccreditationQuery } from "../generated/graphql";
import { MaybeDeepPartial } from "../types/types";

export type Accreditation = ConcessionOrganizationAccreditation

export interface AccreditationRecordManager {
    accreditation?: MaybeDeepPartial<Accreditation>
    accreditationLoading: any
    accreditationError: any
    accreditationRefetch: any
}

interface AccreditationRecordManagerParams {
    id: string
}

export default function useAccreditationRecordManager(params: AccreditationRecordManagerParams): AccreditationRecordManager {

    const { id } = params
    const { data, loading, error, refetch } = useConcessionOrganizationAccreditationQuery({ variables: { id } })

    return {
        accreditation: data?.concessionOrganizationAccreditation,
        accreditationLoading: loading,
        accreditationError: error,
        accreditationRefetch: refetch
    }
}
