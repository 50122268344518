import { ConcessionOrganization, useCreateOneConcessionOrganizationMutation } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";
import useConcession from "./useConcession";

interface CreateConcessionOrganizationData {
    organizationId: string
    concessionId?: string
    organizationTypeId: string
}

export default function useConcessionOrganizationManager() {

    const [_create] = useCreateOneConcessionOrganizationMutation()
    const concession = useConcession()

    const createConcessionOrganization = async (data: CreateConcessionOrganizationData): Promise<OperationResult<ConcessionOrganization>> => {
        const { organizationId, organizationTypeId, concessionId = concession?.id } = data

        if (!concessionId) {
            throw new Error("Concession is undefined")
        }

        try {
            const response = await _create({
                variables: {
                    input: {
                        concessionOrganization: {
                            organization: organizationId,
                            concession: concessionId,
                            organizationType: organizationTypeId
                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response.errors)
                return { success: false, errors }
            }

            return { success: true, data: response.data?.createOneConcessionOrganization }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createConcessionOrganization
    }
}
