import { ApolloQueryResult } from "@apollo/client"
import { TextFieldProps, TypographyProps } from "@mui/material"
import { GraphQLError } from "graphql"
import { ReactNode } from "react"
import { FileRejection } from "react-dropzone"
import { ConcessionOrganizationAccreditationDocument, ContractConcessionOrganization, Maybe, Organization, SurveyRecordPhoto, SurveyRecordQuery, UpdateSurveyRecordPhoto } from "../generated/graphql"
import { FilterConfig, UseFiltersOnChange } from "../hooks/useFilters"
import { Actions, Subjects } from "../services/ability"

// aqui são definidos os tipos que são comuns a mais do que
// um cliente (componente, hook, ...)

export interface FilterProps {
    value: string[]
    onChange: UseFiltersOnChange
    allOptionName?: string
    multiple?: boolean,
    hideAllOption?: boolean
    parent?: any
    width?: number
}

export interface EmptyProps {
    message?: string
    variant?: TypographyProps['variant']
    color?: string
}

export interface RelationFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    autoFocus?: boolean
    disabled?: boolean
    limit?: number
}

export interface VerificationFieldProps extends RelationFieldProps {
    scope?: string
}

export interface SurveyRecordVerificationProps {
    surveyRecordVerification: any
    refetch: () => Promise<ApolloQueryResult<SurveyRecordQuery>>
    isNotUserEdit: boolean
}

export interface SurveyRecordVerificationsProps {
    surveyRecord?: any
    refetch: () => Promise<ApolloQueryResult<SurveyRecordQuery>>
    allowed: boolean
    isNotUserEdit: boolean
}

export interface SurveyRecordProps {
    surveyRecord?: any
    refetch?: () => Promise<ApolloQueryResult<SurveyRecordQuery>>
    allowed: boolean
    isNotUserEdit: boolean
}

export interface GalleryImageProps {
    image?: SurveyRecordPhoto,
    onDelete?: (image: any) => void
    onUpdate: (id: string, update: UpdateSurveyRecordPhoto) => void
    onDownload?: (image: any) => void
    disableDelete?: boolean
    disableDownload?: boolean
    isDragActive?: boolean
    disableUpdate?: boolean
    getRootProps?: any
}

export interface GalleryProps {
    images: any
    onDrop: (accepptedFiles: File[], refejectFiles: FileRejection[]) => void
    onDelete: (image: any) => void
    onUpdate: (id: string, update: UpdateSurveyRecordPhoto) => void
    onDownload: (image: any) => void
    disableAdd?: boolean
    disableDelete?: boolean
    disableDownload?: boolean
    disableUpdate?: boolean
}


export type Nullable<T> = T | null;

export interface ContractConcessionOrganizationUser {
    disabled?: boolean
}

export enum TasksListView {
    PLANNING = "PLANNING",
    CONTROLCENTER = "CONTROLCENTER",
    INTERVENTION = "INTERVENTION"
}

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}


export type MaybeDeepPartial<T> = Maybe<DeepPartial<T>>

export interface ExternalOrganizationConcessionOrganizationUsersProps {
    organization: Partial<Organization>
    onDeleteConcessionOrganizationUser: any
    organizationStore: any
}


export type Subcontract = ContractConcessionOrganization
export type AccreditationDocument = ConcessionOrganizationAccreditationDocument

export interface StateOption {
    stateTo: string
    icon: JSX.Element
    label: string
    action: Actions
    subject: Subjects

}

export interface TransitionMap {
    [key: string]: string[]
}

export type OperationResult<T> = {
    success: boolean;
    errors?: string[];
    data?: Partial<T>;
}

export interface DataProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

export type Anchor = HTMLButtonElement | null

export type SetAnchor = React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>

export interface APIGraphQLError extends GraphQLError {
    extensions: {
        exception?: {
            code: string
        }
    }
}
