import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { useEffect, useState } from "react"
import useFindUserByEmail from "../../hooks/useFindUserByEmail"
import { ConcessionOrganizationUsersMode } from "../ConcessionOrganizationUsers/ConcessionOrganizationUsers"
import SearchUserByEmailField from "../SearchUserByEmailField/SearchUserByEmailField"
import { findUserFormInit } from "./FindUserFormInit"

interface FindUserFormProps {
    setUser: any
    setMode: (mode: ConcessionOrganizationUsersMode) => void
}

export default function FindUserForm(props: FindUserFormProps) {
    const { setUser, setMode } = props
    const [findUser, { user }] = useFindUserByEmail()
    const [submitted, setSubmitted] = useState<boolean>(false)

    const { initial, schema } = findUserFormInit()

    const handleFind = (values: FormikValues) => {
        setSubmitted(true)
        const { email } = values
        findUser(email)
    }

    useEffect(() => {
        setUser(user)
    }, [user])

    return (
        <Formik initialValues={initial} onSubmit={handleFind} validationSchema={schema} validateOnBlur={false} validateOnChange={false} validateOnMount={false}>
            {() => {
                return (
                    <Form>

                        <Grid container justifyContent="space-between" alignItems="flex-end">
                            <Grid item xs={8}>
                                <SearchUserByEmailField />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" variant="contained" size="small">
                                    Pesquisar
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 30 }}>
                            {submitted && !user && <Typography color="secondary">
                                Utilizador não encontrado <b onClick={() => setMode("CREATE")} style={{ cursor: "pointer" }}>
                                    clique para criar.
                                </b>
                            </Typography>}
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
