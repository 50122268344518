import { ApolloProvider } from '@apollo/client';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import pt from 'date-fns/locale/pt';
import { SnackbarProvider } from 'notistack';
import { parse, stringify } from 'query-string';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AccreditationsView from './components/AccreditationsView/AccreditationsView';
import AccreditationView from './components/AccreditationView/AccreditationView';
import AuthView from './components/AuthView/AuthView';
import ContractDocumentsView from './components/ContractDocumentsView/ContractDocumentsView';
import ContractsView from './components/ContractsView/ContractsView';
import ContractView from './components/ContractView/ContractView';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import InterventionsMap from './components/InterventionsMap/InterventionsMap';
import InterventionsView from './components/InterventionsView/InterventionsView';
import InterventionView from './components/InterventionView/InterventionView';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import NotFound from './components/NotFound/NotFound';
import OrganizationView from './components/OrganizationView/OrganizationView';
import ProfileView from './components/ProfileView/ProfileView';
import SetPassword from './components/SetPassword/SetPassword';
import SettingsView from './components/SettingsView/SettingsView';
import Status from './components/Status/Status';
import SubcontractOrganizationView from './components/SubcontractOrganizationView/SubcontractOrganizationView';
import SubcontractsView from './components/SubcontractsView/SubcontractsView';
import SurveyRecordsView from './components/SurveyRecordsView/SurveyRecordsView';
import SurveyRecordView from './components/SurveyRecordView/SurveyRecordView';
import TasksView from './components/TasksView/TasksView';
import UserRedirect from './components/UserRedirect/UserRedirect';
import WeekView from './components/WeekView/WeekView';
import Welcome from './components/Welcome/Welcome';
import WorkPermitsView from './components/WorkPermitsView/WorkPermitsView';
import WorkPermitView from './components/WorkPermitView/WorkPermitView';
import { Actions, Subjects } from './services/ability';
import AuthProvider from './services/auth';
import { client } from './services/client';
import Config from './services/config';
import WorkspaceProvider from './services/workspace';
import theme from './theme';

LicenseInfo.setLicenseKey(Config.MUI_LICENSE_KEY);

function App() {
    const action = Actions.LIST
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <SnackbarProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                        <BrowserRouter>
                            <QueryParamProvider
                                adapter={ReactRouter6Adapter}
                                options={{
                                    searchStringToObject: parse,
                                    objectToSearchString: stringify,
                                }}
                            >
                                <WorkspaceProvider>
                                    <ThemeProvider theme={theme}>
                                        <Routes>
                                            <Route path="" element={<Layout><Welcome /></Layout>} />
                                            <Route path="accreditations" element={<Layout><AccreditationsView /></Layout>} />
                                            <Route path="accreditation/:id" element={<Layout><AccreditationView /></Layout>} />
                                            <Route path="dashboard" element={<Layout action={action} subject={Subjects.DASHBOARD}><TasksView /></Layout>} />
                                            <Route path="documents/:id" element={<Layout><ContractDocumentsView /></Layout>} />
                                            <Route path="week" element={<Layout action={action} subject={Subjects.WEEKLYPLANNING}><WeekView /></Layout>} />
                                            <Route path="contracts" element={<Layout action={action} subject={Subjects.CONTRACT}><ContractsView /></Layout>} />
                                            <Route path="contract/:id" element={<Layout action={action} subject={Subjects.CONTRACT}><ContractView /></Layout>} />
                                            <Route path="create-contract" element={<Layout action={action} subject={Subjects.CONTRACT}><ContractView /></Layout>} />
                                            <Route path="interventions" element={<Layout action={action} subject={Subjects.INTERVENTION}><InterventionsView /></Layout>} />
                                            <Route path="intervention/:id" element={<Layout action={action} subject={Subjects.INTERVENTION}><InterventionView /></Layout>} />
                                            <Route path="create-intervention" element={<Layout action={action} subject={Subjects.INTERVENTION}><InterventionView /></Layout>} />
                                            <Route path="work-permits" element={<Layout action={action} subject={Subjects.WORKPERMIT}><WorkPermitsView /></Layout>} />
                                            <Route path="work-permit/:id" element={<Layout action={action} subject={Subjects.WORKPERMIT}><WorkPermitView /></Layout>} />
                                            <Route path="create-work-permit" element={<Layout action={action} subject={Subjects.WORKPERMIT}><WorkPermitView /></Layout>} />
                                            <Route path="survey-records" element={<Layout action={action} subject={Subjects.SURVEYRECORD}><SurveyRecordsView /></Layout>} />
                                            <Route path="survey-record/:id" element={<Layout action={action} subject={Subjects.SURVEYRECORD}><SurveyRecordView /></Layout>} />
                                            <Route path="create-survey-record" element={<Layout action={action} subject={Subjects.SURVEYRECORD}><SurveyRecordView /></Layout>} />
                                            <Route path="settings" element={<Layout action={action} subject={Subjects.SETTING}><SettingsView /></Layout>} />
                                            <Route path="profile" element={<Layout><ProfileView /></Layout>} />
                                            <Route path="organization" element={<Layout action={action} subject={Subjects.ORGANIZATION}><OrganizationView /></Layout>} />
                                            <Route path="organization/:id" element={<Layout action={action} subject={Subjects.ORGANIZATION}><OrganizationView /></Layout>} />
                                            <Route path="profile/:id" element={<Layout><ProfileView /></Layout>} />
                                            <Route path="status" element={<Status />} />
                                            <Route path="subcontracts" element={<Layout action={action} subject={Subjects.SUBCONTRACT}><SubcontractsView /></Layout>} />
                                            <Route path="subcontract-organization" element={<Layout action={action} subject={Subjects.SUBCONTRACTORGANIZATION}><SubcontractOrganizationView /></Layout>} />
                                            <Route path="login" element={<UserRedirect><AuthView><Login /></AuthView></UserRedirect>} />
                                            <Route path="forgot-password" element={<UserRedirect><AuthView><ForgotPassword /></AuthView></UserRedirect>} />
                                            <Route path="set-password" element={<UserRedirect><AuthView><SetPassword /></AuthView></UserRedirect>} />
                                            <Route path="*" element={<NotFound />} />
                                            <Route path="interventions-map" element={<Layout><InterventionsMap /></Layout>} />
                                        </Routes>
                                    </ThemeProvider>
                                </WorkspaceProvider>
                            </QueryParamProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </SnackbarProvider>
            </AuthProvider>
        </ApolloProvider >
    );
}

export default App;
