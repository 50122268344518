import { Field } from 'formik';
import { FC } from 'react';
import { useContractingOrganizationsFieldQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import ContractConcessionOrganizationAutocomplete from '../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete';

interface ContractingOrganizationFieldProps {
    disabled?: boolean
}

const ContractingOrganizationField: FC<ContractingOrganizationFieldProps> = props => {
    const { disabled } = props
    const concession = useConcession()
    const { data } = useContractingOrganizationsFieldQuery({ fetchPolicy: "no-cache", variables: { concession: concession?.id, paging: {limit: 999} } })

    const name = "contractConcessionOrganizations"
    const label = "Entidade Contratante"
    const options = data?.concessionOrganizations?.nodes || []

    return (
        <Field
            name={name}
            component={ContractConcessionOrganizationAutocomplete}
            options={options}
            label={label}
            disabled={disabled}
            organizationType={1}
        />

    )
}

export default ContractingOrganizationField
