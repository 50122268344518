import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useVerificationsQuery } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import { VerificationFieldProps } from '../../types/types';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

export default function VerificationField(props: VerificationFieldProps) {
    const {
        name = "verification",
        label = "tipo",
        textFieldProps = { variant: "standard" },
        autoFocus,
        disabled,
        limit = 999,
        scope = null
    } = props


    const scopeRef = useRef(scope)
    const context = useFormikContext()
    const concession = useConcession()

    const scopeFilter = scope ? { id: { eq: scope } } : { id: { gt: 0 } };
    const concessionFilter = { id: { eq: concession?.id } };

    const queryFilter = {
        verificationScope: scopeFilter,
        concession: concessionFilter
    };

    useEffect(() => {
        const previous = scopeRef.current;
        if (previous && previous !== scope) {
            context?.setFieldValue(name, null)
        }
        scopeRef.current = scope
    }, [scope])


    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useVerificationsQuery}
            textFieldProps={textFieldProps}
            autoFocus={autoFocus}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={queryFilter}
            querySorting={[{ field: "name", direction: "ASC" }]}
        />
    )
}
