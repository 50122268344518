import { useContractStatesQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function ContractStateFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useContractStatesQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Estado"
            query={query}
            field="contractState"
            multiple={true}
            width={120}
        />
    )
}
