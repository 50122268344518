import { Roles } from "../../../services/ability";
import { isSubset, uniqueValues } from "../../../services/utils";

export default function testContractUsers(array: any): boolean {

    const requiredRoles = [
        Roles.CONTRACTMANAGER,
        Roles.SERVICEMANAGER,
        Roles.SAFETYTECHNICIAN,
        Roles.EXTERNALAUTHORITY,
        Roles.EXTERNALMANAGER,
        Roles.EXTERNALTECHNICIAN
    ]

    const filledRoles = array?.map((obj: any) => {
        return obj?.user?.role || obj?.role
    })

    const uniqueFilledRoles = uniqueValues(filledRoles)
    return isSubset(requiredRoles, uniqueFilledRoles)
}
