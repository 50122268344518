import qrcodegen from 'nayuki-qr-code-generator';

// https://github.com/nayuki/QR-Code-generator/blob/master/typescript-javascript/qrcodegen-output-demo.ts
function drawCanvas(qr: qrcodegen.QrCode, scale: number, border: number, lightColor: string, darkColor: string, canvas: HTMLCanvasElement): void {
    if (scale <= 0 || border < 0)
        throw new RangeError("Value out of range");
    const width: number = (qr.size + border * 2) * scale;
    canvas.width = width;
    canvas.height = width;
    let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    for (let y = -border; y < qr.size + border; y++) {
        for (let x = -border; x < qr.size + border; x++) {
            ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
            ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale);
        }
    }
}

export const generateQRCode = (text: string) => {
    const QRC = qrcodegen.QrCode;
    const qrcode = QRC.encodeText(text, QRC.Ecc.MEDIUM);
    const canvas = document.createElement("canvas");
    drawCanvas(qrcode, 10, 3, "#FFFFFF", "#000000", canvas);
    return canvas.toDataURL("image/png")
}
