import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import { useHasInternalRole } from "../../hooks/useHasRole";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ContractExecutingOrganizationFilter from "../ContractExecutingOrganizationFilter/ContractExecutingOrganizationFilter";
import ContractsMoreFilters from "../ContractsMoreFilters/ContractsMoreFilters";
import ContractStateFilter from "../ContractStateFilter/ContractStateFilter";
import ContractTypeFilter from "../ContractTypeFilter/ContractTypeFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import InterventionTypeFilter from "../InterventionTypeFilter/InterventionTypeFilter";
import RoadFilter from "../RoadFilter/RoadFilter";
import Search from "../Search/Search";
import ServiceFilter from "../ServiceFilter/ServiceFilter";
import Show from "../Show/Show";
import styles from './ContractsFilters.module.scss';

const ContractsFilters = () => {
    const context = useContext(DataViewContext)
    const isInternalUser = useHasInternalRole()

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.ContractsFilters}>
                <ContractsMoreFilters />
                <div style={{ marginRight: 20 }}></div>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <Show on={isInternalUser}>
                    <ContractExecutingOrganizationFilter value={context.filters.executingOrganization} onChange={context.setFilter} />
                </Show>
                <RoadFilter value={context.filters.road} onChange={context.setFilter} />
                <ContractStateFilter value={context.filters.contractState} onChange={context.setFilter} />
                <ServiceFilter value={context.filters.service} onChange={context.setFilter} />
                <ContractTypeFilter value={context.filters.contractType} onChange={context.setFilter} />
                <InterventionTypeFilter value={context.filters.interventionType} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default ContractsFilters;
