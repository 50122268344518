import { Grid, LabelDisplayedRowsArgs } from "@mui/material";
import { DataGridPro, GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Config from "../../services/config";

// deviamos suportar nested relation getter, usando dot path
export const relationGetter = (params: any, relationName: string, field: string = "name", fallback: string = "") => {
    try {
        return params?.row?.[relationName][field]
    } catch (e: unknown) {
        return fallback
    }
}

export const noResults = () => {
    return (
        <div style={{ height: "100%" }}>
            <Grid container justifyContent="center" alignItems="middle">
                <p>Sem Resultados</p>
            </Grid>
        </div>
    )
}

const FooterRowSelected = (props: any) => {
    const { count } = props
    const text = count === 1 ? `${count} linha selecionada` : `${count} linhas selecionadas`
    return <div>{text}</div>
}

const footerRowSelected = (count: number): ReactNode => {
    return <FooterRowSelected count={count} />
}

const FooterTotalVisibleRows = (props: any) => {
    const { paginationInfo } = props
    const { from, to, count } = paginationInfo
    return <div>{from}-{to} de {count}</div >
}

const footerTotalVisibleRows = (paginationInfo: LabelDisplayedRowsArgs) => {
    return <FooterTotalVisibleRows paginationInfo={paginationInfo} />
}

interface DatagridProps {
    rows: GridRowsProp<any>
    columns: GridColDef[]
    loading: boolean
    processRowUpdate?: (newRow: any, oldRow: any) => any
    onProcessRowUpdateError?: any
    onCellClick?: any,
    disableSelectionOnClick?: boolean
    pageSize?: number,
    getRowClassName?: (params: GridRowParams) => string
    onRowSelectionModelChange?: any
    rowSelectionModel?: any
    paginationModel?: any
    setPaginationModel?: any
    totalCount?: number
    setSortModel?: any
    setColumnOrderModel?: any
    columnVisibilityModel?: any
    setColumnVisibilityModel?: any
}

const Datagrid: FC<DatagridProps> = props => {
    const { rows,
        columns,
        loading,
        processRowUpdate,
        onProcessRowUpdateError,
        onCellClick,
        disableSelectionOnClick,
        getRowClassName,
        rowSelectionModel,
        onRowSelectionModelChange,
        paginationModel,
        setPaginationModel,
        totalCount,
        setSortModel,
        setColumnOrderModel,
        columnVisibilityModel,
        setColumnVisibilityModel
    } = props

    // https://mui.com/x/react-data-grid/pagination/#basic-implementation
    const [rowCountState, setRowCountState] = useState(totalCount);

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            totalCount !== undefined ? totalCount : prevRowCountState,
        );
    }, [totalCount, setRowCountState]);


    const gridRef = useRef<HTMLDivElement | null>(null)

    return (
        <DataGridPro
            ref={gridRef}
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
            }}
            localeText={{
                columnsPanelTextFieldPlaceholder: "nome coluna",
                columnsPanelShowAllButton: "Esconder todas",
                columnsPanelHideAllButton: "Mostrar todas",
                columnsPanelTextFieldLabel: "Pesquisar coluna",
                checkboxSelectionHeaderName: "Selecionar",
                columnMenuHideColumn: "Esconder",
                columnMenuShowColumns: "Mostrar Colunas",
                columnMenuManageColumns: "Gerir Colunas",
                pinToLeft: "Fixar à esquerda",
                pinToRight: "Fixar à direita",
                unpin: "Desafixar",
                columnMenuUnsort: "Desordenar",
                columnMenuSortAsc: "Ordenar ASC",
                columnMenuSortDesc: "Ordenar DESC",
                footerRowSelected
            }}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick={disableSelectionOnClick}
            pagination={true}
            rowCount={rowCountState}
            pageSizeOptions={Config.PAGE_SIZE_OPTIONS}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            checkboxSelection
            hideFooter={false}
            loading={loading}
            components={{ NoResultsOverlay: noResults, NoRowsOverlay: noResults }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            onCellClick={onCellClick}
            getRowClassName={getRowClassName}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            autoHeight
            sortingMode="server"
            onSortModelChange={setSortModel}
            onColumnOrderChange={setColumnOrderModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            slotProps={{
                pagination: {
                    labelRowsPerPage: "Linhas por página",
                    labelDisplayedRows: footerTotalVisibleRows
                }
            }}
        />
    )
}

export default Datagrid;
