import * as Yup from "yup";
import { Accreditation } from "../../hooks/useAccreditationRecordManager";
import { getInitialValues } from "../../services/config";
import { MaybeDeepPartial } from "../../types/types";

const getSchema = () => {
    return Yup.object().shape({
        startDate: Yup.date().required().default(null),
        endDate: Yup.date().required().default(null),
        observations: Yup.string().nullable().default("")
    })
}

export default function accreditationFormInit(accreditation?: MaybeDeepPartial<Accreditation>) {
    const schema = getSchema()
    const initial = accreditation ? accreditation : getInitialValues(schema)
    return { schema, initial }
}
