import { getNextDay } from "../services/utils";
import { adjustDateForTimezoneOffset } from "./dates";

export const stateLookupExpression = (value: string[]) => {
    if (value?.length) return { in: value }
}

export const boolLookupExpression = (value: string) => {
    const is = value === "true" ? true : false
    return { is }
}

export const numberLookupExpression = (value: string[]) => {
    if (value?.length) return { in: value.map(val => +val) }
}

export const searchExpFunc = (value: any) => {
    return {
        iLike: `%${value}%`
    }
}

export const taskSearchExpFunc = (value: any) => {
    return [
        { name: { iLike: `%${value}%` } },
        { reason: { iLike: `%${value}%` } }
    ]
}

export const startDateExpFunc = (value: Date) => {
    const lower = value.toISOString()
    const upper = getNextDay(value).toISOString()
    return { between: { lower, upper } }
}

export const isoWeekExpFunc = (value: any) => {
    return { eq: value }
}

export const customFilterIn = (value: any) => {
    return { in: value }
}


export const dateGteLookup = (value: any) => {
    const adjusted = adjustDateForTimezoneOffset(value)
    return { gte: adjusted }
}

export const dateLteLookup = (value: any) => {
    const adjusted = adjustDateForTimezoneOffset(value)
    return { startDate: { lte: adjusted } }
}
