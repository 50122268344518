import useParamId from "../../hooks/useParamId"
import OrganizationDocumentsView from "../OrganizationDocumentsView/OrganizationDocumentsView"

const OrganizationView = () => {

    const id = useParamId()

    return (
        <OrganizationDocumentsView id={id} />
    )
}

export default OrganizationView
