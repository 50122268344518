import { InterventionStates } from '../../../generated/graphql';
import { translate } from '../../../hooks/useTranslate';
import { black, blue, gray, green, red, yellow } from '../../../services/config';
import styles from './ContractStateRender.module.scss';

export const getInterventionStateColor = (state: string) => {
    const contractColors: { [key: string]: string } = {
        [InterventionStates.Preparation]: gray,
        [InterventionStates.Submitted]: yellow,
        [InterventionStates.Closed]: blue,
        [InterventionStates.Cancelled]: red,
        [InterventionStates.Review]: yellow,
        [InterventionStates.Approved]: green
    }

    return contractColors[state] || black
}

export const InterventionStateRender = (params: any) => {
    const backgroundColor = getInterventionStateColor(params?.row?.state)
    return (
        <div className={styles.ContractState} style={{ backgroundColor }}>
            {translate(params?.row?.state, "capitalize")}</div>
    )
}
