import { FC } from 'react';
import { DocumentStateSortFields, SortDirection, useDocumentStatesQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

const DocumentStateFilter: FC<FilterProps> = props => {
    const { value, onChange, allOptionName, multiple = false, hideAllOption = false } = props
    const query = useDocumentStatesQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label={"Estado"}
            query={query}
            field="documentState"
            allOptionName={allOptionName}
            multiple={multiple}
            hideAllOption={hideAllOption}
            sorting={{ field: DocumentStateSortFields.Id, direction: SortDirection.Asc }}
        />
    )
}

export default DocumentStateFilter
