import { FC } from 'react';
import { useContractDocumentCategoriesQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

const ContractDocumentCategoryFilter: FC<FilterProps> = props => {
    const { value, onChange, allOptionName, multiple = false, hideAllOption } = props
    const query = useContractDocumentCategoriesQuery
    const concession = useConcession()

    const filter = {
        concession: { id: { eq: concession?.id } },
        parent: { id: { is: null } }
    }

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label={"Categoria"}
            query={query}
            field="contractDocumentCategory"
            filter={filter}
            allOptionName={allOptionName}
            multiple={multiple}
            hideAllOption={hideAllOption}
            sorting={[{ field: "name", direction: "ASC" }]}
            paging={{ limit: 999 }}
        />
    )
}

export default ContractDocumentCategoryFilter
