import { ArrayParam, StringParam } from "use-query-params"
import { searchExpFunc } from "../../utils/lookups"
import SubcontractsActions from "../SubcontractsActions/SubcontractsActions"
import SubcontractsFilters from "../SubcontractsFilters/SubcontractsFilters"
import SubcontractsList from "../SubcontractsList/SubcontractsList"
import SubcontractProvider from "./SubcontractProvider"
import styles from './SubcontractsView.module.scss'

export default function SubcontractsView() {

    const filterConfig = [
        { name: "description", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "contractConcessionOrganizationState", variant: ArrayParam, defaultValue: [] },
    ]

    return (
        <div className={styles.Wrapper}>
            <SubcontractProvider filterConfig={filterConfig}>
                <SubcontractsActions />
                <SubcontractsFilters />
                <SubcontractsList />
            </SubcontractProvider>
        </div>
    )
}
