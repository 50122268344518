import { endOfWeek, format, getISOWeek, getISOWeekYear, setWeek, setYear, startOfWeek } from 'date-fns';
import { Maybe } from '../generated/graphql';
import config from '../services/config';
import { parseDateGQLField } from '../services/utils';
export { getCurrentIsoYear } from '../services/utils';

interface WeekWithYear {
    year: number;
    week: number;
}

export function adjustDateForTimezoneOffset(inputDate: Date): string {
    inputDate.setHours(12);
    let dateString = format(inputDate, 'yyyy-MM-dd');
    return dateString;
}

export function startOfWeekOfWeekWithYear(weekYear: WeekWithYear): Date {
    const { week, year } = weekYear
    let date = new Date()
    date = setYear(date, year)
    date = setWeek(date, week)
    return startOfWeek(date, { weekStartsOn: 1 })
}

export function endOfWeekOfWeekWithYear(weekYear: WeekWithYear): Date {
    const { week, year } = weekYear
    let date = new Date();
    date = setYear(date, year)
    date = setWeek(date, week)
    return endOfWeek(date, { weekStartsOn: 1 })
}

export function formatDate(date: Date | null) {
    if (!date) return "-"
    try {
        return format(date, config.GIN_DATE_FORMAT)
    } catch (e: unknown) {
        return "-"
    }
}

export function getISOWeekWithYearFromDate(date: Date): WeekWithYear {
    return { week: getISOWeek(date), year: getISOWeekYear(date) }
}

export function getISOWeekWithYearFromDateString(dateStr: string): Maybe<WeekWithYear> {
    const date = parseDateGQLField(dateStr)
    if (!date) return null
    return getISOWeekWithYearFromDate(date)
}

export function isWeekWithYearInRange(startWeek: WeekWithYear, endWeek: WeekWithYear, week: WeekWithYear): boolean {
    const start = new Date(startWeek.year, 0, (startWeek.week - 1) * 7);
    const end = new Date(endWeek.year, 0, (endWeek.week - 1) * 7);
    const current = new Date(week.year, 0, (week.week - 1) * 7);
    return current >= start && current <= end;
}
