import { useCreateOwnSubcontractOrganizationDocumentMutation, useDeleteManyConcessionOrganizationDocumentsMutation, useDeleteOwnSubcontractOrganizationDocumentMutation, useOwnSubcontractOrganizationDocumentsQuery, useUpdateManyConcessionOrganizationDocumentsMutation, useUpdateOwnSubcontractOrganizationDocumentMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const OwnOrganizationDocumentsDataSource = {
    readFunc: useOwnSubcontractOrganizationDocumentsQuery,
    createFunc: useCreateOwnSubcontractOrganizationDocumentMutation,
    updateFunc: useUpdateOwnSubcontractOrganizationDocumentMutation,
    deleteFunc: useDeleteOwnSubcontractOrganizationDocumentMutation,
    updateManyFunc: useUpdateManyConcessionOrganizationDocumentsMutation,
    deleteManyFunc: useDeleteManyConcessionOrganizationDocumentsMutation
}

export default function OwnOrganizationDocumentProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={OwnOrganizationDocumentsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
