import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { UseFiltersOnChange } from '../../hooks/useFilters';
import { firstProperty } from '../../services/utils';

interface SelectFilterProps {
    value: string[]
    onChange: UseFiltersOnChange
    multiple?: boolean
    label?: string
    query: () => any
    field: string
    filter?: any
    sorting?: any
    paging?: any
    allOptionName?: string
    hideAllOption?: boolean
    width?: number
    getListItemText?: (node: any) => any
}

export const SelectFilter: FC<SelectFilterProps> = props => {
    const {
        value,
        onChange,
        multiple = false,
        label,
        query,
        field,
        filter,
        sorting,
        paging,
        allOptionName = "TODOS",
        hideAllOption = false,
        width = label ? label.length * 20 : 200,
        getListItemText
    } = props


    const inputProps = { disableUnderline: true }


    const variables = {}

    if (filter) {
        // @ts-ignore
        variables.filter = filter
    }

    if (sorting) {
        // @ts-ignore
        variables.sorting = sorting
    }

    if (paging) {
        // @ts-ignore
        variables.paging = paging
    }


    // @ts-ignore
    const { data } = query({ fetchPolicy: "no-cache", variables })

    const collection = firstProperty(data)
    const nodes = collection ? data?.[collection]?.nodes : []

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange(field, value)
    }

    const findById = (id: string) => {
        if (collection) {
            return data?.[collection]?.nodes.find((obj: any) => obj?.id === id)
        }
    }

    const render = (selected: string[]) => {
        const first = selected[0]
        const obj = findById(first)
        return selected.length === 1 ? _getListItemText(obj) : _getListItemText(obj) + '...'
    }

    const _getListItemText = (node: any) => {
        return getListItemText ? getListItemText(node) : node?.name
    }

    return (
        <FormControl sx={{ m: 1, width }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                multiple={multiple}
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 750
                        }
                    }
                }}
            >
                {!multiple && <MenuItem key={"__all__"} value={""}>
                    <ListItemText primary={<em>{!hideAllOption ? allOptionName : ""}</em>} />
                </MenuItem>}

                {nodes?.length && nodes?.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        {multiple && <Checkbox checked={value.includes(node?.id)} />}
                        <ListItemText primary={_getListItemText(node)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
