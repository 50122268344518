import * as Yup from "yup";
import { SurveyRecord } from "../../generated/graphql";
import { getInitialValues } from "../../services/config"


const getSchema = () => {
    return Yup.object().shape({
        description: Yup.string().required().default(""),
        startPk: Yup.string().pk().required().default(""),
        endPk: Yup.string().pk().required().default(""),
        surveyedAt: Yup.mixed().required().default(null),
        contract: Yup.mixed().required().default(null),
        participants: Yup.string().default(""),
        weatherCondition: Yup.mixed().required().default(null),
        road: Yup.mixed().required().default(null),
        startJunction: Yup.mixed().required().default(null),
        endJunction: Yup.mixed().required().default(null),
        observations: Yup.string().nullable().default("")
    })
}

const surveyRecordFormInit = (surveyRecord?: SurveyRecord) => {
    const schema = getSchema()
    const initial = surveyRecord ? surveyRecord : getInitialValues(schema)
    return { schema, initial }
}


export default surveyRecordFormInit
