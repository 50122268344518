import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { Maybe } from '../../generated/graphql';
import Config from '../../services/config';



// usado na datagrid - @todo definir se fica aqui ou na data grid

export const dateFormatter = (params: any): Maybe<string> => {
    try {
        return format(params?.value, Config.GIN_DATE_FORMAT)
    } catch (e) {
        return null
    }
}

export const dateTimeFormatter = (params: any): Maybe<string> => {
    try {
        return format(params?.value, Config.GIN_DATETIME_FORMAT)
    } catch (e: unknown) {
        return null
    }
}


//


export const formatDate = (date: string, formatStr: string = Config?.GIN_DATE_FORMAT) => {
    const parsedDate = parseISO(date);
    return format(parsedDate, formatStr, { locale: pt }); // qual o propósito do local neste caso?
}

// componentes

interface FormatDateProps {
    date?: Maybe<Date>
}

export const FormatDate: FC<FormatDateProps> = props => {
    const { date } = props
    try {
        const formatted = format(date!, Config.GIN_DATE_FORMAT)
        return <span>{formatted}</span>
    } catch (_) { }
    return <span></span>
}

interface FormatDateTimeProps {
    datetime?: Maybe<Date>
}

export const FormatDateTime: FC<FormatDateTimeProps> = props => {
    const { datetime } = props
    try {
        const formatted = format(datetime!, Config.GIN_DATETIME_FORMAT)
        return <span>{formatted}</span>
    } catch (_) {
        return <span></span>
    }
}
