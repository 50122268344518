import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useUpdateMeMutation } from '../../generated/graphql'
import useToast from '../../hooks/useToast'
import { useAuth } from '../../services/auth'
import { pick } from '../../services/utils'
import InputPasswordField from '../InputPasswordField/InputPasswordField'
import InputTextField from '../InputTextField/InputTextField'
import SubmitButton from '../SubmitButton/SubmitButton'
import profileFormInit from './ProfileFormInit'
import styles from './ProfileView.module.scss'

const ProfileView = () => {

    const auth = useAuth()
    const user = pick(auth?.user, ["name", "phone"])
    const [updateMe] = useUpdateMeMutation()
    const { schema, initial } = profileFormInit(user)
    const { successToast, errorToast } = useToast()

    const handleSuccess = () => {
        successToast("Dados de perfil atualizados")
        auth?.refresh()
    }

    const handleError = () => {
        errorToast("Erro ao atualizar dados perfil.")
    }

    const handleSubmit = async (values: FormikValues) => {
        const response = await updateMe({ variables: { input: { ...values } } })
        response?.errors?.length ? handleError() : handleSuccess()
    }

    return (
        <div className={styles.Wrapper}>

            <Grid container justifyContent="flex-start" className={styles.Main} component="main">

                <Grid item xs={5} className={styles.Section} component="section">
                    <Card style={{ padding: 20 }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography variant="h4" color="primary">Perfil Utilizador</Typography >
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
                                    {() => {
                                        return (
                                            <Form style={{ minWidth: 250 }}>
                                                <p>
                                                    <InputTextField name="name" label="nome" fullWidth />
                                                </p>
                                                <p>
                                                    <InputTextField name="phone" label="telefone" fullWidth />
                                                </p>
                                                <p>
                                                    <InputPasswordField label="nova palavra-passe" fullWidth />
                                                </p>

                                                <SubmitButton />
                                            </Form>
                                        )
                                    }}
                                </Formik>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </div >

    )
}


export default ProfileView
