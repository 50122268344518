import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Autocomplete, Checkbox, TextField } from "@mui/material"
import { ConcessionOrganizationUserRoles, ConcessionUser, ContractUser } from "../../generated/graphql"
import { useShowArrayFieldError } from "../../hooks/useShowArrayFieldError"
import Config from "../../services/config"
import getContractUserValue from './Helpers/getContractUserValue'


export const findByUserType = (contractUsers: ContractUser[], userRole: ConcessionOrganizationUserRoles) => {
    return contractUsers.find(contractUser => contractUser?.role === userRole)
}

export const getContractExternalAuthority = (contractUsers: ContractUser[]) => {
    return findByUserType(contractUsers, ConcessionOrganizationUserRoles.Externalauthority)
}

export const getContractExternalManager = (contractUsers: ContractUser[]) => {
    return findByUserType(contractUsers, ConcessionOrganizationUserRoles.Externalmanager)
}

export const getContractExternalTechnician = (contractUsers: ContractUser[]) => {
    return findByUserType(contractUsers, ConcessionOrganizationUserRoles.Externaltechnician)
}

function sortConcessionUsers(a: any, b: any) {
    if (a?.user?.name < b?.user?.name) return -1
    if (a?.user?.name > b?.user?.name) return 1
    return 0
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ContractUserAutocomplete = (props: any) => {
    const { form, field, options, role, label, disabled } = props

    const { error, helperText } = useShowArrayFieldError(field.name, role)

    const filterOut = (contractUsers: any[]) => {
        return contractUsers.filter((contractUser: ContractUser) => contractUser?.role !== role)
    }

    const handleChange = (_: any, value: any) => {
        const currentValues = filterOut(field.value)
        form.setFieldValue(field.name, [...currentValues, ...value])
    }

    const getOptionLabel = (option: ConcessionUser) => {
        return option?.user?.name || ""
    }

    const renderTags = (value: any[]) => {
        if (!value?.length) return null
        if (value?.length === 1) return value[0]?.user?.name
        return `${value[0]?.user?.name}...`
    }

    let sortedOptions = [...options].sort(sortConcessionUsers)
    let value = getContractUserValue(options, field.value)

    return (
        <div>
            <Autocomplete
                disablePortal
                options={sortedOptions}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option?.user?.name || ""}
                    </li>
                )}

                sx={{ width: 300 }}
                value={value}
                noOptionsText={Config.NO_OPTIONS_TEXT}
                getOptionLabel={getOptionLabel}
                onChange={handleChange}
                renderTags={renderTags}
                disabled={disabled}
                multiple
                renderInput={(params) => <TextField {...params} variant="standard" label={label} error={error} helperText={helperText} />
                }
            />
        </div>
    )
}

export default ContractUserAutocomplete
