import { useServicesQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';


export default function ServiceFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useServicesQuery
    const concession = useConcession()

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Serviço"
            query={query}
            field="service"
            filter={{ concession: { id: { eq: concession?.id } } }}
            multiple={true}
            paging={{ limit: 999 }}
        />
    )
}
