import { Fade } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Maybe } from '../../generated/graphql';
import useTaskForm from '../../hooks/useTaskForm';
import Config from '../../services/config';
import { TasksListView } from '../../types/types';
import Datagrid from '../Datagrid/Datagrid';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import TaskForm from '../TaskForm/TaskForm';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import getTaskColumns from './Helpers/getTaskColumns';
import styles from './TasksList.module.scss';

interface TasksListProps {
    view: TasksListView
}

interface TaskListViewConfiguration {
    name: Maybe<string>,
    workspace: string,
    classname: string,
    columns: GridColDef[],
    showControlCenter: boolean
}

const getViewConfig = (view: TasksListView): TaskListViewConfiguration => {

    const configurations = {
        [TasksListView.PLANNING]: {
            name: "Planeamento",
            workspace: "planningView",
            classname: styles.TasksLists,
            columns: getTaskColumns(),
            showControlCenter: false
        },
        [TasksListView.CONTROLCENTER]: {
            name: "Centro Controlo",
            workspace: "controlCenterView",
            classname: styles.TasksLists,
            columns: getTaskColumns(),
            showControlCenter: true
        },
        [TasksListView.INTERVENTION]: {
            name: null,
            workspace: "interventionTasksView",
            classname: styles.InterventionTasks,
            columns: getTaskColumns(["executingOrganization", "workPermit", "incident", "intervention", "controlCenterObservations"]),
            showControlCenter: false
        }
    }

    // @ts-ignore
    const configuration = configurations[view]

    if (!configuration) {
        throw Error("Missing Task List Configuration")
    }

    return configuration
}


export default function TasksList(props: TasksListProps) {
    const { view } = props
    const config = useMemo(() => getViewConfig(view), [view])
    const context = useContext(DataViewContext)
    const nodes = context?.data?.tasks?.nodes

    const [detailRow, setDetailRow] = useState<any>(null)

    const reset = () => {
        context?.refetch();
        setDetailRow(null)
    }
    const { handleSubmit } = useTaskForm(undefined, reset, reset)

    const handleCellClick = (params: any) => {
        if (params?.field === 'name') {
            setDetailRow(params.row)
        }
    }

    const handleRowClassName = (params: GridRowParams) => {
        if (params?.row?.overlaps) {
            return styles.Overlaps
        }
        return ""
    }

    const handleRowSelectionChange = (newRowSelectionModel: any) => {
        context?.setRowSelectionModel(newRowSelectionModel);
    }

    const columns = config.columns
    const rows = nodes || []

    useEffect(() => {
        context.setView(config.workspace)
        context.setColumnDefs(columns)
    }, [])

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={config.classname}>
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    onCellClick={handleCellClick}
                    disableSelectionOnClick={true}
                    pageSize={100}
                    getRowClassName={handleRowClassName}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    rowSelectionModel={context?.rowSelectionModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                />
                <TemporaryDrawer isOpen={!!detailRow} setIsOpen={setDetailRow} hideBackdrop={false}>
                    <TaskForm
                        onSubmit={handleSubmit}
                        task={detailRow}
                        contract={detailRow?.intervention?.workPermit?.contract}
                        intervention={detailRow?.intervention}
                        showControlCenter={config.showControlCenter} />
                </TemporaryDrawer>
            </div>
        </Fade>
    );
}
