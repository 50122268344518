// E#886
export const breakLines = (str: string, max: number = 14, truncateValue?: number): string[] => {

    if (truncateValue && str.length > truncateValue) {
        str = str.substring(0, truncateValue) + '...'
    }

    var names = str.split(' '),
        // @ts-ignore
        lines = [],
        line = 0,
        lineLength = 0;

    // @ts-ignore
    names.forEach(function(name) {
        if (lineLength + name.length + 1 > max) {
            line += 1;
            lineLength = 0;
        } else {
            lineLength += 1;
        }
        // @ts-ignore
        lines[line] = lines[line] || [];

        // @ts-ignoreb
        lines[line].push(name);

        lineLength += name.length;
    });

    // @ts-ignore
    return lines.map(function(line) {
        return line.join(' ');
    });
}


export function randomString(length: number = 10): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
