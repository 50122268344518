import { Grid } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { createElement, FC } from 'react';
import ImagePreviewer from '../ImagePreviewer/ImagePreviewer';
import PDFPreviewer from '../PDFPreviewer/PDFPreviewer';
import styles from './DocumentPreviewer.module.scss';

interface DocumentPreviewerProps {
    file: Maybe<string>
    mimeType: Maybe<string>
}

const DocumentPreviewer: FC<DocumentPreviewerProps> = props => {
    const { file, mimeType } = props

    if (!file) return null

    const component = mimeType === 'application/pdf' ? PDFPreviewer : ImagePreviewer

    return (
        <Grid container justifyContent="center" className={styles.DocumentPreviewer}>
            {createElement(component, { file })}
        </Grid>
    );
}

export default DocumentPreviewer
