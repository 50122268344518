import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useInterventionElementsQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface InterventionElementFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
    limit?: number
}

const InterventionElementField: FC<InterventionElementFieldProps> = props => {
    const {
        name = "interventionElement",
        label = "Elemento Infraestrutura",
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props
    const concession = useConcession()
    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useInterventionElementsQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}

        />
    )
}

export default InterventionElementField
