import { useCreateOneControlCenterMutation, useDeleteOneControlCenterMutation, useControlCentersQuery, useUpdateOneControlCenterMutation } from "../../generated/graphql";

const useControlCenterStore = () => {
    const query = useControlCentersQuery
    const name = "controlCenter"
    const [create] = useCreateOneControlCenterMutation()
    const [update] = useUpdateOneControlCenterMutation()
    const [remove] = useDeleteOneControlCenterMutation()
    return { query, name, create, update, remove }
}

export default useControlCenterStore
