import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { countNonEmptyFields } from "../../utils/objects";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import DateFilter from "../DateFilter/DateFilter";
import MoreFilters from "../MoreFilters/MoreFilters";
import styles from './ContractsMoreFilters.module.scss';

export default function ContractsMoreFilters() {
    const context = useContext(DataViewContext)
    const count = countNonEmptyFields(context?.filters, ["startDateGte", "startDateLte"])

    return (
        <MoreFilters count={count}>
            <div className={styles.Wrapper}>
                <Grid container direction="column" className={styles.Section}>
                    <Typography variant="h4" color="primary">
                        Filtros
                    </Typography>
                </Grid>

                <TableContainer>
                    <Table sx={{ minWidth: 300 }} className={styles.Table}>
                        <TableBody>

                            <TableRow key={0}>
                                <TableCell>
                                    <Typography variant="subtitle2" color="secondary" gutterBottom>Data Início Posterior</Typography>
                                    <DateFilter name="startDateGte" value={context.filters.startDateGte} onChange={context.setFilter} />
                                </TableCell>
                            </TableRow>

                            <TableRow key={1}>
                                <TableCell>
                                    <Typography variant="subtitle2" color="secondary" gutterBottom>Data Início Anterior</Typography>
                                    <DateFilter name="startDateLte" value={context.filters.startDateLte} onChange={context.setFilter} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </MoreFilters>
    )
}
