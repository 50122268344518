import { Field } from 'formik';
import { FC } from 'react';
import DateTimePicker from '../DateTimePicker/DateTimePicker';

interface InputDateTimeFieldProps {
    name: string,
    label: string
    disabled?: boolean
    minDate?: Date
    maxDate?: Date
}

const InputDateTimeField: FC<InputDateTimeFieldProps> = props => {
    const { name, label, disabled, minDate, maxDate } = props
    return (
        <Field
            component={DateTimePicker}
            name={name}
            label={label}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}

export default InputDateTimeField
