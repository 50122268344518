import { useContext } from 'react';
import { TaskStates, useClearOverlapsMutation, useDeleteManyTasksMutation, useFindOverlapsMutation, useGenerateWeeklyTasksSheetMutation, useGenerateWeeklyTasksZipMutation, useUpdateManyTasksMutation } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import useToast from '../../hooks/useToast';
import { downloadRequestStatic, parseWeekFilterValue, parseWeekValue } from '../../services/utils';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';

const useTaskStore = () => {

    const context = useContext(DataViewContext)
    const concession = useConcession()
    const { successToast, errorToast } = useToast()
    const [findOverlaps] = useFindOverlapsMutation()
    const [clearOverlaps] = useClearOverlapsMutation()
    const [updateManyTasks] = useUpdateManyTasksMutation()
    const [deleteManyTasks] = useDeleteManyTasksMutation()
    const [generateZip] = useGenerateWeeklyTasksZipMutation()
    const [generateTasksSheet] = useGenerateWeeklyTasksSheetMutation()

    const rows = context?.data?.tasks?.nodes
    const selectedRows = context?.rowSelectionModel

    const handleVerify = async () => {
        if (concession?.id) {
            const { week, year } = parseWeekValue(context?.filters?.isoWeek)
            const response = await findOverlaps({ variables: { input: { concession: concession?.id, year, week } } })
            if (!response?.errors?.length) {
                successToast("Informação sobre sobreposições adicionada com sucesso.")
                context?.refetch()
            } else {
                errorToast("Erro ao efetuar pedido.")
            }
        }
    }

    const handleClear = async () => {
        if (concession?.id) {
            const { week, year } = parseWeekValue(context?.filters?.isoWeek)
            const response = await clearOverlaps({ variables: { input: { concession: concession?.id, year, week } } })
            if (!response?.errors?.length) {
                successToast("Informação sobre sobreposições removida com sucesso.")
                context?.refetch()
            } else {
                errorToast("Erro ao efetuar pedido.")
            }
        }
    }

    const handleBatchUpdateState = async (state: TaskStates) => {
        if (selectedRows?.length) {
            const response = await updateManyTasks({ variables: { input: { filter: { id: { in: selectedRows } }, update: { state } } } })
            if (response?.data?.updateManyTasks?.updatedCount === selectedRows.length) {
                successToast("Linhas Atualizadas.")
                context?.clearSelection()
            } else {
                errorToast("Erro ao efetuar pedido.")
            }
        }
    }

    const handleDelete = async () => {
        if (selectedRows?.length) {
            const response = await deleteManyTasks({ variables: { input: { filter: { id: { in: selectedRows } } } } })
            if (response?.data?.deleteManyTasks?.deletedCount === selectedRows.length) {
                successToast("Linhas Removidas com sucesso.")
                context?.clearSelection()
            } else {
                errorToast("Erro ao efetuar pedido.")
            }
        }
    }

    const handleDownload = async () => {
        const { week, year } = parseWeekFilterValue(context?.filters?.isoWeek)
        try {
            const response = await generateZip({ variables: { input: { week, year } } })
            const path = response?.data?.generateWeeklyTasksZip
            if (path) downloadRequestStatic(path, `tasks_${week}_${year}.zip`)
        } catch (e) {
            errorToast("Erro ao gerar ficheiro zip. Verifique se existem linhas aprovadas.")
        }
    }


    const handleDownloadTasks = async () => {
        const { week, year } = parseWeekFilterValue(context?.filters?.isoWeek)
        try {
            const response = await generateTasksSheet({ variables: { input: { week, year } } })
            const path = response?.data?.generateWeeklyTasksSheet
            if (path) downloadRequestStatic(path, `tasks_${week}_${year}.xlsx`)
        } catch (e) {
            errorToast("Erro ao gerar ficheiro excel.")
        }
    }


    return {
        rows,
        selected: selectedRows,
        verify: handleVerify,
        clear: handleClear,
        batchUpdateState: handleBatchUpdateState,
        remove: handleDelete,
        download: handleDownload,
        downloadTasksSheet: handleDownloadTasks
    }
}


export default useTaskStore
