import { Fade } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import Config from '../../services/config';
import Datagrid from '../Datagrid/Datagrid';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import styles from './ContractsList.module.scss';
import getContractColumns from './Helpers/getContractColumns';

const ContractsList = () => {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.contracts?.nodes
    const rows = nodes || []
    const columns = useMemo(() => getContractColumns, [])

    useEffect(() => {
        context.setView("contractsView")
        context.setColumnDefs(columns)
    }, [])

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.ContractsLists}>
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                />
            </div>
        </Fade>
    );
}

export default ContractsList
