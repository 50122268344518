import { Checklist } from "../../../generated/graphql"
import { Actions } from "../../../services/ability"
import { MaybeDeepPartial } from "../../../types/types"

export default function getDisabledActions(checklist?: MaybeDeepPartial<Checklist>) {
    if (checklist && !checklist?.isCompleted) {
        return [Actions.SUBMIT]
    }
    return []
}
