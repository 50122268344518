import { useCreateOneRoadMutation, useDeleteOneRoadMutation, useRoadsQuery, useUpdateOneRoadMutation } from "../../generated/graphql"

const useRoadStore = () => {
    const query = useRoadsQuery
    const name = "road"
    const [create] = useCreateOneRoadMutation()
    const [update] = useUpdateOneRoadMutation()
    const [remove] = useDeleteOneRoadMutation()
    return { query, name, create, update, remove }
}

export default useRoadStore
