import { Delete } from "@mui/icons-material"
import { Card, CardContent, Grid, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material"
import { FC } from "react"
import { ConcessionOrganization, ConcessionOrganizationUser, Organization } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { AppErrors } from "../../services/config"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import ExecutingOrganizationConcessionOrganizationUsers from "../ExecutingOrganizationConcessionOrganizationUsers/ExecutingOrganizationConcessionOrganizationUsers"
import MoreMenu from "../MoreMenu/MoreMenu"
import OrganizationEditorCreate from "../OrganizationEditorCreate/OrganizationEditorCreate"
import OrganizationEditorUpdate from "../OrganizationEditorUpdate/OrganizationEditorUpdate"
import settingsStyles from '../SettingsView/SettingsView.module.scss'
import SubcontractingOrganizationConcessionOrganizationUsers from "../SubcontractingOrganizationConcessionOrganizationUsers/SubcontractingOrganizationConcessionOrganizationUsers"

interface OrganizationEditorProps {
    selected: any
    store: any
}

const OrganizationEditor: FC<OrganizationEditorProps> = props => {
    const { selected, store } = props
    const { feedback, errorToast } = useToast()

    const title = "Organização"

    const handleDeleteConcessionOrganization = async (concessionOrganization: ConcessionOrganization) => {
        const response = await store?.concessionOrganization?.remove(concessionOrganization?.id)
        feedback(!response?.errors?.length, "Função removida com sucesso", "Erro ao remover função")
        if (!response?.errors?.length) store.refetch()
    }

    const handleCreateConcessionOrganization = async (organization: any, role: any) => {
        if (organization && role) {
            try {
                const response = await store?.concessionOrganization?.create(organization?.id, role)
                feedback(!response?.errors?.length, "Função adicionada com sucesso", "Erro ao adicionar função")
                if (!response?.errors?.length) store.refetch()
            } catch (e: any) {
                const message = e?.message.startsWith("duplicate key value") ? AppErrors.NO_DUPLICATE_ROLE : AppErrors.UNKNOWN_ERROR
                errorToast(message)
            }
        }
    }

    const handleCreateOrganization = async (organization: Partial<Organization>) => {
        return store?.create(organization)
    }

    const handleDeleteConcessionOrganizationUser = async (concessionOrganizationUser: ConcessionOrganizationUser) => {
        return store?.concessionOrganizationUser.remove(concessionOrganizationUser?.id)
    }

    return (
        <>
            <Card className={settingsStyles.SettingsEditor}>
                <CardContent>
                    <Grid container justifyContent="space-between">
                        <Typography variant="h4" color="primary">
                            {title}
                        </Typography>
                        <MoreMenu>
                            <MenuItem disabled={true}>
                                <ListItemIcon>
                                    <ConfirmButton onClick={() => handleDeleteConcessionOrganization(selected)}>
                                        <Delete fontSize="small" />
                                    </ConfirmButton>
                                </ListItemIcon>
                                <ListItemText>
                                    Remover
                                </ListItemText>
                            </MenuItem>
                        </MoreMenu>
                    </Grid>

                    {selected ?
                        <OrganizationEditorUpdate
                            organization={selected}
                            onCreate={handleCreateConcessionOrganization}
                            onDeleteConcessionOrganization={handleDeleteConcessionOrganization}
                        /> :
                        <OrganizationEditorCreate
                            onCreateConcessionOrganization={handleCreateConcessionOrganization}
                            onCreateOrganization={handleCreateOrganization}
                            onDeleteConcessionOrganization={handleDeleteConcessionOrganization}
                        />
                    }

                </CardContent>
            </Card >

            <ExecutingOrganizationConcessionOrganizationUsers
                organization={selected}
                onDeleteConcessionOrganizationUser={handleDeleteConcessionOrganizationUser}
                organizationStore={store}
            />

            <SubcontractingOrganizationConcessionOrganizationUsers
                organization={selected}
                onDeleteConcessionOrganizationUser={handleDeleteConcessionOrganizationUser}
                organizationStore={store}
            />

        </>
    )
}

export default OrganizationEditor
