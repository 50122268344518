import { Grid } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { staticFile } from '../../services/utils';
import PageNavigator from '../PageNavigator/PageNavigator';
import styles from './PDFPreviewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface DocumentPreviewerProps {
    file: Maybe<string>
}

enum PDFPart {
    "DOCUMENT" = "DOCUMENT",
    "PAGE" = "PAGE"
}

interface LoadingPDFProps {
    part: PDFPart
}

const LoadingPDF: FC<LoadingPDFProps> = props => {
    const { part } = props
    const message = part === PDFPart.DOCUMENT ? "A carregar documento..." : "A carregar página..."

    return (
        <div className={styles.Loading}>
            {message}
        </div>
    )
}

const PDFPreviewer: FC<DocumentPreviewerProps> = props => {
    const { file } = props
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    if (!file) return null

    function onDocumentLoadSuccess(obj: any) {
        setNumPages(obj?.numPages);
    }

    return (
        <Grid container justifyContent="center">
            <PageNavigator pageNumber={pageNumber} numPages={numPages} setPageNumber={setPageNumber} />
            <Document file={staticFile(file)} onLoadSuccess={onDocumentLoadSuccess} loading={<LoadingPDF part={PDFPart.DOCUMENT} />}>
                <Page key={1} pageNumber={pageNumber} width={1000} loading={<LoadingPDF part={PDFPart.PAGE} />} />
            </Document>

        </Grid>
    );
}

export default PDFPreviewer
