import { useCreateOneContractTypeMutation, useDeleteOneContractTypeMutation, useContractTypesQuery, useUpdateOneContractTypeMutation } from "../../generated/graphql"

const useContractTypeStore = () => {
    const query = useContractTypesQuery
    const name = "contractType"
    const [create] = useCreateOneContractTypeMutation()
    const [update] = useUpdateOneContractTypeMutation()
    const [remove] = useDeleteOneContractTypeMutation()
    return { query, name, create, update, remove }
}

export default useContractTypeStore
