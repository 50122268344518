import { Settings, useSettingManager } from "../../hooks/useSettingManager"
import { ExternalOrganizationConcessionOrganizationUsersProps } from "../../types/types"
import ConcessionOrganizationUsers from "../ConcessionOrganizationUsers/ConcessionOrganizationUsers"

export default function SubcontractingOrganizationConcessionOrganizationUsers(props: ExternalOrganizationConcessionOrganizationUsersProps) {
    const { organization, onDeleteConcessionOrganizationUser, organizationStore } = props
    const title = "Colaboradores Entidade Subcontratada"
    const getSetting = useSettingManager()
    const organizationTypeId = getSetting(Settings.SUBCONTRACT_ORGANIZATION_TYPE_ID)

    return (
        <ConcessionOrganizationUsers
            title={title}
            organizationTypeId={organizationTypeId}
            organization={organization}
            onDeleteConcessionOrganizationUser={onDeleteConcessionOrganizationUser}
            organizationStore={organizationStore}
        />
    )
}
