import { PersonSearch } from "@mui/icons-material"
import InputTextField from "../InputTextField/InputTextField"

const SearchUserByEmailField = () => {
    return (
        <InputTextField
            name="email"
            label="email utilizador"
            fullWidth
            autoFocus
            InputProps={{
                startAdornment: <PersonSearch color="primary" style={{ marginRight: 10 }} />
            }}
        />

    )
}

export default SearchUserByEmailField
