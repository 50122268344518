import { InfoOutlined } from "@mui/icons-material";
import useAnchor from "../../hooks/useAnchor";
import { MaybeDeepPartial } from "../../types/types";
import DocumentInstructionsPopover from "../DocumentInstructionsPopover/DocumentInstructionsPopover";
import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";

export interface ShowDocument {
    id: string
    name: string
    children?: {
        id: string
        name: string
        observations: string | null
        expirationRequired: boolean
    }[] | null
}

export type DocumentInstructions = MaybeDeepPartial<ShowDocument[]> | undefined

interface ShowDocumentInstructionsProps {
    documents: DocumentInstructions
}

export default function ShowDocumentInstructions(props: ShowDocumentInstructionsProps) {
    const { documents } = props
    const [anchor, setAnchor] = useAnchor()

    const handleClick = (event: any) => {
        setAnchor(event.currentTarget)
    }

    return (
        <div>
            <IconButtonTooltip onClick={handleClick} title="Lista de Documentos" >
                <InfoOutlined />
            </IconButtonTooltip >
            <DocumentInstructionsPopover anchor={anchor} setAnchor={setAnchor} documents={documents} />
        </div>
    )
}
