import { Field } from "formik"
import { FC, useState } from "react"
import FieldText from "../FieldText/FieldText"
import { PasswordVisibility } from "../Login/Login"

interface InputPasswordFieldProps {
    name?: string
    label?: string
    fullWidth?: boolean
    autoFocus?: boolean
}

const InputPasswordField: FC<InputPasswordFieldProps> = props => {
    const {
        name = "password",
        label = "palavra-passe",
        fullWidth = true,
        autoFocus = false
    } = props

    const [fieldType, setFieldType] = useState<string>("password")


    return (
        <Field
            component={FieldText}
            name={name}
            label={label}
            type={fieldType}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            InputProps={{ endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} /> }}
        />
    )
}

export default InputPasswordField
