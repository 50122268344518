import { FC } from "react"
import { SortDirection, useWorkPermitsQuery, WorkPermitSortFields } from "../../generated/graphql"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

const WorkPermitFilter: FC<FilterProps> = props => {
    const { value, onChange } = props
    const query = useWorkPermitsQuery
    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            query={query}
            multiple={true}
            field="workPermit"
            label="Permissão"
            sorting={[
                { field: WorkPermitSortFields.Id, direction: SortDirection.Asc }
            ]}
            width={135}
            paging={{ limit: 999 }}
        />
    )
}

export default WorkPermitFilter
