import { Fade, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useUpdateDocumentName } from '../../hooks/useUpdateDocumentName';
import Config from '../../services/config';
import ContractSubviewSwitcher from '../ContractView/ContractSubviewSwitcher';
import CustomModal from '../CustomModal/CustomModal';
import Datagrid from '../Datagrid/Datagrid';
import { DocumentScopes } from '../Datagrid/Helpers/PolicyToRoles';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import DocumentPreviewer from '../DocumentPreviewer/DocumentPreviewer';
import MessageBoard from '../MessageBoard/MessageBoard';
import Show from '../Show/Show';
import { getContractDocumentColumns } from './ContractDocumentColumns';
import styles from './ContractDocumentsList.module.scss';

const ContractDocumentsList = (props: any) => {
    const { subview, setSubview } = props
    const context = useContext(DataViewContext)
    const updateName = useUpdateDocumentName()

    const nodes = context?.data?.contractDocuments?.nodes
    const rows = nodes || []

    const [previewRow, setPreviewRow] = useState<any | null>(null)

    const contractState = rows[0]?.contract?.contractState?.id
    const showMessageBoard = contractState && +contractState > 2
    const disableNameEditing = contractState && +contractState > 2

    const documentScopes = [DocumentScopes.CONCESSION, DocumentScopes.EXECUTING]
    const contractDocumentColumns = getContractDocumentColumns({ setPreviewRow, disableNameEditing, documentScopes })

    useEffect(() => {
        context.setView("contractDocumentsView")
        context.setColumnDefs(contractDocumentColumns)
    }, [])

    const handleUpdateName = async (newRow: any, oldRow: any) => {
        return await updateName(newRow, oldRow, context)
    }

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.ContractDocumentsList}>
                <Show on={showMessageBoard || false}>
                    <MessageBoard content="Contrato não se encontra em curso. Não é possível atualizar documentos." />
                </Show>
                <ContractSubviewSwitcher subview={subview} setSubview={setSubview} />
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                    onRowSelectionModelChange={context?.setRowSelectionModel}
                    rowSelectionModel={context?.rowSelectionModel}
                    processRowUpdate={handleUpdateName}
                    disableSelectionOnClick={true}
                />
                <CustomModal isActive={!!previewRow} handleClose={() => setPreviewRow(null)} fullScreen={true} backgroundColor="gray" textColor="white">
                    <Grid container justifyContent="center">
                        <DocumentPreviewer file={previewRow?.path} mimeType={previewRow?.fileType?.mimeType} />
                    </Grid>
                </CustomModal>
            </div>
        </Fade>
    );
}

export default ContractDocumentsList
