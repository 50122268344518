import { DocumentState } from "../generated/graphql"
import { useHasInternalRole } from "./useHasRole"
import useToast from "./useToast"

const getDocumentPreparationStateId = (): number => {
    return 1
}

const allowUpdate = (documentState: DocumentState, isInternalUser: boolean): boolean => {
    if (isInternalUser) return true
    const preparationState = getDocumentPreparationStateId()
    return +documentState.id === preparationState
}

export const useUpdateDocumentName = () => {

    const { errorToast } = useToast()
    const isInternalUser = useHasInternalRole()

    return async (newRow: any, oldRow: any, context: any) => {
        if (newRow?.name === oldRow?.name) return oldRow

        if (allowUpdate(oldRow.documentState, isInternalUser)) {
            const options = { successMessage: "Nome ficheiro atualizado", errorMessage: "Erro ao atualizar nome de ficheiro" }
            const response = await context?.update({ variables: { input: { id: newRow.id, update: { name: newRow.name } } } }, options)
            return response?.data?.updateOneContractDocument?.id ? newRow : oldRow
        } else {
            errorToast("Não é permitido atualizar nome de ficheiro depois de submetido")
            return oldRow
        }
    }
}
