import { LockOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from "yup";
import { useSetPasswordMutation } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import FieldText from '../FieldText/FieldText';
import { PasswordVisibility } from '../Login/Login';
import styles from './SetPassword.module.scss';

const SetPassword = () => {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [fieldType, setFieldType] = useState<string>("password")
    const [searchParams] = useSearchParams()
    const [setPassword] = useSetPasswordMutation()
    const navigate = useNavigate()
    const { successToast } = useToast()

    const message = "Erro ao atualizar palavra-passe."

    const schema = Yup.object().shape({
        email: Yup.string().email().required().default(""),
        password: Yup.string().required().default(""),
        token: Yup.string().required().default(""),
    })

    const initialValues = schema.cast({
        token: searchParams.get("token") || "",
        email: searchParams.get("email") || "",
    })


    const handleSubmit = async (values: { email: string, token: string, password: string }) => {
        try {
            const response = await setPassword({ variables: { input: { ...values } } })
            if (response?.data?.setPassword) {
                successToast("Palavra-passe atualizada com sucesso.")
                navigate("/login")
            } else {
                setErrorMessage(message)
            }

        } catch (e) {
            setErrorMessage(message)
        }
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
            {({ isSubmitting, errors }) => {

                if (Object.keys(errors).length) {
                    setErrorMessage(message)
                }

                return (
                    <Form id={styles.SetPasswordForm}>
                        <Typography variant="h4" color="secondary">Re-definir Senha</Typography>

                        <div>
                            <div className={styles.ErrorMessage}>
                                {errorMessage || <span>&nbsp;</span>}
                            </div>

                            <Typography variant="h5" color="primary">Por favor preencha o formulário para re-definir a palavra-passe.</Typography>

                            <Field
                                component={FieldText}
                                name="password"
                                type={fieldType}
                                label="palavra-passe"
                                required
                                fullWidth
                                autoFocus
                                InputProps={{
                                    startAdornment: <LockOutlined color="primary" />,
                                    endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                }}

                            />

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                                className={styles.SubmitButton}
                            >
                                submeter
                            </Button>
                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    );
};

export default SetPassword
