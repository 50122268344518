import { FC } from 'react';
import { FileTypeSortFields, SortDirection, useFileTypesQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

const FileTypeFilter: FC<FilterProps> = props => {
    const { value, onChange, allOptionName, multiple = false, hideAllOption = false } = props
    const query = useFileTypesQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label={"Tipo Ficheiro"}
            query={query}
            field="fileType"
            allOptionName={allOptionName}
            multiple={multiple}
            hideAllOption={hideAllOption}
            sorting={{ field: FileTypeSortFields.Id, direction: SortDirection.Asc }}
        />
    )
}

export default FileTypeFilter
