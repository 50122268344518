import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#C8CAAB",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#B7B7B7"
        }
    },
    typography: {
        fontFamily: "Lato, sans-serif",
        h1: {
            fontSize: 34,
            fontWeight: 600
        },
        h2: {
            fontSize: 30,
            fontWeight: 600
        },
        h3: {
            fontSize: 28,
            fontWeight: 600
        },
        h4: {
            fontSize: 24,
            fontWeight: 600,
            marginBlockEnd: 24
        },
        h5: {
            fontSize: 18,
            fontWeight: 600
        },
        h6: {
            fontSize: 18,
            fontWeight: 600
        }

    },
})

export default theme
