import { Save } from '@mui/icons-material'
import { IconButton, Tooltip } from "@mui/material"
import { noop } from '../../services/utils'

interface SaveIconButtonProps {
    onClick?: () => void,
    tooltip?: string
}

export default function SaveIconButton(props: SaveIconButtonProps) {
    const { onClick = noop, tooltip = "Guardar" } = props
    return (
        <Tooltip title={tooltip}>
            <IconButton type="submit" size="small" onClick={onClick}>
                <Save />
            </IconButton>
        </Tooltip>
    )
}
