import { useCreateOneInterventionTypeMutation, useDeleteOneInterventionTypeMutation, useInterventionTypesQuery, useUpdateOneInterventionTypeMutation } from "../../generated/graphql"

const useInterventionTypeStore = () => {
    const query = useInterventionTypesQuery
    const name = "interventionType"
    const [create] = useCreateOneInterventionTypeMutation()
    const [update] = useUpdateOneInterventionTypeMutation()
    const [remove] = useDeleteOneInterventionTypeMutation()
    return { query, name, create, update, remove }
}

export default useInterventionTypeStore
