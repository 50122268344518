import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useInterventionTypesQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface InterventionTypeFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
    limit?: number
}

const InterventionTypeField: FC<InterventionTypeFieldProps> = props => {
    const {
        name = "interventionType",
        label = "Tipo Trabalho",
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props
    const concession = useConcession()

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useInterventionTypesQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}

        />
    )
}

export default InterventionTypeField
