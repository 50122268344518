import { FilterProps } from '../../types/types';
import SelectBoolFilter from '../SelectBoolFilter/SelectBoolFilter';

export default function CorrectiveRequestsFilter(props: FilterProps) {
    const { value, onChange } = props
    return (
        <SelectBoolFilter
            value={value}
            onChange={onChange}
            label="PACs"
            field="hasCorrectiveRequests"
            trueOptionName="Com PACs"
            falseOptionName="Sem PACs"
        />
    )
}
