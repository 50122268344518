import { useCreateOneConcessionOrganizationAccreditationDocumentMutation, useDeleteOneConcessionOrganizationAccreditationDocumentMutation, useImportConcessionOrganizationAccreditationDocumentsMutation } from "../generated/graphql";
import { AccreditationDocument, OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";

export interface CreateAccreditationDocumentData {
    concessionOrganizationAccreditationId: string,
    concessionOrganizationDocumentId: string
}

export interface AccreditationDocumentManager {
    createAccreditationDocument: (data: CreateAccreditationDocumentData) => Promise<OperationResult<AccreditationDocument>>
    deleteAccreditationDocument: (subcontractId: string) => Promise<OperationResult<AccreditationDocument>>
    importAccreditationDocuments: (accreditationOriginId: string, accreditatioDestinationId: string) => Promise<OperationResult<string[]>>
}

export default function useAccreditationDocumentManager(): AccreditationDocumentManager {

    const [_createAccreditationDocument] = useCreateOneConcessionOrganizationAccreditationDocumentMutation()
    const [_deleteAccreditationDocument] = useDeleteOneConcessionOrganizationAccreditationDocumentMutation()
    const [_importAccreditationDocuments] = useImportConcessionOrganizationAccreditationDocumentsMutation()

    const createAccreditationDocument = async (data: CreateAccreditationDocumentData): Promise<OperationResult<AccreditationDocument>> => {
        const { concessionOrganizationAccreditationId, concessionOrganizationDocumentId } = data

        try {
            const response = await _createAccreditationDocument({
                variables: {
                    input: {
                        concessionOrganizationAccreditationDocument: {
                            concessionOrganizationAccreditation: concessionOrganizationAccreditationId,
                            concessionOrganizationDocument: concessionOrganizationDocumentId
                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response.errors)
                return { success: false, errors }
            }

            return { success: true, data: response.data?.createOneConcessionOrganizationAccreditationDocument }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    const deleteAccreditationDocument = async (id: string): Promise<OperationResult<AccreditationDocument>> => {
        try {
            const response = await _deleteAccreditationDocument({ variables: { input: { id } } })
            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }
            return { success: true }
        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    const importAccreditationDocuments = async (accreditationOriginId: string, accreditationDestinationId: string): Promise<OperationResult<string[]>> => {
        try {
            const response = await _importAccreditationDocuments({ variables: { input: { accreditationOriginId, accreditationDestinationId } } })
            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }
            return {
                success: true, data: response.data?.importConcessionOrganizationAccreditationDocuments?.duplicates
            }
        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createAccreditationDocument,
        deleteAccreditationDocument,
        importAccreditationDocuments

    }
}
