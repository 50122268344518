import { TasksListView } from "../../types/types";
import TasksActions from "../TasksActions/TasksActions";
import TasksFilters from "../TasksFilters/TaskFilters";
import TasksList from "../TasksList/TasksList";
import TaskProvider from "../TasksView/TaskProvider";
import { getTasksFilterConfig } from "../TasksView/TasksView";
import styles from './WeekView.module.scss';

const WeekView = () => {

    const view = TasksListView.PLANNING
    const filterConfig = getTasksFilterConfig(view)

    return (
        <div className={styles.Wrapper}>
            <TaskProvider filterConfig={filterConfig}>
                <TasksActions view={view} />
                <TasksFilters view={view} />
                <TasksList view={view} />
            </TaskProvider>
        </div>
    )
}

export default WeekView
