import { useCreateOneInterventionLocationMutation, useDeleteOneInterventionLocationMutation, useInterventionLocationsQuery, useUpdateOneInterventionLocationMutation } from "../../generated/graphql";

const useInterventionLocationStore = () => {
    const query = useInterventionLocationsQuery
    const name = "interventionLocation"
    const [create] = useCreateOneInterventionLocationMutation()
    const [update] = useUpdateOneInterventionLocationMutation()
    const [remove] = useDeleteOneInterventionLocationMutation()
    return { query, name, create, update, remove }
}

export default useInterventionLocationStore
