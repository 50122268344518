import { useInterventionLocationsQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function InterventionLocationFilter(props: FilterProps) {
    const { value, onChange, width = 150 } = props
    const query = useInterventionLocationsQuery
    const concession = useConcession()

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Localização"
            query={query}
            field="interventionLocation"
            filter={{ concession: { id: { eq: concession?.id } } }}
            multiple={true}
            width={width}
            paging={{ limit: 999 }}
        />
    )
}
