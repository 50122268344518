import { FilterProps } from "../../types/types"
import { getCurrentIsoYear } from "../../utils/dates"
import { rangeToOptions } from "../../utils/filters"
import { range } from "../../utils/numbers"
import SelectNumberFilter from "../SelectNumberFilter/SelectNumberFilter"

export default function YearFilter(props: FilterProps) {
    const { value, onChange } = props

    const currentYear = getCurrentIsoYear()
    const years = range(2022, currentYear + 1)
    const options = rangeToOptions(years)

    return (
        <SelectNumberFilter
            value={value}
            onChange={onChange}
            label="Ano"
            multiple={true}
            options={options}
            field="year"
            width={90}
        />
    )
}
