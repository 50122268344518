import { useCreateOneRestrictionMutation, useDeleteOneRestrictionMutation, useRestrictionsQuery, useUpdateOneRestrictionMutation } from "../../generated/graphql";

const useRestrictionStore = () => {
    const query = useRestrictionsQuery
    const name = "restriction"
    const [create] = useCreateOneRestrictionMutation()
    const [update] = useUpdateOneRestrictionMutation()
    const [remove] = useDeleteOneRestrictionMutation()
    return { query, name, create, update, remove }
}

export default useRestrictionStore
