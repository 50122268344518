import { Block, Delete, Done, Download, Edit, MoreVert, Print, Publish } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { Badge as BadgeType, BadgeStates, useDeleteOneBadgeMutation, useUpdateOneBadgeMutation } from "../../generated/graphql";
import { useHasSafetyTechnicianRole } from "../../hooks/useHasRole";
import useToast from "../../hooks/useToast";
import { Subjects, Actions } from "../../services/ability";
import CustomModal from "../CustomModal/CustomModal";
import EditBadge from "../EditBadge/EditBadge";
import MenuDivider from "../MenuDivider/MenuDivider";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import Permission from "../Permission";
import { PDFMode } from "../WorkPermitView/PDF";

interface BadgeMoreActionsProps {
    badge: any
    refetch: any
    generate: (badge: Partial<BadgeType>, mode: PDFMode) => void,
}

export const getAllowedTransitions = (currentState: string) => {

    if (!currentState) return []

    const transitionsMap: any = {
        [BadgeStates.Preparation]: [BadgeStates.Submitted],
        [BadgeStates.Submitted]: [BadgeStates.Approved, BadgeStates.Rejected],
    }

    return transitionsMap[currentState] || []
}


const BadgeMoreActions: FC<BadgeMoreActionsProps> = props => {
    const { badge, refetch, generate } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [updateBadge] = useUpdateOneBadgeMutation()
    const [deleteBadge] = useDeleteOneBadgeMutation()
    const { feedback } = useToast()
    const [editableBadge, setEditableBadge] = useState<BadgeType | null>(null)

    const isSafetyTechnician = useHasSafetyTechnicianRole()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeAndRefetch = () => {
        handleClose();
        refetch();
    }

    const handleDelete = async () => {
        if (badge?.id) {
            const response = await deleteBadge({ variables: { input: { id: badge.id } } })
            const errors = response?.errors?.length
            feedback(!errors, "Dístico removido com sucesso", "Erro ao remover dístico.")
            closeAndRefetch()
        }
    }

    const handleGeneratePDF = (_: any, mode: PDFMode = PDFMode.PRINT) => {
        generate(badge, mode)
    }

    const handleBadgeUpdated = () => {
        setEditableBadge(null)
        refetch()
        setAnchorEl(null)
    }

    const transitionTo = async (stateTo: string) => {
        if (badge?.id) {
            // @ts-ignore
            const response = await updateBadge({ variables: { input: { id: badge?.id, update: { state: stateTo } } } })
            feedback(!response?.errors?.length, "Transição bem sucedida.", "Erro na transição.")
            closeAndRefetch()
        }
    }

    const getTransitionData = (currentState: string) => {

        const transitions: string[] = getAllowedTransitions(currentState)
        const actionsMap: any = [
            {
                stateTo: BadgeStates.Submitted,
                icon: <Publish fontSize="small" />,
                label: "Submeter",
                action: Actions.SUBMIT,
                subject: Subjects.BADGE
            },
            {
                stateTo: BadgeStates.Approved,
                icon: <Done fontSize="small" />,
                label: "Aprovar",
                action: Actions.APROVE,
                subject: Subjects.BADGE
            },
            {
                stateTo: BadgeStates.Rejected,
                icon: <Block fontSize="small" />,
                label: "Rejeitar",
                action: Actions.REJECT,
                subject: Subjects.BADGE
            },
        ]

        return actionsMap.filter((obj: any) => transitions.includes(obj.stateTo))
    }

    const allowedTransitions = getAllowedTransitions(badge?.state)
    const pdfOptionsDisabled = badge.state !== BadgeStates.Approved

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: 500 }}
            >

                {
                    getTransitionData(badge?.state).map((obj: any) => {
                        const { action, subject, condition, stateTo, confirmationRequired, icon, label } = obj
                        return (
                            <Permission action={action} subject={subject} condition={condition} passThrough>
                                {allowed =>
                                    <MenuItemWithConfirmation
                                        icon={icon}
                                        label={label}
                                        allowed={allowed}
                                        onClick={() => transitionTo(stateTo)}
                                        confirmationRequired={confirmationRequired}
                                    />

                                }
                            </Permission>
                        )
                    })
                }


                <MenuDivider on={allowedTransitions?.length} />


                <Permission action={Actions.UPDATE} subject={Subjects.BADGE} passThrough>
                    {allowed =>
                        <MenuItem disabled={!allowed || allowed && badge?.state !== BadgeStates?.Preparation && !isSafetyTechnician} onClick={() => setEditableBadge(badge)}>
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Editar
                            </ListItemText>
                        </MenuItem>
                    }
                </Permission>

                <MenuDivider />

                <MenuItem disabled={pdfOptionsDisabled} onClick={handleGeneratePDF}>
                    <ListItemIcon>
                        <Print fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Imprimir Dístico
                    </ListItemText>
                </MenuItem>
                <MenuItem disabled={pdfOptionsDisabled} onClick={event => handleGeneratePDF(event, PDFMode.DOWNLOAD)}>
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Descarregar Dístico
                    </ListItemText>
                </MenuItem>

                <MenuDivider />

                <Permission action="delete" subject="badge" passThrough>
                    {allowed =>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover Dístico"
                            allowed={allowed && badge?.state === BadgeStates?.Preparation || isSafetyTechnician}
                            confirmationRequired={true}
                            listItemTextColor="red"
                        />
                    }
                </Permission>
            </Menu>
            <CustomModal isActive={!!editableBadge} handleClose={() => setEditableBadge(null)}>
                {editableBadge && <EditBadge badge={editableBadge} onSuccess={handleBadgeUpdated} />}
            </CustomModal>
        </div >
    )
}

export default BadgeMoreActions
