import { FC, ReactNode } from "react"
import { useCreateOneWorkPermitMutation, useDeleteOneWorkPermitMutation, useUpdateOneWorkPermitMutation, useWorkPermitsQuery } from "../../generated/graphql"
import { FilterConfig } from "../../hooks/useFilters"
import DataViewProvider from "../DataViewProvider/DataViewProvider"

const WorkPermitsDataSource = {
    createFunc: useCreateOneWorkPermitMutation,
    readFunc: useWorkPermitsQuery,
    updateFunc: useUpdateOneWorkPermitMutation,
    deleteFunc: useDeleteOneWorkPermitMutation,
}

interface WorkPermitProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const WorkPermitProvider: FC<WorkPermitProviderProps> = props => {
    const { filterConfig } = props

    return (
        <DataViewProvider datasource={WorkPermitsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default WorkPermitProvider
