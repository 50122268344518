import { Block, Delete, Download, FolderZip, HighlightOff, JoinInner, SwipeDownAlt, SwipeUpAlt, TaskAlt } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { TaskStates } from '../../generated/graphql';
import { TasksListView } from '../../types/types';
import ActionsBar from '../ActionsBar/ActionsBar';
import ActionsBarSeparator from '../ActionsBarSeparator/ActionsBarSeparator';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Show from '../Show/Show';
import styles from './TasksActions.module.scss';
import useTaskStore from './TaskStore';

interface TasksActionsProps {
    view: TasksListView
}

const TasksActions = (props: TasksActionsProps) => {

    const { view } = props
    const { rows, selected, verify, clear, batchUpdateState, remove, download, downloadTasksSheet } = useTaskStore()

    const viewName = view === TasksListView.PLANNING ? "Planeamento" : "Centro Controlo"
    const hasRows = rows?.length
    const hasSelection = selected?.length

    return (
        <ActionsBar>
            <Grid container justifyContent="space-between" alignContent="center" className={styles.Wrapper}>

                <Grid item xs={6} container alignContent="center">
                    <Typography variant="h3" color="primary">{viewName}</Typography>
                </Grid>

                <Grid item xs={6} container justifyContent="flex-end">

                    <Show on={hasSelection}>
                        <Tooltip title="Marcar executado">
                            <IconButton onClick={() => batchUpdateState(TaskStates?.Executed)}>
                                <SwipeUpAlt />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Marcar não executado">
                            <IconButton onClick={() => batchUpdateState(TaskStates?.NotExecuted)}>
                                <SwipeDownAlt />
                            </IconButton>
                        </Tooltip>
                    </Show>

                    <Show on={view === TasksListView.PLANNING}>

                        <Show on={hasSelection}>
                            <Tooltip title="Aprovar">
                                <IconButton onClick={() => batchUpdateState(TaskStates?.Approved)}>
                                    <TaskAlt />
                                </IconButton>
                            </Tooltip>



                            <Tooltip title="Rejeitar">
                                <IconButton onClick={() => batchUpdateState(TaskStates?.Rejected)}>
                                    <Block />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Remover">
                                <ConfirmButton onClick={remove}>
                                    <Delete />
                                </ConfirmButton>
                            </Tooltip>
                        </Show>

                        <ActionsBarSeparator />

                        <Show on={hasRows}>
                            <Tooltip title="Verificar">
                                <IconButton onClick={verify}>
                                    <JoinInner />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Limpar">
                                <IconButton onClick={clear}>
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Descarregar Zip">
                                <IconButton onClick={download}>
                                    <FolderZip />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Descarregar Excel">
                                <IconButton onClick={downloadTasksSheet}>
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </Show>
                    </Show>
                </Grid>
            </Grid>
        </ActionsBar>
    )
}

export default TasksActions;
