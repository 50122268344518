import { ArrayParam, StringParam } from "use-query-params"
import { boolLookupExpression, searchExpFunc, stateLookupExpression } from "../../utils/lookups"
import SurveyRecordsActions from "../SurveyRecordsActions/SurveyRecordsActions"
import SurveyRecordsFilters from "../SurveyRecordsFilters/SurveyRecordsFilters"
import SurveyRecordsList from "../SurveyRecordsList/SurveyRecordsList"
import SurveyRecordProvider from "./SurveyRecordProvider"
import styles from './SurveyRecordsView.module.scss'

const SurveyRecordsView = () => {

    const filterConfig = [
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "contract", variant: ArrayParam, defaultValue: [] },
        { name: "road", variant: ArrayParam, defaultValue: [] },
        { name: "state", variant: ArrayParam, defaultValue: [], lookupExpFunc: stateLookupExpression },
        { name: "isPublished", variant: StringParam, defaultValue: "", lookupExpFunc: boolLookupExpression },
        { name: "user", variant: ArrayParam, defaultValue: [] },
        { name: "hasCorrectiveRequests", variant: StringParam, defaultValue: "", lookupExpFunc: boolLookupExpression },
    ]

    return (
        <div className={styles.Wrapper}>
            <SurveyRecordProvider filterConfig={filterConfig}>
                <SurveyRecordsActions />
                <SurveyRecordsFilters />
                <SurveyRecordsList />
            </SurveyRecordProvider>
        </div>
    )
}


export default SurveyRecordsView
