import { useCreateOneServiceMutation, useDeleteOneServiceMutation, useServicesQuery, useUpdateOneServiceMutation } from "../../generated/graphql";

const useServiceStore = () => {
    const query = useServicesQuery
    const name = "service"
    const [create] = useCreateOneServiceMutation()
    const [update] = useUpdateOneServiceMutation()
    const [remove] = useDeleteOneServiceMutation()
    return { query, name, create, update, remove }
}

export default useServiceStore
