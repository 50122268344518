import { ArrayParam, StringParam } from "use-query-params";
import { FilterConfig } from "../../hooks/useFilters";
import { searchExpFunc } from "../../utils/lookups";
import ContractDocumentsActions from "../ContractDocumentsActions/ContractDocumentsActions";
import ContractDocumentsFilters from "../ContractDocumentsFilters/ContractDocumentsFilters";
import ContractDocumentsList from "../ContractDocumentsList/ContractDocumentsList";
import ContractDocumentProvider from "./ContractDocumentProvider";
import styles from './ContractDocumentsView.module.scss';

const ContractDocumentsView = (props: any) => {

    const { id, subview, setSubview } = props

    const filterConfig: FilterConfig[] = [
        { name: "contract", variant: StringParam, defaultValue: id },
        { name: "contractDocumentCategory", variant: ArrayParam, defaultValue: [] },
        { name: "contractDocumentSubcategory", variant: ArrayParam, defaultValue: [] },
        { name: "documentState", variant: ArrayParam, defaultValue: [] },
        { name: "fileType", variant: ArrayParam, defaultValue: [] },
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "tab", variant: StringParam, defaultValue: "DOCUMENTS", lookupExpFunc: () => undefined }
    ]

    return (
        <div className={styles.Wrapper}>
            <ContractDocumentProvider filterConfig={filterConfig}>
                <ContractDocumentsActions contractId={id} />
                <ContractDocumentsFilters />
                <ContractDocumentsList subview={subview} setSubview={setSubview} />
            </ContractDocumentProvider>
        </div>
    )
}

export default ContractDocumentsView
