import { useContractDocumentsQuery, useCreateOneContractDocumentMutation, useDeleteManyContractDocumentsMutation, useDeleteOneContractDocumentMutation, useUpdateManyContractDocumentsMutation, useUpdateOneContractDocumentMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const ContractDocumentsDataSource = {
    createFunc: useCreateOneContractDocumentMutation,
    readFunc: useContractDocumentsQuery,
    updateFunc: useUpdateOneContractDocumentMutation,
    deleteFunc: useDeleteOneContractDocumentMutation,
    deleteManyFunc: useDeleteManyContractDocumentsMutation,
    updateManyFunc: useUpdateManyContractDocumentsMutation
}

export default function ContractDocumentProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={ContractDocumentsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
