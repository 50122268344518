import { Typography } from "@mui/material"
import { Maybe } from "graphql/jsutils/Maybe"
import { FC } from "react"
import styles from './DisplayState.module.scss'

interface DisplayStateProps {
    stateName?: Maybe<string>
}

const DisplayState: FC<DisplayStateProps> = props => {

    const { stateName } = props

    if (!stateName) return null

    return (
        <Typography variant="body2" className={styles.State}>
            {stateName}
        </Typography>
    )
}

export default DisplayState
