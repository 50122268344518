import { Menu } from "@mui/material";
import { ReactNode, useState } from "react";
import MoreIconButton from "../MoreIconButton/MoreIconButton";

interface MoreMenuProps {
    children: ReactNode
    tooltip?: string
}

export default function MoreMenu(props: MoreMenuProps) {
    const { children, tooltip = "Mais ações" } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <MoreIconButton title={tooltip} onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {children}
            </Menu>
        </div>
    )
}
