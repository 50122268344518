
// settings e config
// temos duas fontes de configurações da app, as configs devem ser vistas como
// configurações estáticas, que no máximo são definidas via env vars
// as settings devem ser providenciadas via API

import { TransitionMap } from "../types/types"


export enum Settings {
    EXECUTING_ORGANIZATION_TYPE_ID = "EXECUTING_ORGANIZATION_TYPE_ID",
    //
    SUBCONTRACT_ORGANIZATION_TYPE_ID = "SUBCONTRACT_ORGANIZATION_TYPE_ID",
    SUBCONTRACT_ONGOING_STATE = "SUBCONTRACT_ONGOING_STATE",
    SUBCONTRACT_COMPLETED_STATE = "SUBCONTRACT_COMPLETED_STATE",
    SUBCONTRACT_CANCELED_STATE = "SUBCONTRACT_CANCELED_STATE",
    //
    ACCREDITATION_PREPARATION_STATE = "ACCREDITATION_PREPARATION_STATE",
    ACCREDITATION_SUBMITTED_STATE = "ACCREDITATION_SUBMITTED_STATE",
    ACCREDITATION_PENDING_STATE = "ACCREDITATION_PENDING_STATE",
    ACCREDITATION_APPROVED_STATE = "ACCREDITATION_APPROVED_STATE",
    ACCREDITATION_REJECTED_STATE = "ACCREDITATION_REJECTED_STATE",
    ACCREDITATION_EXPIRED_STATE = "ACCREDITATION_EXPIRED_STATE"
}

const settingsMap = {
    EXECUTING_ORGANIZATION_TYPE_ID: "2",
    //
    SUBCONTRACT_ORGANIZATION_TYPE_ID: "5",
    SUBCONTRACT_ONGOING_STATE: "2",
    SUBCONTRACT_COMPLETED_STATE: "4",
    SUBCONTRACT_CANCELED_STATE: "1",
    //
    // Accreditation States
    //
    ACCREDITATION_PREPARATION_STATE: "1",
    ACCREDITATION_SUBMITTED_STATE: "2",
    ACCREDITATION_PENDING_STATE: "3",
    ACCREDITATION_APPROVED_STATE: "4",
    ACCREDITATION_REJECTED_STATE: "5",
    ACCREDITATION_EXPIRED_STATE: "6",
}

export const useSettingManager = () => {
    const getSetting = (name: Settings) => {
        return settingsMap[name]
    }
    return getSetting
}

//
// estas settings têm que deixar de estar harcoded
//


export const executingRoles = [
    "EXTERNALADMINISTRATIVE",
    "EXTERNALMANAGER",
    "EXTERNALAUTHORITY",
    "EXTERNALTECHNICIAN"
]

export const subcontractingRoles = [
    "SUBCONTRACTAUTHORITY"
]

export const subcontractTransitionMap: TransitionMap = {
    "1": ["2", "5"],
    "2": ["3", "5"],
    "3": ["4"],
    "4": [],
}

export function getAccreditationEditableStates(): string[] {
    return ["PREPARATION", "SUBMITTED", "PENDING"]
}
