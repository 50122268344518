import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import { Badge, useUpdateOneBadgeMutation } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { omit } from "../../services/utils"
import InputTextField from "../InputTextField/InputTextField"
import BadgeFormInit from "./BadgeFormInit"
import styles from './EditBadge.module.scss'

interface EditBadgeProps {
    badge: Partial<Badge>
    onSuccess: () => any
}

const EditBadge: FC<EditBadgeProps> = props => {
    const { badge, onSuccess } = props
    const { schema, initial } = BadgeFormInit(badge)
    const [updateBadge] = useUpdateOneBadgeMutation()
    const { feedback } = useToast()

    const handleSubmit = async (values: FormikValues) => {
        const { id, ...data } = values
        const update = omit(data, ["name", "state"])
        const response = await updateBadge({ variables: { input: { id, update } } })
        const success = !response?.errors?.length
        feedback(success, "Dístico atualizado com sucesso", "Erro ao atualizar dístico")
        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema}>
                {({ errors }) => {
                    console.log("errors", errors)
                    return (
                        <Form>
                            <Grid container direction="column" alignContent="flex-start" justifyContent="space-between" style={{ height: "100%" }}>
                                <Grid item xs={10} container direction="column">
                                    <Typography variant="h3" color="primary">
                                        Editar Distíco
                                    </Typography>

                                    <div className={styles.Wrapper}>
                                        <div>
                                            <InputTextField name="company" label="empresa" fullWidth />
                                        </div>
                                        <div>
                                            <InputTextField name="driver" label="condutor" fullWidth />
                                        </div>
                                        <div>
                                            <InputTextField name="licensePlate" label="matrícula" fullWidth />
                                        </div>
                                    </div>

                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Button type="submit" variant="contained" size="small">Guardar</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik >
        </div >
    )
}

export default EditBadge
