import { FC } from "react";
import { useContractsQuery, useCreateOneContractMutation, useDeleteOneContractMutation, useUpdateOneContractMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const ContractsDataSource = {
    createFunc: useCreateOneContractMutation,
    readFunc: useContractsQuery,
    updateFunc: useUpdateOneContractMutation,
    deleteFunc: useDeleteOneContractMutation,
}

const ContractProvider: FC<DataProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={ContractsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default ContractProvider
