import { clsx } from "clsx";
import { format } from 'date-fns';
import Config from "../../../services/config";
import styles from '../Datagrid.module.scss';

export const dateRender = (params: any, showExpiration: boolean = true) => {

    const isExpired = showExpiration && params?.value < new Date()
    const classes = clsx({ [styles.Expired]: isExpired })

    try {
        return <span className={classes}>{format(params?.value, Config.GIN_DATE_FORMAT)}</span>
    } catch (e) {
        return null
    }
}
