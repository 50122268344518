import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { FC } from 'react';
import { ContractTransition } from '../../generated/graphql';
import { FormatDate } from '../FormatDate/FormatDate';

interface ContractTimelineProps {
    contract: any
}

const ContractTimeline: FC<ContractTimelineProps> = props => {
    const { contract } = props

    return (
        <Timeline>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    <FormatDate date={contract?.createdAt} />
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    Preparação
                </TimelineContent>
            </TimelineItem>

            {contract?.contractTransitions?.map((contractTransition: ContractTransition) => (
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        <FormatDate date={contractTransition?.createdAt} />
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        {contractTransition?.toState?.name}
                    </TimelineContent>
                </TimelineItem>
            )
            )}
        </Timeline>
    )
}

export default ContractTimeline
