import { FilterProps } from "../../types/types"
import { rangeToOptions } from "../../utils/filters"
import { range } from "../../utils/numbers"
import SelectNumberFilter from "../SelectNumberFilter/SelectNumberFilter"

export default function WeekFilter(props: FilterProps) {
    const { value, onChange } = props

    const weeks = range(1, 53)
    const options = rangeToOptions(weeks)

    return (
        <SelectNumberFilter
            value={value}
            onChange={onChange}
            label="Semana"
            multiple={true}
            options={options}
            field="week"
        />
    )
}
