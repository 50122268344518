import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useWeatherConditionFieldQuery } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface WeatherConditionFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
    limit?: number
}

const WeatherConditionField: FC<WeatherConditionFieldProps> = props => {
    const {
        name = "weatherCondition",
        label = "Meteorologia",
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props

    const concession = useConcession()

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useWeatherConditionFieldQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}
        />
    )
}

export default WeatherConditionField
