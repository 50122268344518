import { useConcessionOrganizationAccreditationStatesQuery as query } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function AccreditationStateFilter(props: FilterProps) {
    const { value, onChange } = props
    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Estado"
            query={query}
            field="concessionOrganizationAccreditationState"
            multiple={true}
        />
    )
}
