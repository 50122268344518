import { Fade, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useUpdateDocumentName } from '../../hooks/useUpdateDocumentName';
import Config from '../../services/config';
import { firstProperty } from '../../services/utils';
import CustomModal from '../CustomModal/CustomModal';
import Datagrid from '../Datagrid/Datagrid';
import { DocumentScopes } from '../Datagrid/Helpers/PolicyToRoles';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import DocumentPreviewer from '../DocumentPreviewer/DocumentPreviewer';
import { getOrganizationDocumentColumns } from './OrganizationDocumentColumns';
import styles from './OrganizationDocumentsList.module.scss';

interface OrganizationDocumentsListProps {
    documentScopes?: DocumentScopes[]
}

const OrganizationDocumentsList = (props: OrganizationDocumentsListProps) => {
    const { documentScopes } = props
    const context = useContext(DataViewContext)
    const updateName = useUpdateDocumentName()
    const first = firstProperty(context?.data)!
    const nodes = context?.data?.[first]?.nodes
    const rows = nodes || []

    const [previewRow, setPreviewRow] = useState<any | null>(null)

    const documentListColumns = getOrganizationDocumentColumns({ setPreviewRow, documentScopes })

    useEffect(() => {
        context.setView("organizationDocumentsView")
        context.setColumnDefs(documentListColumns)
    }, [])

    const handleUpdateName = async (newRow: any, oldRow: any) => {
        return await updateName(newRow, oldRow, context)
    }

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.OrganizationDocumentsList}>
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                    onRowSelectionModelChange={context?.setRowSelectionModel}
                    rowSelectionModel={context?.rowSelectionModel}
                    processRowUpdate={handleUpdateName}
                    disableSelectionOnClick={true}
                />
                <CustomModal isActive={!!previewRow} handleClose={() => setPreviewRow(null)} fullScreen={true} backgroundColor="gray" textColor="white">
                    <Grid container justifyContent="center">
                        <DocumentPreviewer file={previewRow?.path} mimeType={previewRow?.fileType?.mimeType} />
                    </Grid>
                </CustomModal>
            </div >
        </Fade >
    );
}

export default OrganizationDocumentsList
