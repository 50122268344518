import { FC } from 'react'


interface ActionsBarSeparatorProps {
    marginRight?: number
    marginLeft?: number
}

const ActionsBarSeparator: FC<ActionsBarSeparatorProps> = props => {
    const { marginRight = 30, marginLeft = 0 } = props
    return <span style={{ marginRight, marginLeft }}></span>
}

export default ActionsBarSeparator
