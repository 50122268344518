import { Button, Grid } from "@mui/material"
import { useContext } from "react"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import styles from './ClearFilters.module.scss'

export default function ClearFilters() {
    const context = useContext(DataViewContext)

    const clearFilters = () => {
        context?.resetFilters()
    }

    return (
        <Grid className={styles.ClearFilters} direction="column" justifyContent="center">
            <Button onClick={clearFilters} variant="outlined" size="small">
                Limpar
            </Button>
        </Grid>
    )
}
