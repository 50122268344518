import { Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import InputTextField from "../InputTextField/InputTextField"
import SubmitButton from "../SubmitButton/SubmitButton"
import SubcontractOrganizationFormInit from "./SubcontractOrganizationFormInit"

interface CreateSubcontractOrganizationProps {
    onSubmit: (values: FormikValues) => void
}

export default function CreateSubcontractOrganization(props: CreateSubcontractOrganizationProps) {
    const { onSubmit } = props
    const { initial, schema } = SubcontractOrganizationFormInit()
    const errors: any = []

    return (
        <div style={{ padding: 20 }}>
            <Formik initialValues={initial} onSubmit={onSubmit} validationSchema={schema} validateOnBlur={false} validateOnChange={false} validateOnMount={false}>
                {() => {
                    return (
                        <Form>
                            <Typography variant="h4" color="primary">
                                Adicionar Entidade Subcontratada
                            </Typography>

                            <div style={{ color: "red" }}>
                                {errors.map((error: any) => <p key={error}>{error}</p>)}
                            </div>

                            <Grid container>
                                <InputTextField name="name" label="nome" fullWidth autoFocus />
                            </Grid>

                            <Grid container>
                                <InputTextField name="tin" label="nif" fullWidth />
                            </Grid>

                            <Grid container>
                                <InputTextField name="address" label="morada" fullWidth />
                            </Grid>

                            <Grid container>
                                <InputTextField name="zipCode" label="código postal" fullWidth />
                            </Grid>

                            <Grid container style={{ marginTop: 50 }}>
                                <Typography variant="h5" color="secondary">
                                    Responsável Subcontrato
                                </Typography>
                            </Grid>

                            <Grid container>
                                <InputTextField name="contactName" label="nome" fullWidth />
                            </Grid>
                            <Grid container>
                                <InputTextField name="contactEmail" label="email" fullWidth />
                            </Grid>

                            <p></p>

                            <Grid container justifyContent="flex-end">
                                <SubmitButton tooltip="Adicionar Entidade" />
                            </Grid>

                        </Form>
                    )
                }}
            </Formik >
        </div>
    )
}
