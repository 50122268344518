import * as Yup from "yup";
import { Maybe, VerificationScope } from "../../generated/graphql";
import { getInitialValues } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required().default(""),
        abbreviation: Yup.string().required().default(""),
    })
}

const verificationScopeFormInit = (record?: Maybe<VerificationScope>) => {
    const schema = getSchema()
    const initial = record ? record : getInitialValues(schema)
    return { schema, initial }
}

export default verificationScopeFormInit
