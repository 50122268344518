import { FC } from 'react';
import { useConcessionOrganizationDocumentCategoriesQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

const ContractDocumentSubcategoryFilter: FC<FilterProps> = props => {
    const { value, onChange, allOptionName, multiple = false, hideAllOption, parent } = props
    const query = useConcessionOrganizationDocumentCategoriesQuery
    const concession = useConcession()

    const filter = {
        concession: { id: { eq: concession?.id } },
        parent: { id: { isNot: null } }
    }

    if (parent?.length) {
        // @ts-ignore
        filter.parent = { id: { in: parent } }
    }

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label={"Subcategoria"}
            query={query}
            field="concessionOrganizationDocumentSubcategory"
            filter={filter}
            allOptionName={allOptionName}
            multiple={multiple}
            hideAllOption={hideAllOption}
            sorting={[{ field: "name", direction: "ASC" }]}
            paging={{ limit: 999 }}
        />
    )
}

export default ContractDocumentSubcategoryFilter
