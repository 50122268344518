import * as Yup from "yup";
import { getInitialValues } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        email: Yup.string().email().required().default("")
    })
}

export const findUserFormInit = () => {
    const schema = getSchema()
    const initial = getInitialValues(schema)
    return { schema, initial }
}
