import { Delete, Download, Grading, PublishedWithChanges, Unpublished } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { SurveyRecordStates, useDeleteOneSurveyRecordMutation, useExportSurveyRecordPdfMutation, useSendPublishedSurveyRecordEmailMutation, useUpdateOneSurveyRecordMutation } from "../../generated/graphql";
import useGoTo from "../../hooks/useGoto";
import useToast from "../../hooks/useToast";
import { Actions, Subjects } from '../../services/ability';
import { downloadRequestStatic } from "../../services/utils";
import MenuDivider from "../MenuDivider/MenuDivider";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import MoreIconButton from "../MoreIconButton/MoreIconButton";
import Permission from "../Permission";
import Show from "../Show/Show";

interface SurveyRecordViewMoreActionsProps {
    surveyRecord: any
    refetch: any
}

const SurveyRecordViewMoreActions: FC<SurveyRecordViewMoreActionsProps> = props => {
    const { surveyRecord, refetch } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [deleteSurveyRecord] = useDeleteOneSurveyRecordMutation()
    const [updateSurveyRecord] = useUpdateOneSurveyRecordMutation()
    const [publishSurveyRecord] = useSendPublishedSurveyRecordEmailMutation()
    const [exportSurveyRecord] = useExportSurveyRecordPdfMutation()
    const { feedback, infoToast, errorToast } = useToast()
    const { goToSurveyRecords } = useGoTo()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeAndRefetch = () => {
        handleClose();
        refetch();
    }

    const handleDelete = async () => {
        if (surveyRecord?.id) {
            const response = await deleteSurveyRecord({ variables: { input: { id: surveyRecord.id } } })
            const errors = response?.errors?.length
            feedback(!errors, "Registo removido com sucesso", "Erro ao remover registo.")
            if (!errors) {
                goToSurveyRecords()
            }
        }
    }

    const handleIssueSurveyRecord = async () => {
        const response = await updateSurveyRecord({ variables: { input: { id: surveyRecord?.id, update: { state: SurveyRecordStates.Issued } } } })
        feedback(!response?.errors?.length, "Registo emitido com sucesso", "Erro ao emitir registo.")
        closeAndRefetch()
    }

    const handlePublishSurveyRecord = async () => {
        const response = await publishSurveyRecord({ variables: { id: surveyRecord?.id } })
        feedback(!response?.errors?.length, "Registo de visita publicado", "Erro ao publicar registo.")
        closeAndRefetch()
    }

    const handleUnPublishSurveyRecord = async () => {
        const response = await updateSurveyRecord({ variables: { input: { id: surveyRecord?.id, update: { isPublished: false } } } })
        feedback(!response?.errors?.length, "Registo de visita despublicado", "Erro ao despublicar registo.")
        closeAndRefetch()
    }

    const handlePrint = async () => {
        infoToast("Aguarde um momento, por favor, enquanto o registo é gerado")
        const response = await exportSurveyRecord({ variables: { input: { surveyRecord: surveyRecord.id } } })
        if (response?.data?.exportSurveyRecordPDF) {
            downloadRequestStatic(response?.data?.exportSurveyRecordPDF, `${surveyRecord.name}.pdf`)
        } else {
            errorToast("Erro ao gerar PDF, tente novamente por favor")
        }
    }

    const surveyDownloadDisabled = surveyRecord?.state !== SurveyRecordStates.Issued

    return (
        <div>
            <MoreIconButton onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: 500 }}
            >


                <Permission action={Actions.UPDATE} subject={Subjects.SURVEYRECORD} field="state" passThrough>
                    {allowed =>
                        <MenuItem disabled={surveyRecord?.state === SurveyRecordStates.Issued || !allowed} onClick={handleIssueSurveyRecord}>
                            <ListItemIcon>
                                <Grading fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Emitir
                            </ListItemText>
                        </MenuItem>
                    }
                </Permission>

                <MenuDivider />

                <Show on={!surveyRecord?.isPublished}>
                    <Permission action={Actions.UPDATE} subject={Subjects.SURVEYRECORD} field="isPublished" passThrough>
                        {allowed =>
                            <MenuItem disabled={surveyRecord?.state !== SurveyRecordStates.Issued || !allowed} onClick={handlePublishSurveyRecord}>
                                <ListItemIcon>
                                    <PublishedWithChanges fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Publicar
                                </ListItemText>
                            </MenuItem>
                        }
                    </Permission>
                </Show>

                <Show on={surveyRecord?.isPublished}>
                    <Permission action={Actions.UPDATE} subject={Subjects.SURVEYRECORD} field="isPublished" passThrough>
                        {allowed =>
                            <MenuItem disabled={surveyRecord?.state !== SurveyRecordStates.Issued || !allowed} onClick={handleUnPublishSurveyRecord}>
                                <ListItemIcon>
                                    <Unpublished fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Despublicar
                                </ListItemText>
                            </MenuItem>
                        }
                    </Permission>
                </Show>

                <MenuItem onClick={handlePrint} disabled={surveyDownloadDisabled}>
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Descarregar Registo
                    </ListItemText>
                </MenuItem>

                <MenuDivider />

                <Permission action={Actions.DELETE} subject={Subjects.SURVEYRECORD} passThrough>
                    {allowed =>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover Registo"
                            allowed={allowed}
                            confirmationRequired={true}
                            listItemTextColor="red"
                        />
                    }
                </Permission>


            </Menu>
        </div>
    )
}

export default SurveyRecordViewMoreActions
