import { FolderOpen, TextSnippet } from "@mui/icons-material"
import { Button, Grid, Tooltip } from "@mui/material"
import { FC } from "react"
import { useQueryParams } from "use-query-params"
import { ContractSubviews } from "./ContractView"

interface ContractSubviewSwitherProps {
    subview: ContractSubviews
    setSubview: any
    disabled?: boolean
}

const ContractSubviewSwitcher: FC<ContractSubviewSwitherProps> = props => {

    const { subview, setSubview, disabled = false } = props
    const [_, setQuery] = useQueryParams({ tab: "DETAILS" })

    const handleClick = () => {
        if (subview === ContractSubviews.DETAILS) {
            setSubview(ContractSubviews.DOCUMENTS)
            setQuery({ tab: "DOCUMENTS" }, "replace")
        } else {
            setSubview(ContractSubviews.DETAILS)
            setQuery({ tab: "DETAILS" }, "replace")
        }
    }

    const title = subview === ContractSubviews.DETAILS ? "Ver documentos" : "Ver detalhes"
    const icon = subview === ContractSubviews.DETAILS ? <FolderOpen /> : <TextSnippet />

    return (
        <Grid container justifyContent="flex-end" style={{ paddingBottom: 15 }}>
            <Tooltip title={title}>
                <span>
                    <Button onClick={handleClick} disabled={disabled} startIcon={icon} variant="outlined" size="small" color="secondary">
                        {title}
                    </Button>
                </span>
            </Tooltip>
        </Grid>
    )
}


export default ContractSubviewSwitcher
