import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Grid, IconButton } from "@mui/material"
import { Maybe } from "graphql/jsutils/Maybe"
import { FC } from "react"
import styles from './PageNavigator.module.scss'

interface PageNavigatorProps {
    pageNumber: number
    numPages: Maybe<number>
    setPageNumber: any
}

const PageNavigator: FC<PageNavigatorProps> = props => {
    const { pageNumber, numPages, setPageNumber } = props

    if (!numPages) return null

    const handle = (offset: number) => {
        setPageNumber(pageNumber + offset)
    }

    const firstPage = pageNumber === 1
    const lastPage = pageNumber === numPages

    return (
        <Grid container alignItems="center" className={styles.PageNavigator}>
            <IconButton onClick={() => handle(-1)} disabled={firstPage} >
                <ChevronLeft />
            </IconButton>

            <div>
                {pageNumber} de {numPages}
            </div>

            <IconButton onClick={() => handle(1)} disabled={lastPage} >
                <ChevronRight />
            </IconButton>
        </Grid >
    )
}

export default PageNavigator
