import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"

export interface MoreMenuItemProps {
    icon: JSX.Element
    label: string
    onClick: () => void
    disabled?: boolean
}

export const MoreMenuItem = (props: MoreMenuItemProps) => {
    const { icon, label, onClick, disabled = false } = props
    return (
        <MenuItem disabled={disabled} onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                {label}
            </ListItemText>
        </MenuItem>
    )
}
