import { createContext, useContext } from "react";
import { useManageWorkspaceViewColumnMutation, useSetWorkspaceViewMutation, useWorkspaceQuery } from "../generated/graphql";

const workspaceContext = createContext<any>(undefined)

const WorkspaceProvider = ({ children }: { children: any }) => {
    const { data, refetch } = useWorkspaceQuery()
    const [manage] = useManageWorkspaceViewColumnMutation()
    const [setWorkspaceView] = useSetWorkspaceViewMutation()

    return <workspaceContext.Provider value={{ data, manage, refetch, setWorkspaceView }} >
        {children}
    </workspaceContext.Provider >
}

export const useWorkspace = () => useContext(workspaceContext)
export default WorkspaceProvider
