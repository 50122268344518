import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";
import DriveFolderUpload from '@mui/icons-material/DriveFolderUpload';

interface ImportIconButtonProps {
    onClick: (event: any) => void
    disabled?: boolean
    title?: string
}

export default function ImportIconButton(props: ImportIconButtonProps) {
    const { onClick,
        disabled = false,
        title = "importar"
    } = props

    return (
        <IconButtonTooltip title={title} disabled={disabled} onClick={onClick}>
            <DriveFolderUpload />
        </IconButtonTooltip>
    )
}
