import * as Yup from "yup";
import { User } from "../../generated/graphql";
import { getInitialValues } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().default(""),
        phone: Yup.string().default(""),
        password: Yup.string().default("")
    })
}

const profileFormInit = (user?: Partial<User>) => {
    const schema = getSchema()
    const initial = user ? user : getInitialValues(schema)
    Object.assign(initial, {})
    return { schema, initial }
}

export default profileFormInit
