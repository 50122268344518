import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useRestrictionsQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import config from '../../services/config';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface RestrictionFieldProps {
    textFieldProps?: TextFieldProps
    disabled?: boolean
}

const RestrictionField: FC<RestrictionFieldProps> = props => {
    const { textFieldProps = { variant: "standard" }, disabled } = props
    const concession = useConcession()
    return (
        <SelectAutocompleteDataField
            name="restriction"
            label="Condicionalismo"
            query={useRestrictionsQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={config.DEFAULT_FIELD_QUERY_PAGING}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}
        />
    )
}

export default RestrictionField
