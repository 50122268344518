import * as Yup from "yup";
import { getInitialValues } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required().default(""),
        email: Yup.string().email().required().default(""),
        phone: Yup.string().required().default(""),
        password: Yup.string().required().default(""),
        concessionOrganizationUserRole: Yup.mixed().required().default(null)
    })
}

export const createUserFormInit = () => {
    const schema = getSchema()
    const initial = getInitialValues(schema)
    return { schema, initial }
}
