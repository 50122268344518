import * as Yup from "yup";
import { getInitialValues } from "../../services/config";
import { Badge } from "../../generated/graphql"

const getSchema = () => {
    return Yup.object().shape({
        company: Yup.string().required().default(""),
        driver: Yup.string().required().default(""),
        licensePlate: Yup.string().required().default("")
    })
}

const badgeFormInit = (badge: Partial<Badge>) => {
    const schema = getSchema()
    const initial = badge ? badge : getInitialValues(schema)
    return { schema, initial }
}


export default badgeFormInit
