import { useSurveyRecordAuthorsQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function SurveyRecordAuthorFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useSurveyRecordAuthorsQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Autor"
            query={query}
            field="user"
            multiple={true}
        />
    )
}
