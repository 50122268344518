import { Typography } from "@mui/material"
import styles from './LinkedName.module.scss'

interface LinkedNameProps {
    onClick: (event: any) => void
    name: string
}

export default function LinkedName(props: LinkedNameProps) {
    const { onClick, name } = props
    return (
        <span onClick={onClick}>
            <Typography variant="body1" color="primary" className={styles.Name}>
                {name}
            </Typography>
        </span>
    )
}
