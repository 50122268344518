import { Search as SearchIcon } from '@mui/icons-material';
import { FC } from 'react';
import AutoTextField from '../AutoTextField/AutoTextField';
import styles from './Search.module.scss';

interface SearchProps {
    name?: string
    placeholder?: string
    value: any
    onChange: any
}

const Search: FC<SearchProps> = props => {
    const { name = "search", placeholder = "Pesquisar", value, onChange } = props

    const handle = (data: any) => {
        // @ts-ignore
        onChange(name, data[name])
    }

    return (
        <div className={styles.SearchWrapper}>
            <AutoTextField
                name={name}
                onSave={handle}
                placeholder={placeholder}
                value={value}
                interval={500}
                fullWidth={false}
                clearable={true}
                variant="standard"
                InputProps={{
                    autoComplete: "off",
                    autoFocus: true,
                    startAdornment: <SearchIcon style={{ color: "#cccccc", marginRight: 5 }} />,
                    disableUnderline: true,
                }}
            />
        </div>
    )
}

export default Search
