import { useEffect, useState } from "react";
import { SortDirection } from "../../generated/graphql";
import useConcession from "../../hooks/useConcession";
import useToast from "../../hooks/useToast";
import { firstProperty, omit } from "../../services/utils";
import OrganizationEditor from "../OrganizationEditor/OrganizationEditor";
import UserEditor from "../UserEditor/UserEditor";
import contractTypeFormInit from "./ContractTypeFormInit";
import useContractTypeStore from "./ContractTypeStore";
import controlCenterFormInit from "./ControlCenterFormInit";
import useControlCenterStore from "./ControlCenterStore";
import interventionElementFormInit from "./InterventionElementFormInit";
import useInterventionElementStore from "./InterventionElementStore";
import interventionLocationFormInit from "./InterventionLocationFormInit";
import useInterventionLocationStore from "./InterventionLocationStore";
import interventionTypeFormInit from "./InterventionTypeFormInit";
import useInterventionTypeStore from "./InterventionTypeStore";
import layoutFormInit from "./LayoutFormInit";
import useLayoutStore from "./LayoutStore";
import useOrganizationStore from "./OrganizationStore";
import restrictionFormInit from "./RestrictionFormInit";
import useRestrictionStore from "./RestrictionStore";
import roadFormInit from "./RoadFormInit";
import useRoadStore from "./RoadStore";
import serviceFormInit from "./ServiceFormInit";
import useServiceStore from "./ServiceStore";
import { SettingRecordOrganization, SettingRecordUser } from "./SettingsRecords";
import surveyRecordVerificationClassificationFormInit from "./SurveyRecordVerificationClassificationFormInit";
import useSurveyRecordVerificationClassificationStore from "./SurveyRecordVerificationRealizationClassificationStore";
import useUserStore from "./UserStore";
import verificationFormInit from "./VerificationFormInit";
import verificationScopeFormInit from "./VerificationScopeFormInit";
import useVerificationScopeStore from "./VerificationScopeStore";
import useVerificationStore from "./VerificationStore";
import weatherConditionFormInit from "./WeatherConditionFormInit";
import useWeatherConditionStore from "./WeatherConditionStore";

export const getRecords = (data: any) => {
    const first = firstProperty(data)
    return first ? data?.[first]?.nodes : []
}

const useSettingsStore = () => {

    // stores
    const controlCenterStore = useControlCenterStore()
    const layoutStore = useLayoutStore()
    const serviceStore = useServiceStore()
    const weatherConditionStore = useWeatherConditionStore()
    const userStore = useUserStore()
    const organizationStore = useOrganizationStore()
    const interventionLocationStore = useInterventionLocationStore()
    const verificationScopeStore = useVerificationScopeStore()
    const restrictionStore = useRestrictionStore()
    const interventionElementStore = useInterventionElementStore()
    const verificationStore = useVerificationStore()
    const contractTypeStore = useContractTypeStore()
    const interventionTypeStore = useInterventionTypeStore()
    const surveyRecordVerificationClassificationStore = useSurveyRecordVerificationClassificationStore()
    const roadStore = useRoadStore()

    const concession = useConcession()

    const userVariables = { variables: { paging: { limit: 9999 }, sorting: { field: "name", direction: SortDirection.Asc }, concession: concession?.id } }
    const variables = { variables: { paging: { limit: 9999 }, sorting: { field: "name", direction: SortDirection.Asc }, filter: { concession: { id: { eq: concession?.id } } } } }

    // setting ou configuration, temos que encapsular
    const entities = [
        { id: 1, name: "Âmbito Verificação", store: verificationScopeStore, schemaInit: verificationScopeFormInit, variables }, // Realization Scope - 1 campo texto
        { id: 2, name: "Centros Controlo Tráfego", store: controlCenterStore, schemaInit: controlCenterFormInit, variables },
        { id: 3, name: "Classificação Verificação", store: surveyRecordVerificationClassificationStore, schemaInit: surveyRecordVerificationClassificationFormInit, variables },
        { id: 4, name: "Condicionalismos", store: restrictionStore, schemaInit: restrictionFormInit, variables },
        { id: 5, name: "Elementos Infraestrutura", store: interventionElementStore, schemaInit: interventionElementFormInit, variables }, // Intervention Element - 2 campos texto - 31 registos
        { id: 6, name: "Entidades Externas", store: organizationStore, variables: userVariables, viewer: SettingRecordOrganization, editor: OrganizationEditor },
        { id: 7, name: "Esquemas", store: layoutStore, schemaInit: layoutFormInit, variables },
        { id: 8, name: "Localizações Infraestrutura", store: interventionLocationStore, schemaInit: interventionLocationFormInit, variables }, // intervention locations - 2 campos texto
        { id: 9, name: "Metereologia", store: weatherConditionStore, schemaInit: weatherConditionFormInit, variables },
        { id: 10, name: "Serviços", store: serviceStore, schemaInit: serviceFormInit, variables },
        { id: 11, name: "Tipos Contrato", store: contractTypeStore, schemaInit: contractTypeFormInit, variables }, //  Contract Type - 2 campos texto
        { id: 12, name: "Tipo Trabalho", store: interventionTypeStore, schemaInit: interventionTypeFormInit, variables }, // Intervention Type - 2 campos texto
        { id: 13, name: "Utilizadores", editor: UserEditor, viewer: SettingRecordUser, store: userStore, variables: userVariables },
        { id: 14, name: "Verificações", store: verificationStore, schemaInit: verificationFormInit, variables }, // Verification Item - 1 campo texo
        { id: 15, name: "Vias", store: roadStore, schemaInit: roadFormInit, variables }
    ]

    const [selectedRecord, setSelectedRecord] = useState<any>(null)
    const [selectedEntity, setSelectedEntity] = useState<any>(entities[0])

    const { data, loading, refetch } = selectedEntity?.store?.query(selectedEntity?.variables)
    const { errorToast } = useToast()


    useEffect(() => {
        const records = getRecords(data)
        const found = records.find((obj: any) => obj.id === selectedRecord?.id)
        setSelectedRecord(found)
    }, [data]);


    const setSelectedEntityProxy = (entity: any) => {
        setSelectedRecord(null)
        setSelectedEntity(entity)
    }

    const createProxy = async (record: any) => {
        const { create, name } = selectedEntity?.store
        let response = null

        try {
            response = await create({ variables: { input: { [name]: { ...record } } } })
            await refetch()
            const first = firstProperty(response?.data)
            if (first) {
                setSelectedRecord(response.data[first])
            }
        } catch (e) {
            console.log(e)
        }

        return response
    }

    const updateProxy = async (id: any, data: any) => {
        const { update } = selectedEntity?.store
        const response = await update({ variables: { input: { id, update: omit(data, ["concession"]) } } })
        await refetch()
        const first = firstProperty(response?.data)
        if (first) {
            setSelectedRecord(response.data[first])
        }
    }

    const removeProxy = async (id: any) => {
        const { remove } = selectedEntity?.store
        await remove({ variables: { input: { id } } })
        await refetch()
        setSelectedRecord(null)
    }

    return { entities, selectedEntity, setSelectedEntity: setSelectedEntityProxy, data, loading, selectedRecord, setSelectedRecord, store: { ...selectedEntity?.store, create: createProxy, update: updateProxy, remove: removeProxy, refetch }, editor: selectedEntity?.editor, viewer: selectedEntity?.viewer }
}

export default useSettingsStore
