import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { UseFiltersOnChange } from '../../hooks/useFilters';

interface DateFilterProps {
    name: string
    value: Date
    onChange: UseFiltersOnChange
}

const DateFilter: FC<DateFilterProps> = props => {

    const { name, value, onChange } = props

    const handleChange = (date: Maybe<Date>) => {
        if (!date) return null
        onChange(name, date)
    }

    return (
        <div>
            <DatePicker
                value={value}
                onChange={handleChange}
                slotProps={{ textField: { variant: "standard" } }}
            />
        </div>
    )
}

export default DateFilter
