import { createContext, useContext, useState } from "react";

interface LayoutContexInterface {
    isOpen: boolean
    setIsOpen: any // @todo - melhorar typing React.Dispatch<React.SetStateAction<boolean>>
}

export const LayoutContext = createContext<LayoutContexInterface>({ isOpen: false, setIsOpen: undefined })

const LayoutProvider = ({ children }: { children: any }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return <LayoutContext.Provider value={{ isOpen, setIsOpen }}>{children}</LayoutContext.Provider>;
}

export const useSidemenu = () => {
    const { isOpen, setIsOpen } = useContext(LayoutContext)
    return { isOpen, setIsOpen }
}

export default LayoutProvider
