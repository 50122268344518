import { AlarmOff, Block, Delete, Done, Download, Grading, Print, Publish, PublishedWithChanges, Unpublished } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { useDeleteOneWorkPermitMutation, useSendPublishedWorkPermitEmailMutation, useUpdateOneWorkPermitMutation, WorkPermitStates } from "../../generated/graphql";
import useGoTo from "../../hooks/useGoto";
import useToast from "../../hooks/useToast";
import { Actions, Subjects } from "../../services/ability";
import MenuDivider from "../MenuDivider/MenuDivider";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import MoreIconButton from "../MoreIconButton/MoreIconButton";
import Permission from "../Permission";
import Show from "../Show/Show";
import generateWorkPermitPDF from "./GenerateWorkPermitPDF";
import { PDFMode } from "./PDF";


interface WorkPermitViewMoreActionsProps {
    workPermit: any
    refetch: any
    disabledActions: string[]
}

export const getAllowedTransitions = (currentState: string) => {

    if (!currentState) return []

    const transitionsMap: any = {
        [WorkPermitStates.Preparation]: [WorkPermitStates.Submitted],
        [WorkPermitStates.Submitted]: [WorkPermitStates.Validated, WorkPermitStates.Review],
        [WorkPermitStates.Review]: [WorkPermitStates.Submitted],
        [WorkPermitStates.Validated]: [WorkPermitStates.Approved, WorkPermitStates.Cancelled, WorkPermitStates.Review],
        [WorkPermitStates.Cancelled]: [],
        [WorkPermitStates.Approved]: [WorkPermitStates.Expired],
        [WorkPermitStates.Expired]: []
    }

    return transitionsMap[currentState] || []
}


const WorkPermitViewMoreActions: FC<WorkPermitViewMoreActionsProps> = props => {
    const { workPermit, refetch, disabledActions } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [updateWorkPermit] = useUpdateOneWorkPermitMutation()
    const [deleteWorkPermit] = useDeleteOneWorkPermitMutation()
    const [publishWorkPermit] = useSendPublishedWorkPermitEmailMutation()
    const { errorToast, feedback } = useToast()
    const { goToWorkPermits } = useGoTo()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeAndRefetch = () => {
        handleClose();
        refetch();
    }

    const handleGeneratePDF = (_: any, mode: PDFMode = PDFMode.PRINT) => {
        try {
            generateWorkPermitPDF(workPermit, mode)
        } catch (e) {
            errorToast(`Erro ao gerar permissão. ${(e as Error).message}`)
        }
    }

    const handleDelete = async () => {
        if (workPermit?.id) {
            const response = await deleteWorkPermit({ variables: { input: { id: workPermit.id } } })
            const errors = response?.errors?.length
            feedback(!errors, "Permissão removida com sucesso", "Erro ao remover permissão.")
            if (!errors) {
                goToWorkPermits()
            }
        }
    }

    const transitionTo = async (stateTo: string) => {
        if (workPermit?.id) {
            // @ts-ignore
            const response = await updateWorkPermit({ variables: { input: { id: workPermit?.id, update: { state: stateTo } } } })
            feedback(!response?.errors?.length, "Transição bem sucedida.", "Erro na transição.")
            closeAndRefetch()
        }
    }

    const handlePublishWorkPermit = async () => {
        const response = await publishWorkPermit({ variables: { id: workPermit?.id } })
        feedback(!!response?.data?.sendPublishedWorkPermitEmail, "Registo de visita publicado", "Erro ao publicar. Verifique as datas da permissão e do contrato.")
        closeAndRefetch()
    }

    const handleUnPublishWorkPermit = async () => {
        const response = await updateWorkPermit({ variables: { input: { id: workPermit?.id, update: { isPublished: false } } } })
        feedback(!response?.errors?.length, "Permissão de trabalho despublicada", "Erro ao despublicar permissão.")
        closeAndRefetch()
    }

    const getTransitionData = (currentState: string) => {

        const transitions: string[] = getAllowedTransitions(currentState)
        const actionsMap: any = [
            {
                stateTo: WorkPermitStates.Submitted,
                icon: <Publish fontSize="small" />,
                label: "Submeter",
                action: Actions.SUBMIT,
                subject: Subjects.WORKPERMIT
            },
            {
                stateTo: WorkPermitStates.Review,
                icon: <Grading fontSize="small" />,
                label: "Pedir Revisão",
                action: Actions.REVIEW,
                subject: Subjects.WORKPERMIT,
            },
            {
                stateTo: WorkPermitStates.Validated,
                icon: <Done fontSize="small" />,
                label: "Validar",
                action: Actions.VALIDATE,
                subject: Subjects.WORKPERMIT,
            },
            {
                stateTo: WorkPermitStates.Cancelled,
                icon: <Block fontSize="small" />,
                label: "Cancelar",
                action: Actions.CANCEL,
                subject: Subjects.WORKPERMIT,
            },
            {
                stateTo: WorkPermitStates.Approved,
                icon: <Done fontSize="small" />,
                label: "Aprovar",
                action: Actions.APROVE,
                subject: Subjects.WORKPERMIT,
            },
            {
                stateTo: WorkPermitStates.Expired,
                icon: <AlarmOff fontSize="small" />,
                label: "Expirar",
                action: Actions.EXPIRE,
                subject: Subjects.WORKPERMIT,
            }
        ]

        return actionsMap.filter((obj: any) => transitions.includes(obj.stateTo))
    }


    const allowedTransitions = getAllowedTransitions(workPermit?.state)
    const pdfOptionsDisabled = workPermit?.state !== WorkPermitStates.Approved || !workPermit?.isPublished

    return (
        <div>
            <MoreIconButton onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: 500 }}
            >

                {
                    getTransitionData(workPermit?.state).map((obj: any) => {
                        const { action, subject, stateTo, confirmationRequired, icon, label } = obj
                        return (
                            <Permission action={action} subject={subject} passThrough>
                                {allowed =>
                                    <MenuItemWithConfirmation
                                        icon={icon}
                                        label={label}
                                        allowed={allowed}
                                        onClick={() => transitionTo(stateTo)}
                                        confirmationRequired={confirmationRequired}
                                        disabled={disabledActions.includes(action)}
                                    />

                                }
                            </Permission>
                        )
                    })
                }

                <MenuDivider on={allowedTransitions?.length} />

                <Show on={!workPermit?.isPublished}>
                    <Permission action={Actions.PUBLISH} subject={Subjects.WORKPERMIT} passThrough>
                        {allowed =>
                            <MenuItem disabled={!allowed || workPermit?.state !== WorkPermitStates.Approved} onClick={handlePublishWorkPermit}>
                                <ListItemIcon>
                                    <PublishedWithChanges fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Publicar
                                </ListItemText>
                            </MenuItem>
                        }
                    </Permission>
                </Show>

                <Show on={workPermit?.isPublished}>
                    <Permission action={Actions.PUBLISH} subject={Subjects.WORKPERMIT} passThrough>
                        {allowed =>
                            <MenuItem disabled={!allowed || workPermit?.state !== WorkPermitStates.Approved} onClick={handleUnPublishWorkPermit}>
                                <ListItemIcon>
                                    <Unpublished fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Despublicar
                                </ListItemText>
                            </MenuItem>
                        }
                    </Permission>
                </Show>

                <Divider />

                <MenuItem disabled={pdfOptionsDisabled} onClick={handleGeneratePDF}>
                    <ListItemIcon>
                        <Print fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Imprimir Permissão
                    </ListItemText>
                </MenuItem>
                <MenuItem disabled={pdfOptionsDisabled} onClick={event => handleGeneratePDF(event, PDFMode.DOWNLOAD)}>
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Descarregar Permissão
                    </ListItemText>
                </MenuItem>


                <Divider />
                <Permission action="delete" subject="work-permit" passThrough>
                    {allowed =>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover Permissão"
                            allowed={allowed}
                            confirmationRequired={true}
                            listItemTextColor="red"
                        />
                    }
                </Permission>

            </Menu>
        </div>
    )
}

export default WorkPermitViewMoreActions
