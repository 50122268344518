import { useConcessionUsersQuery, useCreateOneConcessionUserMutation, useDeleteOneConcessionUserMutation } from "../../generated/graphql";
import useConcession from "../../hooks/useConcession";
import Config from "../../services/config";

const useConcessionUserStore = () => {
    const query = useConcessionUsersQuery
    const name = "concessionUser"

    const concession = useConcession()

    const [_create] = useCreateOneConcessionUserMutation()
    const [_remove] = useDeleteOneConcessionUserMutation()

    const create = (userId: string, role: any) => {
        if (!concession?.id) throw Error(Config.APP_ERRORS.UNDEFINED_CONCESSION)
        return _create({ variables: { input: { concessionUser: { user: userId, concession: concession?.id, role } } } })
    }

    const remove = (concessionUserId: string) => {
        return _remove({ variables: { input: { id: concessionUserId } } })
    }

    return { query, name, create, remove, _create, _remove }
}

export default useConcessionUserStore
