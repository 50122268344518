import { ArrayParam, DateParam, StringParam } from "use-query-params";
import { customFilterIn, dateGteLookup, dateLteLookup, searchExpFunc } from "../../utils/lookups";
import ContractsActions from "../ContractsActions/ContractsActions";
import ContractsFilters from "../ContractsFilters/ContractsFilters";
import ContractsList from "../ContractsList/ContractsList";
import ContractProvider from "./ContractProvider";
import styles from './Contracts.module.scss';

const Contracts = () => {

    const filterConfig = [
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "contractState", variant: ArrayParam, defaultValue: [] },
        { name: "contractType", variant: ArrayParam, defaultValue: [] },
        { name: "service", variant: ArrayParam, defaultValue: [] },
        { name: "interventionType", variant: ArrayParam, defaultValue: [] },
        { name: "executingOrganization", variant: ArrayParam, defaultValue: [], lookupExpFunc: customFilterIn },
        { name: "road", variant: ArrayParam, defaultValue: [], lookupExpFunc: customFilterIn },
        { name: "startDateGte", variant: DateParam, defaultValue: null, lookupExpFunc: dateGteLookup, alias: "startDate" },
        { name: "startDateLte", variant: DateParam, defaultValue: null, lookupExpFunc: dateLteLookup, alias: "and" },
    ]

    return (
        <div className={styles.Wrapper}>
            <ContractProvider filterConfig={filterConfig}>
                <ContractsActions />
                <ContractsFilters />
                <ContractsList />
            </ContractProvider>
        </div>
    )
}


export default Contracts;
