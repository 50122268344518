import { ArrayParam, StringParam } from "use-query-params"
import { boolLookupExpression, searchExpFunc, stateLookupExpression } from "../../utils/lookups"
import WorkPermitsActions from "../WorkPermitsActions/WorkPermitsActions"
import WorkPermitsFilters from "../WorkPermitsFilters/WorkPermitsFilters"
import WorkPermitsList from "../WorkPermitsList/WorkPermitsList"
import WorkPermitProvider from "./WorkPermitProvider"
import styles from './WorkPermitsView.module.scss'

const WorkPermitsView = () => {

    const filterConfig = [
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc },
        { name: "contract", variant: ArrayParam, defaultValue: [] },
        { name: "state", variant: ArrayParam, defaultValue: [], lookupExpFunc: stateLookupExpression },
        { name: "isPublished", variant: StringParam, defaultValue: "", lookupExpFunc: boolLookupExpression }
    ]

    return (
        <div className={styles.Wrapper}>
            <WorkPermitProvider filterConfig={filterConfig}>
                <WorkPermitsActions />
                <WorkPermitsFilters />
                <WorkPermitsList />
            </WorkPermitProvider>
        </div>
    )
}

export default WorkPermitsView
