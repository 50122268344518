import { ConcessionUserRoles, ConcessionOrganizationUserRoles } from "../generated/graphql"
import { useAuth } from "../services/auth"

const internalRoles = [
    ConcessionUserRoles.Platformmanager,
    ConcessionUserRoles.Contractmanager,
    ConcessionUserRoles.Servicemanager,
    ConcessionUserRoles.Safetytechnician,
    ConcessionUserRoles.Operator
]

const externalRoles = [
    ConcessionOrganizationUserRoles.Externalauthority,
    ConcessionOrganizationUserRoles.Externalmanager,
    ConcessionOrganizationUserRoles.Externaltechnician,
    ConcessionOrganizationUserRoles.Externaladministrative,
]

const useHasRole = () => {
    const auth = useAuth()
    return (role: string) => {
        return auth?.user?.roles?.includes(role)
    }
}

export const useHasOneOfRoles = () => {
    const auth = useAuth()
    return (roles: string[]) => {
        return roles.some(role => auth?.user?.roles?.includes(role))
    }
}

export const useHasPlatformManagerRole = () => {
    const hasRole = useHasRole()
    return hasRole(ConcessionUserRoles.Platformmanager)
}

export const useHasContractManagerRole = () => {
    const hasRole = useHasRole()
    return hasRole(ConcessionUserRoles.Contractmanager)
}

export const useHasServiceManagerRole = () => {
    const hasRole = useHasRole()
    return hasRole(ConcessionUserRoles.Servicemanager)
}

export const useHasSafetyTechnicianRole = () => {
    const hasRole = useHasRole()
    return hasRole(ConcessionUserRoles.Safetytechnician)
}

export const useHasOperatorRole = () => {
    const hasRole = useHasRole()
    return hasRole(ConcessionUserRoles.Operator)
}

export const useHasInternalRole = () => {
    const hasRole = useHasRole()
    for (const internalRole of internalRoles) {
        if (hasRole(internalRole)) return true
    }
    return false
}

export const useHasExternalRole = () => {
    const hasRole = useHasRole()
    for (const externalRole of externalRoles) {
        if (hasRole(externalRole)) return true
    }
    return false
}


export default useHasRole
