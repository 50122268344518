import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { TasksListView } from "../../types/types";
import { countNonEmptyFields } from "../../utils/objects";
import ClearFilters from "../ClearFilters/ClearFilters";
import ContractExecutingOrganizationFilter from "../ContractExecutingOrganizationFilter/ContractExecutingOrganizationFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Hide from "../Hide/Hide";
import InterventionFilter from "../InterventionFilter/InterventionFilter";
import InterventionLocationFilter from "../InterventionLocationFilter/InterventionLocationFilter";
import JunctionFilter from "../JunctionFilter/JunctionFilter";
import MoreFilters from "../MoreFilters/MoreFilters";
import RoadFilter from "../RoadFilter/RoadFilter";
import StartAtFilter from "../StartAtFilter/StartAtFilter";
import { getTasksListFilters } from "./Helpers/getTasksListFilters";
import styles from './TasksMoreFilters.module.scss';

interface TasksMoreFiltersProps {
    view: TasksListView
}

export default function TasksMoreFilters(props: TasksMoreFiltersProps) {
    const { view } = props
    const context = useContext(DataViewContext)
    const filters = getTasksListFilters(view)
    const count = countNonEmptyFields(context?.filters, filters)
    const width = 270

    return (
        <MoreFilters count={count}>
            <div className={styles.Wrapper}>
                <Grid container direction="column" className={styles.Section}>
                    <Typography variant="h4" color="primary">
                        Filtros
                    </Typography>
                </Grid>

                <TableContainer>
                    <Table sx={{ minWidth: 300 }} className={styles.Table}>

                        <TableBody>

                            <TableRow key={0}>
                                <TableCell>
                                    <RoadFilter
                                        value={context.filters.road}
                                        onChange={context.setFilter}
                                        width={width}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow key={1}>
                                <TableCell>
                                    <JunctionFilter
                                        field="startJunction"
                                        label="Nó Inicial"
                                        value={context.filters.startJunction}
                                        onChange={context.setFilter}
                                        roads={context.filters.road}
                                        width={width}
                                    />
                                </TableCell>
                            </TableRow>


                            <TableRow key={2}>
                                <TableCell>
                                    <JunctionFilter
                                        field="endJunction"
                                        label="Nó Final"
                                        value={context.filters.endJunction}
                                        onChange={context.setFilter}
                                        roads={context.filters.road}
                                        width={width}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow key={3}>
                                <TableCell>
                                    <InterventionLocationFilter
                                        value={context.filters.interventionLocation}
                                        onChange={context.setFilter}
                                        width={width}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow key={4}>
                                <TableCell>
                                    <Hide on={view === TasksListView.INTERVENTION}>
                                        <InterventionFilter
                                            value={context.filters.intervention}
                                            onChange={context.setFilter}
                                            width={width}
                                        />
                                    </Hide>
                                </TableCell>
                            </TableRow>

                            <TableRow key={5}>
                                <TableCell>
                                    <Hide on={view === TasksListView.INTERVENTION}>
                                        <ContractExecutingOrganizationFilter
                                            value={context.filters.executingOrganization}
                                            onChange={context.setFilter}
                                            width={width}
                                        />
                                    </Hide>
                                </TableCell>
                            </TableRow>

                            <TableRow key={6}>
                                <TableCell style={{ paddingTop: 25 }}>
                                    <Hide on={view === TasksListView.CONTROLCENTER}>
                                        <div style={{ paddingLeft: 20 }}>
                                            <StartAtFilter
                                                value={context.filters.startAt}
                                                onChange={context.setFilter}
                                                hideArrows
                                                hideClearable={false}
                                                clearableMarginLeft={65}
                                            />
                                        </div>
                                    </Hide>
                                </TableCell>
                            </TableRow>


                            <TableRow key={7}>
                                <TableCell align="right" style={{ paddingRight: 32, paddingTop: 20 }}>
                                    <ClearFilters />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </MoreFilters>
    )
}
