import { AddAPhotoOutlined, Close, Delete, Download, Save } from "@mui/icons-material"
import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { clsx } from "clsx"
import { FC, MouseEvent, useRef, useState, ClipboardEvent } from "react"
import { FileRejection, useDropzone } from "react-dropzone"
import { SurveyRecordPhoto, UpdateSurveyRecordPhoto, useCreateOneSurveyRecordPhotoMutation, useDeleteOneSurveyRecordPhotoMutation, useUpdateOneSurveyRecordPhotoMutation } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import config from "../../services/config"
import { downloadRequestStatic, formatPhotoFormatsEnv, humanizedFileErrors, parsePhotoFormatsEnv, staticFile } from "../../services/utils"
import { GalleryImageProps, GalleryProps, SurveyRecordProps } from "../../types/types"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import Show from "../Show/Show"
import Truncate from "../Truncate/Truncate"
import styles from './SurveyRecordPhotos.module.scss'


const GalleryImage: FC<GalleryImageProps> = props => {
    const { image,
        onDelete,
        onUpdate,
        onDownload,
        disableDelete = false,
        disableDownload = false,
        isDragActive = false,
        disableUpdate = false,
        getRootProps = {}
    } = props
    const [isEditable, setIsEditable] = useState(false);
    const ref = useRef<HTMLSpanElement>(null)
    const { errorToast } = useToast()

    const clickToEditMessage = "Descrição"

    const formats = formatPhotoFormatsEnv(config.ALLOWED_PHOTO_FORMATS)
    const classes = clsx({ [styles.GalleryImagePlaceholder]: true, [styles.DragActive]: isDragActive })

    if (!image) {
        return (
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes} {...getRootProps()}>
                <AddAPhotoOutlined className={styles.AddPhoto} color="primary" />
                <Typography variant="h5" color="primary">
                    Arraste ou clique para adicionar fotos.
                </Typography>
                <Typography variant="h5" color="primary">
                    {formats}
                </Typography>
            </Grid>
        )
    }

    if (!onDelete || !onDownload) return null

    const handleSetIsEditable = () => {
        setIsEditable(true)
    }

    const handleUpdate = () => {
        const description = ref.current?.innerText
        if (description && description?.length > 1 && description !== image.description) {
            onUpdate(image?.id, { description })
        }
        setIsEditable(false)
    }

    const handlePaste = (event: ClipboardEvent) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text/plain')
        if (ref && ref.current) {
            ref.current.innerText = text
        } else {
            errorToast("Erro ao colar texto, tente novamente por favor.")
        }
    }

    const handleCancel = () => {
        setIsEditable(false)
        if (ref?.current && image.description) {
            ref.current.innerText = image.description
        } else if (ref?.current && !image.description) {
            ref.current.innerText = clickToEditMessage
        }
    }

    const hasDescription = image?.description && image?.description?.length > 1
    const descriptionClasses = clsx({ [styles.DescriptionWrapper]: true, [styles.IsEditable]: !disableUpdate && !isEditable })

    return (
        <div>
            <div className={styles.GalleryImage}>
                <img src={staticFile(image.thumbnail)} className={styles.Image} alt={image.name} />
                <Grid container justifyContent="space-between" alignItems="center">
                    <div style={{ maxWidth: "80%" }}>
                        <Typography variant="body1" color="secondary">
                            <Truncate text={image.name} />
                        </Typography>
                    </div>
                    <div>
                        {!disableDelete && <ConfirmButton onClick={() => onDelete(image)} IconButtonProps={{ size: "small", edge: "end" }}>
                            <Delete color="primary" />
                        </ConfirmButton>}

                        <Tooltip title="Descarregar">
                            <IconButton onClick={(event: any) => { event.stopPropagation(); onDownload(image) }} size="small" style={{ marginTop: 5 }} edge="end" disabled={disableDownload}>
                                <Download color="primary" />
                            </IconButton>
                        </Tooltip>

                    </div>
                </Grid>
            </div>
            <div className={descriptionClasses}>
                <div
                    contentEditable={!disableUpdate && isEditable}
                    className={styles.Description}
                    onClick={handleSetIsEditable}
                    onPaste={handlePaste}
                    suppressContentEditableWarning={true}
                >

                    <Typography variant="body2" ref={ref} color={hasDescription ? "text" : "secondary"}>
                        {hasDescription ? image.description : clickToEditMessage}
                    </Typography>
                </div>
                <Grid container justifyContent="flex-end">
                    <Show on={!disableUpdate && isEditable}>
                        <ConfirmButton
                            onClick={handleCancel}
                            message="Quer terminar edição da descrição. Pode perder alterações não guardadas."
                            tooltip="Cancelar"
                        >
                            <Close fontSize="small" />
                        </ConfirmButton>
                    </Show>
                    <Show on={!disableUpdate && isEditable}>
                        <Tooltip title="Guardar">
                            <IconButton onClick={handleUpdate}>
                                <Save fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Show>
                </Grid>
            </div>
        </div>
    )
}

const Gallery: FC<GalleryProps> = props => {
    const { images, onDrop, onDelete, onDownload, onUpdate, disableDelete = false, disableDownload = false, disableAdd = false, disableUpdate = false } = props
    const acceptFormats = parsePhotoFormatsEnv(config.ALLOWED_PHOTO_FORMATS)
    const { getRootProps, isDragActive } = useDropzone({
        onDrop, disabled: disableAdd,
        accept: {
            'image/png': acceptFormats
        }
    })

    return (
        <Grid container className={styles.Gallery}>
            {images?.map((image: SurveyRecordPhoto) => <GalleryImage
                image={image}
                key={image?.id}
                onDelete={onDelete}
                onDownload={onDownload}
                onUpdate={onUpdate}
                disableDelete={disableDelete}
                disableDownload={disableDownload}
                disableUpdate={disableUpdate}
            />)}
            {!disableAdd && <GalleryImage key="add" onUpdate={onUpdate} isDragActive={isDragActive} getRootProps={getRootProps} />}
        </Grid>
    )
}

const SurveyRecordPhotos: FC<SurveyRecordProps> = props => {
    const { surveyRecord, refetch, allowed, isNotUserEdit } = props
    const [createMutation] = useCreateOneSurveyRecordPhotoMutation()
    const [updateMutation] = useUpdateOneSurveyRecordPhotoMutation()
    const [deleteMutation] = useDeleteOneSurveyRecordPhotoMutation()
    const { successToast, errorToast, feedback, unknownErrorToast } = useToast()

    const handleDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        for await (let acceptedFile of acceptedFiles) {
            try {
                successToast(`A adicionar fotografia ${acceptedFile.name} ...`)
                const response = await createMutation({ variables: { input: { surveyRecordPhoto: { surveyRecord: surveyRecord?.id, name: acceptedFile.name, path: acceptedFile } } } })
                if (response?.data?.createOneSurveyRecordPhoto?.id) {
                    successToast(`Fotografia ${acceptedFile.name} Adicionada`)
                } else {
                    errorToast(`Erro ao adicionar fotografia ${acceptedFile.name}`)
                }
            } catch (e) {
                unknownErrorToast()
            }
        }

        if (refetch) {
            refetch()
        }

        rejectedFiles.forEach(rejectedFile => {
            const errors = humanizedFileErrors(rejectedFile.errors)
            errorToast(`Não foi possível adicionar fotografia ${rejectedFile.file.name} verifique ${errors.join(", ")}`)
        })
    }

    const handleDelete = async (image: any) => {
        const response = await deleteMutation({ variables: { input: { id: image.id } } })
        const id = response?.data?.deleteOneSurveyRecordPhoto.id
        feedback(!id, "Fotografia removida com sucesso", "Erro ao remover fotografia")
        if (refetch) refetch()
    }


    const handleUpdate = async (id: string, update: UpdateSurveyRecordPhoto) => {
        const response = await updateMutation({ variables: { input: { id, update } } })
        feedback(!response?.errors?.length, "Foto atualizada.", "Erro ao atualizar foto")
        if (refetch) refetch()
    }

    const handleDownload = (image: any) => {
        downloadRequestStatic(image.path, image.name)
    }

    return (
        <Grid container className={styles.SurveyRecordPhotos}>
            <Card style={{ width: "100%", padding: 20 }}>
                <CardContent>
                    <Typography variant="h4" color="primary">Fotografias</Typography>
                    <Gallery
                        images={surveyRecord?.surveyRecordPhotos}
                        onDrop={handleDrop}
                        onDelete={handleDelete}
                        onDownload={handleDownload}
                        onUpdate={handleUpdate}
                        disableDelete={!allowed || isNotUserEdit}
                        disableAdd={!allowed || isNotUserEdit}
                        disableUpdate={!allowed || isNotUserEdit}
                    />
                </CardContent>
            </Card>
        </Grid>
    )
}

export default SurveyRecordPhotos
