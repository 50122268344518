import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { useShowFieldError } from '../../hooks/useShowFieldError';

interface DateTimePickerProps {
    field: any,
    form: any,
    props: any,
    label?: string,
    fullWidth?: boolean,
    disabled?: boolean
    variant?: "standard" | "filled" | "outlined"
    minDate?: Date
    maxDate?: Date
}

const DateTimePicker: FC<DateTimePickerProps> = ({ field, form, ...props }) => {
    const { variant = "standard", disabled, minDate, maxDate } = props
    const { name, value, onChange } = field
    const { error, helperText } = useShowFieldError(field.name)

    const handleChange = (date: Maybe<Date>) => {
        if (!date) return null
        try {
            const event = {
                target: { name, value: date }
            }
            onChange(event)
        } catch (e) { }
    }

    return (
        <MuiDateTimePicker
            value={value}
            onChange={handleChange}
            slotProps={{ textField: { variant, error, helperText } }}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />

    )
}

export default DateTimePicker
