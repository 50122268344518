import { useCreateOneUserMutation, useDeleteOneUserMutation, useUpdateOneUserMutation, useUsersQuery } from "../../generated/graphql";
import useConcessionUserStore from "./UserConcessionStore";

const useUserStore = () => {
    const query = useUsersQuery
    const name = "user"
    const concessionUser = useConcessionUserStore()
    const [create] = useCreateOneUserMutation()
    const [update] = useUpdateOneUserMutation()
    const [remove] = useDeleteOneUserMutation()
    return { query, name, create, update, remove, concessionUser }
}

export default useUserStore
