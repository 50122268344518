import { Search } from "@mui/icons-material"
import InputTextField from "../InputTextField/InputTextField"

const SearchOrganizationByTinField = () => {
    return (
        <InputTextField
            name="tin"
            label="NIF da Entidade"
            fullWidth
            autoFocus
            InputProps={{
                startAdornment: <Search color="primary" style={{ marginRight: 10 }} />
            }}
        />
    )
}

export default SearchOrganizationByTinField
