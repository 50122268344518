import { Card, CardContent, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC, useEffect, useState } from "react"
import { useContractPreviewLazyQuery, useCreateOneSurveyRecordMutation, useUpdateOneSurveyRecordMutation } from "../../generated/graphql"
import useGoTo from "../../hooks/useGoto"
import useToast from "../../hooks/useToast"
import useTranslate from "../../hooks/useTranslate"
import { createTransform, omit, parseDateGQLField, updateTransform } from "../../services/utils"
import ContractField from "../ContractField/ContractField"
import ContractPreview from "../ContractPreview/ContractPreview"
import DisplayState from "../DisplayState/DisplayState"
import InputDateField from "../InputDateField/InputDateField"
import InputDateTimeField from "../InputDateTimeField/InputDateTimeField"
import InputTextField from "../InputTextField/InputTextField"
import JunctionField from "../JunctionField/JunctionField"
import RoadField from "../RoadField/RoadField"
import Show from "../Show/Show"
import Truncate from "../Truncate/Truncate"
import WeatherConditionField from "../WeatherConditionField/WeatherConditionField"
import styles from './SurveyRecordForm.module.scss'
import surveyRecordFormInit from './SurveyRecordFormInit'

interface SurveyRecordFormProps {
    formRef: any
    surveyRecord?: any
    isNotUserEdit: boolean
    allowed: boolean
}

const SurveyRecordForm: FC<SurveyRecordFormProps> = props => {
    const { formRef, surveyRecord, isNotUserEdit, allowed } = props
    const { schema, initial } = surveyRecordFormInit(surveyRecord)
    const [createMutation] = useCreateOneSurveyRecordMutation()
    const [updateMutation] = useUpdateOneSurveyRecordMutation()
    const [preview, { data: previewData }] = useContractPreviewLazyQuery({ fetchPolicy: "no-cache" })
    const [contractId, setContractId] = useState<any>()

    const { successToast, errorToast } = useToast()
    const { goToSurveyRecord } = useGoTo()
    const t = useTranslate()

    const surveyRecordRelations = ["surveyRecordPhotos", "realizations", "surveyRecordVerifications"]
    const nonUpdatableFields = ["name", "state", "sequence", "createdAt", "contract"]

    const create = async (values: FormikValues) => {
        const surveyRecordValues = omit(values, surveyRecordRelations)
        // @ts-ignore
        const response = await createMutation(createTransform(surveyRecordValues, "surveyRecord"))
        const surveyRecordId = response?.data?.createOneSurveyRecord?.id

        if (surveyRecordId) {
            successToast("Registo de Visita Criado")
            goToSurveyRecord(surveyRecordId)
        } else {
            errorToast("error ao criar contrato")
        }
    }

    const update = async (values: FormikValues) => {
        const surveyRecordValues = omit(values, surveyRecordRelations)
        const response = await updateMutation(updateTransform(surveyRecordValues, nonUpdatableFields))

        if (response?.errors?.length) {
            errorToast("Erro ao atualizar registo visita.")
        } else {
            successToast("Registo de visita atualizado")
        }
    }

    const handleSubmit = async (values: FormikValues) => {
        surveyRecord?.id ? update(values) : create(values)
    }

    const getSurveyRecordName = () => {
        return surveyRecord?.id ? `Registo ${surveyRecord?.name}` : "Novo Registo"
    }

    useEffect(() => {
        if (contractId) {
            preview({ variables: { id: contractId } })
        }
    }, [contractId]);


    const listen = (values: any) => {
        const { contract } = values
        if (contract?.id && contract?.id !== contractId) {
            setContractId(contract?.id)
        }
    }

    const minDate = parseDateGQLField(previewData?.contract?.realStartDate)
    const maxDate = parseDateGQLField(previewData?.contract?.realEndDate)

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize innerRef={formRef}>
            {({ values }) => {
                listen(values)
                return (
                    <Form className={styles.Form}>

                        <Grid container className={styles.SurveyRecordForm}>
                            <Grid item xs={5} className={styles.GeneralSection}>
                                <Card style={{ padding: 20 }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography variant="h4" color="primary">
                                                    <Truncate text={getSurveyRecordName()} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DisplayState stateName={t(surveyRecord?.state)} />
                                            </Grid>
                                        </Grid>
                                        {/* <div>
                                            <ContractField disabled={!allowed} />
                                            </div> */}


                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <ContractField disabled={!allowed || isNotUserEdit} />
                                            </Grid>
                                            <Grid container item xs={2} justifyContent="flex-end">
                                                <ContractPreview contract={previewData?.contract} />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <InputDateTimeField name="surveyedAt" label="Data Visita" minDate={minDate!} maxDate={maxDate!} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <InputTextField name="description" label="trabalhos em curso" multiline={true} fullWidth={true} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <InputTextField name="participants" label="participantes" multiline={true} fullWidth={true} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <WeatherConditionField disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <InputTextField name="observations" label="observações" multiline rows={5} disabled={!allowed || isNotUserEdit} fullWidth />
                                        </div>


                                        <Show on={!!surveyRecord?.isPublished}>
                                            <Grid container justifyContent="flex-end">
                                                <Typography variant="caption">
                                                    Este registo de visita está publicado.
                                                </Typography>
                                            </Grid>
                                        </Show>


                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={5} className={styles.LocationSection}>
                                <Card style={{ padding: 20 }}>
                                    <CardContent>
                                        <Typography variant="h4" color="primary">Localização</Typography>
                                        <div>
                                            <RoadField disabled={!allowed || isNotUserEdit} contract={values?.contract?.id} />
                                        </div>
                                        <div>
                                            <JunctionField name="startJunction" label="Nó Inicial" road={values?.road?.id} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <JunctionField name="endJunction" label="Nó Final" road={values?.road?.id} disabled={!allowed || isNotUserEdit} />
                                        </div>

                                        <div>
                                            <InputTextField name="startPk" label="PK Inicial" fullWidth={true} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                        <div>
                                            <InputTextField name="endPk" label="PK Final" fullWidth={true} disabled={!allowed || isNotUserEdit} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>


                        </Grid>
                    </Form>
                )
            }}
        </Formik >

    )
}

export default SurveyRecordForm
