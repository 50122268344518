import { Add } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { FormikValues } from 'formik';
import { useState } from 'react';
import { CreateSubcontractData, UpdateSubcontractData } from '../../hooks/useSubcontractManager';
import { CreateFullSubcontractOrganizationData, CreateFullSubcontractOrganizationResult, SubcontractOrganization } from '../../hooks/useSubcontractOrganizationManager';
import useToast from '../../hooks/useToast';
import { Actions, Subjects } from '../../services/ability';
import { OperationResult, Subcontract } from '../../types/types';
import AddIconButton from '../AddIconButton/AddIconButton';
import CreateSubcontract from '../CreateSubcontract/CreateSubcontract';
import CustomModal from '../CustomModal/CustomModal';
import Empty from '../Empty/Empty';
import Permission from '../Permission';
import Show from "../Show/Show";
import SubcontractPreview from '../SubcontractPreview/SubcontractPreview';

interface SubcontractsProps {
    subcontracts?: Partial<Subcontract>[]
    subcontractOrganizations: SubcontractOrganization[]
    enableCreate?: boolean
    contractId?: string
    createFullSubcontractOrganization: (data: CreateFullSubcontractOrganizationData) => Promise<CreateFullSubcontractOrganizationResult>
    createSubcontract: (data: CreateSubcontractData) => Promise<OperationResult<Subcontract>>,
    updateSubcontract: (data: UpdateSubcontractData) => Promise<OperationResult<Subcontract>>
    deleteSubcontract: (id: string) => Promise<OperationResult<Subcontract>>
}

export default function Subcontracts(props: SubcontractsProps) {

    const {
        subcontracts,
        enableCreate = true,
        contractId,
        subcontractOrganizations,
        createFullSubcontractOrganization,
        createSubcontract,
        updateSubcontract,
        deleteSubcontract
    } = props


    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const { successToast, errorToast } = useToast()

    const handleSubmit = async (values: FormikValues) => {
        if (!contractId) {
            throw new Error("Contract is undefined")
        }

        const { description, startDate, endDate, concessionOrganization, accreditationType } = values
        const accreditationTypeId = accreditationType?.id
        const concessionOrganizationId = concessionOrganization?.id
        const response = await createSubcontract({
            description,
            contractId,
            startDate,
            endDate,
            concessionOrganizationId,
            accreditationTypeId,
            mediated: true
        })

        if (response.success) {
            successToast("Subcontracto criado com sucesso")
            setShowCreateModal(false)
        } else {
            errorToast("" + response?.errors?.join(","))
        }
    }

    return (
        <Card style={{ padding: 20 }}>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="h4" color="primary">
                        Subcontratos
                    </Typography>
                    <Permission action={Actions.CREATE} subject={Subjects.SUBCONTRACT} passThrough>
                        {allowed =>
                            <Show on={enableCreate && allowed}>
                                <AddIconButton onClick={() => setShowCreateModal(true)} title="Criar subcontrato" />
                            </Show>
                        }
                    </Permission>
                </Grid>
                <Grid container>
                    <Show on={!!subcontracts?.length}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" color="secondary">
                                    Entidade
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" color="secondary">
                                    Estado
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle1" color="secondary">
                                    Acreditação
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Show>

                    {subcontracts?.map(
                        subcontract =>
                            <SubcontractPreview
                                key={subcontract.id}
                                subcontract={subcontract}
                                updateSubcontract={updateSubcontract}
                                deleteSubcontract={deleteSubcontract}
                            />
                    )}

                    <Show on={!subcontracts?.length}>
                        <Empty message="Ainda não existem subcontratos neste contrato" variant="subtitle1" />
                    </Show>

                </Grid>
            </CardContent>


            <CustomModal isActive={showCreateModal} handleClose={() => setShowCreateModal(false)}>
                <CreateSubcontract
                    subcontractOrganizations={subcontractOrganizations}
                    createFullSubcontractOrganization={createFullSubcontractOrganization}
                    onSubmit={handleSubmit}
                />
            </CustomModal>

        </Card>
    )
}
