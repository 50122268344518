import { addDays, format, getISOWeek, getISOWeeksInYear, getISOWeekYear, isDate, parse, parseISO, startOfDay, subDays } from 'date-fns';
import { FormikValues } from "formik";
import { FileError } from 'react-dropzone';
import { Maybe } from '../generated/graphql';
import { translate } from '../hooks/useTranslate';
import config from "./config";

/**
versão própria de pick, outras propostas encontradas online não funcionaram
e não queremos usar loadsh ou underscore
tem testes
**/
export const pick = (obj: {}, keys: string[]) => {
    let newObject = {}
    keys.forEach(key => {
        if (obj && obj.hasOwnProperty(key)) {
            // @ts-ignore
            newObject[key] = obj[key]
        }
    })
    return newObject
}

/**
defeito limpa sempre __typename
tem testes
**/
export const omit = (obj: {}, keys: string[]) => {
    let newObject = {}
    Object.keys(obj).forEach(key => {
        if (!keys.includes(key) && key !== "__typename") {
            // @ts-ignore
            newObject[key] = obj[key]
        }
    })
    return newObject
}

/**
* Returns the name of the first property in an object
* if more than one property exists it is not guaranteed
* that the returned property will be the same
* see: https://stackoverflow.com/questions/983267/how-to-access-the-first-property-of-a-javascript-object
*
* @param checkOnlyOne - throws errror if more than one property exists in the object
*/
export const firstProperty = (obj: object, checkOnlyOne?: boolean) => {
    if (!obj) return null

    const count = Object.keys(obj).length
    if (checkOnlyOne && count !== 1) {
        throw new Error("Object has more or less than one property")
    }
    return Object.keys(obj)[0]
}

// https://stackoverflow.com/a/20381799
export const breakCamelCase = (str: string) => {
    return str.split(/(?=[A-Z])/).map(s => s.toLowerCase())
}

// o value pode ser null em certos casos
// já aconteceu e deu erro, definir como lidar
// com os nulls
// @todo - adicionar testes e documentar
export const payloadTransform = (values: FormikValues) => {
    const transformedValues: any = {}
    Object.entries(values).forEach(([key, value]) => {
        if (value?.__typename) {
            transformedValues[key] = value.id
        } else {
            transformedValues[key] = value
        }
    })
    return transformedValues
}

// @todo - adicionar testes e documentar
export const createTransform = (values: FormikValues, endpoint: string) => {
    const transformedValues = payloadTransform(values)
    return { variables: { input: { [endpoint]: { ...transformedValues } } } }
}

// @todo - adicionar testes e documentar
export const updateTransform = (values: FormikValues, omitFields?: string[]) => {
    let { id, ...data } = values
    if (omitFields) {
        data = omit(data, omitFields)
    }
    const transformedValues = payloadTransform(data)
    return { variables: { input: { id, update: { ...transformedValues } } } }
}


/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return config.MEDIA_URL + relative
}

// https://youmightnotneed.com/lodash/
// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
export const _get = (obj: {}, path: string, defValue?: string) => {
    // If path is not defined or it has false value
    if (!path) return undefined
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
    // Find value
    // @ts-ignore
    const result = pathArray.reduce(
        (prevObj: any, key: any) => prevObj && prevObj[key],
        obj
    )
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result
}


/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}

/**
 * o mesmo que o downlodRequest mas o primeiro argumento é passado
 * ao staticfile, uma vez que é um padrao comum.
 */
export const downloadRequestStatic = (path: string, filename: string) => {
    downloadRequest(staticFile(path), filename)
}

// @todo - precisa de testes para os edge cases
export const getCurrentIsoYear = (): number => {
    return getISOWeekYear(new Date())
}

// @todo - precisa de testes para os edge cases
export const getCurrentIsoWeek = (): number => {
    return getISOWeek(new Date())
}

// retorna uma string e não uma data
export const today = (formatStr: string = config.GIN_DATE_FORMAT): string => {
    const date = new Date()
    return format(date, formatStr)
}

// parte uma string usando um separador
// faz trim por defeito
// se a string estiver vazia retorna []
// https://codingbeautydev.com/blog/javascript-check-if-string-is-empty/
export const split = (str: string, separator: string = ",", trim: boolean = true) => {
    if (str.trim().length === 0) return []
    const elements = str.split(separator)
    if (!trim) return elements
    return elements.map(element => element.trim())
}

// converte entre formatos de datas (strings)
// export const dateConverter = (dateStr: string, mode: string = "app2api") => {
//     if (mode !== "app2api") throw Error("Not Implemented")
//     const [day, month, year] = split(dateStr, "/")
//     return `${year}-${month}-${day}`
// }

// retorna uma data
// o input pode ser uma data ou uma string
// se o valor for já for uma data, retornar data
// se for uma string primeiro tenta converter assumindo iso format
// se não conseguir usa o formato das definições
// export const parseDate = (value: string | Date): Date => {
//     if (typeof value === "object") return value
//     const parsed = parseISO(value)
//     if (parsed.getTime()) return parsed
//     return parse(value, config.GIN_DATE_FORMAT, new Date())
// }

export const parseWeekValue = (value: string) => {
    const [week, year] = value?.split("/")
    return {
        week: +week, year: +year
    }
}

export const getLastISOWeekOfYear = (year: number) => {
    return getISOWeeksInYear(new Date(year, 6, 6))
}


export const previousISOWeek = (year: number, week: number) => {
    if (week === 1) {
        return { year: year - 1, week: getLastISOWeekOfYear(year - 1) }
    }
    return { year, week: week - 1 }
}

export const nextISOWeek = (year?: number, week?: number) => {
    if (year === undefined) year = getCurrentIsoYear()
    if (week === undefined) week = getCurrentIsoWeek()

    if (week === getLastISOWeekOfYear(year)) {
        return { year: year + 1, week: 1 }
    }
    return { year, week: week + 1 }
}

export const getNextDay = (date: Date): Date => addDays(date, 1);

export const getPreviousDay = (date: Date): Date => subDays(date, 1);

/**
 * utilizado para formatar telefones e nifs nos pdfs
 * por essa razão só strings com comprimento 9 são formatadas
 * as restantes são retornadas inalteradas
 */
export const formatStrGroups = (str?: string | null, sep: string = " ") => {
    if (!str) return ""
    if (str?.length !== 9) return str
    return str.substring(0, 3) + sep + str.substring(3, 6) + sep + str.substring(6, 9)
}

export const isSubset = <T>(subset: T[], superset: T[]): boolean => {
    for (const elem of subset) {
        if (!superset.includes(elem)) {
            return false;
        }
    }
    return true;
}

/**
 * converte o valor guardado pelo filtro da semana ww/yyyy (e.g 15/2023)
*  para uma objeto {week: int, year: int}
 */
export const parseWeekFilterValue = (value: string) => {
    const elements = value.split("/")
    if (elements?.length !== 2) throw Error(`${value} is not a valid value`)
    const [week, year] = elements.map(element => +element)
    return { week, year }
}


// formato do valor original ao criar 22/04/2023
// formato do valor original ao atualizar 2023-04-22
export const isCreating = (originalValue: any) => {
    const elements = originalValue.split('/')
    return elements?.length === 3
}

// função de parse ao criar
export const createParse = (originalValue: any) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, config.GIN_DATE_FORMAT, new Date());
}

// função de parse ao atualizar
export const updateParse = (originalValue: any) => {
    return isDate(originalValue), parseISO(originalValue)
}

export const parseDateString = (_: any, originalValue: any) => {
    return isCreating(originalValue) ? createParse(originalValue) : updateParse(originalValue)
}

export const parseDateGQLField = (data?: string | null): Maybe<Date> => {
    if (!data) return null
    return parseISO(data)
}

export const getStartOfDay = () => {
    const now = new Date()
    return startOfDay(now)
}


export const bytesToMegabytes = (bytes: number | string, includeUnit: boolean = false): string | number => {
    const megabytes = +bytes / (1024 * 1024);
    if (includeUnit) {
        return `${megabytes}MB`;
    }
    return megabytes;
};

export const parsePhotoFormatsEnv = (envvar: string): string[] => {
    const trimmed = envvar.trim()
    return trimmed.split(",").map(format => `.${format}`)
}

export const formatPhotoFormatsEnv = (envvar: string): string => {
    const formats = parsePhotoFormatsEnv(envvar)
    return formats.join(", ")
}

export const rejectedFileErrors = (fileErrors: FileError[]): string[] => {
    return fileErrors.map(fileError => fileError.code)
}

export const humanizedFileErrors = (fileErrors: FileError[]): string[] => {
    const codeErrors = rejectedFileErrors(fileErrors)
    // @ts-ignore
    return codeErrors.map(codeError => translate(codeError))
}

export const orderColDefs = (colDefs: any[], workspacePrefs: any[]): any[] => {
    // Cria um mapa para facilitar a busca dos elementos em workspacePrefs
    const workspacePrefsMap = new Map(workspacePrefs.map(item => [item.name, item]));

    // Mapeia colDefs para incluir a propriedade order de workspacePrefs
    const colDefsWithOrder = colDefs.map(colDef => {
        const workspacePref = workspacePrefsMap.get(colDef.field);
        return { ...colDef, order: workspacePref ? workspacePref.order : Infinity };
    });

    // Ordena a nova array com base na propriedade order
    colDefsWithOrder.sort((a, b) => a.order - b.order);

    return colDefsWithOrder;
}

export const convertArrayToObject = (arr: { name: string, visible: boolean }[]): { [key: string]: boolean } => {
    return arr.reduce((obj: any, item: any) => {
        obj[item.name] = item.visible;
        return obj;
    }, {});
};

export const diffObjects = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): { [key: string]: any } => {
    return Object.keys(obj1).reduce((result: any, key: any) => {
        if (obj1[key] !== obj2[key]) {
            result[key] = obj2[key];
        }
        return result;
    }, {});
};

export const truncate = (str: string, length: number = 20): string => {
    if (str.length <= length) {
        return str;
    }
    return str.substring(0, length) + '...';
};

export const noop = () => {
    return []
}

export const uniqueValues = (array: string[]): string[] => {
    return Array.from(new Set(array));
}

export const haveSameContent = <T>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    for (let i = 0; i < arr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}
