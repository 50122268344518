import { defineAbility } from '@casl/ability';
import { ConcessionOrganizationUser, ConcessionUser, Role } from '../generated/graphql';
import { split } from './utils';

export enum Actions {
    CREATE = "create",
    READ = "read",
    UPDATE = "update",
    DELETE = "delete",
    LIST = "list",
    PUBLISH = "publish",
    SUBMIT = "submit",
    APROVE = "aprove",
    REJECT = "reject",
    EXPIRE = "expire",
    CANCEL = "cancel",
    REVIEW = "review",
    VALIDATE = "validate",
    CLOSE = "close",
    REQUEST = "request",
    IMPORT = "import"
}

export enum Subjects {
    BADGE = "badge",
    CONTRACT = "contract",
    DASHBOARD = "dashboard",
    INTERVENTION = "intervention",
    LOGOUT = "logout",
    ORGANIZATION = "organization",
    SETTING = "setting",
    SURVEYRECORD = "survey-record",
    SURVEYRECORDREALIZATION = "survey-record-realization",
    SURVEYRECORDVERIFICATION = "survey-record-verification",
    TASK = "task",
    WORKPERMIT = "work-permit",
    WEEKLYPLANNING = "weekly-planning",
    SUBCONTRACT = "subcontract",
    SUBCONTRACTORGANIZATION = "subcontract-organization",
    ACCREDITATION = "accreditation",
    ACCREDITATIONDOCUMENT = "accreditation-document"
}

export enum Roles {
    PLATFORMMANAGER = "PLATFORMMANAGER",
    CONTRACTMANAGER = "CONTRACTMANAGER",
    SAFETYTECHNICIAN = "SAFETYTECHNICIAN",
    SERVICEMANAGER = "SERVICEMANAGER",
    OPERATOR = "OPERATOR",
    EXTERNALAUTHORITY = "EXTERNALAUTHORITY",
    EXTERNALMANAGER = "EXTERNALMANAGER",
    EXTERNALTECHNICIAN = "EXTERNALTECHNICIAN",
    EXTERNALADMINISTRATIVE = "EXTERNALADMINISTRATIVE"
}

const getUserRoles = (data: any): string[] => {

    const userRoles: string[] = []

    // concession user roles
    data?.me?.concessionUsers?.forEach(
        (concessionUser: ConcessionUser) => {
            if (concessionUser?.concession?.id === data?.me?.defaultConcession?.id) {
                userRoles.push(concessionUser?.role)
            }
        })

    // concession organization user roles
    data?.me?.concessionOrganizationUsers?.forEach(
        (concessionOrganizationUser: ConcessionOrganizationUser) => {
            if (concessionOrganizationUser?.concessionOrganization?.concession?.id === data?.me?.defaultConcession?.id) {
                userRoles.push(concessionOrganizationUser?.role)
            }
        })


    return userRoles
}

const getRoles = (data: any) => {

    const roles: any = {}

    data?.roles?.nodes?.forEach((role: Role) => {
        roles[role.name] = role.permissions
    })

    return roles
}

const abilityFactory = (data: any) => {
    const roles = getRoles(data)
    const userRoles = getUserRoles(data)

    const ability = defineAbility((can) => {
        // can("visit", "logout") // temos que definir pelo menos uma para não ter um undefined?
        userRoles?.map(userRole => {
            const permissions = roles[userRole]
            permissions?.map((permission: any) => {
                const fields = permission?.fields ? split(permission?.fields) : undefined
                can(permission?.action, permission?.subject, fields)
            })
        })
    });

    return { ability, roles: userRoles }
}

export default abilityFactory
