import { useCreateOneLayoutMutation, useDeleteOneLayoutMutation, useLayoutsQuery, useUpdateOneLayoutMutation } from "../../generated/graphql";

const useLayoutStore = () => {
    const query = useLayoutsQuery
    const name = "layout"
    const [create] = useCreateOneLayoutMutation()
    const [update] = useUpdateOneLayoutMutation()
    const [remove] = useDeleteOneLayoutMutation()
    return { query, name, create, update, remove }
}

export default useLayoutStore
