import { Add, Block, Close, Delete, Publish, TaskAlt, WarningOutlined } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { Intervention, InterventionStates, TaskStates, useDeleteManyTasksMutation, useGenerateTaskExcelMutation, useImportManyTasksMutation, useUpdateManyTasksMutation, useValidateTaskImportMutation } from "../../generated/graphql";
import usePermission from "../../hooks/usePermission";
import useTaskForm from "../../hooks/useTaskForm";
import useToast from "../../hooks/useToast";
import { Actions, Subjects } from "../../services/ability";
import Config from "../../services/config";
import { downloadRequestStatic } from "../../services/utils";
import { MaybeDeepPartial } from "../../types/types";
import ConfirmButton from "../ConfirmButton/ConfirmButton";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Hide from "../Hide/Hide";
import Permission from "../Permission";
import Show from "../Show/Show";
import ShowInterventionImportErrors from "../ShowInterventionImportErrors/ShowInterventionImportErrors";
import TaskForm from "../TaskForm/TaskForm";
import TemporaryDrawer from "../TemporaryDrawer/TemporaryDrawer";
import { tasksInStates } from "./Helpers/tasksInStates";

interface InterventionImportProps {
    intervention: MaybeDeepPartial<Intervention>
}

export default function InterventionImport(props: InterventionImportProps) {
    const { intervention } = props

    const context = useContext(DataViewContext)
    const [generate] = useGenerateTaskExcelMutation()
    const [validate] = useValidateTaskImportMutation()
    const [importTasks] = useImportManyTasksMutation()
    const [deleteManyTasks] = useDeleteManyTasksMutation()
    const [updateManyTasks] = useUpdateManyTasksMutation()
    const [errors, setErrors] = useState<any>()
    const [importFile, setImportFile] = useState<any>(undefined)
    const { successToast, errorToast, feedback } = useToast()
    const [detailRow, setDetailRow] = useState<any>(null)
    const [interventionUpdated, setInterventionUpdated] = useState<boolean>(false)

    const reset = () => {
        context?.refetch()
        setDetailRow(null)
    }

    const taskForm = useTaskForm(intervention?.id, reset, reset)
    const notInPreparationOrReview = intervention?.state !== InterventionStates?.Preparation && intervention?.state !== InterventionStates?.Review
    const inReview = intervention?.state === InterventionStates?.Review
    const canCreateTasks = usePermission(Actions.CREATE, Subjects.TASK)

    const handleDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {

        if (acceptedFiles?.length) {
            setImportFile(acceptedFiles[0])
            const response = await validate({ variables: { input: { intervention: intervention?.id!, path: acceptedFiles[0] } } })
            setErrors(response?.data?.validateTaskImport)
        }

        if (rejectedFiles?.length) {
            rejectedFiles.map(rejectedFile => {
                // temos que verificar apenas dois códigos, FileToolarge e FileInvalidtype
                // uma rejeição pode ter mais do que um motivo
                // vamos apenas mostrar um, sendo o mais importante o formato
                const invalidMessage = `Ficheiro ${rejectedFile?.file?.name} não suportado. Apenas suporte para .xlsx.`
                const tooLargeMessage = `Ficheiro ${rejectedFile?.file?.name} excede o tamanho permitido`
                const message = rejectedFile.errors.find(fileError => fileError.code === ErrorCode.FileInvalidType) ? invalidMessage : tooLargeMessage
                errorToast(message)
            })
        }
    }

    const { getRootProps } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'] },
        maxSize: Config.MAX_FILE_SIZE,
        disabled: !canCreateTasks || notInPreparationOrReview
    })

    const handleDownload = async () => {
        try {
            const response = await generate({ variables: { input: { intervention: intervention?.id! } } })
            const path = response?.data?.generateTaskExcelTemplate
            if (path) downloadRequestStatic(path, `${intervention?.name?.toLowerCase()}.xlsx`)
        } catch (e) {
            errorToast("Erro ao gerar template. Verifique pf todas as definições.")
        }
    }

    const handleImport = async () => {
        try {
            const response = await importTasks({ variables: { input: { intervention: intervention?.id!, path: importFile } } })
            feedback(response?.data?.importManyTasks === "done", "Importação bem sucedida.", "Erro ao importar linhas de trabalho")
            setImportFile(undefined)
            context?.refetch()
        }
        catch (e: unknown) {
            errorToast("Erro desconhecido.")
        }
    }

    const handleClearImportFile = (event: any) => {
        setImportFile(undefined)
        setErrors(undefined)
        event.stopPropagation()
    }

    const handleDeleteTasks = async () => {
        if (context?.rowSelectionModel?.length) {
            const selectedRows = getSelectedRows()
            if (tasksInStates(selectedRows, [TaskStates.Preparation, TaskStates.Rejected])) {
                const response = await deleteManyTasks({ variables: { input: { filter: { id: { in: context?.rowSelectionModel } } } } })
                if (response?.data?.deleteManyTasks?.deletedCount === context?.rowSelectionModel?.length) {
                    successToast("Linhas Removidas com sucesso.")
                    context?.clearSelection()
                    context?.refetch()
                    setInterventionUpdated(true)
                } else {
                    errorToast("Erro ao efetuar pedido.")
                }
            } else {
                errorToast("Erro! Só pode remover linhas em preparação ou rejeitadas.")
            }
        }
    }

    // @todo - esta função faz sentido existir dentro do data view provider
    const getSelectedRows = () => {
        return context?.data?.tasks?.nodes.filter((node: any) => context?.rowSelectionModel.includes(node?.id))
    }

    const handleSubmitTasks = async () => {
        if (context?.rowSelectionModel?.length) {
            const selectedRows = getSelectedRows()
            if (tasksInStates(selectedRows, [TaskStates.Preparation, TaskStates.Rejected])) {
                const response = await updateManyTasks({ variables: { input: { filter: { id: { in: context?.rowSelectionModel } }, update: { state: TaskStates.Submitted } } } })
                if (response?.data?.updateManyTasks?.updatedCount === context?.rowSelectionModel?.length) {
                    successToast("Linhas Submetidas com sucesso.")
                    context?.clearSelection()
                    context?.refetch()
                    setInterventionUpdated(true)
                } else {
                    errorToast("Erro ao efetuar pedido.")
                }
            } else {
                errorToast("Erro! Só pode submeter linhas em preparação ou rejeitadas.")
            }
        }
    }

    const handleBatchUpdateState = async (state: TaskStates) => {
        if (context.rowSelectionModel?.length) {
            const response = await updateManyTasks({ variables: { input: { filter: { id: { in: context?.rowSelectionModel } }, update: { state } } } })
            if (response?.data?.updateManyTasks?.updatedCount === context?.rowSelectionModel?.length) {
                successToast("Linhas Atualizadas.")
                context?.clearSelection()
            } else {
                errorToast("Erro ao efetuar pedido.")
            }
        }
    }

    return (
        <div>
            <div>
                <Show on={interventionUpdated && inReview}>
                    <Grid container alignItems="center">
                        <WarningOutlined color="error" style={{ marginRight: 10 }} />
                        <Typography color="error" variant="subtitle1" style={{ fontWeight: 500 }}>
                            Linhas atualizadas não se se esqueça de submeter planeamento
                        </Typography>
                    </Grid>
                </Show>
            </div>
            <div style={{ marginBottom: 75, marginTop: 20 }}>
                <Button onClick={handleDownload} variant="outlined" color="secondary" disabled={!canCreateTasks || notInPreparationOrReview}>
                    Download Template
                </Button>

                <span {...getRootProps()} style={{ marginLeft: 20 }}>
                    <Button variant="contained" disabled={!canCreateTasks || notInPreparationOrReview}>
                        Upload
                    </Button>
                </span>

                <Hide on={!importFile?.name}>
                    <span style={{ fontSize: 12, marginLeft: 15 }}>{importFile?.name}</span>
                    <IconButton onClick={handleClearImportFile}>
                        <Close fontSize="small" />
                    </IconButton>
                </Hide>

                <Button onClick={handleImport} variant="contained" color="primary" disabled={!importFile?.name || errors?.length} style={{ marginLeft: 25 }}>
                    Importar
                </Button>

                <span style={{ marginLeft: 25 }}></span>

                <Hide on={notInPreparationOrReview}>
                    <Tooltip title="Adicionar linha trabalho">
                        <IconButton onClick={() => setDetailRow({})} disabled={!canCreateTasks || notInPreparationOrReview}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Hide>

                <Show on={!notInPreparationOrReview && context.rowSelectionModel?.length}>
                    <Tooltip title="Submeter linhas trabalho">
                        <IconButton
                            onClick={handleSubmitTasks}
                            disabled={!canCreateTasks || notInPreparationOrReview}
                        >
                            <Publish />
                        </IconButton>
                    </Tooltip>
                </Show>

                <Show on={context.rowSelectionModel?.length}>
                    <Permission action={Actions.APROVE} subject={Subjects.TASK}>
                        <Tooltip title="Aprovar Linhas Selecionadas">
                            <IconButton onClick={() => handleBatchUpdateState(TaskStates?.Approved)}>
                                <TaskAlt />
                            </IconButton>
                        </Tooltip>
                    </Permission>
                </Show>

                <Show on={context.rowSelectionModel?.length}>
                    <Permission action={Actions.REJECT} subject={Subjects.TASK}>
                        <Tooltip title="Rejeitar Linhas Selecionadas">
                            <IconButton onClick={() => handleBatchUpdateState(TaskStates?.Rejected)}>
                                <Block />
                            </IconButton>
                        </Tooltip>
                    </Permission>
                </Show>

                <Show on={context.rowSelectionModel?.length}>
                    <ConfirmButton
                        onClick={handleDeleteTasks}
                        component={IconButton}
                        tooltip="Remover Linhas Selecionadas"
                        disabled={!canCreateTasks || notInPreparationOrReview}
                    >
                        <Delete />
                    </ConfirmButton>
                </Show>


                <Hide on={errors?.length || !importFile?.name}>
                    <Typography variant="body1" color="secondary" style={{ marginTop: 50 }}>
                        Ficheiro não contém erros. Pode prosseguir com a importação.
                    </Typography>
                </Hide>

                <ShowInterventionImportErrors errors={errors} />
            </div >
            <TemporaryDrawer isOpen={!!detailRow} setIsOpen={setDetailRow} hideBackdrop={false}>
                <TaskForm onSubmit={taskForm?.handleSubmit} task={detailRow} contract={intervention?.workPermit?.contract!} intervention={intervention} />
            </TemporaryDrawer>
        </div >
    )
}
