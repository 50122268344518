import { GridColDef } from "@mui/x-data-grid-pro";
import { relationGetter } from "../Datagrid/Datagrid";
import { DocumentScopes } from "../Datagrid/Helpers/PolicyToRoles";
import { actionsRender } from "../Datagrid/Renders/ActionsRender";
import { dateRender } from "../Datagrid/Renders/DateRender";
import { contractDocumentCategoryRender } from "../Datagrid/Renders/DocumentCategoryRender";
import { DocumentStateRender } from "../Datagrid/Renders/DocumentStateRender";
import { contractDocumentSubcategoryRender } from "../Datagrid/Renders/DocumentSubcategoryRender";
import { dateFormatter } from "../FormatDate/FormatDate";

interface GetContractDocumentColumnsParams {
    setPreviewRow: any,
    disableNameEditing: boolean
    documentScopes: DocumentScopes[]
}

export const getContractDocumentColumns = (params: GetContractDocumentColumnsParams) => {
    const { setPreviewRow, disableNameEditing = false, documentScopes } = params
    const contractDocumentColumns: GridColDef[] = [
        { field: "name", headerName: "nome", width: 200, filterable: false, editable: !disableNameEditing },
        { field: "contractDocumentCategory", headerName: "categoria", width: 250, renderCell: contractDocumentCategoryRender, sortable: false, filterable: false },
        { field: "contractDocumentSubcategory", headerName: "subcategoria", width: 250, renderCell: params => contractDocumentSubcategoryRender(params, documentScopes), sortable: false, filterable: false },
        { field: "owner", headerName: "autor", valueGetter: params => relationGetter(params, "owner"), width: 150, filterable: false, sortable: false },
        { field: "documentState", headerName: "estado", width: 150, renderCell: DocumentStateRender, filterable: false, sortable: false },
        { field: "createdAt", headerName: "criado", width: 120, valueGetter: dateFormatter },
        { field: "expiresAt", headerName: "expira", width: 120, renderCell: params => dateRender(params, true) },
        { field: "fileType", headerName: "tipo", width: 120, valueGetter: params => relationGetter(params, "fileType", "name", "desconhecido"), sortable: false },
        { field: "actions", headerName: "", renderCell: params => actionsRender(params, setPreviewRow), width: 100, filterable: false, sortable: false, resizable: false, hideable: false, disableColumnMenu: true }
    ]

    return contractDocumentColumns
}
