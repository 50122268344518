import { CenterFocusStrong, Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react';
import useToast from '../../hooks/useToast';
import Config from '../../services/config';

interface Coordinate {
    lat: number
    lng: number
}

interface GeoLocated {
    color: string
    path: Coordinate[]
}

interface GeoLocatedIntervention {
    name: string
    geo: GeoLocated
    visible: boolean
}

const containerStyle = {
    width: '1000px',
    height: '1000px'
}

const defaultCenter = {
    lat: 39.7634366758441,
    lng: -7.599176550076544
}

const path1 = [
    { lat: 39.76341794477888, lng: -7.599139102997059 },
    { lat: 39.7640957110485, lng: -7.598372674141024 },
    { lat: 39.764407755908806, lng: -7.598056035053215 },
    { lat: 39.76498940373292, lng: -7.597477965810973 },
    { lat: 39.765608491284866, lng: -7.5968869062694555 },
    { lat: 39.7664547350448, lng: -7.5961253487334774 },
]

const path2 = [
    { lat: 39.757775301545564, lng: -7.605557373298856 },
    { lat: 39.75785144760604, lng: -7.605370637454694 },
    { lat: 39.75790886916976, lng: -7.605222872569315 },
    { lat: 39.757915110641186, lng: -7.605039384305051 },
    { lat: 39.75792010381792, lng: -7.6048575198307375 },
    { lat: 39.75800498776707, lng: -7.60472924042475 },
    { lat: 39.75809985793937, lng: -7.604683774306171 },
    { lat: 39.75822468691434, lng: -7.604685398096121 },
    { lat: 39.75834951566308, lng: -7.604568485219776 },
    { lat: 39.758440640506755, lng: -7.6044596912931794 },
]


const path3 = [
    { lat: 39.766926796152504, lng: -7.595674203105311 },
    { lat: 39.76715145934385, lng: -7.595464734201861 },
    { lat: 39.76729249752829, lng: -7.595310474156684 },
    { lat: 39.76752714448696, lng: -7.595058786714555 },
    { lat: 39.76768815177789, lng: -7.594896407719633 },
    { lat: 39.76785664737681, lng: -7.5946966815558765 },
    { lat: 39.768080059423944, lng: -7.594432003794153 },
    { lat: 39.768563076302485, lng: -7.593813339782118 },
]

const defaultInterventions: GeoLocatedIntervention[] = [
    {
        name: "INT1",
        geo: { color: "#FF0000", path: path1 },
        visible: true
    },
    {
        name: "INT2",
        geo: { color: "#0000FF", path: path2 },
        visible: true

    },
    {
        name: "INT3",
        geo: { color: "#00FF00", path: path3 },
        visible: true
    }
]

function MyMapComponent() {

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.GOOGLE_MAPS_API_KEY
    })

    const [center, setCenter] = useState<Coordinate>(defaultCenter)
    const [interventions, setInterventions] = useState(defaultInterventions)
    const { errorToast } = useToast()


    const handleSetCenter = (coordinate: Coordinate) => {
        setCenter(coordinate)
    }

    const handleVisibility = (clickedIntervention: GeoLocatedIntervention) => {
        const updatedInterventions = interventions.map(intervention => {
            if (intervention.name === clickedIntervention.name) {
                return { ...intervention, visible: !intervention.visible };
            }
            return intervention;
        });
        setInterventions(updatedInterventions)
    }

    if (loadError) {
        errorToast("Erro ao carregar mapa")
    }

    return isLoaded ? (
        <Grid container justifyContent="center" style={{ marginTop: 200 }}>
            <div style={{ marginRight: 50 }}>
                {interventions?.map((intervention: any, index: number) => (
                    <p key={index}>
                        {intervention.name}
                        <IconButton onClick={() => handleSetCenter(intervention.geo.path[0])} size="small">
                            <CenterFocusStrong />
                        </IconButton>
                        <IconButton onClick={() => handleVisibility(intervention)}>
                            {intervention.visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </p>
                ))}
            </div>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >

                {interventions?.map((intervention) => (
                    <Polyline
                        key={intervention.name}
                        path={intervention.geo.path}
                        options={{
                            strokeColor: intervention.geo.color,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            visible: intervention.visible
                        }}
                    />
                ))}
            </GoogleMap>

        </Grid >
    ) : <></>
}

export default React.memo(MyMapComponent)
