import { Check, Close, Schedule } from "@mui/icons-material";
import { Grid, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import Config, { EXPIRATION_REQUIRED } from "../../../services/config";
import Show from "../../Show/Show";
import styles from '../Datagrid.module.scss';
import policyToRoles, { DocumentScopes } from "../Helpers/PolicyToRoles";

interface ShowPolicyPermissionsProps {
    name: string
    policy: string
    scopes?: DocumentScopes[]
}

const ShowPolicyPermissions = (props: ShowPolicyPermissionsProps) => {
    const { name, policy, scopes } = props
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const popoverOpen = Boolean(anchor);
    const id = popoverOpen ? 'simple-popover' : undefined;

    const handleClick = (event: any) => {
        event.stopPropagation()
        setAnchor(event.currentTarget)
    }

    const rows = policyToRoles(policy, scopes)

    return (
        <>
            <span onClick={handleClick} className={styles.SubcategoryName}>
                {name}
            </span>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }} aria-label="simple table">
                        <TableBody>
                            {rows.map((row: any, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {row?.role}
                                        </TableCell>
                                        <TableCell>
                                            {row?.allowed ? <Check fontSize="small" style={{ color: "green" }} /> : <Close fontSize="small" style={{ color: "red" }} />}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Popover>
        </>
    )
}


const DocumentSubcategoryRender = (params: any, relation: string, scopes?: DocumentScopes[]) => {
    if (!params?.row?.[relation]) return null
    return (
        <Grid container alignContent="center">
            <ShowPolicyPermissions
                name={params?.row?.[relation]?.name}
                policy={params?.row?.[relation]?.policy}
                scopes={scopes}
            />
            <Show on={params?.row?.[relation]?.expirationRequired}>
                <Tooltip title={EXPIRATION_REQUIRED}>
                    <span className={styles.Category}>
                        <Schedule />
                    </span>
                </Tooltip>
            </Show>
        </Grid >
    )
}

export const contractDocumentSubcategoryRender = (params: any, documentScopes?: DocumentScopes[]) => {
    return DocumentSubcategoryRender(params, "contractDocumentSubcategory", documentScopes)
}

export const concessionOrganizationDocumentSubcategoryRender = (params: any, documentScopes?: DocumentScopes[]) => {
    return DocumentSubcategoryRender(params, "concessionOrganizationDocumentSubcategory", documentScopes)
}
