import { Fade } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import { useContext, useEffect, useMemo } from "react"
import Config from "../../services/config"
import Datagrid from "../Datagrid/Datagrid"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import getWorkPermitColumns from "./Helpers/getWorkPermitColumns"
import styles from './WorkPermitsList.module.scss'

export const isPublishedFormatter = (data: any) => {
    return data?.value ? "sim" : "não"
}

const WorkPermitsList = () => {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.workPermits?.nodes
    const rows = nodes || []
    const columns: GridColDef[] = useMemo(() => getWorkPermitColumns(), [])

    useEffect(() => {
        context.setView("workPermitsView")
        context.setColumnDefs(columns)
    }, [])

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.WorkPermitsList}>
                <Datagrid
                    rows={rows}
                    columns={columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                />
            </div>
        </Fade>
    );
}

export default WorkPermitsList
