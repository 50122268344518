import { useParams } from "react-router-dom"

// empty string origina bad request
//  "0" origina sucesso mas null
const useParamId = (defaultValue: string = "") => {
    const { id = defaultValue } = useParams()
    return id
}

export default useParamId
