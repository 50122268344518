import { useEffect, useState } from "react"
import { Maybe, useContractPreviewLazyQuery } from "../generated/graphql";

export default function useContractPreviewData() {

    const [id, setId] = useState<Maybe<string>>()
    const [preview, { data }] = useContractPreviewLazyQuery({ fetchPolicy: "no-cache" })

    useEffect(() => {
        if (id) {
            preview({ variables: { id } })
        }
    }, [id])

    const listen = (contractId: string) => {
        if (contractId && contractId !== id) {
            setId(contractId)
        }
    }

    return { listen, previewData: data }
}
