import { GridColDef } from "@mui/x-data-grid-pro"
import { AccreditationRender } from "../../Datagrid/Renders/AccreditationRender"
import { ContractRender } from "../../Datagrid/Renders/ContractRender"
import { SubcontractStateRender } from "../../Datagrid/Renders/SubcontractStateRender"
import { dateFormatter } from "../../FormatDate/FormatDate"

export default function getSubcontractColumns(hide?: string[]): GridColDef[] {

    const columns: GridColDef[] = [
        { field: "description", headerName: "descrição", width: 150, filterable: false },
        { field: "startDate", headerName: "data início", width: 140, valueGetter: dateFormatter, filterable: false, editable: false },
        { field: "endDate", headerName: "data fim", width: 140, valueGetter: dateFormatter, filterable: false, editable: false },
        { field: "contract", headerName: "contrato", width: 150, renderCell: params => ContractRender(params, { disableLink: true }), sortable: false, filterable: false },
        { field: "contractConcessionOrganizationState", headerName: "estado", width: 150, renderCell: SubcontractStateRender, filterable: false, sortable: false },
        { field: "concessionOrganizationAccreditation", headerName: "tipo acreditação", width: 190, renderCell: params => AccreditationRender(params), sortable: false, filterable: false },

    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}
