import { Add, AddLink, Close, Delete, Label, Publish, Schedule, SwapVert } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC, useContext, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useHasInternalRole } from '../../hooks/useHasRole';
import useToast from '../../hooks/useToast';
import { acceptedFileTypes } from '../../services/config';
import { truncate } from '../../services/utils';
import ActionsBar from '../ActionsBar/ActionsBar';
import ActionsBarSeparator from '../ActionsBarSeparator/ActionsBarSeparator';
import BackButton from '../BackButton/BackButton';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import DocumentStateFilter from '../DocumentStateFilter/DocumentStateFilter';
import OrganizationDocumentCategoryFilter from '../OrganizationDocumentCategoryFilter/OrganizationDocumentCategoryFilter';
import OrganizationDocumentSubcategoryFilter from '../OrganizationDocumentSubcategoryFilter/OrganizationDocumentSubcategoryFilter';
import PopAction from '../PopAction/PopAction';
import Show from '../Show/Show';
import getErrorMessage from './Helpers/getErrorMessage';
import styles from './OrganizationDocumentsActions.module.scss';

export interface OrganizationDocumentsActionsProps {
    concessionOrganizationId?: string
    showAttachAction?: boolean
    showSubmitAction?: boolean
    showBackButton?: boolean
    onAttach?: (selection: SelectedDocument[]) => void
    beforeAttach?: (selection: SelectedDocumentRow[]) => void
    beforeExpiration?: (selection: SelectedDocumentRow[]) => void
    beforeCategorization?: (selection: SelectedDocumentRow[]) => void
    beforeDelete?: (selection: SelectedDocumentRow[]) => void
    modelField?: string
    name?: string
}

export interface SelectedDocument {
    id: string
    name: string
}

export interface SelectedDocumentRow {
    id: string
    name: string
    expiresAt?: Date
    concessionOrganizationDocumentSubcategory?: any
    documentState?: any
}

const OrganizationDocumentsActions: FC<OrganizationDocumentsActionsProps> = props => {

    const {
        concessionOrganizationId,
        showAttachAction = false,
        showSubmitAction = true,
        showBackButton = true,
        onAttach,
        modelField,
        name = 'Documentos Entidade Executante',
        beforeAttach,
        beforeExpiration,
        beforeCategorization,
        beforeDelete
    } = props

    const context = useContext(DataViewContext)
    const selection = context?.rowSelectionModel
    const { errorToast } = useToast()
    const hasInternalRole = useHasInternalRole()

    // pop actions
    const [popActionSchedule, setPopActionSchedule] = useState<boolean>(false)
    const [popActionSubcategory, setPopActionSubcategory] = useState<boolean>(false)
    const [popActionState, setPopActionState] = useState<boolean>(false)


    const [category, setCategory] = useState<any>([])

    const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        for await (let acceptedFile of acceptedFiles) {
            const name = acceptedFile.name
            const displayName = truncate(name)
            const options = {
                successMessage: `Documento ${displayName} adicionado com sucesso`,
                errorMessage: `Erro ao adicionar documento ${displayName}`
            }

            if (concessionOrganizationId) {
                await context.create({
                    variables: {
                        input: {
                            concessionOrganizationDocument:
                                { concessionOrganization: concessionOrganizationId, name, path: acceptedFile }
                        }
                    }
                }, options)
            } else {
                await context.create({
                    variables: {
                        input: {
                            concessionOrganizationDocument:
                                { name, path: acceptedFile }
                        }
                    },
                }, options)
            }
        }

        for await (let rejectedFile of rejectedFiles) {
            errorToast(`Erro ao carregar ${rejectedFile?.file?.name}. Tipos aceites pdf, jpg, png e gif.`)
        }
    }

    const { getRootProps } = useDropzone({ onDrop, maxFiles: 0, multiple: true, accept: acceptedFileTypes })

    const isExternalViewer = () => {
        return Boolean(concessionOrganizationId)
    }

    const handleDelete = async () => {
        try {
            if (beforeDelete) {
                const selectedRows = getSelectedRows()
                beforeDelete(selectedRows)
            }

            if (beforeDelete || inPreparation()) {
                return context.deleteMany({ variables: { input: { filter: { id: { in: selection } } } } })
            } else {
                errorToast("Erro! Apenas documentos em preparação podem ser removidos")
            }
        } catch (e) {
            showErrorMessage(e)
        }
    }

    const getField = () => {
        return isExternalViewer() ? "concessionOrganizationDocuments" : "ownConcessionOrganizationDocuments"
    }

    const inPreparation = () => {
        let field = modelField ? modelField : getField()
        const rows = context?.data?.[field]?.nodes
        const invalid = rows.filter((row: any) => selection.includes(row?.id) && +row?.documentState?.id !== 1)
        return !invalid?.length
    }

    const hasNoRequiredExpiration = (row: any) => {
        return row?.concessionOrganizationDocumentSubcategory?.expirationRequired && !row.expiresAt
    }

    const isExpired = (row: any) => {
        return row?.expiresAt && row?.expiresAt < new Date()
    }

    const isSelected = (row: any) => {
        return selection.includes(row?.id)
    }

    const expirationRequired = () => {
        const field = isExternalViewer() ? "concessionOrganizationDocuments" : "ownConcessionOrganizationDocuments"
        const rows = context?.data?.[field]?.nodes
        const invalid = rows.filter((row: any) => {
            return isSelected(row) && (hasNoRequiredExpiration(row) || isExpired(row))
        })
        return !invalid?.length
    }

    const isCategorized = () => {
        const field = isExternalViewer() ? "concessionOrganizationDocuments" : "ownConcessionOrganizationDocuments"
        const rows = context?.data?.[field]?.nodes
        const invalid = rows.filter((row: any) => selection.includes(row?.id) && !row?.concessionOrganizationDocumentSubcategory)
        return !invalid?.length
    }

    const showErrorMessage = (e: unknown) => {
        const message = getErrorMessage(e)
        errorToast(message)
    }

    const handleSubmit = async () => {
        if (!inPreparation()) {
            errorToast("Erro! Verifique estado do documento")
        } else if (!isCategorized()) {
            errorToast("Não é possível submeter documentos não categorizados")
        } else if (!expirationRequired()) {
            errorToast("Erro! Data expiração em falta ou ultrapassada")
        } else {
            const documentState = 2
            await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { documentState } } } })
            context?.setRowSelectionModel([])
        }
    }

    const handleCategory = async (_: any, value: any) => {
        setCategory([value])
    }

    const handleSubcategory = async (_: any, value: any) => {
        try {
            if (beforeCategorization) {
                const selectedRows = getSelectedRows()
                beforeCategorization(selectedRows)
            }

            if (beforeCategorization || (inPreparation() || hasInternalRole)) {
                const concessionOrganizationDocumentSubcategory = value ? +value : null
                await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { concessionOrganizationDocumentSubcategory } } } })
                context?.setRowSelectionModel([])
                setPopActionSubcategory(false)
                setCategory([])
            } else {
                errorToast("Erro! Apenas é possível atualizar subcategoria de documentos em preparação")
            }
        } catch (e) {
            showErrorMessage(e)
        }
    }

    const handleState = async (_: any, value: any) => {
        const documentState = value ? +value : null
        await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { documentState } } } })
        context?.setRowSelectionModel([])
        setPopActionState(false)
    }

    const handleExpiration = async (value: any) => {
        try {
            if (beforeExpiration) {
                const selectedRows = getSelectedRows()
                beforeExpiration(selectedRows)
            }

            if (beforeExpiration || (inPreparation() || hasInternalRole)) {
                const expiresAt = value
                await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { expiresAt } } } })
                await context?.refetch()
                context?.setRowSelectionModel([])
                setPopActionSchedule(false)
            } else {
                errorToast("Erro! Apenas é possível atualizar data de expiração de documentos em preparação")
            }
        } catch (e) {
            showErrorMessage(e)
        }
    }

    const getRows = () => {
        let field = modelField ? modelField : getField()
        return context?.data?.[field]?.nodes
    }

    const getSelectedRows = () => {
        const rows = getRows()
        return rows.filter((row: any) => selection.includes(row?.id))
    }

    const handleAttach = () => {
        if (onAttach) {
            const selectedRows = getSelectedRows()
            try {
                beforeAttach?.(selectedRows)
                const selectedDocuments = selectedRows.map((row: any) => { return { id: row.id, name: row.name } })
                onAttach(selectedDocuments)
            } catch (e) {
                showErrorMessage(e)
            }
        } else {
            console.error("Error! onAttach callack is undefined")
        }
    }

    const blockActions = +context?.data?.contractDocuments?.nodes[0]?.contract?.contractState?.id > 2 || false
    const hasSelection = selection?.length
    const addProps = blockActions ? {} : getRootProps()

    return (
        <ActionsBar>
            <Grid container justifyContent="flex-between" alignItems="center" className={styles.Wrapper}>

                <Grid item xs={6} container alignItems="center">
                    <Show on={showBackButton}>
                        <BackButton />
                    </Show>
                    <Typography variant="h3" color="primary">{name}</Typography>
                </Grid>

                <Grid item xs={6} container justifyContent="flex-end">

                    <ActionsBarSeparator />

                    <Show on={!hasInternalRole && hasSelection && showAttachAction}>
                        <Tooltip title="Associar documento">
                            <span>
                                <IconButton onClick={handleAttach}>
                                    <AddLink />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Show>


                    <Show on={!hasInternalRole && hasSelection && showSubmitAction}>
                        <Tooltip title="Submeter">
                            <span>
                                <IconButton onClick={handleSubmit}>
                                    <Publish />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Show>

                    <Show on={hasInternalRole && hasSelection && !blockActions}>
                        <Tooltip title="Atualizar estado">
                            <div>
                                <PopAction name="state" icon={<SwapVert />} open={popActionState} setOpen={setPopActionState}>
                                    <div>
                                        <DocumentStateFilter value={[]} onChange={handleState} hideAllOption={true} />
                                    </div>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>

                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Atualizar data expiração">
                            <div>
                                <PopAction name="schedule" icon={<Schedule />} open={popActionSchedule} setOpen={setPopActionSchedule}>
                                    <Grid container style={{ width: 350, padding: 10 }} justifyContent="space-between" alignItems="center">
                                        <MuiDatePicker
                                            slotProps={{ textField: { variant: "standard", inputProps: { disabled: true, placeholder: "Clicar ícone selecionar data" } } }}
                                            value={null}
                                            onChange={handleExpiration}
                                            minDate={new Date()}
                                        />
                                        <div style={{ marginRight: 10 }}>
                                            <Tooltip title="Remover Data Expiração">
                                                <ConfirmButton onClick={() => handleExpiration(null)} message="Tem a certeza que deseja remover data de expiração? ">
                                                    <Close />
                                                </ConfirmButton>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>


                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Atualizar subcategoria">
                            <div>
                                <PopAction name="subcategory" icon={<Label />} open={popActionSubcategory} setOpen={setPopActionSubcategory}>
                                    <Grid container alignItems="center">
                                        <OrganizationDocumentCategoryFilter value={category} onChange={handleCategory} hideAllOption />
                                        <OrganizationDocumentSubcategoryFilter value={[]} parent={category} onChange={handleSubcategory} hideAllOption />
                                        <div style={{ marginRight: 10, marginTop: 10 }}>
                                            <Tooltip title="Remover subcategoria">
                                                <IconButton onClick={() => handleSubcategory(undefined, null)}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>

                    {/* <ActionsBarSeparator /> */}

                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Remover">
                            <span>
                                <ConfirmButton onClick={handleDelete}>
                                    <Delete />
                                </ConfirmButton>
                            </span>
                        </Tooltip>
                    </Show>

                    <Show on={!blockActions}>
                        <Tooltip title="Adicionar">
                            <span {...addProps}>
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Show>


                </Grid>
            </Grid>
        </ActionsBar >
    )
}

export default OrganizationDocumentsActions
