import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useContractFieldQuery } from '../../generated/graphql';
import { ContractStates } from '../ContractView/ContractViewMoreActions';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface ContractFieldProps {
    disabled?: boolean
    textFieldProps?: TextFieldProps
}

const ContractField: FC<ContractFieldProps> = props => {
    const { textFieldProps = { variant: "standard" }, disabled } = props

    return (
        <SelectAutocompleteDataField
            name="contract"
            label="Contrato"
            query={useContractFieldQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryFilter={{ contractState: { id: { eq: ContractStates.Ongoing } } }}
            querySorting={{ field: "id", direction: "ASC" }}
        />
    )
}

export default ContractField
