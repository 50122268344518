import { useCreateOneWeatherConditionMutation, useDeleteOneWeatherConditionMutation, useUpdateOneWeatherConditionMutation, useWeatherConditionsQuery } from "../../generated/graphql";

const useWeatherConditionStore = () => {
    const query = useWeatherConditionsQuery
    const name = "weatherCondition"
    const [create] = useCreateOneWeatherConditionMutation()
    const [update] = useUpdateOneWeatherConditionMutation()
    const [remove] = useDeleteOneWeatherConditionMutation()
    return { query, name, create, update, remove }
}

export default useWeatherConditionStore
