import { ConcessionOrganization, ContractConcessionOrganization } from "../../../generated/graphql"
import { isSubset } from "../../../services/utils"

// ContractConcessionOrganization - on update
// ConcessionOrganization - on create
export default function testContractConcessionOrganizations(array: any) {
    const required = [1, 2]
    const filled = array?.map((obj: ConcessionOrganization | ContractConcessionOrganization) => {
        if (obj?.__typename === "ConcessionOrganization") {
            return +obj?.organizationType?.id
        } else {
            // @ts-ignore
            return +obj?.concessionOrganization?.organizationType?.id
        }

    })
    return isSubset(required, filled)
}
