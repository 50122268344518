import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import DocumentStateFilter from "../DocumentStateFilter/DocumentStateFilter";
import FileTypeFilter from "../FileTypeFilter/FileTypeFilter";
import OrganizationDocumentCategoryFilter from "../OrganizationDocumentCategoryFilter/OrganizationDocumentCategoryFilter";
import OrganizationDocumentsInstructions from "../OrganizationDocumentsInstructions/OrganizationDocumentsInstructions";
import OrganizationDocumentSubcategoryFilter from "../OrganizationDocumentSubcategoryFilter/OrganizationDocumentSubcategoryFilter";
import Search from "../Search/Search";
import styles from './OrganizationDocumentsFilters.module.scss';

const OrganizationDocumentsFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.OrganizationDocumentsFilters}>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <FileTypeFilter value={context.filters.fileType} onChange={context.setFilter} multiple={true} />
                <DocumentStateFilter value={context.filters.documentState} onChange={context.setFilter} multiple={true} />
                <OrganizationDocumentCategoryFilter value={context.filters.concessionOrganizationDocumentCategory} onChange={context.setFilter} multiple={true} />
                <OrganizationDocumentSubcategoryFilter parent={context.filters.concessionOrganizationDocumentCategory}
                    value={context.filters.concessionOrganizationDocumentSubcategory} onChange={context.setFilter} multiple={true} />
                <OrganizationDocumentsInstructions />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}


export default OrganizationDocumentsFilters
