
// export interface CasingOptions {
//     casing: "match" | "uppercase" | "capitalize" | "lowercase"
// }

const isUppercase = (word: string) => {
    return word === word.toUpperCase()
}

const isLowercase = (word: string) => {
    return word === word.toLowerCase()
}


const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

// há a possibilidade de ter um outro casing ex:
// CaSinG, isto não é suportado
const getCasing = (word: string) => {
    if (isUppercase(word)) {
        return "uppercase"
    } else if (isLowercase(word)) {
        return "lowercase"
    } else {
        return "capitalize"
    }
}

const toCasing = (word: string, casing: string) => {
    if (casing === "uppercase") return word.toUpperCase()
    if (casing === "lowercase") return word.toLowerCase()
    if (casing === "capitalize") return capitalize(word)
}

// @fix isto não precisa de ser um hook
// @todo - corrigir typing
export const translate = (word?: string, casing?: "uppercase" | "capitalize" | "lowercase") => {

    if (!word) return null

    let originalCasing = getCasing(word)
    let _casing = casing ? casing : originalCasing

    const dictionary: any = {
        provisional: "provisório",
        draft: "rascunho",
        submitted: "submetida",
        pending: "aprovada",
        review: "revisão",
        validated: "validada",
        cancelled: "cancelada",
        issued: "emitido",
        operator: "Operador CCT",
        servicemanager: "Gestor Obra",
        contractmanager: "Gestor Contrato",
        platformmanager: "Gestor Plataforma",
        safetytechnician: "Técnico Segurança",
        supervisor: "Supervisor",
        name: "nome",
        abbreviation: "abreviatura",
        externalauthority: "Responsável Contrato",
        externalmanager: "Gestor Obra",
        externaltechnician: "Técnico Segurança",
        externaladministrative: "Administrativo",
        approved: "aprovada",
        rejected: "rejeitada",
        // por ordem alfafetica
        closed: "fechado",
        created: "criado",
        executed: "executada",
        expired: "expirada",
        not_executed: "não executada",
        in_execution: "em execução",
        preparation: "preparação",
        progress: "resolução",
        fixed: "resolvido",
        phone: "telefone",
        "file-invalid-type": "formato",
        "file-too-large": "tamanho",
        subcontractauthority: "Responsável Subcontrato"
    }

    let translated = dictionary[word.toLowerCase()]

    if (!translated) return null

    return toCasing(translated, _casing)

}

// @fix isto não precisa de ser um hook
const useTranslate = () => {
    return translate
}


export default useTranslate
