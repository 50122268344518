import { useContractTypesQuery } from "../../generated/graphql"
import useConcession from "../../hooks/useConcession"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

export default function ContractTypeFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useContractTypesQuery
    const concession = useConcession()

    return <SelectFilter
        value={value}
        onChange={onChange}
        query={query}
        multiple={true}
        field="contractType"
        label="Tipo Contrato"
        filter={{
            concession: {
                id: {
                    eq: concession?.id
                }
            }
        }}
        width={180}
        paging={{ limit: 999 }}
    />
}
