import { Close } from "@mui/icons-material"
import { Grid } from "@mui/material"
import { Form, Formik } from "formik"
import { FC } from "react"
import { useDeleteOneSurveyRecordVerificationMutation, useUpdateOneSurveyRecordVerificationMutation } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { SurveyRecordVerificationProps } from "../../types/types"
import AutoSave from "../AutoSave/AutoSave"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import InputAutoTextField from "../InputAutoTextField/InputAutoTextField"
import Show from "../Show/Show"
import SurveyRecordVerificationClassificationField from "../SurveyRecordVerificationClassificationField/SurveyRecordVerificationClassificationField"
import SurveyRecordVerificationStateField from "../SurveyRecordVerificationStateField/SurveyRecordVerificationStateField"
import VerificationField from "../VerificationField/VerificationField"
import styles from './SurveyRecordVerification.module.scss'
import surveyRecordVerificationFormInit from "./SurveyRecordVerificationFormInit"


const SurveyRecordVerification: FC<SurveyRecordVerificationProps> = props => {
    const { surveyRecordVerification, refetch, isNotUserEdit } = props

    const { schema, initial } = surveyRecordVerificationFormInit(surveyRecordVerification)
    const { feedback } = useToast()
    const [update] = useUpdateOneSurveyRecordVerificationMutation()
    const [deleteSurveyRecordVerification] = useDeleteOneSurveyRecordVerificationMutation()

    const handleSubmit = async (values: any) => {
        if (initial && values && initial !== values) {
            const { id, description, surveyRecordVerificationState, verification, surveyRecordVerificationClassification } = values
            if (id) {
                const response = await update({
                    variables: {
                        input: {
                            id, update: {
                                verification: verification?.id,
                                description,
                                surveyRecordVerificationClassification: surveyRecordVerificationClassification?.id,
                                surveyRecordVerificationState: surveyRecordVerificationState?.id
                            }
                        }
                    }
                })
                feedback(!response?.errors?.length, "Linha criada com sucesso", "Erro ao criar linha")
                refetch()
            }
        }
    }

    const handleDelete = async () => {
        const { id } = surveyRecordVerification
        const response = await deleteSurveyRecordVerification({ variables: { input: { id } } })
        feedback(!response?.errors?.length, "Linha removida com sucesso", "Erro ao remover linha")
        refetch()
    }

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema}>
            {({ values }) => {
                return (
                    <Form>
                        <Grid container className={styles.SurveyRecordVerification} alignItems="flex-end">
                            <span style={{ minWidth: 120, marginRight: 30 }}></span>
                            <span style={{ minWidth: 250, marginRight: 30 }}><VerificationField disabled={true} /></span>
                            <span style={{ minWidth: 600, marginRight: 30 }}>
                                <InputAutoTextField
                                    name="description"
                                    multiline
                                    minRows={1}
                                    maxRows={5}
                                    label="descrição"
                                    fullWidth
                                    disabled={isNotUserEdit} />

                            </span>

                            <span style={{ minWidth: 250, marginRight: 30 }}>
                                <SurveyRecordVerificationClassificationField disabled={isNotUserEdit} />
                            </span>

                            <span style={{ minWidth: 250, marginRight: 30 }}>
                                <Show on={values?.surveyRecordVerificationClassification?.id === "3"}>
                                    <SurveyRecordVerificationStateField disabled={isNotUserEdit} />
                                </Show>
                            </span>

                            <ConfirmButton onClick={handleDelete} disabled={isNotUserEdit}>
                                <Close fontSize="small" />
                            </ConfirmButton>
                            <AutoSave />
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )
}

export default SurveyRecordVerification
