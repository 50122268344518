import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Config from "../../services/config";
import Permission from "../Permission";

interface ProtectedProps {
    redirectTo?: string,
    children: ReactElement | null,
    action?: string
    subject?: string
}

const Protected: FC<ProtectedProps> = props => {
    const { children, redirectTo = Config.PROTECT_DEFAULT_REDIRECT_ROUTE, action, subject } = props
    const location = useLocation()
    const token = localStorage.getItem(Config.AUTH_TOKEN_KEY)
    const state = { redirect: location.pathname }

    // se não está autenticado não vale a pena verificar restantes permissões
    // é redirecionado para login (configurável)
    if (!token) {
        return <Navigate to={redirectTo} replace state={state} />;
    }

    // se não existe action e subject
    // significa que basta estar autenticado
    if (!action || !subject) {
        return children
    }

    // se o utilizador estiver a tentar aceder a uma route
    // para a qual não tem permissões é redirecionado para
    // login (configurável) e depois para a initial route
    return (
        <Permission action={action} subject={subject} passThrough>
            {(allowed: boolean) => allowed ? children : <Navigate to={redirectTo} replace state={state} />}
        </Permission>
    )
}

export default Protected;
