import { FilterProps } from '../../types/types';
import SelectBoolFilter from '../SelectBoolFilter/SelectBoolFilter';

export default function SurveyRecordPublishedFilter(props: FilterProps) {
    const { value, onChange } = props
    return (
        <SelectBoolFilter
            value={value}
            onChange={onChange}
            label="Publicado"
            field="isPublished"
            trueOptionName="Publicado"
            falseOptionName="Não Publicado"
        />
    )
}
