import { AutocompleteRenderInputParams, TextField, TextFieldProps } from '@mui/material';
import { FastField } from 'formik';
import { Autocomplete } from 'formik-mui';
import { FC } from 'react';
import { executingRoles, Settings, subcontractingRoles, useSettingManager } from '../../hooks/useSettingManager';
import useTranslate from '../../hooks/useTranslate';

interface ConcessionOrganizationUserRoleFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    organizationTypeId?: string
}

const ConcessionOrganizationUserRoleField: FC<ConcessionOrganizationUserRoleFieldProps> = props => {
    const {
        name = "concessionOrganizationUserRole",
        label = "Função",
        textFieldProps = { variant: "standard" },
        organizationTypeId
    } = props

    const t = useTranslate()
    const getSetting = useSettingManager()

    const subcontractOrganizationTypeId = getSetting(Settings.SUBCONTRACT_ORGANIZATION_TYPE_ID)
    const roles = organizationTypeId === subcontractOrganizationTypeId ? subcontractingRoles : executingRoles

    const options = roles.map(role => {
        return { id: role, label: t(role, "lowercase") }
    })

    return (
        <FastField
            name={name}
            component={Autocomplete}
            disableClearable
            options={options}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                />
            )}
        />
    )
}

export default ConcessionOrganizationUserRoleField
