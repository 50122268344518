import { useContractDocumentCategoriesWithSubcategoriesQuery } from "../../generated/graphql";
import useToast from "../../hooks/useToast";
import ShowDocumentInstructions from "../ShowDocumentInstructions/ShowDocumentInstructions";

export default function ContractDocumentsInstructions() {
    const { data, error } = useContractDocumentCategoriesWithSubcategoriesQuery()
    const { errorToast } = useToast()

    if (error) {
        errorToast(error.message)
    }

    return (
        <ShowDocumentInstructions documents={data?.contractDocumentCategories?.nodes} />
    )
}
