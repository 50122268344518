import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC, useState } from "react"
import { useOrganizationsLazyQuery } from "../../generated/graphql"
import { OrganizationEditorCreateForm } from "../OrganizationEditorCreateForm/OrganizationEditorCreateForm"
import OrganizationEditorUpdate from "../OrganizationEditorUpdate/OrganizationEditorUpdate"
import OrganizationNotFound from "../OrganizationNotFound/OrganizationNotFound"
import SearchOrganizationByTinField from "../SearchOrganizationByTinField/SearchOrganizationByTinField"

interface OrganizationEditorCreateProps {
    onCreateOrganization: any
    onCreateConcessionOrganization: any
    onDeleteConcessionOrganization: any
}

const OrganizationEditorCreate: FC<OrganizationEditorCreateProps> = props => {
    const { onCreateOrganization, onCreateConcessionOrganization, onDeleteConcessionOrganization } = props
    const [query, { data }] = useOrganizationsLazyQuery()
    const [state, setState] = useState<string>("FIND")
    const [submitted, setSubmitted] = useState<boolean>(false)

    const organization = data?.organizations?.nodes[0]

    const handleFindOrganization = async (values: FormikValues) => {
        const { tin } = values
        await query({ variables: { filter: { tin: { eq: tin } }, paging: { limit: 999 } }, fetchPolicy: "no-cache" })
        setSubmitted(true)
    }

    return (
        <div>
            {state === "FIND" && <Formik initialValues={{ tin: "" }} onSubmit={handleFindOrganization}>
                {({ values }) => {
                    return (
                        <Form>
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={8}>
                                    <SearchOrganizationByTinField />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button type="submit" variant="contained" size="small">Pesquisar</Button>
                                </Grid>
                            </Grid>

                            {submitted && !data?.organizations?.nodes?.length && <OrganizationNotFound tin={values.tin} setCreateState={() => setState("CREATE")} />}
                            {values.tin && organization && <div style={{ marginTop: 30 }}>
                                <Typography variant="body1" color="secondary">Já existe uma entidade extena com nif <b>{values.tin}</b>
                                </Typography>

                            </div>}
                        </Form>
                    )
                }}
            </Formik>}

            {organization && <OrganizationEditorUpdate organization={organization} onCreate={onCreateConcessionOrganization} onDeleteConcessionOrganization={onDeleteConcessionOrganization} />}

            {state === "CREATE" && <OrganizationEditorCreateForm onCreateOrganization={onCreateOrganization} onCreateConcessionOrganization={onCreateConcessionOrganization} />}
        </div >
    )
}

export default OrganizationEditorCreate
