import { IconButton, Popover } from "@mui/material"
import { FC, ReactNode, useState } from "react"

interface PopActionProps {
    name: string
    icon: ReactNode
    disabled?: boolean
    children: ReactNode
    open: boolean
    setOpen: any
}

const PopAction: FC<PopActionProps> = props => {
    const { name, icon, disabled = false, open, setOpen, children } = props
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
        setAnchor(event.currentTarget)
        setOpen(true)
    }

    return (
        <>
            <IconButton onClick={handleClick} disabled={disabled}>
                {icon}
            </IconButton>
            <Popover
                id={name}
                open={open}
                anchorEl={anchor}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    {children}
                </div>
            </Popover>
        </>
    )
}

export default PopAction
