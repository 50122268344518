import * as Yup from "yup";
import { Contract } from "../../generated/graphql";
import { getInitialValues, requiredDate, requiredEndDate, REQUIRED_FIELD } from "../../services/config";
import testContractConcessionOrganizations from "./Validators/contractConcessionOrganization";
import testContractUsers from "./Validators/testContractUsers";

const getSchema = () => {
    const requiredDateSchema = requiredDate()
    return Yup.object().shape({
        description: Yup.string().required().default(""),
        startDate: requiredDateSchema,
        endDate: requiredEndDate("startDate"),
        realStartDate: requiredDateSchema,
        realEndDate: requiredEndDate("realStartDate"),
        contractType: Yup.mixed().required().default(null),
        service: Yup.mixed().required().default(null),
        interventionType: Yup.mixed().required().default(null),
        interventionElement: Yup.mixed().required().default(null),
        interventionLocation: Yup.mixed().required().default(null),
        contractRoads: Yup.mixed().required().default(null),
        contractUsers: Yup.array().required().default([]).test("contractUsers", REQUIRED_FIELD, testContractUsers),
        contractConcessionOrganizations: Yup.array().default([]).test("contractConcessionOrganizations", REQUIRED_FIELD, testContractConcessionOrganizations),
        contractRoadsObservations: Yup.string().default(""),
        originalValue: Yup.number().required().default(0), // ver o contractDetails para hack sobre este valor
        amendedValue: Yup.number().required().default(0), // ver o contractDetails para hack sobre este valor
        billedValue: Yup.number().required().default(0) // ver o contractDetails para hack sobre este valor
    })
}

// se recebermos um contrato estamos em modo de edição
const contractFormInit = (contract?: Contract) => {
    const schema = getSchema()
    const initial = contract ? contract : getInitialValues(schema)
    return { schema, initial }
}


export default contractFormInit
