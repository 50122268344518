import { black, blue, gray, green, red } from '../../../services/config'
import styles from './ContractStateRender.module.scss'

const getContractStateColor = (id: number) => {
    const contractColors: { [key: number]: string } = {
        1: gray,
        2: green,
        3: red,
        4: blue,
        5: blue,
        6: blue,
        7: blue
    }

    return contractColors[id] || black
}


export const ContractStateRender = (params: any) => {
    const backgroundColor = getContractStateColor(params?.row?.contractState?.id)
    return (
        <div className={styles.ContractState} style={{ backgroundColor }}>
            {params?.row?.contractState?.name}
        </div>
    )
}
