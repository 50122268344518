import { Divider } from "@mui/material"
import { FC } from "react"

interface MenuDividerProps {
    on?: boolean
}

const MenuDivider: FC<MenuDividerProps> = props => {
    const { on } = props
    return on || on === undefined ? <Divider /> : null
}

export default MenuDivider
