import { Button, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues, useFormikContext } from "formik"
import { FC, useEffect, useState } from "react"
import InputTextField from "../InputTextField/InputTextField"
import styles from './CreateBadges.module.scss'

interface CreateBadgesProps {
    submit: (values: FormikValues) => void
}

const CreateBadgeLine = (props: any) => {
    const { count } = props
    const formikContext = useFormikContext()

    const company = `company_${count}`
    const driver = `driver_${count}`
    const licensePlate = `licensePlate_${count}`

    useEffect(() => {
        formikContext.setFieldValue(company, "")
        formikContext.setFieldValue(driver, "")
        formikContext.setFieldValue(licensePlate, "")
    }, []);


    return (
        <Grid container justifyContent="space-between">
            <InputTextField name={company} label="empresa" variant="standard" />
            <InputTextField name={driver} label="condutor" variant="standard" />
            <InputTextField name={licensePlate} label="matricula" variant="standard" />
        </Grid>
    )
}


const CreateBadges: FC<CreateBadgesProps> = props => {
    const { submit } = props
    const [indexes, setIndexes] = useState<number[]>([0])
    const initial = {}

    // uma linha está totalmente preenchida?
    const lineIsFilled = (values: FormikValues, indexes: number[]) => {
        return values[`company_${indexes.length - 1}`] && values[`driver_${indexes.length - 1}`] && values[`licensePlate_${indexes.length - 1}`]
    }

    const isLineEmpty = (values: FormikValues, index: number) => {
        return !values[`company_${index}`] && !values[`driver_${index}`] && !values[`licensePlate_${index}`]
    }


    const isLastLine = (index: number) => {
        return index === indexes.length - 1
    }

    // uma linha só é válida se todos os campos dessa linha forem válidos
    // para já para um campo estar válido basta estar preenchido
    const handleValidate = (values: FormikValues) => {

        const errors: any = {}

        for (const [key, value] of Object.entries(values)) {
            const index = parseInt(key.split("_")[1])
            const lastLine = isLastLine(index)

            if (!value && !lastLine) {
                errors[key] = "Campo Obrigatório"
            }
        }

        return errors
    }


    return (
        <div id={styles.CreateBadges}>
            <Formik initialValues={initial} onSubmit={submit} validate={handleValidate}>
                {({ values }) => {
                    // @ts-ignore
                    if (lineIsFilled(values, indexes)) {
                        setIndexes([...indexes, indexes.length])
                    }
                    return (
                        <Grid container direction="column" alignContent="flex-start" justifyContent="space-between" style={{ height: "100%" }}>
                            <Grid item xs={10} container direction="column">
                                <Typography variant="h3" color="primary">
                                    Criar Distícos
                                </Typography>
                                <Form id="create-badges-form">
                                    {indexes.map((index) => <p><CreateBadgeLine count={index} key={index} /></p>)}
                                </Form>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button type="submit" variant="contained" size="small" form="create-badges-form">Criar</Button>
                            </Grid>
                        </Grid>
                    )
                }}
            </Formik>
        </div>
    )
}

export default CreateBadges
