import { Block, Close, Delete, Grading, Publish } from "@mui/icons-material";
import { Menu } from "@mui/material";
import { FC, useState } from "react";
import { InterventionStates, useDeleteOneInterventionMutation, useUpdateOneInterventionMutation } from "../../generated/graphql";
import useGoTo from "../../hooks/useGoto";
import useToast from "../../hooks/useToast";
import { Actions, Subjects } from "../../services/ability";
import MenuDivider from "../MenuDivider/MenuDivider";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import MoreIconButton from "../MoreIconButton/MoreIconButton";
import Permission from "../Permission";

interface InterventionViewMoreActionsProps {
    intervention: any
    refetch: any
}

export const getAllowedTransitions = (currentState: string) => {

    if (!currentState) return []

    const transitionsMap: any = {
        [InterventionStates.Preparation]: [InterventionStates.Submitted],
        [InterventionStates.Submitted]: [InterventionStates.Review, InterventionStates.Approved, InterventionStates.Cancelled],
        [InterventionStates.Review]: [InterventionStates.Submitted, InterventionStates.Cancelled],
        [InterventionStates.Approved]: [InterventionStates.Closed, InterventionStates.Cancelled],
    }

    return transitionsMap[currentState] || []
}

const InterventionViewMoreActions: FC<InterventionViewMoreActionsProps> = props => {
    const { intervention, refetch } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)
    const [updateIntervention] = useUpdateOneInterventionMutation()
    const [deleteIntervention] = useDeleteOneInterventionMutation()
    const { feedback } = useToast()
    const { goToInterventions } = useGoTo()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeAndRefetch = () => {
        handleClose();
        refetch();
    }

    const handleDelete = async () => {
        if (intervention?.id) {
            const response = await deleteIntervention({ variables: { input: { id: intervention.id } } })
            const errors = response?.errors?.length
            feedback(!errors, "Permissão removida com sucesso", "Erro ao remover permissão.")
            if (!errors) {
                goToInterventions()
            }
        }
    }

    const transitionTo = async (stateTo: string) => {
        if (intervention?.id) {
            // @ts-ignore
            const response = await updateIntervention({ variables: { input: { id: intervention?.id, update: { state: stateTo } } } })
            feedback(!response?.errors?.length, "Transição bem sucedida.", "Erro na transição.")
            closeAndRefetch()
        }
    }

    const getTransitionData = (currentState: string) => {
        const transitions: string[] = getAllowedTransitions(currentState)
        const actionsMap: any = [
            {
                stateTo: InterventionStates.Submitted,
                icon: <Publish fontSize="small" />,
                label: "Submeter",
                action: Actions.SUBMIT,
                subject: Subjects.INTERVENTION
            },
            {
                stateTo: InterventionStates.Review,
                icon: <Grading fontSize="small" />,
                label: "Pedir Revisão",
                action: Actions.REVIEW,
                subject: Subjects.INTERVENTION
            },
            {
                stateTo: InterventionStates.Closed,
                icon: <Close fontSize="small" />,
                label: "Fechar",
                action: Actions.CLOSE,
                subject: Subjects.INTERVENTION
            },
            {
                stateTo: InterventionStates.Cancelled,
                icon: <Block fontSize="small" />,
                label: "Cancelar",
                action: Actions.CANCEL,
                subject: Subjects.INTERVENTION,
                confirmationRequired: true
            },
            {
                stateTo: InterventionStates.Approved,
                icon: <Close fontSize="small" />,
                label: "Aprovar",
                action: Actions.APROVE,
                subject: Subjects.INTERVENTION
            },
        ]

        return actionsMap.filter((obj: any) => transitions.includes(obj.stateTo))
    }


    const inPreparation = intervention?.state === "PREPARATION"

    return (
        <div>
            <MoreIconButton onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: 500 }}
            >

                {
                    getTransitionData(intervention?.state).map((obj: any) => {
                        const { action, subject, stateTo, confirmationRequired, icon, label } = obj
                        return (
                            <Permission action={action} subject={subject} passThrough>
                                {allowed =>
                                    <MenuItemWithConfirmation
                                        icon={icon}
                                        label={label}
                                        allowed={allowed}
                                        onClick={() => transitionTo(stateTo)}
                                        confirmationRequired={confirmationRequired}
                                    />
                                }
                            </Permission>
                        )
                    })
                }

                <MenuDivider />

                <Permission action={Actions.DELETE} subject={Subjects.INTERVENTION} passThrough>
                    {allowed =>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover Intervenção"
                            allowed={allowed && inPreparation}
                            confirmationRequired={true}
                            listItemTextColor="red"
                        />
                    }
                </Permission>

            </Menu>
        </div>
    )
}

export default InterventionViewMoreActions
