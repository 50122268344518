import { Grid } from "@mui/material";

const documentCategoryRender = (params: any, relation: string) => {
    return (
        <Grid container alignContent="center">
            <span>{params?.row?.[relation]?.name}</span>
        </Grid >
    )
}

export const contractDocumentCategoryRender = (params: any) => {
    return documentCategoryRender(params, "contractDocumentCategory")
}

export const concessionOrganizationDocumentCategoryRender = (params: any) => {
    return documentCategoryRender(params, "concessionOrganizationDocumentCategory")
}
