import { FC, ReactNode } from "react";
import { useCreateOneInterventionMutation, useDeleteOneInterventionMutation, useInterventionsQuery, useUpdateOneInterventionMutation } from "../../generated/graphql";
import { FilterConfig } from "../../hooks/useFilters";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const InterventionsDataSource = {
    createFunc: useCreateOneInterventionMutation,
    readFunc: useInterventionsQuery,
    updateFunc: useUpdateOneInterventionMutation,
    deleteFunc: useDeleteOneInterventionMutation,
}

interface InterventionProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const InterventionProvider: FC<InterventionProviderProps> = props => {
    const { filterConfig } = props

    return (
        <DataViewProvider datasource={InterventionsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default InterventionProvider
