import styles from '../Datagrid.module.scss';
import { Link } from 'react-router-dom';

export const InterventionRender = (params: any) => {
    const intervention = params?.row?.intervention
    const route = 'intervention'
    return (
        <Link to={`/${route}/${intervention.id}`} className={styles.Link}>
            {intervention.name}
        </Link>
    )
}
