import { createElement, Fragment } from "react";
import { ArrayParam, StringParam } from "use-query-params";
import { FilterConfig } from "../../hooks/useFilters";
import { DocumentScopes } from "../Datagrid/Helpers/PolicyToRoles";
import OrganizationDocumentsActions from "../OrganizationDocumentsActions/OrganizationDocumentsActions";
import OrganizationDocumentsFilters from "../OrganizationDocumentsFilters/OrganizationDocumentsFilters";
import OrganizationDocumentsList from "../OrganizationDocumentsList/OrganizationDocumentsList";
import OrganizationDocumentProvider from "./OrganizationDocumentProvider";
import styles from './OrganizationDocumentsView.module.scss';
import OwnOrganizationDocumentProvider from "./OwnOrganizationDocumentProvider";

interface ViewChildrenProps {
    id?: any
    documentScopes?: DocumentScopes[]
}

const ViewChildren = (props: ViewChildrenProps) => {
    const { id, documentScopes } = props
    return (
        <Fragment>
            <OrganizationDocumentsActions concessionOrganizationId={id} />
            <OrganizationDocumentsFilters />
            <OrganizationDocumentsList documentScopes={documentScopes} />
        </Fragment>
    )
}

export default function OrganizationDocumentsView(props: any) {

    const { id } = props

    const searchExpFunc = (value: any) => {
        return {
            iLike: `%${value}%`
        }
    }

    const filterConfig: FilterConfig[] = [
        { name: "concessionOrganizationDocumentCategory", variant: ArrayParam, defaultValue: [] },
        { name: "concessionOrganizationDocumentSubcategory", variant: ArrayParam, defaultValue: [] },
        { name: "documentState", variant: ArrayParam, defaultValue: [] },
        { name: "fileType", variant: ArrayParam, defaultValue: [] },
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc }
    ]

    if (id) {
        const filter = { name: "concessionOrganization", variant: StringParam, defaultValue: id }
        filterConfig.push(filter)
    }

    const documentScopes = [DocumentScopes.CONCESSION, DocumentScopes.EXECUTING]

    return (
        <div className={styles.Wrapper}>
            {id ? (
                createElement(OrganizationDocumentProvider, { filterConfig, children: <ViewChildren id={id} documentScopes={documentScopes} /> })
            ) : (
                createElement(OwnOrganizationDocumentProvider, { filterConfig, children: <ViewChildren documentScopes={documentScopes} /> })
            )}
        </div>
    )
}
