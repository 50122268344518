import { Add } from "@mui/icons-material"
import { Grid, IconButton, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import ConcessionOrganizationRoleField from "../ConcessionOrganizationRoleField/ConcessionOrganizationRoleField"
import ConcessionOrganizationRoles from "../ConcessionOrganizationRoles/ConcessionOrganizationRoles"
import styles from './OrganizationEditorUpdate.module.scss'

interface OrganizationEditorUpdateProps {
    organization: any
    onCreate: any
    onDeleteConcessionOrganization: any
}

const OrganizationEditorUpdate: FC<OrganizationEditorUpdateProps> = props => {
    const { organization, onCreate, onDeleteConcessionOrganization } = props

    const handleSubmit = async (values: FormikValues, helpers: any) => {
        const { organization, concessionOrganizationRole } = values
        await onCreate(organization, concessionOrganizationRole?.id)
        helpers.resetForm()
    }

    const initialValues = { organization, concessionOrganizationRole: "" }

    return (
        <div className={styles.OrganizationEditorUpdate}>
            <Typography variant="h5" color="primary" gutterBottom={false}>{organization?.name}</Typography>
            <Typography variant="body1" color="secondary" gutterBottom={true}>{organization?.address}</Typography>
            <Typography variant="body1" color="secondary" gutterBottom={true}>{organization?.zipCode}</Typography>
            <Typography variant="body1" color="secondary" gutterBottom={true}>NIF: {organization?.tin}</Typography>

            <div style={{ marginBottom: 20 }}></div>

            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={8}>
                                    <ConcessionOrganizationRoleField />
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton type="submit" color="primary"><Add /></IconButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            <ConcessionOrganizationRoles organization={organization} onDeleteConcessionOrganization={onDeleteConcessionOrganization} />
        </div>
    )
}

export default OrganizationEditorUpdate
