import { FC, ReactNode } from "react";
import { useTasksQuery, useCreateOneTaskMutation, useDeleteOneTaskMutation, useUpdateOneTaskMutation } from "../../generated/graphql";
import { FilterConfig } from "../../hooks/useFilters";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const TasksDataSource = {
    createFunc: useCreateOneTaskMutation,
    readFunc: useTasksQuery,
    updateFunc: useUpdateOneTaskMutation,
    deleteFunc: useDeleteOneTaskMutation,
}

interface TaskProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const TaskProvider: FC<TaskProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={TasksDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default TaskProvider
