import { Add } from "@mui/icons-material";
import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";

interface AddIconButtonProps {
    onClick: (event: any) => void
    disabled?: boolean
    title?: string
}

export default function AddIconButton(props: AddIconButtonProps) {
    const { onClick,
        disabled = false,
        title = "adicionar"
    } = props

    return (
        <IconButtonTooltip title={title} disabled={disabled} onClick={onClick}>
            <Add />
        </IconButtonTooltip>
    )
}
