import { Link } from 'react-router-dom';
import styles from '../Datagrid.module.scss';

const getAccreditation = (params: any) => {
    if (params?.row?.__typename === "ContractConcessionOrganization") {
        return params?.row?.concessionOrganizationAccreditation
    }
    return params?.row
}

export const AccreditationRender = (params: any) => {
    const accreditation = getAccreditation(params)
    const id = accreditation?.id
    const name = accreditation?.concessionOrganizationAccreditationType?.name
    const route = 'accreditation'
    return (
        <Link to={`/${route}/${id}`} className={styles.Link}>
            {name}
        </Link>
    )
}
