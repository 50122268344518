import { Button, Typography } from "@mui/material"
import styles from '../OrganizationEditor/OrganizationEditor.module.scss'

const OrganizationNotFound = (props: any) => {
    const { tin, setCreateState } = props
    return (
        <div className={styles.NoOrganizationFound}>
            <Typography variant="body1" color="secondary" className={styles.NotFound}>
                Não existe nenhuma entidade registada no GIN com o NIF <b>{tin}</b>
            </Typography>
            <Typography variant="body1" color="secondary" className={styles.Create}>Para criar uma nova entidade clique no botão em baixo.</Typography>
            <Button variant="outlined" color="primary" onClick={setCreateState}>Criar Nova Entidade</Button>
        </div >
    )
}

export default OrganizationNotFound
