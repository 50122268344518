import { FC } from "react"
import { ContractSortFields, SortDirection, useContractsQuery } from "../../generated/graphql"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

const ContractFilter: FC<FilterProps> = props => {
    const { value, onChange } = props
    const query = useContractsQuery
    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            query={query}
            multiple={true}
            field="contract"
            label="Contrato"
            sorting={[
                { field: ContractSortFields.Id, direction: SortDirection.Asc }
            ]}
            paging={{ limit: 999 }}
        />
    )
}

export default ContractFilter
