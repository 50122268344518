import { createElement, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useParamId from "../../hooks/useParamId"
import ContractDetails from "../ContractDetails/ContractDetails"
import ContractDocumentsView from "../ContractDocumentsView/ContractDocumentsView"

export enum ContractSubviews {
    "DETAILS",
    "DOCUMENTS"
}

const ContractView = () => {
    const id = useParamId()
    // TODO custom hook
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab")

    const defaultView = tab === "DOCUMENTS" ? ContractSubviews.DOCUMENTS : ContractSubviews.DETAILS
    const [subview, setSubview] = useState<ContractSubviews>(defaultView)

    if (subview === ContractSubviews.DETAILS) {
        return createElement(ContractDetails, { id, subview, setSubview })
    } else {
        return createElement(ContractDocumentsView, { id, subview, setSubview })
    }
}

export default ContractView
