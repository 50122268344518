import { useConcessionOrganizationsQuery, useCreateOneConcessionOrganizationMutation, useDeleteOneConcessionOrganizationMutation, useUpdateOneConcessionOrganizationMutation } from "../../generated/graphql";
import useConcession from "../../hooks/useConcession";
import Config from "../../services/config";

const useConcessionOrganizationStore = () => {
    const query = useConcessionOrganizationsQuery
    const name = "concessionOrganization"

    const concession = useConcession()

    const [_create] = useCreateOneConcessionOrganizationMutation()
    const [_remove] = useDeleteOneConcessionOrganizationMutation()

    const create = (organizationId: string, roleId: string) => {
        if (!concession?.id) throw Error(Config.APP_ERRORS.UNDEFINED_CONCESSION)
        return _create({ variables: { input: { concessionOrganization: { concession: concession?.id, organization: organizationId, organizationType: roleId } } } })
    }

    const remove = (concessionOrganizationId: string) => {
        return _remove({ variables: { input: { id: concessionOrganizationId } } })
    }

    return { query, name, create, remove, _create, _remove }
}

export default useConcessionOrganizationStore
