import { FormikContextType, useFormikContext } from "formik"


export const useShowArrayFieldError = (name: string, role: string, matchFunc?: any) => {
    const context: FormikContextType<any> = useFormikContext()
    const error = context.errors[name] ? true : false
    let helperText = error ? context.errors[name] as string : ""
    // @ts-ignore
    const values = context.values[name]

    // @ts-ignore
    const matched = values?.filter(value => {
        return matchFunc ? matchFunc(value, role) : value.role === role
    })

    if (!matched?.length) {
        return { error, helperText }
    }

    return { error: false, helperText: null }
}
