import { ConcessionUser, ContractUser } from "../../../generated/graphql"

function getContractUserValue(options: ConcessionUser[], value: ContractUser[]): ConcessionUser[] // on update
function getContractUserValue(options: ConcessionUser[], value: ConcessionUser[]): ConcessionUser[] // on create
function getContractUserValue(options: ConcessionUser[], value: ContractUser[] | ConcessionUser[]): ConcessionUser[] {

    if (!options || !value) return []

    if (value.length > 0 && value[0].__typename === 'ContractUser') {
        return options.filter(option =>
            value.some(v => v.role === option.role && v.user.id === option.user.id)
        );

    } else {
        return options.filter((option: ConcessionUser) => {
            // @ts-ignore
            return value.includes(option)
        })
    }
}

export default getContractUserValue
