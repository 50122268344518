import { TaskStates } from '../../generated/graphql';
import { translate } from '../../hooks/useTranslate';
import { RelationFieldProps } from '../../types/types';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

export default function TaskStateField(props: RelationFieldProps) {
    const { textFieldProps = { variant: "standard" }, disabled } = props
    const t = translate

    const options = Object.values(TaskStates)

    const query = (_: any) => {
        return {
            error: false,
            loading: false,
            data: {
                states: {
                    nodes: options
                }
            }
        }
    }

    const getOptionLabel = (option: any) => {
        return t(option, "capitalize")
    }

    return (
        <SelectAutocompleteDataField
            name="state"
            label="Estado"
            query={query}
            textFieldProps={textFieldProps}
            disabled={disabled}
            getOptionLabel={getOptionLabel}
        />
    )
}
