import { ConcessionOrganization, ConcessionOrganizationFilter, ConcessionOrganizationSort, ConcessionOrganizationSortFields, OffsetPaging, SortDirection, useConcessionOrganizationsQuery } from "../generated/graphql";
import Config from "../services/config";
import { useConcessionId } from "./useConcession";
import { Settings, useSettingManager } from "./useSettingManager";
import useSubcontractOrganizationManager, { CreateFullSubcontractOrganizationData, CreateFullSubcontractOrganizationResult, SubcontractOrganizationManager } from "./useSubcontractOrganizationManager";

export type SubcontractOrganization = ConcessionOrganization

export interface SubcontractOrganizationDataManager extends SubcontractOrganizationManager {
    subcontractOrganizations: any
}

export default function useSubcontractOrganizationDataManager(): SubcontractOrganizationDataManager {

    const concessionId = useConcessionId()
    const getSetting = useSettingManager()
    const organizationTypeId = getSetting(Settings.SUBCONTRACT_ORGANIZATION_TYPE_ID)

    const { createFullSubcontractOrganization } = useSubcontractOrganizationManager()

    const filter: ConcessionOrganizationFilter = { concession: { id: { eq: concessionId } }, organizationType: { id: { eq: organizationTypeId } } }
    const paging: OffsetPaging = { limit: Config.MAX_RESULTS_SIZE }
    const sorting: ConcessionOrganizationSort = { field: ConcessionOrganizationSortFields.Id, direction: SortDirection.Asc }

    const { data, refetch } = useConcessionOrganizationsQuery({
        variables: { filter, paging, sorting }
    })

    const _createFullSubcontractOrganization = async (data: CreateFullSubcontractOrganizationData): Promise<CreateFullSubcontractOrganizationResult> => {
        const result = await createFullSubcontractOrganization(data)
        if (result.success) refetch()
        return result
    }

    return {
        subcontractOrganizations: data?.concessionOrganizations?.nodes,
        createFullSubcontractOrganization: _createFullSubcontractOrganization
    }
}
