import { GridColDef } from '@mui/x-data-grid-pro';
import { ContractRender } from '../../Datagrid/Renders/ContractRender';
import { detailsRender } from '../../Datagrid/Renders/DetailsRender';
import { InterventionStateRender } from '../../Datagrid/Renders/InterventionStateRender';
import { WorkPermitRender } from '../../Datagrid/Renders/WorkPermitRender';

export default function getInterventionColumns(hide?: string[]): GridColDef[] {

    const columns: GridColDef[] = [
        { field: "name", headerName: "nome", renderCell: params => detailsRender(params, "intervention"), width: 150, filterable: false },
        { field: "week", headerName: "semana", width: 150, filterable: false },
        { field: "year", headerName: "ano", width: 150, filterable: false },
        { field: "state", headerName: "estado", width: 150, renderCell: InterventionStateRender, filterable: false, sortable: false },
        { field: "workPermit", headerName: "permissão", width: 150, renderCell: params => WorkPermitRender(params), sortable: false, filterable: false },
        { field: "contract", headerName: "contrato", width: 150, renderCell: params => ContractRender(params), sortable: false, filterable: false },
    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}
