import { useFormikContext } from "formik";
import { useEffect } from "react";

const AutoSave = () => {
    const { values, submitForm } = useFormikContext();

    useEffect(() => {
        submitForm()
    }, [values, submitForm]);

    return null
}

export default AutoSave
