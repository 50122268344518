import { downloadRequestStatic } from "../../../services/utils";
import DownloadIconButton from "../../DownloadIconButton/DownloadIconButton";
import PreviewIconButton from "../../PreviewIconButton/PreviewIconButton";

export const actionsRender = (params: any, setPreviewFile: any) => {

    const disablePreview = !params?.row?.fileType?.isPreviewable

    const handleDownload = (event: any) => {
        event.stopPropagation();
        downloadRequestStatic(params?.row?.path, params?.row?.name)
    }

    const handlePreview = (event: any) => {
        event.stopPropagation();
        setPreviewFile(params?.row)
    }

    return (
        <div>
            <PreviewIconButton onClick={handlePreview} disabled={disablePreview} />
            <DownloadIconButton onClick={handleDownload} />
        </div>
    )
}
