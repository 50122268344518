import { Intervention } from "../../generated/graphql";
import { MaybeDeepPartial, TasksListView } from "../../types/types";
import InterventionImport from "../InterventionView/InterventionImport";
import TasksFilters from "../TasksFilters/TaskFilters";
import TasksList from "../TasksList/TasksList";
import TaskProvider from "../TasksView/TaskProvider";
import { getTasksFilterConfig } from "../TasksView/TasksView";
import styles from './InterventionTasks.module.scss';

interface InterventionTasksProps {
    intervention: MaybeDeepPartial<Intervention>
}

export default function InterventionTasks(props: InterventionTasksProps) {

    const { intervention } = props
    const view = TasksListView.INTERVENTION

    if (!intervention?.id) return null

    const filterConfig = getTasksFilterConfig(view, intervention?.id)

    return (
        <div className={styles.Wrapper}>
            <TaskProvider filterConfig={filterConfig}>
                <InterventionImport intervention={intervention} />
                <TasksFilters view={view} />
                <TasksList view={view} />
            </TaskProvider>
        </div>
    )
}
