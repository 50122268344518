import { Popover, Typography } from "@mui/material"
import { Anchor, SetAnchor } from "../../types/types"
import styles from './ShowMoreInfoPopover.module.scss'

interface ShowMoreInfoProps {
    anchor: Anchor
    setAnchor: SetAnchor
    content: string | string[]
}

export default function ShowMoreInfoPopover(props: ShowMoreInfoProps) {
    const { anchor,
        setAnchor,
        content
    } = props

    const popoverOpen = Boolean(anchor)
    const id = popoverOpen ? 'popover' : undefined

    const paragraphs: string[] = typeof content === "string" ? [content] : content

    return (
        <div>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.Wrapper}>
                    <div className={styles.Content}>
                        {paragraphs.map(paragraph => (
                            <Typography variant="body1">
                                {paragraph}
                            </Typography>
                        ))}
                    </div>
                </div>
            </Popover >
        </div >
    )
}
