import { useConcessionOrganizationUsersQuery, useCreateOneConcessionOrganizationUserMutation, useDeleteOneConcessionOrganizationUserMutation } from "../../generated/graphql";
import useConcession from "../../hooks/useConcession";

const useConcessionOrganizationUserStore = () => {
    const query = useConcessionOrganizationUsersQuery
    const name = "concessionOrganizationUser"

    const concession = useConcession()

    const [_create] = useCreateOneConcessionOrganizationUserMutation()
    const [_remove] = useDeleteOneConcessionOrganizationUserMutation()

    const create = (concessionOrganizationId: string, userId: string, role: any) => {
        return _create({ variables: { input: { concessionOrganizationUser: { concessionOrganization: concessionOrganizationId, user: userId, role } } } })
    }

    const remove = (concessionOrganizationUserId: string) => {
        return _remove({ variables: { input: { id: concessionOrganizationUserId } } })
    }

    return { query, name, create, remove, _create, _remove }
}

export default useConcessionOrganizationUserStore
