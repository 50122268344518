import { FormikValues } from "formik";
import { useCreateOneTaskMutation, useUpdateOneTaskMutation } from "../generated/graphql";
import { createTransform, updateTransform } from "../services/utils";
import { useHasExternalRole, useHasOperatorRole, useHasServiceManagerRole } from "./useHasRole";
import useToast from "./useToast";
import { TaskStates } from "../generated/graphql"
import { translate } from "./useTranslate";

export const operatorTaskStates = [
    TaskStates.Approved,
    TaskStates.Executed,
    TaskStates.NotExecuted,
    TaskStates.InExecution
]

export const externalRoleTaskStates = [
    TaskStates.Preparation,
    TaskStates.Rejected
]


const useTaskForm = (intervention: string | undefined, onSuccess: any, onFail: any) => {
    const [createTask] = useCreateOneTaskMutation()
    const [updateTask] = useUpdateOneTaskMutation()
    const { errorToast, feedback } = useToast()
    const isOperator = useHasOperatorRole()
    const isServiceManager = useHasServiceManagerRole()
    const hasExternalRole = useHasExternalRole()
    const t = translate

    const handleCreateTask = async (values: FormikValues) => {
        try {
            // @ts-ignore
            const response = await createTask(createTransform({ ...values, intervention }, "task"))
            feedback(!response?.errors?.length, "Linha criada com sucesso", "Erro ao criar linhas")
            onSuccess(response)
        } catch (e: unknown) {
            errorToast("Erro Desconhecido")
        }
    }

    const stateInOperatorRange = (state: string) => {
        return operatorTaskStates.includes(state as TaskStates)
    }

    const stateInExternalRoleRange = (state: string) => {
        return externalRoleTaskStates.includes(state as TaskStates)
    }

    const canUpdateToState = (values: FormikValues) => {
        if (isServiceManager) {
            return true
        } else if (isOperator && stateInOperatorRange(values.state)) {
            return true
        } else if (hasExternalRole && stateInExternalRoleRange(values.state)) {
            return true
        }
        return false
    }

    const handleUpdateTask = async (values: FormikValues) => {
        const nonUpdatableFields = ["intervention", "name", "sequence", "extension", "overlaps"]
        if (canUpdateToState(values)) {
            try {
                const response = await updateTask(updateTransform(values, nonUpdatableFields))
                feedback(!response?.errors?.length, "Linha(s) atualizada com sucesso", "Erro ao atualizar linha(s)")
                onFail(response)
            } catch (e: unknown) {
                errorToast("Erro Desconhecido")
            }
        } else {
            errorToast(`Erro. Não tem permissão para atualizar o estado para ${t(values?.state, "capitalize")}.`)
        }
    }

    const handleTaskSubmit = async (values: FormikValues): Promise<void> => {
        values?.id ? handleUpdateTask(values) : handleCreateTask(values)
    }

    return { handleSubmit: handleTaskSubmit }
}

export default useTaskForm
