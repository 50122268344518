import { useState } from "react"


const useCustomModal = (defaultState: boolean = false) => {
    const [state, setState] = useState<boolean>(defaultState)

    const open = () => {
        console.log('open', open);
        setState(true)
    }

    const close = () => {
        setState(false)
    }


    return { state, open, close }
}

export default useCustomModal
