import { useNavigate } from "react-router-dom"

interface GoToOptions {
    replace?: boolean
}

const useGoTo = () => {
    const navigate = useNavigate()

    // contracts

    const goToContract = (id?: number | string, options: GoToOptions = { replace: true }) => {
        const { replace } = options
        if (id) {
            navigate(`/contract/${id}`, { replace })
        }
    }

    const goToContracts = () => {
        navigate(`/contracts/`)
    }

    // work permits

    const goToWorkPermit = (id?: number | string, options: GoToOptions = { replace: true }) => {
        const { replace } = options
        if (id) {
            navigate(`/work-permit/${id}`, { replace })
        }
    }

    const goToWorkPermits = () => {
        navigate(`/work-permits/`)
    }

    // interventions

    const goToIntervention = (id?: number | string, options: GoToOptions = { replace: true }) => {
        const { replace } = options
        if (id) {
            navigate(`/intervention/${id}`, { replace })
        }
    }

    const goToInterventions = () => {
        navigate(`/interventions/`)
    }

    // survey records

    const goToSurveyRecords = () => {
        navigate(`/survey-records/`)
    }

    const goToSurveyRecord = (id?: number | string, options: GoToOptions = { replace: true }) => {
        const { replace } = options
        navigate(`/survey-record/${id}`, { replace })
    }

    // accreditations
    const goToAccreditation = (id?: number | string, options: GoToOptions = { replace: false }) => {
        const { replace } = options
        navigate(`/accreditation/${id}`, { replace })
    }


    return {
        goToIntervention,
        goToInterventions,
        goToWorkPermit,
        goToWorkPermits,
        goToContract,
        goToContracts,
        goToSurveyRecords,
        goToSurveyRecord,
        goToAccreditation,
        navigate
    }
}

export default useGoTo
