export enum DocumentPolicyScopes {
    CONCESSION,
    EXECUTING,
    SUBCONTRACT
}

export enum DocumentScopes {
    CONCESSION,
    EXECUTING,
    SUBCONTRACT
}

const isAllowed = (policy: string, position: number) => {
    return policy.charAt(position) === "1"
}

const policyToRoles = (policy: string,
    scopes: DocumentScopes[] = [
        DocumentScopes.CONCESSION,
        DocumentScopes.EXECUTING,
        DocumentScopes.SUBCONTRACT]) => {
    const rows = [
        { role: "Gestor Contrato", allowed: isAllowed(policy, 0), scope: DocumentScopes.CONCESSION },
        { role: "Gestor Obra", allowed: isAllowed(policy, 1), scope: DocumentScopes.CONCESSION },
        { role: "Técnico Segurança", allowed: isAllowed(policy, 2), scope: DocumentScopes.CONCESSION },
        { role: "Responsável Contrato EE", allowed: isAllowed(policy, 3), scope: DocumentScopes.CONCESSION },
        { role: "Gestor Obra EE", allowed: isAllowed(policy, 4), scope: DocumentScopes.EXECUTING },
        { role: "Técnico Segurança EE", allowed: isAllowed(policy, 5), scope: DocumentScopes.EXECUTING },
        { role: "Administrativo EE", allowed: isAllowed(policy, 6), scope: DocumentScopes.EXECUTING },
        { role: "Responsável Subcontrato", allowed: isAllowed(policy, 7), scope: DocumentScopes.SUBCONTRACT }
    ]
    return rows.filter(row => scopes.includes(row.scope))
}

export default policyToRoles
