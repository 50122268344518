import { Fade, Grid } from "@mui/material";
import { useRef } from "react";
import { useSurveyRecordQuery } from "../../generated/graphql";
import useGoTo from "../../hooks/useGoto";
import useParamId from "../../hooks/useParamId";
import usePermission from "../../hooks/usePermission";
import { Actions, Subjects } from "../../services/ability";
import { useAuth } from "../../services/auth";
import ActionsBar from "../ActionsBar/ActionsBar";
import BackButton from "../BackButton/BackButton";
import RecordNotFound from "../RecordNotFound/RecordNotFound";
import SaveIconButton from "../SaveIconButton/SaveIconButton";
import Show from "../Show/Show";
import SurveyRecordForm from "../SurveyRecordForm/SurveyRecordForm";
import SurveyRecordPhotos from "../SurveyRecordPhotos/SurveyRecordPhotos";
import SurveyRecordVerifications from "../SurveyRecordVerifications/SurveyRecordVerifications";
import styles from './SurveyRecordView.module.scss';
import SurveyRecordViewMoreActions from "./SurveyRecordViewMoreActions";

const SurveyRecordView = () => {
    const id = useParamId()
    const { data, loading, refetch } = useSurveyRecordQuery({ variables: { id } })
    const action = data?.surveyRecord?.id ? Actions.UPDATE : Actions.CREATE
    const allowed = usePermission(action, Subjects.SURVEYRECORD)
    const { goToSurveyRecords } = useGoTo()
    const auth = useAuth()
    const formRef = useRef()

    const handleSubmit = () => {
        if (formRef?.current) {
            // @ts-ignore
            return formRef?.current?.handleSubmit()
        }
    }

    const handleSubmitAndClose = () => {
        handleSubmit()
        goToSurveyRecords()
    }

    const isAuthor = data?.surveyRecord?.user?.id === auth?.user?.id
    const isEdit = action === Actions.UPDATE
    const isNotUserEdit = isEdit && !isAuthor

    if (id && !loading && !data?.surveyRecord) {
        return <RecordNotFound message="Erro! Registo visita não encontrado." />
    }

    return (
        <Fade in={!loading} timeout={500}>
            <div className={styles.Wrapper}>

                <ActionsBar variant="DETAIL">
                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                            <BackButton />
                        </Grid>
                        <Grid item xs={10} container justifyContent="flex-end" alignContent="center">
                            <SaveIconButton onClick={handleSubmit} />
                            <SurveyRecordViewMoreActions surveyRecord={data?.surveyRecord} refetch={refetch} />
                        </Grid>
                    </Grid>
                </ActionsBar>

                <Grid container direction="column" className={styles.Content}>
                    <Grid container>
                        <Grid item xs={9}>
                            <SurveyRecordForm formRef={formRef} surveyRecord={data?.surveyRecord} isNotUserEdit={isNotUserEdit} allowed={allowed} />
                        </Grid>
                        <Grid item xs={2} container>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Show on={Boolean(id)}>
                            <SurveyRecordVerifications surveyRecord={data?.surveyRecord} refetch={refetch} isNotUserEdit={isNotUserEdit} allowed={allowed} />
                        </Show>
                    </Grid>


                    <Grid container>
                        <Show on={Boolean(id)}>
                            <SurveyRecordPhotos surveyRecord={data?.surveyRecord} refetch={refetch} isNotUserEdit={isNotUserEdit} allowed={allowed} />
                        </Show>
                    </Grid>
                </Grid>
            </div >
        </Fade>
    )
}

export default SurveyRecordView
