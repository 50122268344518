import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import InterventionStateFilter from "../InterventionStateFilter/InterventionStateFilter";
import Search from "../Search/Search";
import WeekFilter from "../WeekFilter/WeekFilter";
import WorkPermitFilter from "../WorkPermitFilter/WorkPermitFilter";
import YearFilter from "../YearFilter/YearFilter";
import styles from './InterventionsFilters.module.scss';

const InterventionsFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.InterventionsFilters}>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <YearFilter value={context.filters.year} onChange={context.setFilter} />
                <WeekFilter value={context.filters.week} onChange={context.setFilter} />
                <WorkPermitFilter value={context.filters.workPermit} onChange={context.setFilter} />
                <InterventionStateFilter value={context.filters.state} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default InterventionsFilters;
