import { Download } from "@mui/icons-material";
import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";

interface DownloadIconButtonProps {
    onClick: (event: any) => void
    disabled?: boolean
    title?: string
}

export default function DownloadIconButton(props: DownloadIconButtonProps) {
    const { onClick,
        disabled = false,
        title = "descarregar"
    } = props

    return (
        <IconButtonTooltip title={title} disabled={disabled} onClick={onClick}>
            <Download />
        </IconButtonTooltip>
    )
}
