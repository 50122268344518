import { Fade } from '@mui/material'
import Config from '../../services/config'
import Empty from '../Empty/Empty'
import styles from './RecordNotFound.module.scss'

interface RecordNotFoundProps {
    message?: string
}

export default function RecordNotFound(props: RecordNotFoundProps) {
    const { message = "Erro! Registo não encontrado." } = props
    return (
        <Fade in={true} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.Wrapper}>
                <Empty message={message} variant="h3" />
            </div>
        </Fade >
    )
}
