import { useSnackbar } from "notistack";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { StringParam, useQueryParam } from 'use-query-params';
import { FilterConfig, useFilters } from "../../hooks/useFilters";
import useToast from "../../hooks/useToast";
import Config from "../../services/config";
import { breakCamelCase, convertArrayToObject, diffObjects, firstProperty, noop, orderColDefs, _get } from "../../services/utils";
import { useWorkspace } from "../../services/workspace";
import { queryBuilder } from "../QueryBuilder/QueryBuilder";

export const DataViewContext = createContext<any>(undefined)

interface DataViewProviderProps {
    children: ReactNode
    datasource: { createFunc: any, readFunc: any, updateFunc: any, deleteFunc: any, deleteManyFunc?: any, updateManyFunc?: any }
    filterConfig: FilterConfig[]
}

interface DataViewOptions {
    successMessage?: string
    errorMessage?: string
}

const DataViewProvider: FC<DataViewProviderProps> = props => {
    const { children, datasource, filterConfig } = props

    const [filters, setFilter, reset] = useFilters(filterConfig)
    const [queryPage] = useQueryParam('page', StringParam)
    const [queryPageSize] = useQueryParam('pageSize', StringParam)

    const [paginationModel, setPaginationModel] = useState({
        page: queryPage ? +queryPage : 0,
        pageSize: queryPageSize ? +queryPageSize : Config.PAGE_SIZE_OPTIONS[0]
    });

    const [columOrderModel] = useState([])
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
    const [columnDefs, setColumnDefs] = useState([])
    const [columns, setColumns] = useState<any>([])
    const [view, setView] = useState<any>()
    const [sortModel, setSortModel] = useState();

    const params = queryBuilder(filterConfig, filters, paginationModel, sortModel)


    const { deleteManyFunc = noop, updateManyFunc = noop } = datasource

    const { data, loading, refetch } = datasource.readFunc({ ...params })
    const [createOne] = datasource.createFunc()
    const [updateOne] = datasource.updateFunc()
    const [deleteOne] = datasource.deleteFunc()
    const [deleteMany] = deleteManyFunc()
    const [updateMany] = updateManyFunc()
    const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const workspace = useWorkspace()
    const { errorToast } = useToast()


    useEffect(() => {
        refetch()
    }, [params, refetch]);

    // @todo - melhorar esta função para lidar com mensagens de erro
    const getMessageComponents = (response: any) => {
        const endpoint = firstProperty(response.data)
        if (!endpoint) return {}
        const parts = breakCamelCase(endpoint)
        const action = parts[0]
        const subject = parts[2]
        return { action, subject }
    }

    const messageBuilder = (response: any) => {
        const { action, subject } = getMessageComponents(response)
        return [subject, action].join(" ")
    }

    const getMessage = (response: any) => {
        return messageBuilder(response)
    }

    const handle = (response: any, status: "success" | "error", message?: string) => {
        if (status === "error") {
            enqueueSnackbar(message, { variant: status });
        } else {
            const successMessage = message ? message : getMessage(response)
            enqueueSnackbar(successMessage, { variant: status });
            refetch()
        }
    }

    const handleError = (response: any, options: DataViewOptions = {}) => {
        const { errorMessage = "Erro ao efetuar pedido!" } = options
        handle(response, "error", errorMessage)
    }

    const handleResponse = (response: any, options: DataViewOptions = {}) => {
        const { successMessage = "Sucesso" } = options
        handle(response, "success", successMessage)
    }

    const doCreate = (data: any, options?: DataViewOptions) => {
        return createOne(data).then((response: any) => handleResponse(response, options)).catch((response: any) => handleError(response, options))
    }

    const doUpdate = (data: any, options?: DataViewOptions) => {
        return updateOne(data).then((response: any) => handleResponse(response, options)).catch((response: any) => handleError(response, options))
    }

    const doDelete = (data: any) => {
        return deleteOne(data).then(handleResponse).catch(handleError)
    }

    const doDeleteMany = (data: any) => {
        if (!deleteMany) {
            errorToast("Delete many is not defined")
        } else {
            return deleteMany(data).then(handleResponse).catch(handleError)
        }
    }

    const doUpdateMany = (data: any) => {
        if (!updateMany) {
            errorToast("Update many is not defined")
        } else {
            return updateMany(data).then(handleResponse).catch(handleError)
        }
    }


    const clearSelection = () => {
        setRowSelectionModel([])
    }

    const name = firstProperty(data)
    const totalCount = _get(data, `${name}.totalCount`)

    const findView = () => {
        return workspace?.data?.workspace?.workspaceViews.find(
            (workspaceView: any) => workspaceView.name === view)
    }

    const checkView = () => {
        if (!view) errorToast("Vista não configurada")
    }

    const setWorkspaceView = async () => {
        if (view && columnDefs?.length) {
            const columns = columnDefs?.map(colDef => {
                const { field, width } = colDef
                return { name: field, size: width, visible: true }
            })
            const response = await workspace?.setWorkspaceView({ variables: { input: { view, columns } } })
            if (!response?.data?.setWorkspaceView) errorToast("Erro ao inicializar workspace.")
            await workspace?.refetch()
        }
    }

    useEffect(() => {
        setWorkspaceView()
    }, [view, columnDefs])

    useEffect(() => {
        const view = findView()
        if (view) {
            const orderedColumns = orderColDefs(columnDefs, view?.workspaceViewColumns)
            setColumns(orderedColumns)
            const newVisibilityModel = convertArrayToObject(view?.workspaceViewColumns)
            // @ts-ignore
            setColumnVisibilityModel(newVisibilityModel)
        }
    }, [workspace?.data, columnDefs]);

    // lida com ordenação
    const handleSetColumnOrderModel = async (newModel: any) => {
        checkView()
        const { oldIndex, targetIndex } = newModel
        await workspace?.manage({ variables: { input: { view, oldIndex, targetIndex } } })
        await workspace?.refetch()
    }

    const handleSetColumnVisibility = async (newModel: any) => {
        checkView()
        const diffCol = diffObjects(columnVisibilityModel, newModel)
        const column = Object.keys(diffCol)[0]
        await workspace?.manage({ variables: { input: { view, column, visible: newModel[column] } } })
        await workspace?.refetch()
    }

    const doSetPaginationModel = (model: any) => {
        // @ts-ignore
        setFilter("page", model.page)
        // @ts-ignore
        setFilter("pageSize", model.pageSize)
        setPaginationModel(model)
    }

    return (
        <DataViewContext.Provider value={{
            filters,
            setFilter,
            data,
            loading,
            create: doCreate,
            update: doUpdate,
            delete: doDelete,
            refetch,
            rowSelectionModel,
            setRowSelectionModel,
            clearSelection,
            paginationModel,
            setPaginationModel: doSetPaginationModel,
            totalCount,
            setSortModel,
            columOrderModel,
            setColumnOrderModel: handleSetColumnOrderModel,
            setColumnDefs,
            columns,
            setView,
            columnVisibilityModel,
            setColumnVisibilityModel: handleSetColumnVisibility,
            deleteMany: doDeleteMany,
            updateMany: doUpdateMany,
            resetFilters: reset
        }} >
            {children}
        </DataViewContext.Provider >
    )
}

export default DataViewProvider
