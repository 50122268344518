import { Typography } from "@mui/material"
import { ImportValidationErrorOutput } from "../../generated/graphql"
import styles from './ShowInterventionImportErrors.module.scss'

interface ShowInterventionImportErrorsProps {
    errors: ImportValidationErrorOutput[]
}

const getErrorMessage = (error: ImportValidationErrorOutput): string => {
    if (error.code === "DOCUMENT_ERROR") {
        return `Erro na linha ${error?.address} ${error?.value}.`
    }
    if (error.code === "ROW_ERROR") {
        return `Erro na linha ${error?.address} ${error?.value}.`
    }
    return `Erro na célula ${error?.address} valor ${error?.value} não é válido.`
}

export default function ShowInterventionImportErrors(props: ShowInterventionImportErrorsProps) {
    const { errors } = props

    if (!errors) {
        return null
    }

    return (
        <div className={styles.Wrapper}>
            {
                errors.map(error => (
                    <Typography variant="body1" color="error">
                        {getErrorMessage(error)}
                    </Typography>
                ))
            }
        </div>
    )
}
