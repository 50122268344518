import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import { useHasInternalRole } from "../../hooks/useHasRole";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ContractFilter from "../ContractFilter/ContractFilter";
import CorrectiveRequestsFilter from "../CorrectiveRequestsFilter/CorrectiveRequests";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import RoadFilter from "../RoadFilter/RoadFilter";
import Search from "../Search/Search";
import Show from "../Show/Show";
import SurveyRecordAuthorFilter from "../SurveyRecordAuthorFilter/SurveyRecordAuthorFilter";
import SurveyRecordIsPublishedFilter from "../SurveyRecordIsPublishedFilter/SurveyRecordIsPublishedFilter";
import SurveyRecordStateFilter from "../SurveyRecordStateFilter/SurveyRecordStateFilter";
import styles from './SurveyRecordsFilters.module.scss';

const SurveyRecordsFilters = () => {
    const context = useContext(DataViewContext)
    const isInternalUser = useHasInternalRole()
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.SurveyRecordsFilters}>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <ContractFilter value={context.filters.contract} onChange={context.setFilter} />
                <RoadFilter value={context.filters.road} onChange={context.setFilter} />
                <SurveyRecordStateFilter value={context.filters.state} onChange={context.setFilter} />
                <Show on={isInternalUser}>
                    <SurveyRecordIsPublishedFilter value={context.filters.isPublished} onChange={context.setFilter} />
                </Show>
                <CorrectiveRequestsFilter value={context.filters.hasCorrectiveRequests} onChange={context.setFilter} />
                <SurveyRecordAuthorFilter value={context.filters.user} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default SurveyRecordsFilters
