import { Add, Close, Delete, DownloadForOffline, Label, Publish, Schedule, SwapVert } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC, useContext, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useHasInternalRole } from '../../hooks/useHasRole';
import useToast from '../../hooks/useToast';
import { acceptedFileTypes } from '../../services/config';
import { truncate } from '../../services/utils';
import ActionsBar from '../ActionsBar/ActionsBar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import ContractDocumentCategoryFilter from '../ContractDocumentCategoryFilter/ContractDocumentCategoryFilter';
import ContractDocumentSubcategoryFilter from '../ContractDocumentSubcategoryFilter/ContractDocumentSubcategoryFilter';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import DocumentStateFilter from '../DocumentStateFilter/DocumentStateFilter';
import DocumentTemplatesPopover from '../DocumentTemplatesPopover/DocumentTemplatesPopover';
import Hide from '../Hide/Hide';
import PopAction from '../PopAction/PopAction';
import Show from '../Show/Show';
import styles from './ContractDocumentsActions.module.scss';

interface ContractDocumentsActionsProps {
    contractId: string
}

const ContractDocumentsActions: FC<ContractDocumentsActionsProps> = props => {

    const { contractId } = props
    const context = useContext(DataViewContext)
    const selection = context?.rowSelectionModel
    const { errorToast } = useToast()
    const hasInternalRole = useHasInternalRole()

    // pop actions
    const [popActionSchedule, setPopActionSchedule] = useState<boolean>(false)
    const [popActionSubcategory, setPopActionSubcategory] = useState<boolean>(false)
    const [popActionState, setPopActionState] = useState<boolean>(false)
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const [category, setCategory] = useState<any>([])

    const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        for await (let acceptedFile of acceptedFiles) {
            const name = acceptedFile.name
            const displayName = truncate(name)
            const options = {
                successMessage: `Documento ${displayName} adicionado com sucesso`,
                errorMessage: `Erro ao adicionar documento ${displayName}`
            }
            await context.create({ variables: { input: { contractDocument: { contract: contractId, name, path: acceptedFile } } } }, options)
        }

        for await (let rejectedFile of rejectedFiles) {
            errorToast(`Erro ao carregar ${rejectedFile?.file?.name}. Tipos aceites pdf, jpg, png e gif.`)
        }
    }

    const { getRootProps } = useDropzone({ onDrop, maxFiles: 0, multiple: true, accept: acceptedFileTypes })

    const handleDelete = async () => {
        if (inPreparation()) {
            return context.deleteMany({ variables: { input: { filter: { id: { in: selection } } } } })
        } else {
            errorToast("Erro! Apenas documentos em preparação podem ser removidos")
        }
    }

    const inPreparation = () => {
        const rows = context?.data?.contractDocuments?.nodes
        const invalid = rows.filter((row: any) => selection.includes(row?.id) && +row?.documentState?.id !== 1)
        return !invalid?.length
    }

    const hasNoRequiredExpiration = (row: any) => {
        return row?.contractDocumentSubcategory?.expirationRequired && !row.expiresAt
    }

    const isExpired = (row: any) => {
        return row?.expiresAt && row?.expiresAt < new Date()
    }

    const isSelected = (row: any) => {
        return selection.includes(row?.id)
    }

    const expirationRequired = () => {
        const rows = context?.data?.contractDocuments?.nodes
        const invalid = rows.filter((row: any) => {
            return isSelected(row) && (hasNoRequiredExpiration(row) || isExpired(row))
        })
        return !invalid?.length
    }

    const isCategorized = () => {
        const rows = context?.data?.contractDocuments?.nodes
        const invalid = rows.filter((row: any) => selection.includes(row?.id) && !row?.contractDocumentSubcategory)
        return !invalid?.length
    }

    const handleSubmit = async () => {
        if (!inPreparation()) {
            errorToast("Erro! Verifique estado do documento")
        } else if (!isCategorized()) {
            errorToast("Não é possível submeter documentos não categorizados")
        } else if (!expirationRequired()) {
            errorToast("Erro! Data expiração em falta ou ultrapassada")
        } else {
            const documentState = 2
            await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { documentState } } } })
            context?.setRowSelectionModel([])
        }
    }

    const handleCategory = async (_: any, value: any) => {
        setCategory([value])
    }

    const handleSubcategory = async (_: any, value: any) => {
        if (inPreparation() || hasInternalRole) {
            const contractDocumentSubcategory = value ? +value : null
            await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { contractDocumentSubcategory } } } })
        } else {
            errorToast("Erro! Apenas é possível atualizar subcategoria de documentos em preparação")
        }
        context?.setRowSelectionModel([])
        setPopActionSubcategory(false)
        setCategory([])
    }

    const handleState = async (_: any, value: any) => {
        const documentState = value ? +value : null
        await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { documentState } } } })
        context?.setRowSelectionModel([])
        setPopActionState(false)
    }

    const handleExpiration = async (value: any) => {
        if (inPreparation() || hasInternalRole) {
            const expiresAt = value
            await context.updateMany({ variables: { input: { filter: { id: { in: selection } }, update: { expiresAt } } } })
        } else {
            errorToast("Erro! Apenas é possível atualizar data de expiração de documentos em preparação")
        }
        await context?.refetch()
        context?.setRowSelectionModel([])
        setPopActionSchedule(false)
    }

    const blockActions = +context?.data?.contractDocuments?.nodes[0]?.contract?.contractState?.id > 2 || false
    const addProps = blockActions ? {} : getRootProps()
    const hasSelection = selection?.length

    return (
        <ActionsBar>

            <Grid container justifyContent="flex-between" alignContent="center" className={styles.Wrapper}>
                <Grid item xs={6} container alignContent="center">
                    <Typography variant="h3" color="primary">Documentos Contratos</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">

                    {/* <ActionsBarSeparator /> */}

                    <Hide on={hasInternalRole || !hasSelection}>
                        <Tooltip title="Submeter">
                            <span>
                                <IconButton onClick={handleSubmit} disabled={!selection?.length || blockActions}>
                                    <Publish />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Hide>

                    <Show on={hasInternalRole && hasSelection && !blockActions}>
                        <Tooltip title="Atualizar estado">
                            <div>
                                <PopAction name="state" icon={<SwapVert />} open={popActionState} setOpen={setPopActionState}>
                                    <div>
                                        <DocumentStateFilter value={[]} onChange={handleState} hideAllOption={true} />
                                    </div>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>

                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Atualizar data expiração">
                            <div>
                                <PopAction name="schedule" icon={<Schedule />} open={popActionSchedule} setOpen={setPopActionSchedule}>
                                    <Grid container style={{ width: 350, padding: 10 }} justifyContent="space-between" alignItems="center">
                                        <MuiDatePicker
                                            slotProps={{ textField: { variant: "standard", inputProps: { disabled: true, placeholder: "Clicar ícone selecionar data" } } }}
                                            value={null}
                                            onChange={handleExpiration}
                                            minDate={new Date()}
                                        />
                                        <div style={{ marginRight: 10 }}>
                                            <Tooltip title="Remover Data Expiração">
                                                <ConfirmButton onClick={() => handleExpiration(null)} message="Tem a certeza que deseja remover data de expiração? ">
                                                    <Close />
                                                </ConfirmButton>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>

                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Atualizar subcategoria">
                            <div>
                                <PopAction name="subcategory" icon={<Label />} open={popActionSubcategory} setOpen={setPopActionSubcategory}>
                                    <Grid container alignItems="center">
                                        <ContractDocumentCategoryFilter value={category} onChange={handleCategory} hideAllOption />
                                        <ContractDocumentSubcategoryFilter value={[]} parent={category} onChange={handleSubcategory} hideAllOption />
                                        <div style={{ marginRight: 10, marginTop: 10 }}>
                                            <Tooltip title="Remover subcategoria">
                                                <IconButton onClick={() => handleSubcategory(undefined, null)}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                </PopAction>
                            </div>
                        </Tooltip>
                    </Show>

                    {/* <ActionsBarSeparator /> */}

                    <Show on={hasSelection && !blockActions}>
                        <Tooltip title="Remover">
                            <span>
                                <ConfirmButton onClick={handleDelete}>
                                    <Delete />
                                </ConfirmButton>
                            </span>
                        </Tooltip>
                    </Show>

                    <Show on={!blockActions}>
                        <Tooltip title="Adicionar">
                            <span {...addProps}>
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Show>

                    <Tooltip title="Descarregar Modelos" disableHoverListener={Boolean(anchor)}>
                        <span>
                            <IconButton onClick={(event: any) => setAnchor(event.currentTarget)}>
                                <DownloadForOffline />
                            </IconButton>
                            <DocumentTemplatesPopover anchor={anchor} setAnchor={setAnchor} />
                        </span>
                    </Tooltip>

                </Grid>
            </Grid>
        </ActionsBar>
    )
}

export default ContractDocumentsActions
