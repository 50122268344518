import { TextFieldProps } from '@mui/material';
import { FastField } from 'formik';
import { FC } from 'react';
import { useRoadsQuery } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import ComboField from '../ComboField/ComboField';

interface ContractRoadsFieldProps {
    name?: string
    label?: string
    emptyMessage?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
}


const ContractRoadsField: FC<ContractRoadsFieldProps> = props => {
    const {
        name = "contractRoads",
        label = "Selecionar vias",
        emptyMessage = "Sem vias selecionadas",
        textFieldProps,
        disabled
    } = props

    const concession = useConcession()

    const handleRemoveElement = (element: any, value: any) => {
        if (element?.road) {
            return element?.road?.id != value.id
        } else {
            return element?.id != value.id
        }
    }

    return (
        <FastField
            name={name}
            component={ComboField}
            label={label}
            emptyMessage={emptyMessage}
            query={useRoadsQuery}
            handleRemoveElement={handleRemoveElement}
            rowOptions={{ path: "road" }}
            disabled={disabled}
            filter={{ concession: { id: { eq: concession?.id } } }}
        />
    )
}


export default ContractRoadsField;
