import { WorkPermitStates } from "../../generated/graphql";
import { Actions } from "../../services/ability";

/**
 * retorna true se a permissão estiver a ser criado ou se o estado
* do contrato for preparação
 */
const creatingOrPreparation = (params: any) => {
    const { state, action } = params
    if (action === Actions.CREATE || state === WorkPermitStates.Preparation) return true
    return false
}

/**
 * retorna true se a permissão estiver a ser criado ou se o estado
* do contrato for preparação
 */
const creatingOrReviewing = (params: any) => {
    const { state, action } = params
    if (action === Actions.CREATE || state === WorkPermitStates.Preparation || state === WorkPermitStates.Review) return true
    return false
}

const approvedOrExpired = (params: any) => {
    const { state } = params
    return state === WorkPermitStates.Approved || state === WorkPermitStates.Expired
}

export const workPermitFieldRules = {
    contract: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrPreparation(params) ? false : true
        },
    },
    startAt: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrReviewing(params) ? false : true
        },
    },
    endAt: {
        shouldBeDisabled: (params: any): boolean => {
            return creatingOrReviewing(params) ? false : true
        },
    },
    observations: {
        shouldBeDisabled: (params: any): boolean => {
            return params.isInternalUser ? false : approvedOrExpired(params)
        }
    }
};
