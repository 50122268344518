import { Typography } from "@mui/material";
import { endOfWeekOfWeekWithYear, formatDate, startOfWeekOfWeekWithYear } from "../../utils/dates";

interface WeekBoundariesDisplayProps {
    week: number
    year: number
}

export default function WeekBoundariesDisplay(props: WeekBoundariesDisplayProps) {
    const { week, year } = props
    const start = formatDate(startOfWeekOfWeekWithYear({ year, week }))
    const end = formatDate(endOfWeekOfWeekWithYear({ year, week }))

    return (
        <Typography variant="body1" color="secondary">
            Semana {week} {year} - <b>de {start} a {end}</b>
        </Typography>
    )
}
