import { Card, CardContent, Typography } from "@mui/material";
import { clsx } from "clsx";
import { createElement, FC } from "react";
import Config from "../../services/config";
import styles from './SettingsView.module.scss';

interface SettingsRecordsProps {
    records: any
    selected: any
    onSelect: any
    viewer: any
    selectedEntity: any
}


interface SettingRecordProps {
    record: any
    onSelect: any
    classes: any
}

const SettingRecord: FC<SettingRecordProps> = props => {
    const { record, onSelect, classes } = props
    return (
        <Typography variant="h5" color="secondary" key={record.id} onClick={() => onSelect(record)} className={classes}>
            {record.name}
        </Typography>
    )
}

export const SettingRecordUser = (props: any) => {
    const { record, onSelect, classes } = props

    // se o utilizador não está associado à concessão é ignorado
    if (!record?.concessionUsers?.length) return null

    return (
        <div onClick={() => onSelect(record)} style={{ marginBottom: 25 }}>
            <Typography variant="h5" color="primary" className={classes} style={{ marginBlockEnd: 0 }}>
                {record.name}
            </Typography>
            <Typography variant="body1" color="secondary" style={{ cursor: "pointer" }}>
                {record?.email}
            </Typography>
            {/* <Typography variant="body1" color="secondary" style={{ cursor: "pointer" }}>
                xxx {record?.concessionUsers?.length}
		</Typography> */}
        </div>
    )
}

export const SettingRecordOrganization = (props: any) => {
    const { record, onSelect, classes } = props

    // se a organização não está associada à concessão é ignorada
    if (!record?.concessionOrganizations?.length) return null

    return (
        <div onClick={() => onSelect(record)} style={{ marginBottom: 25 }}>
            <Typography variant="h5" color="primary" className={classes} style={{ marginBlockEnd: 0 }}>
                {record?.name}
            </Typography>
            <Typography variant="body1" color="secondary" style={{ cursor: "pointer" }}>
                {record?.tin}
            </Typography>
            {/* <Typography variant="body1" color="secondary" style={{ cursor: "pointer" }}>
                {record?.concessionOrganizations?.length}
		</Typography> */}
        </div>
    )
}




// vamos usar o usestate para guardar qual
// o registo selecionado, ou se nenhum está selecionado
// temos null
const SettingsRecords: FC<SettingsRecordsProps> = props => {
    const { records, selected, onSelect, viewer, selectedEntity } = props

    /* if (viewer) {
     *     return createElement(viewer, { records, selected, onSelect })
     * } */

    return (
        <Card id={styles.SettingsRecords} className={styles.SettingsRecords}>
            <CardContent>
                <Typography variant="h4" color="primary">{selectedEntity.name}</Typography>
                {!records?.length && <Typography variant="h6" color="secondary">{Config.NO_OPTIONS_TEXT}</Typography>}
                {records?.map((record: any) => {
                    const classes = clsx(record?.id === selected?.id ? styles.Selected : null)
                    return (
                        viewer ? createElement(viewer, { record, onSelect, classes }) : <SettingRecord record={record} onSelect={onSelect} classes={classes} />
                    )
                }
                )}
            </CardContent>
        </Card>
    )
}

export default SettingsRecords
