import { InterventionSortFields, SortDirection, useInterventionsQuery } from "../../generated/graphql"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

export default function InterventionFilter(props: FilterProps) {
    const { value, onChange, width = 150 } = props
    const query = useInterventionsQuery
    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            query={query}
            multiple={true}
            field="intervention"
            label="Intervenção"
            sorting={[
                { field: InterventionSortFields.Id, direction: SortDirection.Asc }
            ]}
            width={width}
            paging={{ limit: 999 }}
        />
    )
}
