import { useCreateOneSurveyRecordVerificationClassificationMutation, useDeleteOneSurveyRecordVerificationClassificationMutation, useSurveyRecordVerificationClassificationsQuery, useUpdateOneSurveyRecordVerificationClassificationMutation } from "../../generated/graphql"

export default function useSurveyRecordVerificationClassificationStore() {
    const query = useSurveyRecordVerificationClassificationsQuery
    const name = "surveyRecordVerificationClassification"
    const [create] = useCreateOneSurveyRecordVerificationClassificationMutation()
    const [update] = useUpdateOneSurveyRecordVerificationClassificationMutation()
    const [remove] = useDeleteOneSurveyRecordVerificationClassificationMutation()
    return { query, name, create, update, remove }
}
