import { Button, Grid, Popover, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import useAccreditationCollectionManager from "../../hooks/useAccreditationCollectionManager"
import useGoTo from "../../hooks/useGoto"
import { Anchor, SetAnchor } from "../../types/types"
import { FormatDate } from "../FormatDate/FormatDate"
import LinkedName from "../LinkedName/LinkedName"
import Show from "../Show/Show"
import styles from './AccreditationDocumentsImportPopover.module.scss'

interface AccreditationDocumentsImportPopoverProps {
    anchor: Anchor
    setAnchor: SetAnchor
    concessionOrganizationId?: string
    concessionOrganizationAccreditationTypeId?: string
    handleImport: (originAccreditationId: string) => void
}

export default function AccreditationDocumentsImportPopover(props: AccreditationDocumentsImportPopoverProps) {
    const {
        anchor,
        setAnchor,
        concessionOrganizationId,
        concessionOrganizationAccreditationTypeId,
        handleImport
    } = props

    const { accreditations } = useAccreditationCollectionManager({ concessionOrganizationId, concessionOrganizationAccreditationTypeId })
    const { goToAccreditation } = useGoTo()

    const popoverOpen = Boolean(anchor)
    const id = popoverOpen ? 'popover' : undefined

    const handleGoToAccreditation = (accreditationId: string) => {
        setAnchor(null)
        goToAccreditation(accreditationId)
    }

    return (
        <div className={styles.Wrapper}>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.Wrapper}>
                    <Table>
                        <TableBody>

                            <Show on={!accreditations?.length}>
                                <div className={styles.NoImports}>
                                    <Typography variant="body1">
                                        Atualmente, não existem <b>acreditações aprovadas</b> a partir das quais se possa importar documentos
                                    </Typography>
                                </div>
                            </Show>

                            {accreditations?.map(accreditation => (
                                <TableRow key={accreditation?.id}>
                                    <TableCell width="350">
                                        <p>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <LinkedName
                                                    onClick={() => handleGoToAccreditation(accreditation?.id)}
                                                    name={accreditation?.name}
                                                />

                                                <Button variant="outlined" size="small" onClick={() => handleImport(accreditation?.id)}>
                                                    Importar
                                                </Button>
                                            </Grid>
                                        </p>
                                        <p><b>Inicio:</b> <FormatDate date={accreditation?.startDate} /> <b>Fim:</b> <FormatDate date={accreditation?.endDate} /></p>
                                        <p>{accreditation?.concessionOrganizationAccreditationState?.name}</p>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Popover >
        </div >
    )
}
