import { Field } from 'formik';
import { FC } from 'react';
import { useProjectingOrganizationsFieldQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import ContractConcessionOrganizationAutocomplete from '../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete';

interface ProjectingOrganizationFieldProps {
    disabled?: boolean
}

const ProjectingOrganizationField: FC<ProjectingOrganizationFieldProps> = props => {
    const { disabled } = props
    const concession = useConcession()
    const { data } = useProjectingOrganizationsFieldQuery({ fetchPolicy: "no-cache", variables: { concession: concession?.id, paging: { limit: 999 } } })

    const name = "contractConcessionOrganizations"
    const label = "Entidade Projetista"
    const options = data?.concessionOrganizations?.nodes || []

    return (
        <Field
            name={name}
            component={ContractConcessionOrganizationAutocomplete}
            options={options}
            label={label}
            disabled={disabled}
            organizationType={4}
            required={false}
        />
    )
}

export default ProjectingOrganizationField
