import { ApolloError } from "@apollo/client";
import { ContractConcessionOrganizationFilter, ContractConcessionOrganizationSort, ContractConcessionOrganizationSortFields, OffsetPaging, SortDirection, useSubcontractsQuery } from "../generated/graphql";
import Config from "../services/config";
import { OperationResult, Subcontract } from "../types/types";
import useSubcontractManager, { CreateSubcontractData, SubcontractManager, UpdateSubcontractData } from "./useSubcontractManager";

export interface SubcontractDataManagerOptions {
    contractId?: string
}

export interface SubcontractDataManager extends SubcontractManager {
    subcontracts?: Partial<any>[]
    subcontractsLoading: boolean
    subcontractsError: ApolloError | undefined
    refetch: any
}

export default function useDataSubcontractManager(opts?: SubcontractDataManagerOptions): SubcontractDataManager {

    const { contractId = "" } = opts || {}

    const filter: ContractConcessionOrganizationFilter = { contract: { id: { eq: contractId } } }
    const paging: OffsetPaging = { limit: Config.MAX_RESULTS_SIZE }
    const sorting: ContractConcessionOrganizationSort = { field: ContractConcessionOrganizationSortFields.Id, direction: SortDirection.Asc }

    const { data, loading, error, refetch } = useSubcontractsQuery({ variables: { filter, paging, sorting } })
    const { createSubcontract, updateSubcontract, deleteSubcontract } = useSubcontractManager()

    const _createSubcontract = async (data: CreateSubcontractData): Promise<OperationResult<Subcontract>> => {
        const result = await createSubcontract(data)
        if (result.success) refetch()
        return result
    }

    const _updateSubcontract = async (data: UpdateSubcontractData): Promise<OperationResult<Subcontract>> => {
        const result = await updateSubcontract(data)
        if (result.success) refetch()
        return result
    }

    const _deleteSubcontract = async (id: string): Promise<OperationResult<Subcontract>> => {
        const result = await deleteSubcontract(id)
        if (result.success) refetch()
        return result
    }

    return {
        subcontracts: data?.subcontracts?.nodes,
        subcontractsLoading: loading,
        subcontractsError: error,
        createSubcontract: _createSubcontract,
        updateSubcontract: _updateSubcontract,
        deleteSubcontract: _deleteSubcontract,
        refetch
    }
}
