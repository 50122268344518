import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import { Contract, Task, TaskStates } from "../../generated/graphql"
import { useHasServiceManagerRole } from "../../hooks/useHasRole"
import { Actions, Subjects } from "../../services/ability"
import { endOfWeekOfWeekWithYear, startOfWeekOfWeekWithYear } from "../../utils/dates"
import { DeepPartial } from "../../types/types"
import DirectionField from "../DirectionField/DirectionField"
import InputDateTimeField from "../InputDateTimeField/InputDateTimeField"
import InputTextField from "../InputTextField/InputTextField"
import InterventionLocationField from "../InterventionLocationField/InterventionLocationField"
import JunctionField from "../JunctionField/JunctionField"
import LayoutField from "../LayoutField/LayoutField"
import Permission from "../Permission"
import RestrictionField from "../RestrictionField/RestrictionField"
import RoadField from "../RoadField/RoadField"
import Show from "../Show/Show"
import SubmitButton from "../SubmitButton/SubmitButton"
import TaskStateField from "../TaskStateField/TaskStateField"
import styles from './TaskForm.module.scss'
import taskFormInit from "./TaskFormInit"

interface TaskFormProps {
    task: Partial<Task>
    contract?: DeepPartial<Contract>
    onSubmit: (values: FormikValues) => Promise<void>
    showControlCenter?: boolean
    intervention?: any
}

const TaskForm: FC<TaskFormProps> = props => {
    const { task, onSubmit, contract, intervention = {}, showControlCenter = false } = props
    const { week, year } = intervention
    const { schema, initial } = taskFormInit(task)
    const isServiceManager = useHasServiceManagerRole()
    const conditionalEdit = ([TaskStates.Preparation, TaskStates.Rejected].includes(task?.state as TaskStates) || isServiceManager) || false

    const minDate = startOfWeekOfWeekWithYear({ week, year })
    const maxDate = endOfWeekOfWeekWithYear({ week, year })

    const handleSubmit = async (values: FormikValues) => {
        onSubmit(values)
    }

    return (
        <div className={styles.TaskFormWrapper}>
            <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {({ values }) => {
                    return (
                        <Form>


                            <Grid container direction="column" className={styles.Section}>
                                <Typography variant="h4" color="primary">
                                    {task?.name}
                                </Typography>
                            </Grid>

                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>

                                        <Show on={Boolean(task?.id)}>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="state" passThrough>
                                                        {allowed =>
                                                            <TaskStateField disabled={!allowed || !conditionalEdit} />
                                                        }
                                                    </Permission>
                                                </TableCell>
                                            </TableRow>
                                        </Show>

                                        <TableRow key={1}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="road" passThrough>
                                                    {allowed =>
                                                        <RoadField contract={contract?.id} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="direction" passThrough>
                                                    {allowed =>
                                                        <DirectionField disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={3}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="interventionLocation" passThrough>
                                                    {allowed =>
                                                        <InterventionLocationField disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>


                                        <TableRow key={4}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="startJunction" passThrough>
                                                    {allowed =>
                                                        <JunctionField name="startJunction" label="Nó Inicial" road={values?.road?.id} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={5}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="endJunction" passThrough>
                                                    {allowed =>
                                                        <JunctionField name="endJunction" label="Nó Final" road={values?.road?.id} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={6}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="startPk" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="startPk" label="PK Inicial" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={7}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="endPk" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="endPk" label="PK Final" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={8}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="reason" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="reason" label="Justificação" multiline={true} fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={9}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="startAt" passThrough>
                                                    {allowed =>
                                                        <InputDateTimeField name="startAt" label="Data início" minDate={minDate} maxDate={maxDate} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>


                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={10}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="endAt" passThrough>
                                                    {allowed =>
                                                        <InputDateTimeField name="endAt" label="Data fim" minDate={minDate} maxDate={maxDate} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={11}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="layout" passThrough>
                                                    {allowed =>
                                                        <LayoutField disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={12}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="restriction" passThrough>
                                                    {allowed =>
                                                        <RestrictionField disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={13}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="executingAuthority" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="executingAuthority" label="Responsável Entidade" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={14}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="executingAuthorityContact" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="executingAuthorityContact" label="Contacto Responsável Entidade" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={15}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="signalingAuthority" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="signalingAuthority" label="Responsável Sinalização" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>


                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={16}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="signalingAuthorityContact" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="signalingAuthorityContact" label="Contacto Responsável Sinalização" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={17}>
                                            <TableCell>
                                                <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="observations" passThrough>
                                                    {allowed =>
                                                        <InputTextField name="observations" label="Observações" fullWidth={true} disabled={!allowed || !conditionalEdit} />
                                                    }
                                                </Permission>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={18}>
                                            <TableCell>
                                                <p><b>Sobreposições</b></p>
                                                {task?.overlaps || "Não existem sobreposições"}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <Show on={showControlCenter}>

                                <Grid container className={styles.Section}>
                                    <Typography variant="h6" color="primary">
                                        Centro Controlo Trafego
                                    </Typography>
                                </Grid>

                                <TableContainer>
                                    <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                        <TableBody>

                                            <TableRow key={0}>
                                                <TableCell>
                                                    <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="state" passThrough>
                                                        {allowed =>
                                                            <TaskStateField disabled={!allowed} />
                                                        }
                                                    </Permission>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={1}>
                                                <TableCell>
                                                    <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="incident" passThrough>
                                                        {allowed =>
                                                            <InputTextField name="incident" label="Ocorrência" fullWidth disabled={!allowed} />
                                                        }
                                                    </Permission>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={2}>
                                                <TableCell>
                                                    <Permission action={Actions.UPDATE} subject={Subjects.TASK} field="controlCenterObservations" passThrough>
                                                        {allowed =>
                                                            <InputTextField name="controlCenterObservations" label="Comentário CCT" multiline fullWidth rows={3} disabled={!allowed} />
                                                        }
                                                    </Permission>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Show>

                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div >
    )
}

export default TaskForm
