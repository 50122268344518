import { AccountCircle, Business, Logout, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useConcession from '../../hooks/useConcession';
import { useHasInternalRole } from '../../hooks/useHasRole';
import { Actions, Subjects } from '../../services/ability';
import { useAuth } from '../../services/auth';
import Hide from '../Hide/Hide';
import { useSidemenu } from '../Layout/LayoutProvider';
import MenuDivider from '../MenuDivider/MenuDivider';
import Permission from '../Permission';
import Show from '../Show/Show';
import styles from './Header.module.scss';

const Header = () => {

    const { isOpen, setIsOpen } = useSidemenu()
    const concession = useConcession()
    const auth = useAuth()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const hasInternalRole = useHasInternalRole()

    const handle = () => {
        setIsOpen(!isOpen)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <AppBar position="fixed">
            <Toolbar className={styles.AppToolbar}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                        <IconButton color="inherit" aria-label="menu" onClick={handle}>
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <div className={styles.ConcessionName}>

                        <Hide on={!concession?.name}>
                            {concession?.name} <span style={{ fontSize: 16 }}>({auth?.user?.name})</span>
                        </Hide>

                        <IconButton onClick={handleClick} className={styles.Profile}>
                            <AccountCircle fontSize="medium" style={{ color: "white", fontSize: 28 }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            sx={{ width: 500 }}
                        >


                            <MenuItem onClick={() => navigate("/profile")}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText>
                                    Dados Perfil
                                </ListItemText>
                            </MenuItem>

                            <Permission action={Actions.LIST} subject={Subjects.ORGANIZATION} passThrough>
                                {allowed =>
                                    <Show on={allowed && !hasInternalRole}>
                                        <MenuItem onClick={() => navigate("/organization")}>
                                            <ListItemIcon>
                                                <Business />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Entidade Executante
                                            </ListItemText>
                                        </MenuItem>
                                    </Show>
                                }
                            </Permission>

                            <Permission action={Actions.LIST} subject={Subjects.SUBCONTRACTORGANIZATION} passThrough>
                                {allowed =>
                                    <Show on={allowed && !hasInternalRole}>
                                        <MenuItem onClick={() => navigate("/subcontract-organization")}>
                                            <ListItemIcon>
                                                <Business />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Entidade Subcontratada
                                            </ListItemText>
                                        </MenuItem>
                                    </Show>
                                }
                            </Permission>


                            <MenuDivider />

                            <MenuItem onClick={() => auth?.logout()}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText>
                                    Sair
                                </ListItemText>
                            </MenuItem>
                        </Menu>

                    </div>
                </Grid>
            </Toolbar>
        </AppBar >

    );
};

export default Header;
