import { Fade, Grid } from "@mui/material";
import { FC, useContext } from "react";
import Config from "../../services/config";
import { TasksListView } from "../../types/types";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import IsoWeekFilter from "../IsoWeekFilter/IsoWeekFilter";
import Search from "../Search/Search";
import Show from "../Show/Show";
import StartAtFilter from "../StartAtFilter/StartAtFilter";
import TasksMoreFilters from "../TasksMoreFilters/TasksMoreFilters";
import TaskStateFilter from "../TaskStateFilter/TaskStateFilter";
import styles from './TaskFilters.module.scss';

interface TasksFiltersProps {
    view: TasksListView
}

const getViewConfig = (view: TasksListView) => {

    const configurations = {
        [TasksListView.PLANNING]: {
            classname: styles.TasksFilters
        },
        [TasksListView.CONTROLCENTER]: {
            classname: styles.TasksFilters
        },
        [TasksListView.INTERVENTION]: {
            classname: styles.InterventionTasksFilters
        }
    }

    // @ts-ignore
    const configuration = configurations[view]

    if (!configuration) {
        throw Error("Missing Task Filters Configuration")
    }

    return configuration
}


const TasksFilters: FC<TasksFiltersProps> = props => {
    const { view } = props
    const config = getViewConfig(view)
    const context = useContext(DataViewContext)

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-end" className={config.classname}>

                <Grid container justifyContent="flex-start" alignItems="center" className={styles.Exp}>

                    <TasksMoreFilters view={view} />
                    <div style={{ marginRight: 20 }}></div>

                    <Search value={context.filters.search} onChange={context.setFilter} name="search" placeholder="nome ou motivo" />

                    <TaskStateFilter value={context.filters.state} onChange={context.setFilter} />

                    <Show on={view === TasksListView.CONTROLCENTER}>
                        <div style={{ marginTop: 5, marginLeft: 20, marginRight: 30 }}>
                            <StartAtFilter value={context.filters.startAt} onChange={context.setFilter} />
                        </div>
                    </Show>

                    <Show on={view === TasksListView.PLANNING}>
                        <div style={{ marginTop: 5, marginLeft: 20, marginRight: 30 }}>
                            <IsoWeekFilter value={context.filters.isoWeek} onChange={context.setFilter} />
                        </div>
                    </Show>

                    <ClearFilters />

                </Grid>
            </Grid>
        </Fade >
    )
}

export default TasksFilters
