import { Tune } from '@mui/icons-material';
import { Badge, IconButton } from "@mui/material";
import { useState } from "react";
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';

export default function MoreFilters(props: any) {

    const { children, count } = props
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Badge badgeContent={count} color="error">
                <IconButton onClick={handleClick}>
                    <Tune />
                </IconButton>
            </Badge>
            <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} hideBackdrop={false} anchor="left">
                {children}
            </TemporaryDrawer>
        </>
    )
}
