import { Field } from 'formik';
import { FC } from 'react';
import { useInspectingOrganizationsFieldQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import ContractConcessionOrganizationAutocomplete from '../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete';

interface InspectingOrganizationFieldProps {
    disabled?: boolean
}

const InspectingOrganizationField: FC<InspectingOrganizationFieldProps> = props => {
    const { disabled } = props
    const concession = useConcession()
    const { data } = useInspectingOrganizationsFieldQuery({ fetchPolicy: "no-cache", variables: { concession: concession?.id, paging: { limit: 999 } } })

    const name = "contractConcessionOrganizations"
    const label = "Entidade Fiscalizadora"
    const options = data?.concessionOrganizations?.nodes || []

    return (
        <Field
            name={name}
            component={ContractConcessionOrganizationAutocomplete}
            options={options}
            label={label}
            disabled={disabled}
            organizationType={3}
            required={false}
        />
    )
}

export default InspectingOrganizationField
