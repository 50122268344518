import { useConcessionOrganizationsQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function ContractExecutingOrganizationFilter(props: FilterProps) {
    const { value, onChange, width = 200 } = props
    const query = useConcessionOrganizationsQuery
    const concession = useConcession()

    const getListItemText = (node: any) => {
        return node?.organization?.name
    }

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Entidade Executante"
            query={query}
            field="executingOrganization"
            filter={{
                concession: { id: { eq: concession?.id } },
                organizationType: { id: { eq: 2 } }
            }}
            multiple={true}
            paging={{ limit: 999 }}
            width={width}
            getListItemText={getListItemText}
        />
    )
}
