import { Grid } from "@mui/material";

const NotFound = () => {
    return (
        <Grid container justifyContent="center">
            <h3 style={{ marginTop: 500, color: "#6a6a6a", fontSize: 40 }}>PÁGINA NÃO ENCONTRADA</h3>
        </Grid>
    )
}

export default NotFound
