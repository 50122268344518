import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ContractDocumentCategoryFilter from "../ContractDocumentCategoryFilter/ContractDocumentCategoryFilter";
import ContractDocumentsInstructions from "../ContractDocumentsInstructions/ContractDocumentsInstructions";
import ContractDocumentSubcategoryFilter from "../ContractDocumentSubcategoryFilter/ContractDocumentSubcategoryFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import DocumentStateFilter from "../DocumentStateFilter/DocumentStateFilter";
import FileTypeFilter from "../FileTypeFilter/FileTypeFilter";
import Search from "../Search/Search";
import styles from './ContractDocumentsFilters.module.scss';

const ContractDocumentsFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.ContractDocumentsFilters}>
                <Search value={context.filters.name} onChange={context.setFilter} name="name" />
                <FileTypeFilter value={context.filters.fileType} onChange={context.setFilter} multiple={true} />
                <DocumentStateFilter value={context.filters.documentState} onChange={context.setFilter} multiple={true} />
                <ContractDocumentCategoryFilter value={context.filters.contractDocumentCategory} onChange={context.setFilter} multiple={true} />
                <ContractDocumentSubcategoryFilter parent={context.filters.contractDocumentCategory} value={context.filters.contractDocumentSubcategory} onChange={context.setFilter} multiple={true} />
                <ContractDocumentsInstructions />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default ContractDocumentsFilters
