import { useCreateOneVerificationMutation, useDeleteOneVerificationMutation, useVerificationsQuery, useUpdateOneVerificationMutation } from "../../generated/graphql"

export default function useVerificationStore() {
    const query = useVerificationsQuery
    const name = "verification"
    const [create] = useCreateOneVerificationMutation()
    const [update] = useUpdateOneVerificationMutation()
    const [remove] = useDeleteOneVerificationMutation()
    return { query, name, create, update, remove }
}
