import { useCreateOneOrganizationMutation, useDeleteOneOrganizationMutation, useOrganizationsQuery, useUpdateOneOrganizationMutation } from "../../generated/graphql"
import useConcessionOrganizationStore from "./ConcessionOrganizationStore"
import useConcessionOrganizationUserStore from "./ConcessionOrganizationUserStore"
import useUserStore from "./UserStore"

const useOrganizationStore = () => {
    const query = useOrganizationsQuery
    const name = "organization"
    const concessionOrganization = useConcessionOrganizationStore()
    const concessionOrganizationUser = useConcessionOrganizationUserStore()
    const user = useUserStore()
    const [create] = useCreateOneOrganizationMutation()
    const [update] = useUpdateOneOrganizationMutation()
    const [remove] = useDeleteOneOrganizationMutation()
    return { query, name, create, update, remove, concessionOrganization, concessionOrganizationUser, user }

}

export default useOrganizationStore
