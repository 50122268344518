import { Preview } from "@mui/icons-material";
import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";
import { MouseEvent } from 'react';

interface PreviewIconButtonProps {
    onClick: (event: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    title?: string
}

export default function PreviewIconButton(props: PreviewIconButtonProps) {
    const {
        onClick,
        disabled = false,
        title = "pré-visualizar"
    } = props

    return (
        <IconButtonTooltip title={title} disabled={disabled} onClick={onClick}>
            <Preview />
        </IconButtonTooltip>
    )
}
