import { FC } from "react"
import { useRoadsQuery } from "../../generated/graphql"
import { useConcession } from "../../hooks/useConcession"
import { FilterProps } from "../../types/types"
import { SelectFilter } from "../SelectFilter/SelectFilter"

const RoadFilter: FC<FilterProps> = props => {
    const { value, onChange, width = 90 } = props
    const query = useRoadsQuery
    const concession = useConcession()

    return <SelectFilter
        value={value}
        onChange={onChange}
        query={query}
        multiple={true}
        field="road"
        label="Via"
        filter={{ concession: { id: { eq: concession?.id } } }}
        width={width}
        paging={{ limit: 999 }}
    />
}

export default RoadFilter
