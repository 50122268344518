import { CircularProgress } from "@mui/material";
import { clsx } from "clsx";
import { useStatusQuery } from "../../generated/graphql";
import styles from './Status.module.scss';

const Status = () => {
    const { data, loading } = useStatusQuery()

    const classes = clsx(styles.Base, loading ? styles.Loading : styles.Ok)

    return (
        <>
            {loading ? <CircularProgress /> : null}
            <div className={classes}>Status: {data?.status}</div>
        </>
    )
}

export default Status;
