import { Actions, Subjects } from '../../services/ability';
import { MoreMenuItem, MoreMenuItemProps } from '../MoreMenuItem/MoreMenuItem';
import Permission from '../Permission';

interface MoreMenuItemPermissionProps extends MoreMenuItemProps {
    action: Actions
    subject: Subjects
    passTrough?: boolean
}

export const MoreMenuItemPermission = (props: MoreMenuItemPermissionProps) => {
    const { icon, label, onClick, disabled = false, action, subject, passTrough = true } = props

    return (
        <Permission action={action} subject={subject} passThrough={passTrough}>
            {allowed =>
                <MoreMenuItem
                    icon={icon}
                    label={label}
                    onClick={onClick}
                    disabled={!allowed || disabled}
                />
            }
        </Permission>
    )
}
