import { Card, CardContent, Checkbox, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Checklist, ChecklistItem } from '../../generated/graphql';
import { MaybeDeepPartial } from '../../types/types';
import DisplayState from '../DisplayState/DisplayState';
import styles from './WorkPermitChecklist.module.scss';

interface WorkPermitChecklistProps {
    checklist?: MaybeDeepPartial<Checklist>
    onItemCheck: (id: string, isCompleted: boolean) => void
    disabled?: boolean
}

export default function WorkPermitChecklist(props: WorkPermitChecklistProps) {
    const { checklist, onItemCheck, disabled = false } = props

    const handleCheck = (checklistItem: ChecklistItem) => {
        onItemCheck(checklistItem.id, !checklistItem.isCompleted)
    }

    const state = checklist?.isCompleted ? "completa" : "pendente"

    return (
        <Card className={styles.Wrapper}>
            <CardContent>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="h4" color="primary" gutterBottom={false}>
                            Checklist
                        </Typography >
                    </Grid>
                    <Grid item xs={3}>
                        <DisplayState stateName={state} />
                    </Grid>
                </Grid>
                <Grid className={styles.Instructions}>
                    <Typography variant="body1" color="secondary">
                        Antes de submeter a permissão de trabalho assinale os documentos que já carregou na plataforma.
                        Só pode submeter a permissão depois de carregados os documentos obrigatórios, assinalados com *.
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                    {checklist?.checklistItems?.map((item: any) => (
                        <Fragment key={item.id}>
                            <Grid item xs={10} className={styles.Name}>
                                <Checkbox
                                    checked={item.isCompleted}
                                    onChange={() => handleCheck(item)}
                                    style={{ padding: 9, paddingLeft: 0 }}
                                    disabled={disabled}
                                />
                                {item.name}
                            </Grid>
                            <Grid item xs={1} container justifyContent="center">
                                {item.isOptional ? "" : "*"}
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}
