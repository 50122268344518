import * as Yup from "yup";
import { Maybe, Layout } from "../../generated/graphql";

const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required().default(""),
    })
}

export const getInitialValues = (schema: Yup.ObjectSchema<any>) => {
    return schema.cast({});
}

const layoutFormInit = (record?: Maybe<Layout>) => {
    const schema = getSchema()
    const initial = record ? record : getInitialValues(schema)
    return { schema, initial }
}

export default layoutFormInit
