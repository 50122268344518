import { translate } from '../../../hooks/useTranslate';
import { black, blue, gray } from '../../../services/config';
import styles from './ContractStateRender.module.scss';

export const getSurveyRecordStateColor = (state: string) => {
    const contractColors: { [key: string]: string } = {
        PREPARATION: gray,
        ISSUED: blue,
    };

    return contractColors[state] || black
};


export const SurveyRecordStateRender = (params: any) => {
    const backgroundColor = getSurveyRecordStateColor(params?.row?.state)
    return (
        <div className={styles.ContractState} style={{ backgroundColor }}>
            {translate(params?.row?.state, "capitalize")}</div>
    )
}
