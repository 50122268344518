export function countNonEmptyFields(obj: { [key: string]: any }, fields: string[]): number {
    if (!obj) return 0
    return fields.reduce((count, field) => {
        const value = obj[field];
        if (
            value !== null &&
            value !== undefined &&
            value !== "" &&
            !(Array.isArray(value) && value.length === 0)
        ) {
            return count + 1;
        }
        return count;
    }, 0);
}
