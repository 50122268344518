import { Fade, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import config from "../../services/config";
import Permission from "../Permission";
import { MenuItem, menuItems } from "../Sidemenu/Sidemenu";
import styles from './Welcome.module.scss';

const WelcomeAction = (props: any) => {
    const { menuItem } = props
    const { action, subject, name, icon, route } = menuItem

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(route)
    }

    return (
        <Permission action={action} subject={subject}>
            <Grid container direction="column" alignItems="center" className={styles.WelcomeAction} onClick={handleClick}>
                <div>{icon}</div>
                <Typography variant="h4">
                    {name}
                </Typography>
            </Grid>
        </Permission>
    )
}

const Welcome = () => {

    const auth = useAuth()
    const userName = auth?.user?.name
    const concessionName = auth?.user?.concession?.name

    return (
        <Fade in={userName} timeout={config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="center" alignContent="center" className={styles.Wrapper}>
                <Grid container direction="column" justifyContent="center" alignItems="center" className={styles.Welcome}>
                    <Typography variant="h1" color="primary" gutterBottom>
                        Olá, <strong>{userName}</strong>, bem-vindo(a)
                    </Typography>
                    <Typography variant="h1" color="primary" gutterBottom>
                        ao <strong>GIN</strong> e à concessão <strong>{concessionName}</strong>
                    </Typography>

                    <div className={styles.WelcomeActions}>

                        <Grid container justifyContent="center" >
                            <Typography variant="h4" className={styles.ChooseYourOption}>
                                Escolha uma das seguintes opções
                            </Typography>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">

                            {menuItems?.map((menuItem: MenuItem) => (
                                <WelcomeAction menuItem={menuItem} key={menuItem?.name} />
                            )
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Fade>
    )
}

export default Welcome;
