
type FieldDisabledParams = {
    fieldName: string
    [key: string]: any
}

const useFormFieldManager = (rules: any) => {

    const isFieldDisabled = (params: FieldDisabledParams) => {
        const rule = rules[params.fieldName];
        if (rule && rule.shouldBeDisabled) {
            return rule.shouldBeDisabled(params);
        }
        return false;
    }

    return isFieldDisabled
}

export default useFormFieldManager
