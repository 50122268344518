import { useServicesQuery } from '../../generated/graphql';
import useConcession from '../../hooks/useConcession';
import { RelationFieldProps } from '../../types/types';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

export default function ServiceField(props: RelationFieldProps) {
    const {
        name = "service",
        label = "Serviço",
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props
    const concession = useConcession()
    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useServicesQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}
        />
    )
}
