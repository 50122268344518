import { Fade, Grid } from "@mui/material";
import { useRef } from "react";
import { useInterventionQuery } from "../../generated/graphql";
import useParamId from "../../hooks/useParamId";
import { Actions, Subjects } from "../../services/ability";
import Config from "../../services/config";
import ActionsBar from "../ActionsBar/ActionsBar";
import BackButton from "../BackButton/BackButton";
import InterventionForm from "../InterventionForm/InterventionForm";
import InterventionTasks from "../InterventionTasks/InterventionTasks";
import Permission from "../Permission";
import RecordNotFound from "../RecordNotFound/RecordNotFound";
import SaveIconButton from "../SaveIconButton/SaveIconButton";
import Show from "../Show/Show";
import styles from './InterventionView.module.scss';
import InterventionViewMoreActions from "./InterventionViewMoreActions";

const InterventionView = () => {
    const id = useParamId()
    const action = id ? Actions.UPDATE : Actions.CREATE
    const subject = Subjects.INTERVENTION

    // https://github.com/jaredpalmer/formik/issues/2290
    const formRef = useRef()
    const { data, loading, refetch } = useInterventionQuery({ variables: { id } })

    const handleSubmit = () => {
        if (formRef.current) {
            // @ts-ignore
            return formRef?.current?.handleSubmit()
        }
    }

    if (id && !loading && !data?.intervention) {
        return <RecordNotFound message="Erro! Intervenção não encontrada." />
    }

    return (
        <Fade in={!loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.Wrapper}>

                <ActionsBar variant="DETAIL">
                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                            <BackButton />
                        </Grid>
                        <Grid item xs={10} container justifyContent="flex-end" alignContent="center">

                            <Permission action={action} subject={subject}>
                                <SaveIconButton onClick={handleSubmit} />
                            </Permission>

                            <InterventionViewMoreActions intervention={data?.intervention} refetch={refetch} />

                        </Grid>
                    </Grid>
                </ActionsBar>

                <Grid container direction="column" className={styles.Content}>
                    <Grid container>
                        <Grid item xs={7}>
                            <InterventionForm intervention={data?.intervention} formRef={formRef} />
                        </Grid>
                    </Grid>

                    <Show on={!!data?.intervention?.id}>
                        <Grid container direction="column" style={{ marginTop: 100 }}>
                            {data?.intervention && <InterventionTasks intervention={data.intervention} />}
                        </Grid>
                    </Show>
                </Grid>
            </div>
        </Fade>
    )
}


export default InterventionView
