import { TaskStates } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { enumToOptions } from '../../utils/filters';
import SelectEnumFilter from '../SelectEnumFilter/SelectEnumFilter';

export default function TaskStateFilter(props: FilterProps) {
    const { value, onChange } = props
    const options = enumToOptions(TaskStates)
    return (
        <SelectEnumFilter
            value={value}
            onChange={onChange}
            label="Estado"
            field="state"
            multiple
            options={options}
        />
    )
}
