import { Close, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { UseFiltersOnChange } from "../../hooks/useFilters";
import { getNextDay, getPreviousDay } from "../../services/utils";
import DateFilter from "../DateFilter/DateFilter";
import Hide from "../Hide/Hide";

interface StartAtFilterProps {
    value: Date
    name?: string
    onChange: UseFiltersOnChange
    hideArrows?: boolean
    hideClearable?: boolean
    clearableMarginLeft?: number
}

const StartAtFilter: FC<StartAtFilterProps> = props => {
    const {
        value,
        name = "startAt",
        onChange,
        hideArrows = false,
        hideClearable = true,
        clearableMarginLeft = 15
    } = props

    const handle = (direction: number | null) => {
        if (direction) {
            const date = direction < 0 ? getPreviousDay(value) : getNextDay(value)
            onChange(name, date)
        } else {
            onChange(name, null)
        }
    }

    return (
        <Grid container alignContent="center">
            <Hide on={hideArrows}>
                <Tooltip title="Dia Anterior">
                    <IconButton onClick={() => handle(-1)}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Dia Seguinte">
                    <IconButton style={{ marginRight: 15 }}>
                        <KeyboardArrowRight onClick={() => handle(1)} />
                    </IconButton>
                </Tooltip>
            </Hide>
            <div style={{ width: 150 }}>
                <DateFilter name={name} value={value} onChange={onChange} />
            </div>
            <Hide on={hideClearable}>
                <Tooltip title="Limpar Data">
                    <IconButton onClick={() => handle(null)} style={{ marginLeft: clearableMarginLeft }}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </Hide>
        </Grid>
    )
}

export default StartAtFilter
