import { useCreateOwnConcessionOrganizationDocumentMutation, useDeleteManyConcessionOrganizationDocumentsMutation, useDeleteOwnConcessionOrganizationDocumentMutation, useOwnConcessionOrganizationDocumentsQuery, useUpdateManyConcessionOrganizationDocumentsMutation, useUpdateOwnConcessionOrganizationDocumentMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const OwnOrganizationDocumentsDataSource = {
    readFunc: useOwnConcessionOrganizationDocumentsQuery,
    createFunc: useCreateOwnConcessionOrganizationDocumentMutation,
    updateFunc: useUpdateOwnConcessionOrganizationDocumentMutation,
    deleteFunc: useDeleteOwnConcessionOrganizationDocumentMutation,
    updateManyFunc: useUpdateManyConcessionOrganizationDocumentsMutation,
    deleteManyFunc: useDeleteManyConcessionOrganizationDocumentsMutation
}

export default function OwnOrganizationDocumentProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={OwnOrganizationDocumentsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
