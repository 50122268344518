import { Actions, Subjects } from '../../services/ability';
import MenuItemWithConfirmation from '../MenuItemWithConfirmation/MenuItemWithConfirmation';
import { MoreMenuItemProps } from '../MoreMenuItem/MoreMenuItem';
import Permission from '../Permission';

interface MoreMenuItemPermissionWithConfirmationProps extends MoreMenuItemProps {
    action: Actions
    subject: Subjects
    passTrough?: boolean
}

export const MoreMenuItemPermissionWithConfirmation = (
    props: MoreMenuItemPermissionWithConfirmationProps) => {

    const { icon, label, onClick, disabled = false, action, subject, passTrough = true } = props

    return (
        <Permission action={action} subject={subject} passThrough={passTrough}>
            {allowed =>
                <MenuItemWithConfirmation
                    label={label}
                    allowed={allowed}
                    icon={icon}
                    onClick={onClick}
                    confirmationRequired={true}
                    listItemTextColor="red"
                    disabled={disabled}
                />
            }
        </Permission>
    )
}
