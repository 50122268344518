import { TextFieldProps } from '@mui/material';
import { useOrganizationTypesQuery } from '../../generated/graphql';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface ConcessionOrganizationRoleFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
}

export default function ConcessionOrganizationRoleField(props: ConcessionOrganizationRoleFieldProps) {

    const {
        name = "concessionOrganizationRole",
        label = "Função",
        textFieldProps = { variant: "standard" }
    } = props

    const query = useOrganizationTypesQuery

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={query}
            textFieldProps={textFieldProps}
        />
    )
}
