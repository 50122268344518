import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import AccreditationStateFilter from "../AccreditationStateFilter/AccreditationStateFilter";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Search from "../Search/Search";
import styles from './AccreditationsFilters.module.scss';

export default function AccreditationsFilters() {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.Filters}>
                <Search value={context.filters.observations} onChange={context.setFilter} name="observations" />
                <AccreditationStateFilter
                    value={context?.filters?.concessionOrganizationAccreditationState}
                    onChange={context.setFilter}
                />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}
