import { Airplay, Article, Menu as MenuIcon, Newspaper, RemoveRoad, Settings, TripOrigin, Verified, ViewWeek } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { Actions, Subjects } from '../../services/ability';
import { useSidemenu } from '../Layout/LayoutProvider';
import Permission from '../Permission';
import styles from './Sidemenu.module.scss';

export interface MenuItem {
    action: string
    subject: string
    route?: string
    name: string
    icon: any
}

export const menuItems: MenuItem[] = [
    { action: Actions.LIST, subject: Subjects.DASHBOARD, name: "Centro Controlo", route: "/dashboard", icon: <Airplay /> },
    { action: Actions.LIST, subject: Subjects.ACCREDITATION, name: "Acreditações", route: "/accreditations", icon: <Verified /> },
    { action: Actions.LIST, subject: Subjects.CONTRACT, name: "Contratos", route: "/contracts", icon: <Newspaper /> }, // @fix
    { action: Actions.LIST, subject: Subjects.SUBCONTRACT, name: "Subcontratos", route: "/subcontracts", icon: <Newspaper /> },
    { action: Actions.LIST, subject: Subjects.WORKPERMIT, name: "Permissões", route: "/work-permits", icon: <Article /> },
    { action: Actions.LIST, subject: Subjects.INTERVENTION, name: "Intervenções", route: "/interventions", icon: <RemoveRoad /> },
    { action: Actions.LIST, subject: Subjects.WEEKLYPLANNING, name: "Planeamento", route: "/week", icon: <ViewWeek /> },
    { action: Actions.LIST, subject: Subjects.SURVEYRECORD, name: "Visitas", route: "/survey-records", icon: <TripOrigin /> },
    { action: Actions.LIST, subject: Subjects.SETTING, name: "Definições", route: "/settings", icon: <Settings /> }
]

const Sidemenu = () => {

    const navigate = useNavigate()


    const { isOpen, setIsOpen } = useSidemenu()

    const handleNavigate = (route: string = '/dashboard') => {
        setIsOpen(false)
        navigate(route)
    }

    return (
        <Drawer open={isOpen} onClose={() => setIsOpen(false)} style={{ zIndex: 1099 }}>
            <Grid className={styles.Sidemenu}>
                <List>

                    {menuItems.map(({ action, subject, route, icon, name }) => {

                        const handleClick = (route?: string) => {
                            handleNavigate(route)
                        }

                        return (
                            <Permission action={action} subject={subject} key={name}>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleClick(route)}>
                                        <ListItemIcon>
                                            {icon}
                                        </ListItemIcon>
                                        <ListItemText primary={name} />
                                    </ListItemButton>
                                </ListItem>
                            </Permission>
                        )
                    })}

                </List>
            </Grid>
        </Drawer>
    );
};

export default Sidemenu;
