import { Paper, Popover, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { FC } from "react"
import useGoto from "../../hooks/useGoto"
import { parseDateGQLField } from "../../services/utils"
import { formatDate } from "../../utils/dates"
import { getContractContractingOrganization, getContractExecutingOrganization } from "../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete"
import LinkedName from "../LinkedName/LinkedName"
import styles from './ContractPopover.module.scss'

interface ContractPopoverProps {
    anchor: any
    setAnchor: any
    contract: any
}

const ContractPopover: FC<ContractPopoverProps> = props => {
    const { anchor, setAnchor, contract } = props
    const { goToContract } = useGoto()

    if (!contract) return null

    const popoverOpen = Boolean(anchor);
    const id = popoverOpen ? 'simple-popover' : undefined;

    const getExecutingOrganization = () => {
        const contractConcessionOrganization = getContractExecutingOrganization(contract?.contractConcessionOrganizations)
        return contractConcessionOrganization?.concessionOrganization?.organization?.name || "-"
    }

    const getContractingOrganization = () => {
        const contractConcessionOrganization = getContractContractingOrganization(contract?.contractConcessionOrganizations)
        return contractConcessionOrganization?.concessionOrganization?.organization?.name || "-"
    }

    return (
        <div>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <TableContainer component={Paper}>
                    <Table className={styles.ContractTable} aria-label="contract preview table">
                        <TableBody>
                            <TableRow key={1}>
                                <TableCell>
                                    <b>Nome</b>
                                </TableCell>
                                <TableCell>
                                    <LinkedName name={contract.name} onClick={() => goToContract(contract?.id)} />
                                </TableCell>
                            </TableRow>
                            <TableRow key={2}>
                                <TableCell>
                                    <b>Descrição</b>
                                </TableCell>
                                <TableCell>{contract.description}</TableCell>
                            </TableRow>
                            <TableRow key={3}>
                                <TableCell>
                                    <b>Estado</b>
                                </TableCell>
                                <TableCell>{contract?.contractState?.name}</TableCell>
                            </TableRow>
                            <TableRow key={4}>
                                <TableCell>
                                    <b>Entidade Executante</b>
                                </TableCell>
                                <TableCell>{getExecutingOrganization()}</TableCell>
                            </TableRow>
                            <TableRow key={5}>
                                <TableCell>
                                    <b>Entidade Contratante</b>
                                </TableCell>
                                <TableCell>{getContractingOrganization()}</TableCell>
                            </TableRow>
                            <TableRow key={6}>
                                <TableCell>
                                    <b>Início Real</b>
                                </TableCell>
                                <TableCell>
                                    {formatDate(parseDateGQLField(contract?.realStartDate))}
                                </TableCell>
                            </TableRow>
                            <TableRow key={7}>
                                <TableCell>
                                    <b>Fim Real</b>
                                </TableCell>
                                <TableCell>
                                    {formatDate(parseDateGQLField(contract?.realEndDate))}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Popover>
        </div >
    )
}

export default ContractPopover
