import { Typography } from "@mui/material"
import SelectAutocompleteField from "../SelectAutocompleteField/SelectAutocompleteField"
import styles from './SubcontractOrganizations.module.scss'

interface SubcontractOrganizationsProps {
    name?: string
    label?: string
    options: any
}

export default function SubcontractOrganizations(props: SubcontractOrganizationsProps) {
    const {
        name = "concessionOrganization",
        label = "entidade subcontratada",
        options
    } = props

    const _options = options?.map((option: any) => {
        return {
            id: option?.id,
            label: option?.organization?.name,
            tin: option?.organization?.tin
        }
    })

    const renderOption = (props: any, option: any) => {
        return (
            <div {...props} className={styles.RenderOption}>
                <Typography variant="body1" color="text">
                    {option?.label}
                </Typography>
                <Typography variant="body2" color="secondary">
                    {option?.tin}
                </Typography>
            </div>
        )
    }

    return (
        <SelectAutocompleteField
            name={name}
            label={label}
            options={_options}
            textFieldProps={{ variant: "standard" }}
            renderOption={renderOption}
        />
    )
}
