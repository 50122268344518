import { Button, Grid } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import useToast from "../../hooks/useToast"
import ConcessionOrganizationRoleField from "../ConcessionOrganizationRoleField/ConcessionOrganizationRoleField"
import InputTextField from "../InputTextField/InputTextField"
import OrganizationFormInit from "../SettingsView/OrganizationFormInit"
import styles from './OrganizationEditorCreateForm.module.scss'

const ERROR_CREATE_CONCESSION_ORGANIZATION = "Erro ao associar função."

interface OrganizationEditorCreateFormProps {
    onCreateOrganization: any
    onCreateConcessionOrganization: any
}

export const OrganizationEditorCreateForm: FC<OrganizationEditorCreateFormProps> = props => {
    const { onCreateConcessionOrganization, onCreateOrganization } = props
    const { errorToast } = useToast()

    const { initial } = OrganizationFormInit()

    const handleSubmit = async (values: FormikValues) => {
        const { concessionOrganizationRole, ...organization } = values
        let response = null
        try {
            response = await onCreateOrganization(organization)
            if (!response?.errors?.length) {
                response = await onCreateConcessionOrganization(response?.data?.createOneOrganization, concessionOrganizationRole?.id)
            } else {
                errorToast(ERROR_CREATE_CONCESSION_ORGANIZATION)
            }

        } catch (e) {
            errorToast(ERROR_CREATE_CONCESSION_ORGANIZATION)
        }
    }

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} >
            {() => {
                return (
                    <Form className={styles.CreateUserForm}>
                        <p><InputTextField name="name" label="nome" fullWidth autoFocus /></p>
                        <p><InputTextField name="tin" label="nif" fullWidth /></p>
                        <p><InputTextField name="address" label="morada" fullWidth /></p>
                        <p><InputTextField name="zipCode" label="código postal" fullWidth /></p>
                        <p><ConcessionOrganizationRoleField /></p>
                        <Grid container justifyContent="flex-end">
                            <Button type="submit" variant="contained">Criar</Button>
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )
}

export default OrganizationEditorCreateForm
