import { InputLabelProps, InputProps, TextField } from "@mui/material";
import { Field } from "formik";
import { FC, useEffect, useState } from "react";
import { Nullable } from "../../types/types";

interface InputAutoTextFieldProps {
    name: string
    label?: string
    autoFocus?: boolean
    variant?: "standard" | "outlined" | "contained"
    multiline?: boolean
    fullWidth?: boolean
    rows?: number
    minRows?: number
    maxRows?: number
    InputProps?: Partial<InputProps>
    InputLabelProps?: Partial<InputLabelProps>
    type?: "text" | "password" | string
    disabled?: boolean
}

const InputAutoTextField: FC<InputAutoTextFieldProps> = props => {
    const { name,
        label = name,
        autoFocus = false,
        multiline = false,
        variant = "standard",
        fullWidth = false,
        rows,
        minRows,
        maxRows,
        InputLabelProps,
        InputProps,
        type = "text",
        disabled

    } = props
    return (
        <Field
            component={AutoFieldText}
            name={name}
            label={label}
            type={type}
            autoFocus={autoFocus}
            variant={variant}
            multiline={multiline}
            fullWidth={fullWidth}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            disabled={disabled}
        />
    )
}

const buildFakeEvent = (name: string, value?: string, type: string = "change") => {
    return {
        type,
        target: {
            name,
            value: value
        }
    }
}

type AutoTextFieldData = Nullable<{ [key: string]: string }>

const AutoFieldText = (props: any) => {
    const { field: { name, value, onChange }, ...rest } = props
    const interval = 1000

    const [lastData, setLastData] = useState<AutoTextFieldData>(null);
    const [data, setData] = useState<AutoTextFieldData>(null);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (lastData?.[name] !== data?.[name]) {
                const event = buildFakeEvent(name, data?.[name])
                onChange(event);
                setLastData(data);
            }
        }, interval);
        return () => window.clearTimeout(timer);
    }, [data, value, lastData, onChange, name]);


    const handleChange = (event: any) => {
        setData({ [name]: event.target.value });
    };

    if (!data) {
        setData({ [name]: value });
        setLastData({ [name]: value });
    }

    const currentValue = data && data[name] ? data[name] : ''

    return (
        <TextField
            value={currentValue}
            name={name}
            onChange={handleChange}
            {...rest}
        />
    )
}

export default InputAutoTextField
