import { AutocompleteRenderInputParams, TextField, TextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { Autocomplete } from 'formik-mui';
import { useShowFieldError } from '../../hooks/useShowFieldError';
import Config from '../../services/config';
import { firstProperty, _get } from '../../services/utils';
import styles from './SelectAutocompleteDataField.module.scss';

interface SelectAutocompleteDataFieldProps {
    name: string
    label: string
    query: any
    queryFilter?: {}
    queryPaging?: {}
    querySorting?: {}
    field?: string
    textFieldProps?: TextFieldProps
    autoFocus?: boolean
    disabled?: boolean
    getOptionLabel?: any
    getOptions?: any
    noOptionsText?: string
}

/**
 * atualmente apenas suporta queries que retornam nodes
 */
export const defaultGetOptions = (collection?: any) => {
    if (!collection) return []
    const collectionName = firstProperty(collection, true)
    if (!collectionName) return []
    return collection[collectionName].nodes
}

/**
 * atualmente apenas suporta queries que retornam nodes
 */
const defaultGetOptionLabel = (option: any, field: string) => {
    if (option.node) return _get(option.node, field)
    return _get(option, field) || ""
}

export default function SelectAutocompleteDataField(props: SelectAutocompleteDataFieldProps) {
    const { name,
        label,
        query,
        field = "name",
        queryFilter = {},
        queryPaging = { limit: Config.PAGE_SIZE },
        querySorting = { field: "id", direction: "ASC" },
        autoFocus = false,
        disabled = false,
        getOptionLabel,
        getOptions,
        noOptionsText = Config.NO_OPTIONS_TEXT
    } = props
    const { textFieldProps } = props
    const { data, loading } = query({ fetchPolicy: "no-cache", variables: { filter: queryFilter, paging: queryPaging, sorting: querySorting } })
    const { error, helperText } = useShowFieldError(name)

    /* if (loading) {
     *     return null
     * } */

    const _getOptionLabel = (option: any) => {
        return getOptionLabel ? getOptionLabel(option, field) : defaultGetOptionLabel(option, field)
    }

    const _getOptions = (data?: any) => {
        return getOptions ? getOptions(data) : defaultGetOptions(data)
    }

    return (
        <Field
            className={styles.Field}
            name={name}
            component={Autocomplete}
            disableClearable
            disabled={disabled}
            noOptionsText={noOptionsText}
            options={_getOptions(data)}
            getOptionLabel={_getOptionLabel}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                    error={error}
                    autoFocus={autoFocus}
                    helperText={helperText}
                />
            )}
        />
    )
}
