import { ApolloQueryResult } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { Grid, IconButton, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC } from "react"
import { SurveyRecordQuery, useCreateOneSurveyRecordVerificationMutation } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import InputTextField from "../InputTextField/InputTextField"
import Show from "../Show/Show"
import surveyRecordVerificationFormInit from "../SurveyRecordVerification/SurveyRecordVerificationFormInit"
import SurveyRecordVerificationClassificationField from "../SurveyRecordVerificationClassificationField/SurveyRecordVerificationClassificationField"
import SurveyRecordVerificationStateField from "../SurveyRecordVerificationStateField/SurveyRecordVerificationStateField"
import VerificationField from "../VerificationField/VerificationField"
import VerificationScopeField from "../VerificationScopeField/VerificationScopeField"
import styles from './CreateSurveyRecordVerification.module.scss'

interface CreateSurveyRecordVerificationProps {
    surveyRecord: any
    isNotUserEdit: boolean
    refetch: () => Promise<ApolloQueryResult<SurveyRecordQuery>>
}

const CreateSurveyRecordVerification: FC<CreateSurveyRecordVerificationProps> = props => {
    const { surveyRecord, isNotUserEdit, refetch } = props

    const { schema, initial } = surveyRecordVerificationFormInit()
    const { feedback } = useToast()
    const [create] = useCreateOneSurveyRecordVerificationMutation()

    const handleSubmit = async (values: any, helpers: any) => {
        const { description, surveyRecordVerificationState, verification, surveyRecordVerificationClassification } = values
        const response = await create({
            variables: {
                input: {
                    surveyRecordVerification: {
                        surveyRecord: surveyRecord?.id,
                        verification: verification?.id,
                        description,
                        surveyRecordVerificationClassification: surveyRecordVerificationClassification?.id,
                        surveyRecordVerificationState: surveyRecordVerificationState?.id
                    }
                }
            }
        })
        feedback(!response?.errors?.length, "Linha criada com sucesso", "Erro ao criar linha")
        helpers?.resetForm()
        refetch()
    }

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} validateOnBlur={false} validateOnChange={false} validateOnMount={false}>
            {({ values }) => {
                return (
                    <div>
                        <Typography variant="h6" color="secondary">
                            Criar Verificação
                        </Typography>
                        <Form>
                            <Grid container className={styles.CreateSurveyRecordVerification} alignItems="flex-end">
                                <span style={{ minWidth: 120, marginRight: 30 }}>
                                    <VerificationScopeField autoFocus={true} disabled={isNotUserEdit} />
                                </span>
                                <span style={{ minWidth: 250, marginRight: 30 }}>
                                    <VerificationField scope={values?.verificationScope?.id} disabled={isNotUserEdit} />
                                </span>
                                <span style={{ minWidth: 600, marginRight: 30 }}>
                                    <InputTextField
                                        name="description"
                                        multiline
                                        minRows={1}
                                        maxRows={5}
                                        fullWidth
                                        label="descrição"
                                        disabled={isNotUserEdit} />
                                </span>

                                <span style={{ minWidth: 250, marginRight: 30 }}>
                                    <SurveyRecordVerificationClassificationField disabled={isNotUserEdit} />
                                </span>

                                <span style={{ minWidth: 250, marginRight: 30 }}>
                                    <Show on={values?.surveyRecordVerificationClassification?.id === "3"}>
                                        <SurveyRecordVerificationStateField disabled={isNotUserEdit} />
                                    </Show>
                                </span>

                                <IconButton type="submit" disabled={isNotUserEdit}>
                                    <Save fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Form>
                    </div>
                )
            }}
        </Formik >
    )
}

export default CreateSurveyRecordVerification
