import { TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useWorkPermitFieldQuery } from '../../generated/graphql';
import { _get } from '../../services/utils';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface WorkPermitFieldProps {
    name?: string
    label?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
}

const WorkPermitField: FC<WorkPermitFieldProps> = props => {
    const { name = "workPermit", label = "Permissão Trabalho", textFieldProps = { variant: "standard" }, disabled } = props

    const getOptionLabel = (option: any, field: string) => {
        if (option.node) return _get(option.node, field)
        return _get(option, field) + ` (${option?.contract?.name})` || ""
    }

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useWorkPermitFieldQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            queryFilter={{ isPublished: { is: true }, state: { eq: "APPROVED" } }}
            noOptionsText="Sem permissões ativas"
        />
    )
}

export default WorkPermitField
