import { useCreateOneUserMutation, User } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";
import { randomString } from "../utils/strings";
import { useConcession } from "./useConcession";

interface CreateUserData {
    name: string
    email: string
    defaultConcession?: string
    password?: string
}

export default function useUserManager() {

    const [_create] = useCreateOneUserMutation()
    const concession = useConcession()

    const createUser = async (data: CreateUserData): Promise<OperationResult<User>> => {
        const { name, email, defaultConcession = concession?.id, password = randomString() } = data

        if (!defaultConcession) {
            throw new Error("Concession is undefined")
        }

        try {
            const response = await _create({
                variables: {
                    input: { user: { name, email, defaultConcession, password } }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response.errors)
                return { success: false, errors }
            }

            return { success: true, data: response.data?.createOneUser }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createUser
    }
}
