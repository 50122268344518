import { Add } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import { useState } from "react";
import { CreateFullSubcontractOrganizationData, SubcontractOrganization } from "../../hooks/useSubcontractOrganizationManager";
import useToast from "../../hooks/useToast";
import AccreditationTypeField from "../AccreditationTypeField/AccreditationTypeField";
import AddIconButton from '../AddIconButton/AddIconButton';
import CreateSubcontractOrganization from "../CreateSubcontractOrganization/CreateSubcontractOrganization";
import CustomModal from "../CustomModal/CustomModal";
import InputDateField from "../InputDateField/InputDateField";
import InputTextField from "../InputTextField/InputTextField";
import ShowSelectedAccreditation from '../ShowSelectedAccreditation/ShowSelectedAccreditation';
import SubcontractOrganizations from "../SubcontractOrganizations/SubcontractOrganizations";
import SubmitButton from "../SubmitButton/SubmitButton";
import { subcontractFormInit } from "./SubcontractFormInit";

interface CreateSubcontractProps {
    subcontractOrganizations: SubcontractOrganization[]
    createFullSubcontractOrganization: (data: CreateFullSubcontractOrganizationData) => any
    onSubmit: (values: FormikValues) => void
}

export default function CreateSubcontract(props: CreateSubcontractProps) {
    const { subcontractOrganizations, createFullSubcontractOrganization, onSubmit } = props

    const { schema, initial } = subcontractFormInit()
    const [errors] = useState<string[]>([])
    const { successToast, errorToast } = useToast()
    const [isOpen, setIsOpen] = useState<boolean>(false)


    const handleSubmit = async (values: FormikValues) => {
        const { name, tin, address, zipCode, contactName, contactEmail } = values

        const result = await createFullSubcontractOrganization({ name, tin, address, zipCode, contactName, contactEmail })

        if (result.success) {
            successToast("Entidade criada com sucesso")
            setIsOpen(false)
        } else {
            errorToast(result?.errors?.join(",") || "")
        }
    }

    return (
        <div style={{ padding: 20 }}>
            <Formik initialValues={initial} onSubmit={onSubmit} validationSchema={schema} validateOnBlur={false} validateOnChange={false} validateOnMount={false}>
                {({ values }) => {
                    return (
                        <Form>
                            <Typography variant="h4" color="primary">
                                Criar Subcontrato
                            </Typography>

                            <div style={{ color: "red" }}>
                                {errors.map(error => <p key={error}>{error}</p>)}
                            </div>

                            <Grid container justifyContent="space-between" alignContent="middle">
                                <Grid item xs={10}>
                                    <SubcontractOrganizations options={subcontractOrganizations} />
                                </Grid>
                                <Grid container item xs={2} justifyContent="flex-end" alignItems="center">
                                    <AddIconButton onClick={() => setIsOpen(true)} title="Adicionar entidade subcontratada" />
                                </Grid>
                            </Grid>

                            <p>
                                <InputTextField name="description" label="descrição" fullWidth />
                            </p>

                            <Grid container justifyContent="space-between" spacing={4}>

                                <Grid item xs={6} container justifyContent="flex-start" direction="column">
                                    <Typography variant="body1" color="text" gutterBottom>
                                        Início Subcontrato
                                    </Typography>
                                    <InputDateField name="startDate" label="início" />
                                </Grid>
                                <Grid item xs={6} container justifyContent="flex-end" direction="column">
                                    <Typography variant="body1" color="text" gutterBottom>
                                        Fim Subcontrato
                                    </Typography>
                                    <InputDateField name="endDate" label="fim" />
                                </Grid>

                            </Grid>

                            <p>
                                <AccreditationTypeField />
                            </p>

                            <div>
                                <ShowSelectedAccreditation
                                    concessionOrganization={values?.concessionOrganization}
                                    startDate={values?.startDate}
                                    endDate={values?.endDate}
                                    accreditationType={values?.accreditationType}
                                />
                            </div>


                            <Grid container justifyContent="flex-end">
                                <SubmitButton tooltip="Criar subcontrato" />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik >

            <CustomModal isActive={isOpen} handleClose={() => setIsOpen(false)}>
                <CreateSubcontractOrganization onSubmit={handleSubmit} />
            </CustomModal>
        </div>
    )
}
