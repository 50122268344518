import { GridColDef } from '@mui/x-data-grid-pro';
import { relationGetter } from '../../Datagrid/Datagrid';
import { ContractStateRender } from '../../Datagrid/Renders/ContractStateRender';
import { detailsRender } from '../../Datagrid/Renders/DetailsRender';
import { ExecutingOrganizationRender } from '../../Datagrid/Renders/ExecutingOrganization';
import { dateFormatter } from '../../FormatDate/FormatDate';

export default function getContractColumns(hide?: string[]): GridColDef[] {

    const columns: GridColDef[] = [
        { field: "name", headerName: "nome", renderCell: params => detailsRender(params, "contract"), width: 150, filterable: false },
        { field: "contractType", headerName: "tipo contrato", width: 300, valueGetter: params => relationGetter(params, "contractType"), sortable: false, filterable: false },
        { field: "contractState", headerName: "estado", width: 150, renderCell: ContractStateRender, filterable: false, sortable: false },
        { field: "executingOrganization", headerName: "executante", width: 120, renderCell: ExecutingOrganizationRender, sortable: true, filterable: false },
        { field: "startDate", headerName: "início", width: 120, valueGetter: dateFormatter, sortable: true, filterable: false },
        { field: "endDate", headerName: "fim", width: 120, valueGetter: dateFormatter, sortable: true, filterable: false, editable: false },
        { field: "service", headerName: "serviço", width: 100, valueGetter: params => relationGetter(params, "service"), sortable: false, filterable: false },
        { field: "interventionType", headerName: "tipo trabalho", width: 300, valueGetter: params => relationGetter(params, "interventionType"), sortable: false, filterable: false },
    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}
