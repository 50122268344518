import { ConcessionOrganizationUser, ConcessionOrganizationUserRoles, useCreateOneConcessionOrganizationUserMutation } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";

interface CreateConcessionOrganizationUserData {
    userId: string
    concessionOrganizationId: string
    roleId: ConcessionOrganizationUserRoles
}

export default function useConcessionOrganizationUserManager() {

    const [_create] = useCreateOneConcessionOrganizationUserMutation()

    const createConcessionOrganizationUser = async (data: CreateConcessionOrganizationUserData): Promise<OperationResult<ConcessionOrganizationUser>> => {
        const { userId, concessionOrganizationId, roleId } = data

        try {
            const response = await _create({
                variables: {
                    input: {
                        concessionOrganizationUser: {
                            user: userId,
                            concessionOrganization: concessionOrganizationId,
                            role: roleId
                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response.errors)
                return { success: false, errors }
            }

            return { success: true, data: response.data?.createOneConcessionOrganizationUser }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createConcessionOrganizationUser
    }
}
