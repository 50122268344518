import { ArrayParam, DateParam, StringParam } from "use-query-params";
import { FilterConfig } from "../../hooks/useFilters";
import { operatorTaskStates } from "../../hooks/useTaskForm";
import { getCurrentIsoWeek, getCurrentIsoYear, getStartOfDay } from "../../services/utils";
import { TasksListView } from "../../types/types";
import { relationFilterConfig } from "../../utils/filters";
import { customFilterIn, isoWeekExpFunc, startDateExpFunc, stateLookupExpression, taskSearchExpFunc } from "../../utils/lookups";
import TasksActions from "../TasksActions/TasksActions";
import TasksFilters from "../TasksFilters/TaskFilters";
import TasksList from "../TasksList/TasksList";
import TaskProvider from "./TaskProvider";
import styles from './TasksView.module.scss';

const getWeekDefaultValue = () => {
    const week = getCurrentIsoWeek()
    const year = getCurrentIsoYear()
    return `${week}/${year}`
}

export const getTasksFilterConfig = (view: TasksListView, intervention?: string): FilterConfig[] => {

    const commonFilters = [
        { name: "search", variant: StringParam, defaultValue: "", lookupExpFunc: taskSearchExpFunc, alias: "or" },
        relationFilterConfig({ name: "road" }),
        relationFilterConfig({ name: "intervention", defaultValue: intervention ? [intervention] : undefined }),
        relationFilterConfig({ name: "layout" }),
        relationFilterConfig({ name: "interventionLocation" }),
        relationFilterConfig({ name: "startJunction" }),
        relationFilterConfig({ name: "endJunction" }),
        { name: "executingOrganization", variant: ArrayParam, defaultValue: [], lookupExpFunc: customFilterIn }
    ];

    const stateFilter = { name: "state", variant: ArrayParam, lookupExpFunc: stateLookupExpression };
    const startAtFilter = { name: "startAt", variant: DateParam, lookupExpFunc: startDateExpFunc };

    if (view === TasksListView.CONTROLCENTER) {
        return [
            ...commonFilters,
            { ...stateFilter, defaultValue: operatorTaskStates },
            { ...startAtFilter, defaultValue: getStartOfDay() },
        ];
    } else if (view === TasksListView.PLANNING) {
        return [
            ...commonFilters,
            { ...stateFilter, defaultValue: [] },
            { name: "isoWeek", variant: StringParam, defaultValue: getWeekDefaultValue(), lookupExpFunc: isoWeekExpFunc },
            { ...startAtFilter, defaultValue: null },
        ];
    }

    return [
        ...commonFilters,
        { ...stateFilter, defaultValue: [] },
        { ...startAtFilter, defaultValue: null },
    ];
}


const TasksView = () => {

    const view = TasksListView.CONTROLCENTER
    const filterConfig = getTasksFilterConfig(view)

    return (
        <div className={styles.Wrapper}>
            <TaskProvider filterConfig={filterConfig}>
                <TasksActions view={view} />
                <TasksFilters view={view} />
                <TasksList view={view} />
            </TaskProvider>
        </div>
    )
}


export default TasksView
