import { Field } from 'formik';
import { FC } from 'react';
import DatePicker from '../DatePicker/DatePicker';

interface InputDateFieldProps {
    name: string,
    label: string
    disabled?: boolean
    minDate?: any
    maxDate?: any
}

const InputDateField: FC<InputDateFieldProps> = props => {
    const { name, label, disabled, maxDate, minDate } = props
    return (
        <Field
            component={DatePicker}
            name={name}
            label={label}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}

export default InputDateField
