import { TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { useJunctionFieldQuery } from '../../generated/graphql';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface JunctionFieldProps {
    name?: string
    label?: string
    road?: string
    textFieldProps?: TextFieldProps
    disabled?: boolean
}

const JunctionField: FC<JunctionFieldProps> = props => {
    const { name = "junction", label = "Nó", road = null, textFieldProps = { variant: "standard" }, disabled } = props
    const queryFilter = road ? { road: { id: { eq: road } } } : { road: { id: { is: null } } }
    const roadRef = useRef(road);
    const context = useFormikContext()

    useEffect(() => {
        const previousRoad = roadRef.current;
        if (previousRoad && previousRoad !== road) {
            context?.setFieldValue(name, null)
        }
        roadRef.current = road
    }, [road])

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useJunctionFieldQuery}
            queryFilter={queryFilter}
            textFieldProps={textFieldProps}
            disabled={disabled}
        />
    )
}

export default JunctionField
