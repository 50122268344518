import { AutocompleteRenderInputParams, TextField, TextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { Autocomplete } from 'formik-mui';
import { useShowFieldError } from '../../hooks/useShowFieldError';
import styles from './SelectAutocompleteField.module.scss';

interface SelectAutocompleteFieldProps {
    name: string
    label: string
    options: any[]
    field?: string
    textFieldProps?: TextFieldProps
    autoFocus?: boolean
    disabled?: boolean
    getOptionLabel?: any
    getOptions?: any
    noOptionsText?: string
    renderOption?: (props: any, option: any) => JSX.Element
}

export default function SelectAutocompleteField(props: SelectAutocompleteFieldProps) {
    const {
        name,
        label,
        options,
        renderOption,
    } = props

    const { textFieldProps } = props
    const autoFocus = false
    const disabled = false

    const { error, helperText } = useShowFieldError(name)

    return (
        <Field
            className={styles.Field}
            name={name}
            component={Autocomplete}
            disableClearable
            disabled={disabled}
            options={options}
            renderOption={renderOption}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                    error={error}
                    autoFocus={autoFocus}
                    helperText={helperText}
                />
            )}
        />
    )
}
