import { MoreVert } from "@mui/icons-material";
import { MouseEvent } from 'react';
import IconButtonTooltip from "../IconButtonTooltip/IconButtonTooltip";

interface MoreIconButtonProps {
    onClick: (event: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    title?: string
}

export default function MoreIconButton(props: MoreIconButtonProps) {
    const {
        onClick,
        disabled,
        title = "Mais ações"
    } = props
    return (
        <IconButtonTooltip title={title} onClick={onClick} disabled={disabled}>
            <MoreVert />
        </IconButtonTooltip>
    )
}
