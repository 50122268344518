import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { FormatDate } from "../FormatDate/FormatDate"
import styles from './SubcontractDetails.module.scss'

interface SubcontractDetailsProps {
    subcontract: any
}

export default function SubcontractDetails(props: SubcontractDetailsProps) {
    const { subcontract: {
        description,
        endDate,
        startDate,
        concessionOrganization,
        concessionOrganizationAccreditation,
        contractConcessionOrganizationState
    } } = props

    const { organization: { name } } = concessionOrganization
    const state = contractConcessionOrganizationState
    const accreditation = concessionOrganizationAccreditation

    return (
        <div>
            <Typography variant="h4" color="primary">
                Detalhes Subcontrato
            </Typography>
            <TableContainer>
                <Table className={styles.ContractTable} aria-label="subcontract details">
                    <TableBody>
                        <TableRow key={1}>
                            <TableCell>
                                <b>Entidade Subcontratada</b>
                            </TableCell>
                            <TableCell>
                                {name}
                            </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                            <TableCell>
                                <b>Descrição</b>
                            </TableCell>
                            <TableCell>
                                {description}
                            </TableCell>
                        </TableRow>
                        <TableRow key={3}>
                            <TableCell>
                                <b>Estado Subcontrato</b>
                            </TableCell>
                            <TableCell>
                                {state?.name}
                            </TableCell>
                        </TableRow>
                        <TableRow key={4}>
                            <TableCell>
                                <b>Início Subcontrato</b>
                            </TableCell>
                            <TableCell>
                                <FormatDate date={startDate} />
                            </TableCell>
                        </TableRow>
                        <TableRow key={5}>
                            <TableCell>
                                <b>Fim Subcontrato</b>
                            </TableCell>
                            <TableCell>
                                <FormatDate date={endDate} />
                            </TableCell>
                        </TableRow>

                        <TableRow key={6}>
                            <TableCell>
                                <b>Tipo Acreditação</b>
                            </TableCell>
                            <TableCell>
                                {accreditation?.concessionOrganizationAccreditationType?.name} -- {accreditation?.concessionOrganizationAccreditationState?.name}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </div >
    )
}
