import { Close } from '@mui/icons-material';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useShowFieldError } from '../../hooks/useShowFieldError';
import Config from '../../services/config';
import { firstProperty, _get } from '../../services/utils';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { flatContractRoads } from '../ContractDetails/ContractDetails';


/* const Row = (props: any) => {
 *     const { obj, handleRemove } = props
 *     // no ato de atualização precisamos de obj.road.name
 *     // no ato de criação precisamos de obj.name
 *
 *     console.log("row", obj)
 *
 *     // estamos a atualizar
 *     if (obj?.road) {
 *         return (
 *             <div>{obj?.road?.name} | ({obj?.road?.id}) | &nbsp;
 *                 <span onClick={() => handleRemove(obj?.road)}> x</span>
 *             </div>
 *         )
 *     }
 *
 *     // estamos a criar
 *     return (
 *         <div>{obj?.name} | ({obj?.id}) |&nbsp;
 *             <span onClick={() => handleRemove(obj)}> x</span>
 *         </div>
 *     )
 * } */


const Row = (props: any) => {
    const { obj, handleRemove, rowOptions, disabled } = props
    // no ato de atualização precisamos de obj.road.name
    // no ato de criação precisamos de obj.name

    const warningMessage = "Tem a certeza que pretende remover via?"

    const editPath = rowOptions.path

    const editPathName = rowOptions.path + ".name"
    const editPathId = rowOptions.path + ".id"

    // estamos a atualizar objetos carregados da api
    if (_get(obj, editPath)) {
        return (
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginLeft: 10 }}>
                <Typography variant="body1" color="text" gutterBottom={false}>
                    {_get(obj, editPathName)} {/* | <small>ascendente</small> */}
                </Typography>
                <ConfirmButton onClick={() => handleRemove(_get(obj, editPath))} message={warningMessage} disabled={disabled}>
                    <Close style={{ fontSize: 18 }} />
                </ConfirmButton>
            </Grid>
        )
    }

    // estamos a criar
    // ou a atualizar objetos criados localmente
    return (
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginLeft: 10 }}>
            <Typography variant="body1" color="text" gutterBottom={false}>
                {obj?.name} {/* | <small>ascendente</small> */}
            </Typography>
            <ConfirmButton onClick={() => handleRemove(obj)} message={warningMessage} disabled={disabled}>
                <Close style={{ fontSize: 18 }} />
            </ConfirmButton>
        </Grid>
    )
}



interface ComboListProps {
    value: any
    handleRemove: any
    emptyMessage: string
    rowOptions: { path: string, key?: string, value?: string }
    disabled?: boolean
}

const ComboList: FC<ComboListProps> = props => {
    const { value, handleRemove, emptyMessage, rowOptions, disabled } = props
    if (!value) return <Typography variant="body1" color="text">{emptyMessage}</Typography>
    return value?.map((obj: any) => <Row key={obj?.id} obj={obj} handleRemove={handleRemove} rowOptions={rowOptions} disabled={disabled} />)
}


interface ComboFieldProps {
    field: any
    form: any
    label: string
    emptyMessage?: string
    query: any
    getOptions?: (data: any) => any
    handleRemoveElement: (element: any) => any
    rowOptions: { path: string, key?: string, value?: string }
    filter?: {}
    disabled?: boolean
}


const _getOptions = (data: any) => {
    if (!data) return []
    const first = firstProperty(data)
    const nodes = _get(data, `${first}.nodes`)
    return nodes?.map((obj: any) => { return { id: obj?.id, name: obj?.name } })
}

/**
 * @param rowOptions - como usar?
 */
const ComboField: FC<ComboFieldProps> = (props: any) => {

    const {
        field,
        form,
        label,
        emptyMessage = "Seleção vazia",
        query = () => null,
        getOptions,
        handleRemoveElement,
        rowOptions,
        filter,
        disabled = false
    } = props

    const { error, helperText } = useShowFieldError(field.name)

    const { data } = query({ fetchPolicy: "no-cache", variables: { filter } })

    const options: any = getOptions ? getOptions(data) : _getOptions(data)

    const handleChange = (_: any, value: any) => {
        if (!field.value) {
            form?.setFieldValue(field.name, [value])
        } else {
            const roads = flatContractRoads(field.value)
            if (roads.includes(value.id)) return
            form?.setFieldValue(field.name, [...field.value, value])
        }
    }

    const handleRemove = (value: any) => {
        const newValue = field.value.filter((element: any) => {
            return handleRemoveElement(element, value)
            // no ato de atualização funciona duas maneiras
            // no ato de criação funciona assim
            /* if (element?.road) {
             *     return element?.road?.id != value.id
             * } else {
             *     return element?.id != value.id
             * } */
        })
        form?.setFieldValue(field.name, newValue)
    }

    const getOptionLabel = (option: any) => {
        return option?.name
    }

    return (
        <div>
            <Autocomplete
                disablePortal
                value={null}
                options={options}
                getOptionLabel={getOptionLabel}
                blurOnSelect={true}
                noOptionsText={Config.NO_OPTIONS_TEXT}
                onChange={handleChange}
                fullWidth
                disabled={disabled}
                renderInput={(params) => <TextField {...params} label={label} variant="standard" fullWidth error={error} helperText={helperText} />}
            />

            <ComboList value={field?.value} handleRemove={handleRemove} emptyMessage={emptyMessage} rowOptions={rowOptions} disabled={disabled} />

        </div >
    )
}

export default ComboField;
