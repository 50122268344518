import { Article, CloseFullscreen, Delete, Done, HourglassBottom, LinearScale, Pause, Receipt, Send } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { useDeleteOneContractMutation, useSendContractClosedNotificationMutation, useSendContractOpenedNotificationMutation, useUpdateOneContractMutation } from "../../generated/graphql";
import useGoTo from "../../hooks/useGoto";
import usePermission from "../../hooks/usePermission";
import useToast from "../../hooks/useToast";
import { Actions, Subjects } from "../../services/ability";
import MenuDivider from "../MenuDivider/MenuDivider";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import MoreIconButton from "../MoreIconButton/MoreIconButton";
import Permission from "../Permission";

interface ContractViewMoreActionsProps {
    contract: any
    refetch: any
}

export enum ContractStates {
    Preparation = "1",
    Ongoing = "2",
    Suspended = "3",
    Concluded = "4",
    Closed = "5",
    Warranty = "6",
    Received = "7",
}

export const getAllowedTransitions = (currentState: string) => {

    if (!currentState) return []

    const transitionsMap: any = {
        [ContractStates.Preparation]: [ContractStates.Ongoing, ContractStates.Suspended],
        [ContractStates.Ongoing]: [ContractStates.Suspended, ContractStates.Concluded],
        [ContractStates.Suspended]: [ContractStates.Ongoing],
        [ContractStates.Concluded]: [ContractStates.Closed],
        [ContractStates.Closed]: [ContractStates.Warranty],
        [ContractStates.Warranty]: [ContractStates.Received],
        [ContractStates.Received]: []
    }
    return transitionsMap[currentState]
}


const ContractViewMoreActions: FC<ContractViewMoreActionsProps> = props => {
    const { contract, refetch } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [sendContractOpenedNotification] = useSendContractOpenedNotificationMutation()
    const [sendContractClosedNotification] = useSendContractClosedNotificationMutation()
    const [updateContract] = useUpdateOneContractMutation()
    const [deleteContract] = useDeleteOneContractMutation()
    const { feedback, errorToast } = useToast()
    const { goToContracts } = useGoTo()

    const allowUpdate = usePermission(Actions.UPDATE, Subjects.CONTRACT)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeAndRefetch = () => {
        handleClose();
        refetch();
    }

    const handleOpenNotification = async () => {
        if (contract?.id) {
            const response = await sendContractOpenedNotification({ variables: { id: contract?.id } })
            feedback(!response?.errors?.length, "Email de abertura enviado.", "Erro ao enviar email de abertura.")
            closeAndRefetch()
        }
    }

    const handleCloseNotification = async () => {
        if (contract?.id) {
            const response = await sendContractClosedNotification({ variables: { id: contract?.id } })
            feedback(!response?.errors?.length, "Email de fecho enviado.", "Erro ao enviar email de fecho.")
            closeAndRefetch()
        }
    }

    const handleDelete = async () => {
        if (contract?.id) {
            try {
                const response = await deleteContract({ variables: { input: { id: contract.id } } })
                feedback(!response?.errors?.length, "Contrato removido com sucesso", "Erro ao remover contrato.")
                if (!response?.errors?.length) goToContracts()
            } catch (e) {
                errorToast("Erro Desconhecido.",)
            }
        }
    }

    const transitionTo = async (stateTo: string) => {
        if (contract?.id) {
            const response = await updateContract({ variables: { input: { id: contract?.id, update: { contractState: stateTo } } } })
            feedback(!response?.errors?.length, "Transição bem sucedida.", "Erro na transição.")
            closeAndRefetch()
        }
    }

    const getTransitionData = (currentState: string) => {

        const transitions: string[] = getAllowedTransitions(currentState)
        const actionsMap: any = [
            {
                stateTo: "1",
                icon: <HourglassBottom fontSize="small" />,
                label: "Preparar Contrato",
                action: "update",
                subject: "contract"
            },
            {
                stateTo: "2",
                icon: <LinearScale fontSize="small" />,
                label: "Colocar em Curso",
                action: "update",
                subject: "contract"
            },
            {
                stateTo: "3",
                icon: <Pause fontSize="small" />,
                label: "Suspender Contrato",
                action: "update",
                subject: "contract"
            },
            {
                stateTo: "4",
                icon: <Done fontSize="small" />,
                label: "Concluir Contrato",
                action: "update",
                subject: "contract"
            },
            {
                stateTo: "5",
                icon: <CloseFullscreen fontSize="small" />,
                label: "Fechar Contrato",
                action: "update",
                subject: "contract"

            },
            {
                stateTo: "6",
                icon: <Article fontSize="small" />,
                label: "Colocar em Garantia",
                action: "update",
                subject: "contract"

            },
            {
                stateTo: "7",
                icon: <Receipt fontSize="small" />,
                label: "Receção Final",
                action: "update",
                subject: "contract"

            }
        ]

        return actionsMap.filter((obj: any) => transitions.includes(obj.stateTo))
    }


    const showSendOpenedNotification = () => {
        return allowUpdate && !contract?.contractOpenedNotificationSent && +contract?.contractState?.id === 2;
    }


    const showSendClosedNotification = () => {
        return allowUpdate && contract?.contractOpenedNotificationSent && +contract?.contractState?.id === 5;
    }


    const allowedTransitions = getAllowedTransitions(contract?.contractState?.id)

    return (
        <div>
            <MoreIconButton onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: 500 }}
            >
                {
                    getTransitionData(contract?.contractState?.id).map((obj: any) => {
                        return (
                            <MenuItem onClick={() => transitionTo(obj.stateTo)} disabled={!allowUpdate}>
                                <ListItemIcon>
                                    {obj.icon}
                                </ListItemIcon>
                                <ListItemText>{obj.label}</ListItemText>
                            </MenuItem>
                        )
                    })
                }

                <MenuDivider on={allowedTransitions?.length} />

                {showSendOpenedNotification() && <MenuItem onClick={handleOpenNotification}>
                    <ListItemIcon>
                        <Send fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Email Abertura</ListItemText>
                </MenuItem>}

                {showSendClosedNotification() && <MenuItem onClick={handleCloseNotification} disabled={contract?.contractClosedNotificationSent}>
                    <ListItemIcon>
                        <Send fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Email Fecho</ListItemText>
                </MenuItem>}

                <MenuDivider on={showSendOpenedNotification() || showSendClosedNotification()} />

                <Permission action={Actions.DELETE} subject={Subjects.CONTRACT} passThrough>
                    {allowed =>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover Contrato"
                            allowed={allowed}
                            confirmationRequired={true}
                            listItemTextColor="red"
                        />
                    }
                </Permission>


            </Menu>
        </div>
    )
}

export default ContractViewMoreActions
