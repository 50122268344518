import { Fade } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import Config from '../../services/config';
import Datagrid from '../Datagrid/Datagrid';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import getInterventionColumns from './Helpers/getInterventionColumns';
import styles from './InterventionsList.module.scss';

const InterventionsList = () => {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.interventions?.nodes
    const columns = useMemo(() => getInterventionColumns(), [])
    const rows = nodes || []

    useEffect(() => {
        context.setView("interventionsView")
        context.setColumnDefs(columns)
    }, [])

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.InterventionsList}>
                <Datagrid
                    rows={rows}
                    columns={columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                />
            </div>
        </Fade>
    );
}

export default InterventionsList
