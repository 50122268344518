import { Paper, Popover, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material"
import { DocumentTemplate, DocumentTemplateSortFields, SortDirection, useDocumentTemplatesQuery } from "../../generated/graphql"
import { downloadRequestStatic } from "../../services/utils"
import DownloadIconButton from "../DownloadIconButton/DownloadIconButton"
import styles from './DocumentTemplatesPopover.module.scss'

interface DocumentTemplatesPopoverProps {
    anchor: HTMLButtonElement | null
    setAnchor: any
}

export default function DocumentTemplatesPopover(props: DocumentTemplatesPopoverProps) {
    const { anchor, setAnchor } = props
    const { data } = useDocumentTemplatesQuery({
        variables: {
            sorting: { field: DocumentTemplateSortFields.Name, direction: SortDirection.Asc }
        }
    })

    const popoverOpen = Boolean(anchor)
    const id = popoverOpen ? 'popover' : undefined

    const handleDownload = (documentTemplate: Partial<DocumentTemplate>) => {
        if (documentTemplate.path && documentTemplate.name) {
            downloadRequestStatic(documentTemplate.path, documentTemplate.name)
        }
    }

    return (
        <div>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="modelos de documento">
                        <TableBody>
                            {data?.documentTemplates?.nodes.map(documentTemplate =>
                                <TableRow key={documentTemplate.id}>
                                    <TableCell className={styles.TemplateName}>
                                        <Tooltip title={documentTemplate.name}>
                                            <span>
                                                {documentTemplate.name}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <DownloadIconButton onClick={() => handleDownload(documentTemplate)} />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Popover >
        </div>
    )
}
