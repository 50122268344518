import { useConcessionOrganizationAccreditationsQuery } from "../../generated/graphql"
import { noop } from "../../services/utils"
import { DataProviderProps } from "../../types/types"
import DataViewProvider from "../DataViewProvider/DataViewProvider"

const AccreditationsDataSource = {
    createFunc: noop,
    readFunc: useConcessionOrganizationAccreditationsQuery,
    updateFunc: noop,
    deleteFunc: noop
}

export default function AccreditationProvider(props: DataProviderProps) {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={AccreditationsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}
