import { Field } from "formik"
import { FC } from "react"
import FieldText from "../FieldText/FieldText"

interface InputNumberFieldProps {
    name: string
    label?: string
    size?: "small" | "medium"
    variant?: "standard" | "outlined" | "contained"
    fullWidth?: boolean
    disabled?: boolean
}

const InputNumberField: FC<InputNumberFieldProps> = props => {
    const { name, variant, label = name, size = "medium", disabled, fullWidth } = props
    return (
        <Field
            component={FieldText}
            name={name}
            label={label}
            type="number"
            size={size}
            variant={variant}
            disabled={disabled}
            fullWidth={fullWidth}
        />
    )
}

export default InputNumberField
