import { Add } from "@mui/icons-material"
import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { FormikValues } from "formik"
import { FC } from "react"
import { Badge as BadgeType, useCreateManyBadgesMutation } from "../../generated/graphql"
import useCustomModal from "../../hooks/useCustomModal"
import useToast from "../../hooks/useToast"
import Badge from "../Badge/Badge"
import CreateBadges from "../CreateBadges/CreateBadges"
import CustomModal from "../CustomModal/CustomModal"
import Empty from "../Empty/Empty"
import Permission from "../Permission"
import { PDFMode } from "../WorkPermitView/PDF"
import styles from './Badges.module.scss'

interface BadgesProps {
    badges?: Partial<BadgeType>[] | null
    workPermitId?: string | null
    refetch: any
    show?: boolean
    generate: (badge: Partial<BadgeType>, mode: PDFMode) => void
    disabled?: boolean
}

export const group = (values: FormikValues) => {
    const groups: any = []

    for (const [key, value] of Object.entries(values)) {

        if (value) {
            const components = key.split("_")
            const field = components[0]
            const index = parseInt(components[1])

            if (groups[index]) {
                groups[index] = { ...groups[index], [field]: value }
            } else {
                groups[index] = { [field]: value }
            }
        }
    }
    return groups
}

export const expand = (objarr: any[], data: {}) => {
    return objarr.map((obj: any) => Object.assign(obj, data))
}


const Badges: FC<BadgesProps> = props => {
    const { badges, refetch, workPermitId, generate, show = true, disabled = false } = props
    const { state, open, close } = useCustomModal()
    const [createManyBadges] = useCreateManyBadgesMutation()
    const { successToast } = useToast()

    if (!show) return null

    const handleSubmit = async (values: FormikValues) => {
        const groups = expand(group(values), { workPermit: workPermitId })
        createManyBadges({ variables: { input: { badges: groups } } }).then(() => {
            refetch().then(() => {
                successToast("Dísticos criados com sucesso.")
                close()
            })
        })
    }

    return (
        <Grid container id={styles.Badges}>
            <Grid container justifyContent="space-between" alignItems="flex-start" className={styles.Title}>
                <Typography variant="h4" color="primary">Criação Dísticos</Typography>
                <Permission action="create" subject="badge" passThrough>
                    {allowed =>
                        <IconButton onClick={open} disabled={!allowed || disabled}>
                            <Tooltip title="Criar Dísticos">
                                <Add />
                            </Tooltip>
                        </IconButton>
                    }
                </Permission>
            </Grid>
            <div style={{ width: "100%" }}>
                {badges?.map(badge => <Badge badge={badge} key={badge.id} generate={generate} refetch={refetch} />)}
                {!badges?.length && <Empty message="Sem Dísticos" variant="h6" />}
            </div>
            <CustomModal isActive={state} handleClose={close} maxWidth="md">
                <CreateBadges submit={handleSubmit} />
            </CustomModal>
        </Grid>
    )
}

export default Badges
