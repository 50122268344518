import { Add, Close, Delete } from "@mui/icons-material"
import { Button, Card, CardContent, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC, useState } from "react"
import { Concession, ConcessionUser, User, useUsersLazyQuery } from "../../generated/graphql"
import useConcession from "../../hooks/useConcession"
import useToast from "../../hooks/useToast"
import useTranslate from "../../hooks/useTranslate"
import { AppErrors } from "../../services/config"
import ConcessionUserRoleField from "../ConcessionUserRoleField/ConcessionUserRoleField"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import InputPasswordField from "../InputPasswordField/InputPasswordField"
import InputTextField from "../InputTextField/InputTextField"
import MoreMenu from "../MoreMenu/MoreMenu"
import SearchUserByEmailField from "../SearchUserByEmailField/SearchUserByEmailField"
import settingsStyles from '../SettingsView/SettingsView.module.scss'
import styles from './UserEditor.module.scss'

interface UserEditorProps {
    selected: any
    store: any
}


interface UserEditorCreateProps {
    onCreate: any
    onDelete: any
    onCreateUser: any
}

const CreateUserForm = (props: any) => {
    const { onCreateUser, onCreate, email } = props
    const { errorToast } = useToast()
    const concession = useConcession()

    const initialValues = { email, defaultConcession: concession?.id }

    const handleSubmit = async (values: FormikValues) => {
        const { concessionUserRole, ...user } = values
        let response = null
        try {
            response = await onCreateUser(user)
            if (!response?.errors?.length) {
                response = await onCreate(response?.data?.createOneUser, concessionUserRole?.id)
                // feedback(!response?.errors?.length, "Utilizador criado com sucesso", "Erro ao criar utilizador.")
            } else {
                errorToast("Erro ao criar utilizador. Verifique se o email está associado a outra conta.")
            }

        } catch (e) {
            errorToast("Erro ao criar utilizador. Verifique se o email está associado a outra conta.")
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} >
            {() => {
                return (
                    <Form className={styles.CreateUserForm}>
                        <p><InputTextField name="name" label="nome" fullWidth /></p>
                        <p><InputTextField name="email" label="email" fullWidth /></p>
                        <p><InputPasswordField /></p>
                        <p><ConcessionUserRoleField /></p>
                        <Grid container justifyContent="flex-end">
                            <Button type="submit" variant="contained">Criar</Button>
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )

}


const UserNotFound = (props: any) => {
    const { email, setCreateState } = props
    return (
        <div className={styles.NoUserFound}>
            <Typography variant="body1" color="secondary" className={styles.NotFound}>
                Não existe nenhum utilizador registado no GIN com o email <b>{email}</b>
            </Typography>
            <Typography variant="body1" color="secondary" className={styles.Create}>Se pretender criar um novo utilizador clique no botão em baixo.</Typography>
            <Button variant="outlined" color="primary" onClick={setCreateState}>Criar Novo Utilizador</Button>
        </div >
    )
}


const UserEditorCreate: FC<UserEditorCreateProps> = props => {
    const { onCreate, onDelete, onCreateUser } = props
    const [query, { data }] = useUsersLazyQuery()
    const [state, setState] = useState<string>("FIND")
    const [submitted, setSubmitted] = useState<boolean>(false)

    const user = data?.users?.nodes[0]


    const handleFindUser = async (values: FormikValues) => {
        const { email } = values
        await query({ variables: { filter: { email: { eq: email } }, paging: { limit: 999 } }, fetchPolicy: "no-cache" })
        setSubmitted(true)
    }

    return (
        <div>
            {state === "FIND" && <Formik initialValues={{ email: "" }} onSubmit={handleFindUser}>
                {({ values }) => {
                    return (
                        <Form>
                            {/* <p>Submetido {submitted ? "sim" : "não"} </p> */}
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={8}>
                                    <SearchUserByEmailField />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button type="submit" variant="contained" size="small">Pesquisar</Button>
                                </Grid>
                            </Grid>

                            {submitted && !data?.users?.nodes?.length && <UserNotFound email={values.email} setCreateState={() => setState("CREATE")} />}
                            {values.email && user && <div style={{ marginTop: 30 }}>
                                <Typography variant="body1" color="secondary">Já existe um utilizador com email <b>{values.email}</b>
                                </Typography>

                            </div>}
                        </Form>
                    )
                }}
            </Formik>}

            {user && <UserEditorUpdate user={user} onCreate={onCreate} onDelete={onDelete} />}

            {state === "CREATE" && <CreateUserForm onCreateUser={onCreateUser} onCreate={onCreate} email={""} />}
        </div >
    )
}


interface UserEditorUpdateProps {
    user: any
    onCreate: any
    onDelete: any
}


// retorna as funções de um utilizador para uma determinada concessão
const filterUserRolesByConcession = (user: User, concession?: Partial<Concession>) => {
    if (!concession) return []
    return user?.concessionUsers?.filter(concessionUser => concessionUser?.concession?.id === concession?.id);
}

const UserEditorUpdate: FC<UserEditorUpdateProps> = props => {
    const { user, onCreate, onDelete } = props
    const t = useTranslate()
    const concession = useConcession()


    const roles = filterUserRolesByConcession(user, concession)

    const handleSubmit = async (values: FormikValues, helpers: any) => {
        console.log("é este forma?",)
        const { user, concessionUserRole } = values
        await onCreate(user, concessionUserRole?.id)
        helpers.resetForm()
    }

    const initialValues = { user, concessionUserRole: "" }

    return (
        <div className={styles.UserEditorUpdate}>
            <Typography variant="h5" color="primary" gutterBottom={false}>{user?.name}</Typography>
            <Typography variant="body1" color="secondary">{user?.email}</Typography>


            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={8}>
                                    <ConcessionUserRoleField />
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton type="submit" color="primary"><Add /></IconButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            <Grid container justifyContent="space-between" alignItems="flex-end" style={{ paddingTop: 20 }}>
                {!roles?.length && <Typography variant="body1" color="secondary">Utilizador sem funções associadas.</Typography>}
                {roles?.map((concessionUser: ConcessionUser) => (
                    <Grid item xs={8} key={concessionUser?.id} className={styles.Role}>
                        {t(concessionUser?.role, "lowercase")}
                        <ConfirmButton onClick={() => onDelete(concessionUser)} message="Tem a certeza que pretende remover função?">
                            <Close fontSize="small" />
                        </ConfirmButton>
                    </Grid>
                )
                )}
            </Grid>
        </div>
    )
}

// temos o modo / estado search e o create

const UserEditor: FC<UserEditorProps> = props => {
    const { selected, store } = props
    const { feedback, errorToast } = useToast()

    const title = "Pesquisar Utilizador"

    const handleDelete = async (concessionUser: ConcessionUser) => {
        const response = await store?.concessionUser?.remove(concessionUser?.id)
        feedback(!response?.errors?.length, "Função removida com sucesso", "Erro ao remover função")
        if (!response?.errors?.length) store.refetch()
    }

    const handleCreate = async (user: any, role: any) => {
        if (user && role) {
            try {
                const response = await store?.concessionUser?.create(user?.id, role)
                feedback(!response?.errors?.length, "Função adicionada com sucesso", "Erro ao adicionar função")
                if (!response?.errors?.length) store.refetch()
            } catch (e: any) {
                const message = e?.message?.startsWith("duplicate key value") ? AppErrors.NO_DUPLICATE_ROLE : AppErrors.UNKNOWN_ERROR
                errorToast(message)
            }
        }
    }

    const handleCreateUser = async (user: Partial<User>) => {
        return store?.create(user)
    }


    return (
        <Card className={settingsStyles.SettingsEditor}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Typography variant="h4" color="primary">{title}</Typography>
                    <MoreMenu>
                        <MenuItem disabled={true}>
                            <ListItemIcon>
                                <ConfirmButton onClick={() => handleDelete(selected)}>
                                    <Delete fontSize="small" />
                                </ConfirmButton>
                            </ListItemIcon>
                            <ListItemText>
                                Remover
                            </ListItemText>
                        </MenuItem>


                    </MoreMenu>
                </Grid>

                {!selected ? <UserEditorCreate onCreate={handleCreate} onCreateUser={handleCreateUser} onDelete={handleDelete} /> : <UserEditorUpdate user={selected} onCreate={handleCreate} onDelete={handleDelete} />}

            </CardContent>
        </Card >

    )
}

export default UserEditor
