import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';
import styles from '../CustomModal/CustomModal.module.scss';

interface CustomModalProps {
    isActive?: boolean
    children: ReactNode | null
    title?: string
    handleClose: (event?: any) => void
    showClose?: boolean
    maxWidth?: 'md' | 'lg' | 'sm'
    fullWidth?: boolean,
    fullScreen?: boolean,
    backgroundColor?: string
    textColor?: string
}


const CustomModal: FC<CustomModalProps> = props => {

    const {
        isActive = false,
        children,
        title,
        handleClose,
        showClose = true,
        maxWidth = "sm",
        fullWidth = true,
        fullScreen = false,
        backgroundColor = "#ffffff",
        textColor = "#000000"
    } = props


    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            onClose={handleClose}
            className={styles.CustomModal}
            open={isActive || false}
        >
            <DialogTitle style={{ backgroundColor }}>{title}</DialogTitle>
            {
                showClose &&
                <IconButton
                    className={styles.CloseAction}
                    aria-label="close"
                    onClick={handleClose}
                    style={{ color: textColor }}
                >
                    <Close />
                </IconButton>
            }
            <DialogContent onClick={event => event.stopPropagation()} style={{ backgroundColor }}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CustomModal;
