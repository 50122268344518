import { useCreateOneVerificationScopeMutation, useDeleteOneVerificationScopeMutation, useUpdateOneVerificationScopeMutation, useVerificationScopesQuery } from "../../generated/graphql"

const useVerificationScopeStore = () => {
    const query = useVerificationScopesQuery
    const name = "verificationScope"
    const [create] = useCreateOneVerificationScopeMutation()
    const [update] = useUpdateOneVerificationScopeMutation()
    const [remove] = useDeleteOneVerificationScopeMutation()
    return { query, name, create, update, remove }
}

export default useVerificationScopeStore
