import * as Yup from "yup";
import { Intervention } from "../../generated/graphql";
import { getInitialValues } from "../../services/config";
import { nextISOWeek } from "../../services/utils";
import { getISOWeekWithYearFromDateString, isWeekWithYearInRange } from "../../utils/dates";

const getSchema = () => {
    const { year, week } = nextISOWeek()

    return Yup.object().shape({
        workPermit: Yup.mixed().required().default(null),
        year: Yup.number().integer().required().default(year),
        week: Yup.number().integer().required().default(week).test(
            "contractBounded", "Fora das datas do contrato",
            function() {
                const { week, year, workPermit } = this.parent
                if (!workPermit) return true
                const { contract } = workPermit
                const { realStartDate, realEndDate } = contract
                const startWeekWithYear = getISOWeekWithYearFromDateString(realStartDate)
                const endWeekWithYear = getISOWeekWithYearFromDateString(realEndDate)
                if (!startWeekWithYear || !endWeekWithYear || !week || !year) return false
                return isWeekWithYearInRange(startWeekWithYear, endWeekWithYear, { week, year })
            })
    })
}

const interventionFormInit = (intervention: Intervention) => {
    const schema = getSchema()
    const initial = intervention ? intervention : getInitialValues(schema)
    return { schema, initial }
}


export default interventionFormInit
