import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Accreditation } from '../../hooks/useAccreditationRecordManager'
import { Actions, Subjects } from '../../services/ability'
import { MaybeDeepPartial } from '../../types/types'
import DisplayState from '../DisplayState/DisplayState'
import InputDateField from '../InputDateField/InputDateField'
import InputTextField from '../InputTextField/InputTextField'
import Permission from '../Permission'
import Show from '../Show/Show'
import Truncate from '../Truncate/Truncate'
import styles from './AccreditationInfo.module.scss'
import isAccreditationEditable from './Helpers/isAccreditationEditable'

interface AccreditationInfoProps {
    accreditation?: MaybeDeepPartial<Accreditation>
}

export default function AccreditationInfo(props: AccreditationInfoProps) {

    const { accreditation } = props

    const organizationName = accreditation?.concessionOrganization?.organization?.name
    const mediatingOrganizationName = accreditation?.mediatingOrganization?.organization?.name
    const accreditationName = accreditation?.name
    const accreditationTypeName = accreditation?.concessionOrganizationAccreditationType?.name
    const state = accreditation?.concessionOrganizationAccreditationState?.name
    const isEditable = isAccreditationEditable(
        accreditation?.concessionOrganizationAccreditationState?.code)
    const disableEditDates = !isEditable

    return (
        <Grid container className={styles.SurveyRecordForm}>
            <Grid item xs={10} className={styles.GeneralSection}>
                <Card style={{ padding: 20 }}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={9} alignItems="middle">
                                <Typography variant="h4" color="primary" style={{ marginBottom: 0 }}>
                                    <Truncate text={accreditationName} />
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                    <Truncate text={`${accreditationTypeName}: ${organizationName}`} />
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <DisplayState stateName={state} />
                            </Grid>

                            <Show on={!!mediatingOrganizationName}>
                                <Grid container className={styles.MediatingOrganization}>
                                    <Typography variant="body2">
                                        <Truncate text={`Processo de acreditação mediado pela ${mediatingOrganizationName}`} />
                                    </Typography>
                                </Grid>
                            </Show>
                        </Grid>

                        <Grid container alignItems="center" style={{ marginTop: 30 }}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={6}>
                                    <Permission action={Actions.UPDATE} subject={Subjects.ACCREDITATION} field="startDate" passThrough >
                                        {allowed =>
                                            <InputDateField name="startDate" label="Início" disabled={!allowed || disableEditDates} />
                                        }
                                    </Permission>
                                </Grid>
                                <Grid item xs={6} container justifyContent="flex-end">
                                    <Permission action={Actions.UPDATE} subject={Subjects.ACCREDITATION} field="endDate" passThrough >
                                        {allowed =>
                                            <InputDateField name="endDate" label="Fim" disabled={!allowed || disableEditDates} />
                                        }
                                    </Permission>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 20 }}>
                                <Permission action={Actions.UPDATE} subject={Subjects.ACCREDITATION} field="observations" passThrough >
                                    {allowed =>
                                        <InputTextField name="observations" label="observações" multiline fullWidth rows={3} disabled={!allowed} />
                                    }
                                </Permission>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )
}
