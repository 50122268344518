import { Autocomplete, TextField } from "@mui/material";
import { ConcessionOrganization, ContractConcessionOrganization } from '../../generated/graphql';
import { useShowArrayFieldError } from "../../hooks/useShowArrayFieldError";
import Config from "../../services/config";

export const findByOrganizationType = (contractConcessionOrganizations: ContractConcessionOrganization[], organizationType: number) => {
    return contractConcessionOrganizations.find(contractOrganization => +contractOrganization?.concessionOrganization?.organizationType?.id === organizationType)
}

export const getContractExecutingOrganization = (contractConcessionOrganizations: ContractConcessionOrganization[]) => {
    return findByOrganizationType(contractConcessionOrganizations, 2)
}

export const getContractContractingOrganization = (contractConcessionOrganizations: ContractConcessionOrganization[]) => {
    return findByOrganizationType(contractConcessionOrganizations, 1)
}

const findConcessionOrganizationOfType = (contractConcessionOrganizations: any[], organizationType: number) => {
    return contractConcessionOrganizations?.find((contractConcessionOrganization: any) => {
        const onCreateCondition = contractConcessionOrganization?.organizationType?.id == organizationType
        const onUpdateCondition = +contractConcessionOrganization?.concessionOrganization?.organizationType?.id === organizationType
        return onCreateCondition || onUpdateCondition
    })
}

const matchOption = (options: ConcessionOrganization[], concessionOrganization?: ConcessionOrganization) => {
    if (!concessionOrganization) return null
    return options.find((option) => {
        // @ts-ignore
        return option?.id === concessionOrganization?.concessionOrganization?.id || option?.id === concessionOrganization?.id
    })
}

const ContractConcessionOrganizationAutocomplete = (props: any) => {
    const { form, field, options, organizationType, label, disabled = false, required = true } = props

    const { error, helperText } = useShowArrayFieldError(field.name, label, (value: any) => +value?.organizationType?.id === organizationType || !required)

    const filterOut = (concessionOrganizations: ConcessionOrganization[]) => {
        return concessionOrganizations.filter((
            // @ts-ignore
            concessionOrganization) => +concessionOrganization?.concessionOrganization?.organizationType?.id !== organizationType)
    }

    const handleChange = (_: any, value: any) => {
        const previousValues = field.value ? filterOut(field.value) : []
        value = [...previousValues, value]
        form.setFieldValue(field.name, value)
    }

    const getOptionLabel = (option: ConcessionOrganization): string => {
        return option?.organization?.name || ""
    }

    const getValue = (options: ConcessionOrganization[], value: ContractConcessionOrganization[]) => {
        if (!options) return null
        const concessionOrganizationOfType = findConcessionOrganizationOfType(value, organizationType)
        const matchedOption = matchOption(options, concessionOrganizationOfType)
        return matchedOption || null
    }

    const value = getValue(options, field.value)

    return (
        <div>
            <Autocomplete
                disablePortal
                options={options}
                sx={{ width: 300 }}
                value={value}
                noOptionsText={Config.NO_OPTIONS_TEXT}
                getOptionLabel={getOptionLabel}
                onChange={handleChange}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} variant="standard" label={label} error={error && required} helperText={required ? helperText : null} />
                }
            />
        </div>
    )

}

export default ContractConcessionOrganizationAutocomplete
