import { GridColDef } from "@mui/x-data-grid-pro"
import { relationGetter } from "../../Datagrid/Datagrid"
import { ContractRender } from "../../Datagrid/Renders/ContractRender"
import { detailsRender } from "../../Datagrid/Renders/DetailsRender"
import { PublishedRender } from "../../Datagrid/Renders/PublishedRender"
import { SurveyRecordStateRender } from "../../Datagrid/Renders/SurveyRecordStateRender"
import { dateTimeFormatter } from "../../FormatDate/FormatDate"
import { isPublishedFormatter } from "../../WorkPermitsList/WorkPermitsList"

export default function getSurveyRecordColumns(hide?: string[]): GridColDef[] {

    const columns: GridColDef[] = [
        { field: "name", headerName: "nome", renderCell: params => detailsRender(params, "survey-record"), width: 150, filterable: false },
        { field: "contract", headerName: "contrato", width: 150, renderCell: params => ContractRender(params), sortable: false, filterable: false },
        { field: "author", headerName: "autor", width: 120, valueGetter: params => relationGetter(params, "user"), sortable: false, filterable: false },
        { field: "surveyedAt", headerName: "data visita", width: 140, valueGetter: dateTimeFormatter, filterable: false, editable: false },
        { field: "road", headerName: "via", width: 100, valueGetter: params => relationGetter(params, "road"), sortable: false, filterable: false },
        { field: "startJunction", headerName: "nó inicial", width: 150, valueGetter: params => relationGetter(params, "startJunction"), sortable: false, filterable: false },
        { field: "endJunction", headerName: "nó final", width: 150, valueGetter: params => relationGetter(params, "endJunction"), sortable: false, filterable: false },
        { field: "startPk", headerName: "pk inicial", width: 150, filterable: false },
        { field: "endPk", headerName: "pk final", width: 150, filterable: false },
        { field: "state", headerName: "estado", width: 150, renderCell: SurveyRecordStateRender, filterable: false, sortable: false },
        { field: "isPublished", headerName: "Publicado", renderCell: PublishedRender, width: 80, filterable: false },
        { field: "weatherCondition", headerName: "meterologia", width: 150, valueGetter: params => relationGetter(params, "weatherCondition"), sortable: false, filterable: false },
        { field: "hasCorrectiveRequests", headerName: "pacs", width: 150, valueFormatter: isPublishedFormatter, sortable: false, filterable: false },
        { field: "openCorrectiveRequests", headerName: "pacs abertas", width: 150, sortable: false, filterable: false },
    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}
