import { PublishedWithChanges, Unpublished } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { gray, green } from "../../services/config";

const getNode = (isPublished: boolean) => {
    if (isPublished) {
        return (
            <Tooltip title="Publicado">
                <PublishedWithChanges fontSize="small" style={{ color: green }} />
            </Tooltip>
        )
    }

    return (
        <Tooltip title="Não publicado">
            <Unpublished fontSize="small" style={{ color: gray }} />
        </Tooltip>
    )
}

interface WorkPermitIsPublishedProps {
    isPublished?: boolean
}

export default function WorkPermitIsPublished(props: WorkPermitIsPublishedProps) {
    const { isPublished } = props
    if (isPublished === undefined || isPublished === null) return null
    const node = getNode(isPublished)
    return (
        <span>{node}</span>
    )
}
