import { Maybe } from "graphql/jsutils/Maybe"
import { FC } from "react"
import { staticFile } from "../../services/utils"
import styles from './ImagePreviewer.module.scss'

interface DocumentPreviewerProps {
    file: Maybe<string>
}

const ImagePreviewer: FC<DocumentPreviewerProps> = props => {

    const { file } = props

    if (!file) return null

    return (
        <div className={styles.ImagePreviewer}>
            <img src={staticFile(file)} />
        </div>
    )
}

export default ImagePreviewer
