import { TasksListView } from "../../../types/types";

export const TASKS_LIST_FILTERS = {
    COMMON: ["road", "startJunction", "endJunction", "interventionLocation"],
    PLANNING: ["intervention", "startAt", "executingOrganization"],
    CONTROLCENTER: ["intervention", "executingOrganization"],
    INTERVENTION: ["startAt"],
}

export const getTasksListFilters = (view: TasksListView): string[] => {
    return [...TASKS_LIST_FILTERS.COMMON, ...TASKS_LIST_FILTERS[view]];
}
