import { Maybe, User, useUsersLazyQuery } from "../generated/graphql";
import Config from "../services/config";
import { DeepPartial } from "../types/types";

interface useFindUserByEmailProps {
    (): [(email: string) => void, { user: Maybe<DeepPartial<User>> }];
}

const useFindUserByEmail: useFindUserByEmailProps = () => {
    const [query, { data }] = useUsersLazyQuery()

    const findUser = async (email: string) => {
        await query({ variables: { filter: { email: { eq: email } }, paging: { limit: Config.MAX_RESULTS_SIZE } }, fetchPolicy: "no-cache" })
    }

    const user = data?.users?.nodes[0] || null
    return [findUser, { user }]
}

export default useFindUserByEmail
