import { Add } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Actions, Subjects } from '../../services/ability';
import ActionsBar from '../ActionsBar/ActionsBar';
import Permission from '../Permission';
import styles from './WorkPermitsActions.module.scss';

export default function WorkPermitsActions() {
    const navigate = useNavigate()
    return (
        <ActionsBar>
            <Grid container justifyContent="flex-between" alignItems="center" className={styles.Wrapper}>
                <Grid item xs={6} container alignContent="center">
                    <Typography variant="h3" color="primary">Permissões</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <Permission action={Actions.CREATE} subject={Subjects.WORKPERMIT}>
                        <Tooltip title="Criar permissão">
                            <IconButton onClick={() => navigate("/create-work-permit")}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Permission>
                </Grid>
            </Grid>
        </ActionsBar>
    )
}
