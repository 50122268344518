import { Can } from "@casl/react"
import { CircularProgress } from "@mui/material"
import { FC, ReactNode } from "react"
import { ErrorBoundary } from 'react-error-boundary'
import { useAuth } from "../services/auth"

interface PermissionProps {
    action: string,
    subject: string,
    field?: string,
    passThrough?: boolean
    children: ReactNode | ((allowed: boolean) => any)
    condition?: boolean
}

const Permission: FC<PermissionProps> = props => {
    const { action, subject, field, condition = true, passThrough = false, children } = props
    const auth = useAuth()

    const ability = auth?.user?.ability

    if (!ability) {
        return <CircularProgress />
    }

    if (passThrough && typeof children === "function") {
        return (
            <ErrorBoundary fallback={<div>Erro!</div>}>
                <Can do={action} on={subject} field={field} ability={ability} passThrough not={!condition}>
                    {allowed => children(allowed)}
                </Can>
            </ErrorBoundary>
        )
    }

    return (
        <ErrorBoundary fallback={<div>Erro!</div>}>
            <Can do={action} on={subject} field={field} ability={ability} not={!condition}>
                {children}
            </Can>
        </ErrorBoundary>
    )
}

export default Permission
