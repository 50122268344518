import * as Yup from "yup";
import { Task } from "../../generated/graphql";
import { getInitialValues, REQUIRED_FIELD } from "../../services/config";

const getSchema = () => {
    return Yup.object().shape({
        state: Yup.mixed().nullable().default(null),
        road: Yup.mixed().required().default(null),
        direction: Yup.mixed().required().default(null),
        startJunction: Yup.mixed().required().default(null),
        endJunction: Yup.mixed().required().default(null),
        startPk: Yup.string().pk().required().default(""),
        endPk: Yup.string().pk().required().default(""),
        reason: Yup.string().required().default(""),
        restriction: Yup.mixed().required().default(null),
        layout: Yup.mixed().required().default(null),
        interventionLocation: Yup.mixed().required().default(null),
        executingAuthority: Yup.string().required().default(""),
        executingAuthorityContact: Yup.string().required().default(""),
        signalingAuthority: Yup.string().required().default(""),
        signalingAuthorityContact: Yup.string().required().default(""),
        startAt: Yup.date().required().default(null).typeError(REQUIRED_FIELD),
        endAt: Yup.date().required().default(null).typeError(REQUIRED_FIELD),
        observations: Yup.string().nullable().default(""),
        incident: Yup.string()
            .nullable()
            .test(
                'incident-test',
                'Formato inválido, usar (0000-000000) e separar com ponto e vírgula (;)',
                value => {
                    if (!value) return true;  // Allow null
                    return value.split(';').every(
                        incident => incident.trim() === '' || !!incident.trim().match(/^\d{4}-\d{6}$/)
                    );
                }
            ),
        controlCenterObservations: Yup.string().nullable().default("")
    })
}

const taskFormInit = (task?: Partial<Task>) => {
    const schema = getSchema()
    const initial = task ? task : getInitialValues(schema)
    return { schema, initial }
}


export default taskFormInit
