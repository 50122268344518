import { FormikContextType, useFormikContext } from 'formik';

/**
* atualmente a mensagem de erro aparece mesmo
* que o campo nunca tenha sido tocado
* não é consistente com a interação que se pretende
* mas não é fácil definir quando é que este campo foi tocado
* o fieldText tem uma função semelhante a este hook
**/
export const useShowFieldError = (name: string) => {
    const context: FormikContextType<any> = useFormikContext()
    const error = context.errors[name] ? true : false
    let helperText = error ? context.errors[name] as string : ""
    return { error, helperText }
}

export default useShowFieldError
