import { ArrayParam, StringParam } from "use-query-params";
import { FilterConfig } from "../../hooks/useFilters";
import { searchExpFunc } from "../../utils/lookups";
import { DocumentScopes } from "../Datagrid/Helpers/PolicyToRoles";
import OrganizationDocumentsActions, { OrganizationDocumentsActionsProps, SelectedDocument, SelectedDocumentRow } from "../OrganizationDocumentsActions/OrganizationDocumentsActions";
import OrganizationDocumentsFilters from "../OrganizationDocumentsFilters/OrganizationDocumentsFilters";
import OrganizationDocumentsList from "../OrganizationDocumentsList/OrganizationDocumentsList";
import OwnSubcontractOrganizationDocumentProvider from "./OwnSubcontractOrganizationDocumentProvider";
import styles from './SubcontractOrganizationDocumentsView.module.scss';

interface SubcontractOrganizationDocumentsViewProps {
    id?: string
    onAttach?: (selection: SelectedDocument[]) => void
    beforeAttach?: (selection: SelectedDocumentRow[]) => void
    beforeExpiration?: (selection: SelectedDocumentRow[]) => void
    beforeCategorization?: (selection: SelectedDocumentRow[]) => void
    beforeDelete?: (selection: SelectedDocumentRow[]) => void
    organizationDocumentsActionsProps?: OrganizationDocumentsActionsProps
}

export default function SubcontractOrganizationDocumentsView(props: SubcontractOrganizationDocumentsViewProps) {

    const { id,
        onAttach,
        beforeAttach,
        beforeExpiration,
        beforeCategorization,
        beforeDelete,
        organizationDocumentsActionsProps = {} } = props
    const { showBackButton, showAttachAction = true } = organizationDocumentsActionsProps

    const filterConfig: FilterConfig[] = [
        { name: "concessionOrganizationDocumentCategory", variant: ArrayParam, defaultValue: [] },
        { name: "concessionOrganizationDocumentSubcategory", variant: ArrayParam, defaultValue: [] },
        { name: "documentState", variant: ArrayParam, defaultValue: [] },
        { name: "fileType", variant: ArrayParam, defaultValue: [] },
        { name: "name", variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc }
    ]

    const documentScopes = [DocumentScopes.SUBCONTRACT]

    return (
        <div className={styles.Wrapper}>
            <OwnSubcontractOrganizationDocumentProvider filterConfig={filterConfig}>
                <OrganizationDocumentsActions
                    concessionOrganizationId={id}
                    showAttachAction={showAttachAction}
                    showSubmitAction={false}
                    onAttach={onAttach}
                    beforeAttach={beforeAttach}
                    beforeExpiration={beforeExpiration}
                    beforeCategorization={beforeCategorization}
                    beforeDelete={beforeDelete}
                    modelField="ownSubcontractOrganizationDocuments"
                    showBackButton={showBackButton}
                    name="Documentos Entidade Subcontratada"
                />
                <OrganizationDocumentsFilters />
                <OrganizationDocumentsList documentScopes={documentScopes} />
            </OwnSubcontractOrganizationDocumentProvider>
        </div>
    )
}
