import { ReactNode } from 'react'
import styles from './ActionsBar.module.scss'

interface ActionsBarProps {
    children: ReactNode
    variant?: "LIST" | "DETAIL"
}

export default function ActionsBar(props: ActionsBarProps) {

    const { children, variant = "LIST" } = props

    const classes = variant === "LIST" ? styles.ActionsBarList : styles.ActionsBarDetail

    return (
        <div className={classes}>
            {children}
        </div >
    )
}
