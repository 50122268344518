import { useConcessionOrganizationAccreditationTypesQuery } from "../../generated/graphql";
import Config from "../../services/config";
import { RelationFieldProps } from "../../types/types";
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

export default function AccreditationTypeField(props: RelationFieldProps) {
    const {
        name = "accreditationType",
        label = "tipo acreditação",
        textFieldProps = { variant: "standard" },
        disabled,
        limit = 999
    } = props

    const organizationType = Config.SUBCONTRACT_ORGANIZATION_TYPE

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            query={useConcessionOrganizationAccreditationTypesQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                organizationType: { code: { eq: organizationType } }
            }}
        />
    )
}
