import { ApiErrorMap, AppErrors } from "../services/config";
import { APIGraphQLError } from "../types/types";

function codeToErrorMessage(code?: string) {
    if (!code) {
        return AppErrors.UNKNOWN_ERROR
    }
    return ApiErrorMap[code] || AppErrors.UNKNOWN_ERROR
}

export const errorParser = (e: unknown): string[] => {
    const graphQLErrors = (e as any).graphQLErrors as APIGraphQLError[] | undefined

    if (graphQLErrors) {
        const errorMessages: string[] = []
        graphQLErrors.map(graphQLError => {
            const code = graphQLError?.extensions?.exception?.code
            const message = codeToErrorMessage(code)
            errorMessages.push(message)
        })
        return errorMessages
    }

    if (e instanceof Error && e.message.includes("violates unique constraint")) {
        return ["Chave duplicada"]
    }

    if (e instanceof Error && e.message.includes("violates foreign key constraint")) {
        return ["Erro com chave estrangeira"]
    }

    return [AppErrors.UNKNOWN_ERROR]
}
