import * as Yup from "yup";
import { getInitialValues, requiredDate, requiredEndDate } from "../../services/config";

const getSchema = () => {
    const requiredDateSchema = requiredDate()
    return Yup.object().shape({
        description: Yup.string().required().default(""),
        startDate: requiredDateSchema,
        endDate: requiredEndDate("startDate"),
        accreditationType: Yup.mixed().required().default(null),
        concessionOrganization: Yup.mixed().required().default(null),
    })
}

export const subcontractFormInit = (subcontract?: any) => {
    const schema = getSchema()
    const initial = subcontract ? subcontract : getInitialValues(schema)
    return { schema, initial }
}
