import { translate } from "../../hooks/useTranslate"
import styles from './WorkPermitState.module.scss'
import { black, blue, gray, green, red, yellow } from '../../services/config';

interface WorkPermitStateProps {
    state?: string
    variant?: "standard" | "compact"
}

export const getWorkPermitStateColor = (state: string) => {
    const contractColors: { [key: string]: string } = {
        PREPARATION: gray,
        REVIEW: yellow,
        SUBMITTED: yellow,
        APPROVED: green,
        CANCELLED: red,
        EXPIRED: red,
        VALIDATED: blue,
    };

    return contractColors[state] || black
};

export default function WorkPermitState(props: WorkPermitStateProps) {
    const { state, variant = "standard" } = props
    if (!state) return null
    const backgroundColor = getWorkPermitStateColor(state)
    const classes = variant === "standard" ? styles.Standard : styles.Compact
    return (
        <div className={classes} style={{ backgroundColor }}>
            {translate(state, "capitalize")}</div>
    )
}
