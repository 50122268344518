import { Close } from "@mui/icons-material"
import { Card, CardContent, Fade, Typography } from "@mui/material"
import { FormikHelpers, FormikValues } from "formik"
import { useState } from "react"
import { Organization, useConcessionOrganizationUsersQuery } from "../../generated/graphql"
import useConcession from "../../hooks/useConcession"
import useToast from "../../hooks/useToast"
import useTranslate from "../../hooks/useTranslate"
import Config from "../../services/config"
import AssignUserForm from "../AssignUserForm/AssignUserForm"
import { getConcessionOrganizationByOrganizationTypeId } from "../ConcessionOrganizationRoles/ConcessionOrganizationRoles"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import CreateUserForm from "../CreateUserForm/CreateUserForm"
import styles from './ConcessionOrganizationUsers.module.scss'


interface ConcessionOrganizationUsersProps {
    title: string
    organizationTypeId: string
    organization: Partial<Organization>
    onDeleteConcessionOrganizationUser: any
    organizationStore: any
}

export type ConcessionOrganizationUsersMode = "FIND" | "CREATE"

export default function ConcessionOrganizationUsers(props: ConcessionOrganizationUsersProps) {
    const { organization, onDeleteConcessionOrganizationUser, organizationStore, title, organizationTypeId } = props
    const concession = useConcession()
    const concessionOrganization = getConcessionOrganizationByOrganizationTypeId(organization, concession, organizationTypeId)
    const { data, loading, refetch } = useConcessionOrganizationUsersQuery({ variables: { filter: { concessionOrganization: { id: { eq: concessionOrganization?.id } } } } })
    const t = useTranslate()
    const { errorToast } = useToast()

    const [mode, _setMode] = useState<ConcessionOrganizationUsersMode>("FIND")

    const setMode = (mode: ConcessionOrganizationUsersMode) => {
        _setMode(mode)
    }

    if (!concessionOrganization) return null

    const defineErrorMessage = (error: any) => {
        return error?.message?.startsWith("duplicate key value") ? "Não é possível adicionar a mesma função múltiplas vezes." : Config.APP_ERRORS.UNKNOWN_ERROR
    }

    const handleDelete = async (concessionOrganizationUser: any) => {
        const response = await onDeleteConcessionOrganizationUser(concessionOrganizationUser)
        if (!response?.errors?.length) refetch()
    }

    const handleCreateOrganizationUserRole = async (values: FormikValues, helpers: FormikHelpers<any>) => {
        const { concessionOrganizationUserRole, ...data } = values
        let response = await organizationStore?.user?.create({ variables: { input: { user: { ...data, defaultConcession: concession?.id } } } })
        if (!response?.errors?.length) {
            try {
                const user = response?.data?.createOneUser
                response = await organizationStore?.concessionOrganizationUser.create(
                    concessionOrganization?.id, user?.id, concessionOrganizationUserRole?.id)

                if (!response?.errors?.length) refetch()
                helpers.resetForm()
                setMode("FIND")
            } catch (e: any) {
                const message = defineErrorMessage(e)
                errorToast(message)
            }
        }
    }

    const handleAssignOrganizationUserRole = async (values: FormikValues, helpers: FormikHelpers<any>) => {
        const { concessionOrganizationUserRole, user } = values
        try {
            const response = await organizationStore?.concessionOrganizationUser.create(
                concessionOrganization?.id, user?.id, concessionOrganizationUserRole?.id)
            if (!response?.errors?.length) refetch()
            helpers.resetForm()
        } catch (e: any) {
            const message = defineErrorMessage(e)
            errorToast(message)
        }
    }

    return (
        <Fade in={!loading}>
            <Card className={styles.Main}>
                <CardContent>
                    <div>
                        <Typography variant="h5" color="primary">
                            {title}
                        </Typography>
                        <div className={styles.Roles}>
                            {data?.concessionOrganizationUsers?.nodes?.map(
                                concessionOrganizationUser => <p key={concessionOrganizationUser?.id} className={styles.Role}>
                                    {concessionOrganizationUser?.user?.name} | {t(concessionOrganizationUser?.role, "lowercase")}
                                    <ConfirmButton onClick={() => handleDelete(concessionOrganizationUser)} message="Tem a certeza que pretende remover colaborador?">
                                        <Close fontSize="small" />
                                    </ConfirmButton>
                                </p>
                            )}
                        </div>
                    </div>
                    {mode === "FIND" && <AssignUserForm onSubmit={handleAssignOrganizationUserRole} setMode={setMode} organizationTypeId={organizationTypeId} />}
                    {mode === "CREATE" && <CreateUserForm onSubmit={handleCreateOrganizationUserRole} setMode={setMode} organizationTypeId={organizationTypeId} />}
                </CardContent>
            </Card>
        </Fade>
    )
}
