import { GridColDef } from '@mui/x-data-grid-pro';
import { relationGetter } from '../../Datagrid/Datagrid';
import { ExecutingAuthorityRender } from '../../Datagrid/Renders/ExecutingAuthorityRender';
import { ExecutingOrganizationRender } from '../../Datagrid/Renders/ExecutingOrganization';
import { InterventionRender } from '../../Datagrid/Renders/InterventionRender';
import { SignalingAuthorityRender } from '../../Datagrid/Renders/SignalingAuthorityRender';
import { TaskNameRender } from '../../Datagrid/Renders/TaskNameRender';
import { TaskStateRender } from '../../Datagrid/Renders/TaskStateRender';
import { WorkPermitRender } from '../../Datagrid/Renders/WorkPermitRender';
import { dateTimeFormatter } from '../../FormatDate/FormatDate';

const getTaskColumns = (hide?: string[]): GridColDef[] => {
    const columns: GridColDef[] = [
        { field: "name", headerName: "nome", width: 140, renderCell: params => TaskNameRender(params), sortable: false, filterable: false },
        { field: "executingOrganization", headerName: "executante", width: 120, renderCell: ExecutingOrganizationRender, sortable: false, filterable: false },
        { field: "via", width: 80, valueGetter: params => relationGetter(params, "road"), sortable: false, filterable: false },
        { field: "direction", headerName: "sentido", valueGetter: params => relationGetter(params, "direction"), width: 80, sortable: false, filterable: false },
        { field: "interventionLocation", headerName: "localização", valueGetter: params => relationGetter(params, "interventionLocation"), width: 80, sortable: false, filterable: false },
        { field: "startJunction", headerName: "nó inicial", width: 150, valueGetter: params => relationGetter(params, "startJunction"), sortable: false, filterable: false },
        { field: "endJunction", headerName: "nó final", width: 150, valueGetter: params => relationGetter(params, "endJunction"), sortable: false, filterable: false },
        { field: "startPk", headerName: "pk inicial", width: 100, sortable: false, filterable: false },
        { field: "endPk", headerName: "pk final", width: 100, sortable: false, filterable: false },
        { field: "extension", headerName: "extensão", width: 100, sortable: false, filterable: false },
        { field: "reason", headerName: "motivo", width: 150, sortable: false, filterable: false },
        { field: "startAt", headerName: "início", width: 200, valueFormatter: dateTimeFormatter, sortable: false, filterable: false },
        { field: "endAt", headerName: "fim", width: 200, valueFormatter: dateTimeFormatter, sortable: false, filterable: false },
        { field: "restriction", headerName: "condicionalismo", valueGetter: params => relationGetter(params, "restriction"), width: 200, sortable: false, filterable: false },
        { field: "layout", headerName: "esquema", width: 150, valueGetter: params => relationGetter(params, "layout"), sortable: false, filterable: false },
        { field: "workPermit", headerName: "permissão", width: 150, renderCell: params => WorkPermitRender(params), sortable: false, filterable: false },
        { field: "state", headerName: "estado", width: 150, renderCell: TaskStateRender, filterable: false, sortable: false },
        { field: "incident", headerName: "ocorrência", width: 120, sortable: false, filterable: false, editable: false },
        { field: "observations", headerName: "observações", width: 150, sortable: false, filterable: false },
        { field: "controlCenterObservations", headerName: "comentário CCT", width: 150, sortable: false, filterable: false },
        { field: "executingAuthority", headerName: "responsável entidade", renderCell: params => ExecutingAuthorityRender(params), width: 200, sortable: false, filterable: false },
        { field: "signalingAuthority", headerName: "responsável sinalização", renderCell: params => SignalingAuthorityRender(params), width: 200, sortable: false, filterable: false },
        { field: "intervention", headerName: "intervenção", width: 150, renderCell: params => InterventionRender(params), sortable: false, filterable: false },
    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}


export default getTaskColumns
