export function range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function randomInt(min: number, max: number): number {
    // valores de min e max são inteiros
    min = Math.ceil(min);
    max = Math.floor(max);

    // número aleatório no intervalo [min, max]
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
