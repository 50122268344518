import { format } from 'date-fns';
import { jsPDF } from "jspdf";
import { Badge } from "../../generated/graphql";
import config from "../../services/config";
import { generateQRCode } from "../../services/qrcode";
import { formatStrGroups, staticFile } from "../../services/utils";
import { breakLines } from '../../utils/strings';
import { getContractExecutingOrganization } from "../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete";
import { ControlCentersSupport, MissingPDFRequiredInformation, PDFaddCustomFonts, PDFcolors, PDFMode } from "./PDF";

const prepareData = (workPermit: any, badge: Partial<Badge>) => {
    const executingOrganization = getContractExecutingOrganization(workPermit?.contract?.contractConcessionOrganizations)

    return {
        badge: {
            name: badge?.name,
            licensePlate: badge?.licensePlate,
            company: badge?.company
        },
        workPermit: {
            id: workPermit?.id,
            name: workPermit?.name,
            endAt: format(workPermit?.endAt, config.GIN_DATE_FORMAT),
        },
        contract: {
            name: workPermit?.contract?.name,
            description: workPermit?.contract?.description
        },
        concession: {
            name: executingOrganization?.concessionOrganization?.concession?.name,
            logo: executingOrganization?.concessionOrganization?.concession?.logo,
        },
        executingOrganization: {
            name: executingOrganization?.concessionOrganization?.organization?.name,
        },
        controlCenters: executingOrganization?.concessionOrganization?.concession?.controlCenters,

    }
}

const generateBadgePDF = (workPermit: any, badge: Partial<Badge>, mode: PDFMode = PDFMode.PRINT) => {

    if (!workPermit || !badge) {
        throw new MissingPDFRequiredInformation()
    }

    const data = prepareData(workPermit, badge)

    // solução temporária para o número variável de centros de controlo
    if (data?.controlCenters?.length !== 1) {
        throw new ControlCentersSupport()
    }

    const doc = new jsPDF({
        orientation: "landscape"
    });

    PDFaddCustomFonts(doc)

    doc.addImage(staticFile(data?.concession?.logo!), 'PNG', 189, 35, 59, 31)

    const qrcode = generateQRCode(`${config.APP_URL}${data?.workPermit?.id}`)
    doc.addImage(qrcode, 'PNG', 200, 122, 32, 32)


    // Semibold 9pt Color2
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(9)
    doc.setTextColor(PDFcolors.COLOR2)
    doc.text("Concessão", 33, 35)
    doc.text("Entidade Executante", 33, 133)
    doc.text("Empresa (proprietária viatura)", 33, 149)

    // Bold 12pt Color1
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(12)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text(data?.concession?.name!, 52, 35)

    doc.text(data?.contract?.name, 33, 42)

    doc.text(data?.executingOrganization?.name!, 33, 139)
    doc.text(data?.badge?.company!, 33, 155)
    doc.text(data?.workPermit?.endAt, 214, 111)

    // Bold 12pt Color5
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(12)
    doc.setTextColor(PDFcolors.COLOR5)

    // SemiBold 8pt Color1
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(8)
    doc.setTextColor(PDFcolors.COLOR1)
    breakLines(data?.contract?.description!, 70, 300).map((line, index) => doc.text(line, 33, 49 + index * 5))


    // SemiBold 9pt Color1
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(9)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("Dístico nº", 33, 80)
    doc.text("Matrícula", 33, 93)
    doc.text("Permissão Trabalho", 33, 107)
    doc.text("Validade", 199, 111)

    // Bold 14pt Color1
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(14)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text(data?.badge?.name!, 51, 80)
    doc.text(data?.badge?.licensePlate!, 50, 93)
    doc.text(data?.workPermit?.name!, 67, 107)

    // Bold 7pt Color4
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(7)
    doc.setTextColor(PDFcolors.COLOR4)
    doc.text("Contactos dos Centros de Controlo de Tráfego", 33, 180)
    doc.text("(ou através dos postos SOS)", 90, 180)
    doc.text("Nota:", 167, 180)

    // SemiBold 7pt Color3
    doc.setFont("OpenSans", "semi-bold")
    doc.setFontSize(7)
    doc.setTextColor(PDFcolors.COLOR3)
    // @ts-ignore
    doc.text(data?.controlCenters[0]?.name, 33, 184)
    // @ts-ignore
    doc.text(formatStrGroups(data?.controlCenters[0]?.phone), 50, 184)

    // Regular 7pt Color4
    doc.setFont("OpenSans", "regular")
    doc.setFontSize(7)
    doc.setTextColor(PDFcolors.COLOR4)
    doc.text("A atribuição desde dístico à empresa requerente representa um comprometimento", 175, 180)
    doc.text("desta, de transmitir a todo e qualquer condutor da viatura em questão, as regras de acesso,", 167, 185)
    doc.text("circulação e sinalização constantes no manual de segurança e circulação da " + data?.concession?.name + ".", 167, 190)


    // Elipse
    doc.setFillColor(PDFcolors.COLOR3)
    doc.roundedRect(172, 86, 93.5, 16, 4, 4, "F")

    // Bold 18 Color6
    doc.setFont("OpenSans", "bold")
    doc.setFontSize(18)
    doc.setTextColor(PDFcolors.COLOR6)
    doc.text("Viatura Autorizada", 188, 95)

    // regular 6pt Color1
    doc.setFont("OpenSans", "regular")
    doc.setFontSize(6)
    doc.setTextColor(PDFcolors.COLOR1)
    doc.text("mod. v1 - gin.meom.pt", 33, 195)

    if (mode === PDFMode.DOWNLOAD) {
        doc.save(`${badge?.name}.pdf`);
    } else {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    }
}

export default generateBadgePDF;
