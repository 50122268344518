import { Link } from 'react-router-dom';
import styles from '../Datagrid.module.scss';

const getContract = (params: any) => {
    if (params?.row?.__typename === "Intervention") {
        return params?.row?.workPermit?.contract
    }
    return params?.row?.contract
}

interface ContractRenderOptions {
    disableLink?: boolean
}

export const ContractRender = (params: any, options: ContractRenderOptions = {}) => {
    const { disableLink = false } = options
    const contract = getContract(params)
    const route = 'contract'

    if (disableLink) {
        return (
            <p>
                {contract.name}
            </p>
        )
    }

    return (
        <Link to={`/${route}/${contract.id}`} className={styles.Link}>
            {contract.name}
        </Link>
    )
}
