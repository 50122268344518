import { TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { ContractRoad, useContractRoadsQuery } from '../../generated/graphql';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

interface RoadFieldProps {
    textFieldProps?: TextFieldProps
    disabled?: boolean
    contract?: string
    name?: string
}

const RoadField: FC<RoadFieldProps> = props => {
    const {
        textFieldProps = { variant: "standard" },
        disabled,
        contract = null,
        name = "road"
    } = props

    const queryFilter = contract ? { contract: { id: { eq: contract } } } : { contract: { id: { eq: contract } } }
    const contractRef = useRef(contract);
    const context = useFormikContext()

    const getOptions = (collection: any) => {
        if (!collection) return []
        return collection?.contractRoads?.nodes?.map(
            (element: ContractRoad) => element?.road)
    }

    useEffect(() => {
        const previousContract = contractRef.current;
        if (previousContract && previousContract !== contract) {
            context?.setFieldValue(name, null)
        }
        contractRef.current = contract
    }, [contract])

    return (
        <SelectAutocompleteDataField
            name="road"
            label="Via"
            query={useContractRoadsQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryFilter={queryFilter}
            getOptions={getOptions}
        />
    )
}

export default RoadField
