import { DocumentStateDisplay } from "../../DocumentStateDisplay/DocumentStateDisplay"

export const DocumentStateRender = (params: any) => {
    return (
        <DocumentStateDisplay
            id={params?.row?.documentState?.id}
            name={params?.row?.documentState?.name}
        />
    )
}
