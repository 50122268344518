import styles from './TaskNameRender.module.scss'

export const TaskNameRender = (params: any) => {
    const name = params?.row?.name
    return (
        <span className={styles.TaskName}>
            {name}
        </span>
    )
}
