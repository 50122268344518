import { useCreateOneSubcontractMutation, useDeleteOneSubcontractMutation, useUpdateOneSubcontractMutation } from "../generated/graphql";
import { OperationResult, Subcontract } from "../types/types";
import { errorParser } from "../utils/errorParser";

export interface CreateSubcontractData {
    contractId: string
    description: string
    startDate: Date
    endDate: Date
    concessionOrganizationId: string
    accreditationTypeId: string
    mediated: boolean
}

export interface UpdateSubcontractData {
    id: string
    state: string
}

export interface SubcontractManager {
    createSubcontract: (data: CreateSubcontractData) => Promise<OperationResult<Subcontract>>
    updateSubcontract: (data: UpdateSubcontractData) => Promise<OperationResult<Subcontract>>
    deleteSubcontract: (subcontractId: string) => Promise<OperationResult<Subcontract>>
}

export interface ChangeToResult {
    success: boolean
    errors?: string[]
}

export default function useSubcontractManager(): SubcontractManager {

    const [_createSubcontract] = useCreateOneSubcontractMutation()
    const [_deleteSubcontract] = useDeleteOneSubcontractMutation()
    const [_updateSubcontract] = useUpdateOneSubcontractMutation()

    const deleteSubcontract = async (id: string): Promise<OperationResult<Subcontract>> => {
        try {
            const response = await _deleteSubcontract({ variables: { input: { id } } })
            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }
            return { success: true }
        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    const updateSubcontract = async (data: UpdateSubcontractData): Promise<OperationResult<Subcontract>> => {
        const { id, state } = data
        try {
            const response = await _updateSubcontract({
                variables: {
                    input: {
                        id,
                        update: {
                            contractConcessionOrganizationState: state
                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }

            return { success: true }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    const createSubcontract = async (data: CreateSubcontractData): Promise<OperationResult<Subcontract>> => {
        const { description, contractId, startDate, endDate, concessionOrganizationId, accreditationTypeId, mediated } = data
        try {
            const response = await _createSubcontract({
                variables: {
                    input: {
                        startDate,
                        endDate,
                        description,
                        contract: contractId,
                        concessionOrganization: concessionOrganizationId,
                        concessionOrganizationAccreditationType: accreditationTypeId,
                        mediated
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }

            return { success: true }

        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        createSubcontract,
        updateSubcontract,
        deleteSubcontract,
    }
}
