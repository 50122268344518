import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { WorkPermit } from "../../generated/graphql"
import { DeepPartial } from "../../types/types"
import Hide from "../Hide/Hide"
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton"
import Show from "../Show/Show"
import WorkPermitIsPublished from "../WorkPermitIsPublished/WorkPermitIsPublished"
import WorkPermitState from "../WorkPermitState/WorkPermitState"
import styles from './WorkPermitsOverview.module.scss'

interface WorkPermitsOverviewProps {
    workPermits?: DeepPartial<WorkPermit>[],
    current?: string
}
export default function WorkPermitsOverview(props: WorkPermitsOverviewProps) {
    const { workPermits, current } = props
    return (
        <div>
            <Show on={!!workPermits?.length}>
                <Grid container alignItems="center">
                    <Typography>O contrato selecionado tem {workPermits?.length} permissões</Typography>
                    <MoreInfoButton>
                        <TableContainer>
                            <Table style={{ minWidth: 400 }} aria-label="work permits overview">
                                <TableBody>
                                    {workPermits?.map(workPermit => {
                                        return (
                                            <TableRow key={workPermit?.id}>
                                                <TableCell>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item style={{ margin: 0 }}>
                                                            <Hide on={workPermit?.id === current}>
                                                                <Link to={`/work-permit/${workPermit?.id}`} className={styles.Name}>
                                                                    {workPermit.name}
                                                                </Link>
                                                            </Hide>
                                                            <Show on={workPermit?.id === current}>
                                                                {workPermit.name}
                                                            </Show>
                                                        </Grid>
                                                        <Grid item style={{ margin: 0 }}>
                                                            <Grid container alignItems="center">
                                                                <div style={{ marginRight: 20, marginBottom: -15 }}>
                                                                    <WorkPermitState state={workPermit.state} variant="compact" />
                                                                </div>
                                                                <div style={{ marginBottom: -15 }}>
                                                                    <WorkPermitIsPublished isPublished={workPermit.isPublished} />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ margin: 0 }}>
                                                        {workPermit.startAt} - {workPermit.endAt}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </MoreInfoButton>
                </Grid>
            </Show>
            <Hide on={!!workPermits?.length}>
                <Typography>O contrato selecionado ainda não tem permissões</Typography>
            </Hide>
        </div>
    )
}
