import { Grid, Typography } from '@mui/material';
import ActionsBar from '../ActionsBar/ActionsBar';
import styles from './SubcontractsActions.module.scss';

export default function SubcontractsActions() {
    return (
        <ActionsBar>
            <Grid container justifyContent="flex-between" alignItems="center" className={styles.Wrapper}>
                <Grid item xs={6} container alignContent="center">
                    <Typography variant="h3" color="primary">Subcontratos</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                </Grid>
            </Grid>
        </ActionsBar>
    )
}
