import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { UseFiltersOnChange } from '../../hooks/useFilters';

interface SelectBoolFilterProps {
    value: string[]
    onChange: UseFiltersOnChange
    label?: string
    field: string
    trueOptionName?: string
    falseOptionName?: string
    allOptionName?: string
    hideAllOption?: boolean
}

export default function SelectBoolFilter(props: SelectBoolFilterProps) {
    const {
        value,
        onChange,
        label,
        field,
        allOptionName = "TODOS",
        hideAllOption = false,
        trueOptionName = "True",
        falseOptionName = "False"
    } = props


    const inputProps = { disableUnderline: true }

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange(field, value as string)
    }

    const render = (selected: string[]) => {
        if (Array.isArray(selected)) {
            throw Error("Not Implemented")
        } else {
            return selected === "true" ? trueOptionName : falseOptionName
        }
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
            >
                {!hideAllOption && <MenuItem value="">{allOptionName}</MenuItem>}
                <MenuItem value="true">{trueOptionName}</MenuItem>
                <MenuItem value="false">{falseOptionName}</MenuItem>
            </Select>
        </FormControl >
    )
}
