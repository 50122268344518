import { Visibility } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useState } from "react"
import ContractPopover from "../ContractPopover/ContractPopover"

const ContractPreview = (props: any) => {
    const { contract } = props
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    return (
        <div>
            <IconButton onClick={(event: any) => setAnchor(event.currentTarget)} disabled={!contract} size="small">
                <Visibility />
            </IconButton>
            <ContractPopover anchor={anchor} setAnchor={setAnchor} contract={contract} />
        </div>
    )
}

export default ContractPreview
