import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ConcessionOrganizationUserRoles, Contract, useConcessionOrganizationUsersLazyQuery } from '../../generated/graphql';
import { translate } from '../../hooks/useTranslate';
import { ContractConcessionOrganizationUser } from '../../types/types';
import { ContractUserAutocomplete } from '../ContractUserAutocomplete/ContractUserAutocomplete';
import { matchOrganization } from '../ExternalAuthorityField/ExternalAuthorityField';

export default function ExternalAdministrativeField(props: ContractConcessionOrganizationUser) {
    const { disabled } = props
    const context = useFormikContext<Contract>()
    const [query, { data }] = useConcessionOrganizationUsersLazyQuery()

    const name = "contractUsers"
    const role = ConcessionOrganizationUserRoles.Externaladministrative
    const label = translate(ConcessionOrganizationUserRoles.Externaladministrative, "capitalize")
    const options = data?.concessionOrganizationUsers?.nodes || []

    const contractConcessionOrganizations = context?.values["contractConcessionOrganizations"]

    useEffect(() => {
        const id = matchOrganization(contractConcessionOrganizations)
        if (id) {
            query({ variables: { filter: { concessionOrganization: { id: { eq: id } }, role: { eq: role } } } })
        }
    }, [contractConcessionOrganizations, query, role])

    return (
        <div>
            <Field
                name={name}
                component={ContractUserAutocomplete}
                options={options}
                label={label}
                role={role}
                disabled={disabled}
            />
        </div>
    )
}
