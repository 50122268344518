import { GridColDef } from '@mui/x-data-grid-pro';
import { ContractRender } from '../../Datagrid/Renders/ContractRender';
import { detailsRender } from '../../Datagrid/Renders/DetailsRender';
import { PublishedRender } from '../../Datagrid/Renders/PublishedRender';
import { WorkPermitStateRender } from '../../Datagrid/Renders/WorkPermitStateRender';
import { dateFormatter } from '../../FormatDate/FormatDate';

export default function getWorkPermitColumns(hide?: string[]): GridColDef[] {

    const columns: GridColDef[] = [
        { field: "name", headerName: "nome", renderCell: params => detailsRender(params, "work-permit"), width: 150, filterable: false },
        { field: "state", headerName: "estado", width: 150, renderCell: WorkPermitStateRender, filterable: false, sortable: false },
        { field: "isPublished", headerName: "Publicada", renderCell: PublishedRender, width: 80, filterable: false },
        { field: "startAt", headerName: "Início", width: 120, valueGetter: dateFormatter, filterable: false },
        { field: "endAt", headerName: "Fim", width: 120, valueGetter: dateFormatter, filterable: false, editable: false },
        { field: "contract", headerName: "contrato", width: 150, renderCell: params => ContractRender(params), sortable: false, filterable: false },
    ]

    if (!hide?.length) return columns
    return columns.filter(column => !hide.includes(column.field))
}
