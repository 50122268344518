import { Concession } from "../generated/graphql"
import { useAuth } from "../services/auth"

export const useConcession = (): Partial<Concession> => {
    const auth = useAuth()
    // return localStorage.getItem("concession") || ""
    return auth?.user?.concession
}

export const useConcessionId = () => {
    const concession = useConcession()
    return concession?.id
}


export default useConcession
