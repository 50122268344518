import useParamId from "../../hooks/useParamId"
import SubcontractOrganizationDocumentsView from "../SubcontractOrganizationDocumentsView/SubcontractOrganizationDocumentsView"

export default function SubcontractOrganizationView() {

    const id = useParamId()

    return (
        <SubcontractOrganizationDocumentsView
            id={id}
            organizationDocumentsActionsProps={{ showAttachAction: false }}
        />
    )
}
