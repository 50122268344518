import { useVerificationScopesQuery } from '../../generated/graphql';
import { useConcession } from '../../hooks/useConcession';
import { RelationFieldProps } from '../../types/types';
import SelectAutocompleteDataField from '../SelectAutocompleteDataField/SelectAutocompleteDataField';

export default function VerificationScopeField(props: RelationFieldProps) {
    const {
        name = "verificationScope",
        label = "âmbito",
        textFieldProps = { variant: "standard" },
        autoFocus,
        disabled,
        limit = 999
    } = props

    const concession = useConcession()

    return (
        <SelectAutocompleteDataField
            name={name}
            label={label}
            field="abbreviation"
            query={useVerificationScopesQuery}
            textFieldProps={textFieldProps}
            autoFocus={autoFocus}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            queryFilter={{
                concession: {
                    id: {
                        eq: concession?.id
                    }
                }
            }}
            querySorting={[{ field: "abbreviation", direction: "ASC" }]}
        />
    )
}
