import { Grid, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { FC } from "react";
import styles from './MessageBoard.module.scss';

interface MessageBoardProps {
    content: string
    variant?: Variant
}

const MessageBoard: FC<MessageBoardProps> = props => {
    const { content, variant = "h3" } = props

    return (
        <Grid container justifyContent="space-around" id={styles.MessageBoard} component="aside">
            <Typography variant={variant} color="secondary">
                {content}
            </Typography>
        </Grid>
    )
}

export default MessageBoard
