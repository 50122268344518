import { ExpandMore, Schedule } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Popover, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import { EXPIRATION_REQUIRED } from "../../services/config"
import { Anchor, SetAnchor } from "../../types/types"
import Show from "../Show/Show"
import { DocumentInstructions } from "../ShowDocumentInstructions/ShowDocumentInstructions"
import styles from './DocumentInstructionsPopover.module.scss'

interface DocumentInstructionsPopoverProps {
    anchor: Anchor
    setAnchor: SetAnchor
    documents: DocumentInstructions
}

export default function DocumentInstructionsPopover(props: DocumentInstructionsPopoverProps) {
    const { anchor, setAnchor, documents } = props

    const popoverOpen = Boolean(anchor)
    const id = popoverOpen ? 'popover' : undefined

    return (
        <div>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.Wrapper}>
                    {
                        documents?.map((document: any) => (
                            <Accordion
                                sx={{
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                }}
                            >
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography variant="h5" color="secondary" className={styles.Category}>
                                        {document?.name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="50%">
                                                        <b>Subcategoria</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Observações</b>
                                                    </TableCell>
                                                </TableRow>
                                                {document.children.map((child: any) => (
                                                    <TableRow key={child?.id}>

                                                        <TableCell>
                                                            <Grid container alignItems="center">
                                                                {child?.name}
                                                                <Show on={child?.expirationRequired}>
                                                                    <Tooltip title={EXPIRATION_REQUIRED}>
                                                                        <Schedule fontSize="small" style={{ marginLeft: 10 }} />
                                                                    </Tooltip>
                                                                </Show>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {child?.observations}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </div>
            </Popover>
        </div >
    )
}
