import { useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery } from "../../generated/graphql";
import useToast from "../../hooks/useToast";
import ShowDocumentInstructions from "../ShowDocumentInstructions/ShowDocumentInstructions";

export default function OrganizationDocumentsInstructions() {
    const { data, error } = useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery()
    const { errorToast } = useToast()

    if (error) {
        errorToast(error.message)
    }

    return (
        <ShowDocumentInstructions documents={data?.concessionOrganizationDocumentCategories?.nodes} />
    )
}
