import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/auth';
import Config from '../../services/config';
import FieldText from '../FieldText/FieldText';
import styles from './Login.module.scss';

interface Credentials {
    email: string,
    password: string
}

interface PasswordVisibilityProps {
    fieldType: any
    setFieldType: any
}

export const PasswordVisibility: FC<PasswordVisibilityProps> = props => {
    const { fieldType, setFieldType } = props

    const toogle = () => {
        if (fieldType === "password") {
            setFieldType("text")
        } else {
            setFieldType("password")
        }
    }

    return (
        <IconButton onClick={toogle}>
            {fieldType === "password" ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
        </IconButton>
    )
}

const Login = () => {

    const { login } = useAuth()
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [fieldType, setFieldType] = useState<string>("password")
    const navigate = useNavigate()
    const location = useLocation()
    const initialValues: Credentials = { email: "", password: "" }

    const normalizeEmail = (email: string): string => {
        return email.trim().toLowerCase()
    }

    const handleSubmit = (values: Credentials) => {
        const email = normalizeEmail(values.email)
        return login(email, values.password.trim()).then((isSuccess: boolean) => {
            if (isSuccess) {
                // @ts-ignore
                const redirectTo = location?.state?.redirect
                const route = redirectTo ? redirectTo : Config.START_ROUTE
                navigate(route)
            } else {
                setErrorMessage("Erro ao iniciar sessão. Verifique as credenciais por favor.")
            }
        })
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ isSubmitting }) => (
                <Form className={styles.LoginForm}>
                    <Grid container direction="column" justifyContent="space-between" style={{ height: "100%" }}>

                        <Typography variant="h4" color="secondary">Iniciar Sessão</Typography>

                        <div>
                            <div className={styles.ErrorMessage}>
                                {errorMessage || <span>&nbsp;</span>}
                            </div>

                            <div className={styles.Field}>
                                <Field
                                    component={FieldText}
                                    label="Email"
                                    name="email"
                                    required
                                    fullWidth
                                    autoFocus
                                    autoComplete="current-username"
                                    InputProps={{
                                        startAdornment: <MailOutlined color="primary" />
                                    }}
                                />
                            </div>

                            <div className={styles.Field}>
                                <Field
                                    component={FieldText}
                                    label="Password"
                                    name="password"
                                    type={fieldType}
                                    required
                                    fullWidth
                                    autoComplete="current-password"
                                    InputProps={{
                                        startAdornment: <LockOutlined color="primary" />,
                                        endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                    }}
                                />
                            </div>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                                style={{ float: "right" }}
                            >
                                iniciar
                            </Button>
                        </div>

                        <Typography color="primary">
                            <Link to="/forgot-password">Esqueci-me da senha</Link>
                        </Typography>

                    </Grid>
                </Form>
            )}
        </Formik>
    )
};

export default Login;
