import { useUpdateOneConcessionOrganizationAccreditationMutation } from "../generated/graphql";
import { OperationResult } from "../types/types";
import { errorParser } from "../utils/errorParser";
import { Accreditation } from "./useAccreditationRecordManager";

export interface UpdateAccreditationData {
    id: string
    state?: string
    observations?: string
    startDate?: Date
    endDate?: Date
}

export interface AccreditationManager {
    updateAccreditation: (data: UpdateAccreditationData) => Promise<OperationResult<Accreditation>>
}

export default function useAccreditationManager(): AccreditationManager {

    const [_updateAccreditation] = useUpdateOneConcessionOrganizationAccreditationMutation()

    const updateAccreditation = async (data: UpdateAccreditationData): Promise<OperationResult<Accreditation>> => {
        const { id, state, observations, startDate, endDate } = data
        try {
            const response = await _updateAccreditation({
                variables: {
                    input: {
                        id,
                        update: {
                            concessionOrganizationAccreditationState: state,
                            observations,
                            startDate,
                            endDate

                        }
                    }
                }
            })

            if (response?.errors?.length) {
                const errors = errorParser(response?.errors)
                return { success: false, errors }
            }
            return { success: true }
        } catch (e: unknown) {
            const errors = errorParser(e)
            return { success: false, errors }
        }
    }

    return {
        updateAccreditation
    }
}
